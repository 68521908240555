import React, { useEffect } from 'react';
import { Wrapper, Background, Footer } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';

import PopupComponent from '../../../components/PopupContainer';
import MainLayout from '../header';


const DefaultLayout: React.FC = ({ children }) => {
  const { menu } = usePages();
  const { token, user, handleApiErrors, signOut } = useAuth();
  useEffect(() => {

    /* if (user && user.email !== 'guilherme@encontrodigital.com.br') {
      signOut();
      window.location.href = '/';
    } */

  }, [user])

  return (<MainLayout>
    <Wrapper>
      <Menu menuList={menu} fixedMenu={false} />
      {children}

      <PopupComponent />

    </Wrapper>

  </MainLayout>
  );
};

export default DefaultLayout;
