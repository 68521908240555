import styled, { keyframes } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  padding: 0px;
  background: #ddd;
  width: 100%;

  p,span,h3 {
    color: #fff;
  }


  .sidebar-title{
    background: rgb(151,151,167);
    color: #fff;
    width: 100%;
    padding: 10px;
    text-align: center;

  }

  .sidebar-subtitle{
    background: rgb(121,120,167);
    color: #fff;
    width: 100%;
    padding: 10px;
    text-align: center;

  }
  .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }
`;

export const Header = styled.div`
  width: 100%;
  background: none;
  color: #333;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    max-width: 400px;
    form {
      display: flex;

      > input {
        border: 0;
        padding: 20px;
        background: #eee;
        color: #333;
      }
      > button {
        border: 0;
        padding: 20px;
        background: rgb(0, 100, 150);
        color: #fff;
      }
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;

  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: auto;
    background: white;
    margin-top: 10px;

    tr {
      td,
      th {
        border: 1px solid #ddd;
        text-align: left;
        color: #333;
        padding: 5px;
      }
    }

    tr + tr {
      border-top: 1px solid #999;
    }
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  justify-content: center;
  > div, >a {
    margin: 10px;
    width: 200px;
    border-radius: 10px;
   color: #fff;
    display: flex;
    padding: 15px;
    align-items: center;
    background: rgb(0,100,50);
    justify-content: center;
    cursor: pointer;
    transition: 0.4s all;
    &:hover {
      color: rgb(46,250,50);
    }
    background: #000000;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    > svg {
      font-size: 60px;
    }
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
  > button {
    margin: 10px;
    width: 130px;
    height: 50px;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(0, 100, 150);
    color: #fff;
    justify-content: center;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.9;
    }

    > svg {
      font-size: 15px;
    }
  }
`;

export const SideTab = styled.div`
  width: 300px;
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  min-height: 100vh;

  z-index: 1000;


  >ul {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 200;
    background: #fff;
    animation : ${fadeFromRight} 1s;

    > li {
      width: 100%;
      padding: 5px;
      display: flex;
      align-items: center;

    >div.image {
      width: 10px;
      height: 10px;
      min-width: 10px;
      border-radius: 50%;
      background: rgb(0,200,0);
      color: #fff;
      margin-right: 10px;
    }

    >div.text {
      > h3{
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }

      > p{
        font-size: 14px;
        color: #444;
      }
    }
    }

    > li + li {
      border-top: 1px solid #ddd;
    }
  }

  }
`;

export const RoomReport = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #333;
  color: #fff;
  padding: 10px;
  width: 100%;
  border: 1px solid #666;

  > p {
    padding: 10px;
    font-size: 12px;
  }

  > p.p1 {
    min-width: 200px;
    max-width: 200px;
    overflow-wrap: break-word;
    font-size: 12px;
    @media (max-width: 1200px) {
      min-width: 150px;
      max-width: auto;
    }
  }
`;

export const RoomReportDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #333;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  color: #fff;
  padding: 0px;

`;

interface ISidebar {
  active : boolean;
}

export const Sidebar = styled.div<ISidebar>`
  display: flex;
  position: relative;
  top: 0px;
  left:0px;
  min-height: 100vh;
  
  transition: 0.5s all;
  z-index: 10000;
  
  justify-content: flex-start;
  align-items: flex-start;
  background: #333;
  width: 100%;
  max-width: 300px;
  flex-direction: column;
  color: #fff;
  padding: 0px;
  margin-left: ${props => props.active ? '0px' : '-300px'};


  .sidebarPrevButton{
    width: 100%;
    padding: 10px;
    background: #333;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
  }

  >div.menu {
    position: absolute;
    top: 30px;
    right: ${props => props.active ? '-20px' : '-50px'};
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #333;
    color: #fff;
    cursor: pointer;
    transition: 0.3s all;

    &:hover{
      background: rgb(0,250,50);
      color: rgb(0,100,0);
    }
    
  }

  >a, >nav{
    width: 100%;
    padding:5px 30px;
    background: none;
    color: #fff;
    border-bottom: 0px solid #555;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.5s all;
    >svg {
      width: 18px;
      font-size: 18px;
    }
    >p{
      padding: 10px;
      margin-left: 20px;
    }

    &:hover{
      color: rgb(0,250,50)
    
    }
  }
`;
