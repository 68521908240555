import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import { Error } from '../styles';
import ReactDatePicker from 'react-datepicker';
import { uuid } from 'uuidv4';
import { FaTrash } from 'react-icons/fa';
interface IListInput {
  label: string;
  name: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  defaultItem?: Array<Record<string, any>>;
  placeholder: string;
  implementRealTimeInputUpdate?: Function;
}

const JsonList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  implementRealTimeInputUpdate = (values) => { },
  placeholder,
  defaultItem,
  list,
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<Array<Record<string, any>>>(defaultValue || defaultItem || []);

  const addRow = () => {
    const newValue = [...value];

    const newLine = Object.keys(list).reduce((prev, key) => {
      prev[list[key].name] = (list[key].type === 'input' || list[key].type === 'select' || list[key].type === 'datePickerHour') ? '' : [];
      return prev;
    }, {});

    newValue.push(newLine);

    setValue([...newValue]);
  };

  const change = () => {

    setValue(state => {
      let newValue = [...state];

      newValue = implementRealTimeInputUpdate(newValue);

      return [...newValue];

    })


  }




  const addSubcategory = (index, key) => {

    const newValue = [...value];
    if (!newValue[index]?.[list?.[key]?.name]) {
      newValue[index][list[key]?.name] = [];
    }
    newValue[index]?.[list?.[key]?.name].push({ title: '', value: 0, reference: uuid() });

    setValue([...newValue]);
  }

  const changeSubcategory = (e, index, key, subIndex, column) => {


    const newValue = [...value];


    if (!newValue[index]?.[list?.[key]?.name]?.[subIndex]) {
      newValue[index][list[key].name][subIndex] = { title: '', value: 0 };
    }

    newValue[index][list[key].name][subIndex][column] = e.target.value;
    setValue([...newValue]);
  }


  const removeItem = (index) => {

    const newItem: Array<Record<string, any>> = [];

    value.map((item, indexItem) => {
      if (indexItem !== index) {
        newItem.push(item);
      }
    });

    setValue([...newItem]);


  }


  const printLine = (name, index, list) => {
    const inputs: Array<JSX.Element> = [];

    Object.keys(list).map(key => {
      inputs.push(

        <td>

          {list?.[key]?.type && list?.[key]?.type === 'select' && list?.[key]?.options &&
            <select
              onChange={e => {
                const newValue = [...value];

                if (!newValue[index]) {
                  newValue[index] = {};
                }
                if (!newValue[index]) {
                  newValue[index][list[key].name] = '';
                }

                newValue[index][list[key].name] = e.target.value;
                setValue([...newValue]);
              }}
              name={`${name}_${index}`}>
              <option value="" selected={!value?.[index]?.[list?.[key]?.name]}>Selecionar </option>
              {list?.[key]?.options && list?.[key]?.options.map(l => {
                return <option value={l.value} selected={value?.[index]?.[list?.[key]?.name] === l.value}>{l.text} </option>
              })}
            </select>}
          {list?.[key]?.type && list?.[key]?.type === 'input' &&
            <input
              key={`${name}_${index}_${list[key].name}`}

              placeholder={list[key].label}
              defaultValue={
                value && value[index] && value[index][list[key].name]
                  ? value[index][list[key].name]
                  : ''
              }
              onChange={e => {
                const newValue = [...value];

                if (!newValue[index]) {
                  newValue[index] = {};
                }
                if (!newValue[index]) {
                  newValue[index][list[key].name] = '';
                }

                newValue[index][list[key].name] = e.target.value;
                setValue([...newValue]);
              }}
              name={`${name}_${index}`}
            />}

          {list?.[key]?.type && list?.[key]?.type === 'datePickerHour' &&
            <ReactDatePicker
              locale="pt"
              showTimeSelect
              timeFormat="p"
              timeIntervals={10}
              dateFormat="Pp"
              selected={value && value[index] && value[index][list[key].name]
                ? value[index][list[key].name]
                : ''}
              value={value && value[index] && value[index][list[key].name]
                ? value[index][list[key].name]
                : ''}

              onChange={date => {
                const newValue = [...value];

                if (!newValue[index]) {
                  newValue[index] = {};
                }
                if (!newValue[index]) {
                  newValue[index][list[key].name] = '';
                }

                newValue[index][list[key].name] = date;
                setValue([...newValue]);

                change();

              }}
              name={`${name}_${index}`}
            />}

          {list?.[key]?.type && list?.[key]?.type === 'example' && <>

            <button type="button" onClick={() => addSubcategory(index, key)}>Adicionar Subcategoria</button>

            {value?.[index]?.subcategories ? value?.[index]?.subcategories.map(
              (sub, subindex) => {

                return <div key={sub.reference} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <input onChange={(e) => changeSubcategory(e, index, key, subindex, 'title')} value={value?.[index]?.subcategories[subindex]?.title} placeholder='Subcategoria' name={'title'} />
                  <input onChange={(e) => changeSubcategory(e, index, key, subindex, 'value')} value={value?.[index]?.subcategories[subindex]?.value} placeholder="valor" name={'value'} />
                </div>

              }
            ) : <></>}

          </>}

        </td>,
      );
    });

    return <tr key={`content_${index}`}>{inputs}<td>       <FaTrash onClick={() => removeItem(index)} size={20} /></td></tr>;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
    });
  }, [fieldName, registerField]);

  console.log(list);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h2>{placeholder}</h2>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />
      <button type="button" onClick={() => addRow()}>
        Adicionar
      </button>
      <table><tbody>
        <tr>
          {list ? Object?.keys(list)?.map(key => {
            return <th key={`head-${key}`}>{list[key].label}</th>
          }) : <></>}
        </tr>
        {value.map((line, index) => (

          printLine(name, index, list)


        ))}
      </tbody>
      </table>
    </div>
  );
};

export default JsonList;
