import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container} from './styles';
import {Error } from '../styles';
interface IListInput {
  label: string;
  name: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  placeholder: string;
}

const JsonList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  list,
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);

  const [value, setValue] = useState(defaultValue || {});

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
    });
  }, [fieldName, registerField]);

  return ( 
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />
      {list.map(listInput => {
        return (
          <Container
            key={listInput.name}
            {...rest}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocus}
          >
            <span>{listInput.label}</span>
            {Icon && <Icon />}
            <input
              placeholder={listInput.label}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={
                defaultValue && defaultValue[listInput.name]
                  ? defaultValue[listInput.name]
                  : ''
              }
              onChange={e => {
                const valueJson = {
                  ...value,
                  [listInput.name]: e.target.value,
                };
                setValue({ ...valueJson });
              }}
              name={listInput.name}
            />
            {error && (
              <Error title={error}>
                <FiAlertCircle />
              </Error>
            )}
          </Container>
        );
      })}
    </div>
  );
};

export default JsonList;
