import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { dateLanguage, dateSimple, simpleDateUS } from '../../../../utils/date';
import { addHours } from 'date-fns';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import downloadit, { downloaditPost } from '../../../../utils/download';
import { apiUrl } from '../../../../config';
import { useToast } from '../../../../hooks/Toast';
import { date } from 'yup';
import Loader from '../../core/components/Loader';
import { FaChartBar, FaChartPie, FaChevronCircleDown, FaChevronCircleUp, FaChevronRight, FaEdit, FaFileCsv, FaFileExcel, FaInfo, FaSpinner } from 'react-icons/fa';
import ChartBar from '../../core/components/Charts/Bar';
import PieChart from '../../core/components/Charts/Pie';
import { useConfiguration } from '../../../../hooks/Configuration';
import Table from '../../../../styles/components/table';


interface IGroup {
  search: Array<Record<string, any>>;
  data: Array<Record<string, any>>;
  where?: Record<string, any>;
  prefix?: string;
}


const getGroup = ({ data, search, where = {}, prefix = '' }: IGroup) => {

  const keys = Object.keys(where);


  let newData = data;
  /* Make a filter on data */
  keys.map(key => {

    newData = newData.filter(d => d[key] === where[key]);
  })

  const report: Array<IResume> = [];

  /* Searchable without ignored fields */
  search.map(s => {

    report.push({
      title: `${prefix}${s.label}`,
      column: s.ref, /* column name */
      data: []
    })


  })

  newData.map(filterDataLine => {


    report.map((item, index) => {

      const columnName = filterDataLine[item.column] ? filterDataLine[item.column].trim().toUpperCase() : 'Não informado';

      const indexSubItem = report[index].data.findIndex(elem => elem?.title === columnName);



      if (indexSubItem >= 0) {
        report[index].data[indexSubItem].count = report[index].data[indexSubItem].count + 1;
        report[index].data[indexSubItem].value = report[index].data[indexSubItem]?.value ? (report[index]?.data[indexSubItem]?.value || 0) + 1 : 1;
      }
      else {
        report[index].data.push({ column: item?.column, id: columnName, title: columnName, label: columnName, value: 1, count: 1 })
      }



    });


  })





  return report;

}


interface IDetails {
  title: string;
  column?: string;
  id?: string;
  count: number;
  label?: string;
  value?: number;
}


interface IResume {
  data: Array<IDetails>;
  title: string;
  column: string;
  setCallback?: Function;
  active?: boolean;
}

interface IResumeList {
  data: Array<IResume>
}

const ReadResumeList: React.FC<IResumeList> = ({ data }) => {



  const [currentResume, setCurrentResume] = useState('');



  return <>
    {data?.length > 0 ? data.map(resume => <ReadResume column={resume.column} title={resume.title} data={resume.data} active={resume.title === currentResume ? true : false} setCallback={(value) => setCurrentResume(value)} />) : <></>}
  </>

}

const ReadResume: React.FC<IResume> = ({ title = '', data, active = false, setCallback = (value) => { } }) => {

  const [type, setType] = useState('details');

  const convertToArrayAndExport = ({ data, type = 'xlsx', title }) => {
    data.sort((a, b) => a?.count < b?.count ? 1 : a?.count > b?.count ? -1 : 0);
    const content = [[title, 'Quantidade']];

    data.map(item => {
      content.push([item.title, item.count || 0])
    })



    return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

  }

  return <>

    <div className='list-template-dark'>
      <div className='list-template-date'>{title}</div>
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <div className='list-template-quantity' style={{ width: '70px' }}>{data.length || '0'}</div>


        <div className='list-template-quantity' style={{ cursor: 'pointer' }}>
          <FaInfo onClick={() => { setCallback(title); setType('details') }} size={20} />
          <FaFileExcel size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'xlsx' })} />
          <FaFileCsv size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'csv' })} />
          {/*<FaChartBar onClick={() =>   { setType('chartBar'); setCallback(title)}} size={20}/> */}
          <FaChartPie onClick={() => { setType('chartPie'); setCallback(title) }} size={20} />{active ? <FaChevronCircleUp onClick={() => setCallback(title)} size={20} /> : <FaChevronCircleDown onClick={() => setCallback(title)} size={20} />}</div>
      </div>

    </div>
    <div style={{ display: active ? 'flex' : 'none', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ReadDetails type={type} data={data} />

    </div>

  </>



}



interface IPropsReadDetails {
  data: Array<IDetails>;
  type: string;
}


const ReadDetails: React.FC<IPropsReadDetails> = ({ data, type = 'details' }) => {

  /* type can be "Details" or "Chart" */


  const [contentList, setContentList] = useState<Array<IDetails>>([]);
  const [content, setContent] = useState<Array<JSX.Element>>([]);

  const getContent = {
    details: (dataDetails: Array<IDetails>) => {
      return <table style={{ width: '100%' }}>
        {dataDetails.map((d, index) => {

          return <tr key={`${d.title}-${d.count}-${index}`} className='list-template'>
            <td className='list-template-date'>{d.title}</td>
            <td className='list-template-quantity'>{d.count || '0'}</td>
          </tr>

        })}
      </table>;




    },
    chartBar: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', minWidth: `${dataDetails.length * 100}px`, height: '50vh' }}><ChartBar indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    },
    chartPie: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', height: '50vh', padding: '20px 0px', overflowY: 'auto' }}><PieChart indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    }

  }


  useEffect(() => {

    const newList = [...data];

    newList.sort((a, b) => {
      if (a?.count < b?.count) { return 1; }
      else if (a?.count > b?.count) { return -1; }
      else { return 0; }

    })

    setContentList(newList);

  }, [data]);




  return getContent?.[type] ? getContent?.[type](contentList) : <> - </>;



}



interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IProps {
  hash?: string;
}

const ResumoSalesGlobal: React.FC<IProps> = ({ hash = '' }) => {

  const [project, setProject] = useState<Record<string, any>>({});
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [currentReport, setCurrentReport] = useState('');

  const { projectHash = hash, eventHash } = useParams<IParams>();

  const [blockDetail, setBlockDetail] = useState<Array<Record<string, any>>>([]);

  const [report, setReport] = useState<Array<IResume>>([]);
  const [showReport, setShowReport] = useState(1);
  return <Container>
    <Table table={'Sale'} project_id={project?.url} component_id="sales" endpoint="sales" orderBy="createdAt" orderDirection="asc"
      resources={
        {
          search: 'yes',
          pagination: 'yes',
          head_modules: 'yes',
          filters: 'yes',
        }
      }

      header={[
        { title: 'Projeto', column: 'project_id', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
        { title: 'Produto', column: 'product_id', includeColumn: 'title', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
        { title: 'Cliente', column: 'client_id', includeColumn: 'name', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
        { title: 'Forma de Pagamento', column: 'payment_method_id', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
        { title: 'Categoria', column: 'category', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
        { title: 'Área', column: 'area', insert: 'yes', update: 'yes', search: 'no', export: 'yes' },
        { title: 'Subárea', column: 'subarea', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
        { title: 'Campos adicionais', column: 'fields_text', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },

        { title: 'Status de Pag', column: 'payment_status_id', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },

        { title: 'Parcelamento', column: 'installment', insert: 'yes', update: 'yes', search: 'no', export: 'yes' },
      ]} />
  </Container>

};

export default ResumoSalesGlobal;
