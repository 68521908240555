import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Container, Content, Background, AnimatedDiv2 } from './styles';

import PosterComponent from '../../components/PosterComponent';
import { useSocket } from '../../hooks/Socket';

interface PosterData {
  email: string;
  password: string;
  name: string;
}

const Poster: React.FC = () => {
  const { socket } = useSocket();

  useEffect(() => {
    const ping = setInterval(() => {
      socket.emit('SetAttendance', {
        room: 87,
      });
    }, 120000);

    return () => clearInterval(ping);
  }, []);

  useEffect(() => {
    socket.emit('SetAttendance', {
      room: 87,
    });
  }, []);

  return (
    <Container>
      <PosterComponent />
    </Container>
  );
};
export default Poster;
