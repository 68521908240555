/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import pagarme from 'pagarme';
import { type } from 'os';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../services/api';
import QRCode from 'qrcode.react';
import Button from '../../../../components/Button';


import { eventName, gateKey, urlWebsite } from '../../../../config';
import imgBoleto from '../../../../assets/barcode.png';

import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';

import Whatsapp from '../../../../components/Whatsapp';

import { ActiveButton, BuyContext } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';

import UpdateUserContainer from '../../../../components/UserUpdateComponent';
import { useAuth } from '../../../../hooks/Auth';
import { useLanguage } from '../../../../hooks/Language';
import { FaCreditCard, FaInfoCircle, FaReceipt } from 'react-icons/fa';
import AtualizarCadastroContainer from '../AtualizarCadastro';
import { useConfiguration } from '../../../../hooks/Configuration';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { ProductsContent } from '../Products/styles';
import AskSpecialTreatment from '../../../DashboardUserPages/Pages/Home/modules/AskSpecialTreatment';
import IsentionPage from '../../../DashboardUserPages/Pages/IsentionPage';

interface IComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface ICardProps {
  id?: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_number: string;
  card_cvv: string;
  installments?: number;
  document_type: string;
}

interface IInstallment {
  label: string;
  value: string;
}

interface IParams {
  payment_hash: string;
  projectHash: string;
}
declare var window: any;
const PaymentConfirmation: React.FC = () => {

  const [buyContext, setBuyContext] = useState<Record<string, any>>({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const [productNotFound, setProductNotFound] = useState(false);

  const { payment_hash, projectHash } = useParams<IParams>();

  const { addToast } = useToast();
  const { addModal } = useModal();
  const { eventHash } = useConfiguration();
  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

  const { translate, language } = useLanguage();
  const { configuration } = useConfiguration();
  const { user } = useAuth();


  const askSpecialTreatment = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Atendimento especial',
      key: 'specialTreatment',
      content: <AskSpecialTreatment projectHash={projectHash} />

    })

  }


  const askIsention = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: '',
      key: 'isention',
      content: <IsentionPage hash={configuration?.url || ''} />

    })

  }


  const load = async (hash, recheck = false) => {

    if (user) {

      try {
        const paymentInfo = await api.get(`/verify-sale/${payment_hash}`);


        if (window?.fbq) {
          window.fbq('track', 'CompletePayment');
        }

        if (recheck) {
          if (paymentInfo?.data?.payment_status_id !== 'paid') {
            if (paymentInfo?.data?.payment_method_id === 'pix') {
              setTimeout(() => { load(hash, true); }, 5000)

            }
            return;
          }
        }

        if (!paymentInfo) {
          setProductNotFound(true);
          return;
        }

        const { payment_status_id, payment_method_id, boleto_url, category_id, hash_link, ticket, quantity } = paymentInfo.data;


        const readAfterProducts = (product) => {

          if (!product.after_products) { return <></> }
          if (product.after_products.length < 1) {
            return <></>
          }


          return <><h2 style={{ marginTop: '15px', fontSize: '16px' }}>Aproveite e adquira:</h2>
            {product.after_products.map(item => {

              return <div className='productBlock' style={{ display: 'flex', minHeight: '70px', border: '2px solid #333', borderRadius: '10px', margin: '5px', padding: '0px', alignItems: 'center', justifyContent: 'space-between', width: '700px', maxWidth: '100%' }}>
                <aside style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', background: 'none', alignItems: 'center' }}>
                  <aside style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <h2 className='productTitle'  >{item?.[`title_${language}`] ? item?.[`title_${language}`] : item.title}

                    </h2>


                  </aside>
                  <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', gap: '10px' }}>

                    <aside style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: '10px', width: '100%' }}>

                      <button onClick={() => { window.location.href = `/app/${projectHash}/checkout/${item.hash_link}`; }} className='defaultButtonMiniRed'>
                        Comprar
                      </button>

                    </aside>
                    <aside style={{ display: 'flex', minWidth: '115px', padding: '5px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>

                      <button onClick={() => window.location.href = `/app/${projectHash}/checkout/${item.hash_link}`}
                        className="defaultButtonMini" style={{ marginBottom: '0px', minWidth: '130px' }}

                        type="button"
                      >
                        {`R$ `} {format(item.price)}


                      </button>


                      {language !== 'ptBr' ? <p style={{ fontSize: '12px', color: '#333', marginTop: '5px' }}>{translate("Aprox.")}: $ {parseInt(((item.price / 100) / 5.2).toString(), 10)}</p> : <></>}
                    </aside>


                    <FaInfoCircle title={translate('+ informações')} style={{ minWidth: '30px', color: 'rgb(0,15,64)' }} size={25} onClick={() => {
                      addModal({ theme: 'whiteModalMedium', title: '', content: <><h2 style={{ fontSize: '18px', marginBottom: '15px', color: '#333' }}>{translate('Benefícios')}</h2><p style={{ fontSize: '16px', lineHeight: '24px', padding: '15px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: item?.[`description_${language}`] ? item[`description_${language}`] : item.description }} /></> })
                    }} />
                  </aside>

                </aside>

              </div>

            })
            }

          </>

        }

        if (payment_status_id === 'verificating') {
          setResponseMessage({
            icon: () => <FiMail />,
            active: true,
            title: translate('Seu pagamento está em análise!'),
            type: 'info',
            description: (
              <>
                {translate('Em breve você receberá um e-mail confirmando sua compra. ')}<br /><br />{translate('Caso ocorra algum problema, nossa equipe entrará em contato com você')}
                <br />
                <br />
                {translate('Para acompanhar seus pagamentos, acesse o dashboard')}
                <Link style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/dashboard` : `/app/${projectHash}/dashboard`}>
                  <Button type="button">{translate('Dashboard')} </Button>
                </Link>

                {configuration?.allow_special_treatment === 'yes' ? <> <button className='defaultButtonGreen' style={{ maxWidth: '100%', width: '100%', margin: '0px', border: '0px', marginTop: '10px', boxShadow: 'none', padding: '15px' }} onClick={() => { askSpecialTreatment() }}>Solicitar atendimento especial</button>
                  <br /></> : <></>}

                {configuration?.allow_isention_recurse === 'yes' ? <button onClick={() => { askIsention() }} style={{ margin: '0px', border: '0px', boxShadow: '0px', maxWidth: '100%', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar isenção de pagamento</button> : <></>}




              </>
            ),
          });

        }
        else if (payment_method_id === 'boleto' && payment_status_id === 'waiting') {
          setResponseMessage({
            icon: () => <FiMail />,
            active: true,
            title: translate('Inscrição realizada com sucesso!'),
            type: 'success',
            description: (
              <>
                <br />       {configuration?.allow_special_treatment === 'yes' ? <> <button className='defaultButtonGreen' style={{ maxWidth: '100%', width: '100%', margin: '0px', border: '0px', marginTop: '10px', boxShadow: 'none', padding: '15px' }} onClick={() => { askSpecialTreatment() }}>Solicitar atendimento especial</button>
                  <br /></> : <></>}

                {configuration?.allow_isention_recurse === 'yes' ? <button onClick={() => { askIsention() }} style={{ margin: '0px', border: '0px', boxShadow: '0px', maxWidth: '100%', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar isenção de pagamento</button> : <></>}

                {translate('Acesse o Dashboard para acompanhar seu pagamento')}
                <Link style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/dashboard` : `/app/${projectHash}/dashboard`}>
                  <Button type="button">{translate('Dashboard')} </Button>
                </Link><br />

                {translate('Clique no botão abaixo para acessar o boleto.')}
                <br />
                <a href={boleto_url} title="boleto" style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }}>
                  <Button type="button">{translate('Boleto')} </Button>
                </a>
                <br />
                {translate('Ou no link abaixo')}:
                <br />
                <a href={boleto_url} title={translate('boleto')} style={{ wordWrap: 'break-word', marginTop: '20px', color: '#333' }}>
                  <strong>{boleto_url}</strong>
                </a>
                <br />

              </>
            ),
          });
        }

        else if (payment_method_id === 'pix' && payment_status_id === 'waiting') {
          setResponseMessage({
            icon: () => <FiMail />,
            active: true,
            title: translate(`Obrigado por ${paymentInfo?.data?.product_id_response?.any_price === 'yes' ? 'doar' : 'se inscrever'}`),
            type: 'success',
            description: (
              <>
                <br />
                {configuration?.allow_special_treatment === 'yes' ? <> <button className='defaultButtonGreen' style={{ maxWidth: '100%', width: '100%', margin: '0px', border: '0px', marginTop: '10px', boxShadow: 'none', padding: '15px' }} onClick={() => { askSpecialTreatment() }}>Solicitar atendimento especial</button>
                  <br /></> : <></>}

                {configuration?.allow_isention_recurse === 'yes' ? <button onClick={() => { askIsention() }} style={{ margin: '0px', border: '0px', boxShadow: '0px', maxWidth: '100%', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar isenção de pagamento</button> : <></>}

                {translate('Acesse o Dashboard para acompanhar seu pagamento')}
                <Link style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/dashboard` : `/app/${projectHash}/dashboard`}>
                  <Button type="button">{translate('Dashboard')} </Button>
                </Link><br />

                {translate('Leia o QRCode abaixo para efetuar seu pagamento via pix')}
                <br />
                <QRCode style={{ width: '250px', height: '200px', marginTop: '20px' }}
                  renderAs="svg"
                  value={paymentInfo.data.pix_qr_code}
                />

                <br />
                {translate('Ou utilize o botão abaixo para copiar o código pix:')}: <button className='defaultButton'><CopyToClipboard message={translate('Clique para copiar')} text={paymentInfo.data.pix_qr_code} /></button>


                <br />

              </>
            ),
          });
        }

        else {
          setResponseMessage({
            icon: () => <FiMail />,
            active: true,
            title: translate(ticket ? translate('Pagamento realizado com sucesso!') : translate('Pagamento realizado com sucesso!')),
            type: 'success',
            description: (
              <>
                {translate(ticket ? ' ' : translate('Seu pagamento foi confirmado!'))}
                <br />
                {configuration?.allow_special_treatment === 'yes' ? <> <button className='defaultButtonGreen' style={{ maxWidth: '100%', width: '100%', margin: '0px', border: '0px', marginTop: '10px', boxShadow: 'none', padding: '15px' }} onClick={() => { askSpecialTreatment() }}>Solicitar atendimento especial</button>
                  <br /></> : <></>}

                {configuration?.allow_isention_recurse === 'yes' ? <button onClick={() => { askIsention() }} style={{ margin: '0px', border: '0px', boxShadow: '0px', maxWidth: '100%', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar isenção de pagamento</button> : <></>}

                <aside style={{ display: 'flex', padding: '10px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }} >
                  {readAfterProducts(paymentInfo?.data?.product_id_response)}
                </aside>

                {configuration?.current_event_id_response?.product_success ?
                  < aside className='productSuccess' style={{ display: 'flex', color: '#333', padding: '10px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.[`product_success_${language}`] ? configuration.current_event_id_response[`product_success_${language}`] : configuration.current_event_id_response[`product_success`] }} />
                  : <></>}

                {translate('Para acompanhar seus pagamentos, acesse o dashboard')}
                < Link style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/dashboard` : `/app/${projectHash}/dashboard`
                }>
                  <Button type="button">{translate('Dashboard')} </Button>
                </Link >





              </>
            ),
          });

        }

        setLoading(false);

        setReady(true);

        if (payment_method_id === 'pix' && payment_status_id === 'waiting') {
          setTimeout(() => { load(hash, true); }, 5000)

        }

      } catch (err) {

        setLoading(false);
        setProductNotFound(true);
        addToast({ type: "error", title: translate("Erro ao identificar compra.") });
        return;
      }




    }
  }

  useEffect(() => {

    if (!payment_hash) {
      window.location.href = '/planos';
      return;
    }

    if (configuration) {

      load(payment_hash);
    }
    setLoading(false);

  }, [payment_hash, configuration])






  if (!user) {
    return <div className="onlineMessage" style={{ display: 'flex', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>

      <h2 className="defaultMarked"><div />{translate('Falha ao completar ação')}</h2>
      <p style={{ color: '#555', textAlign: 'center', marginTop: '20px' }}>
        {translate(
          'Você precisa estar logado para finalizar a incrição.',
        )}
      </p><br /><br />
      <Link to="/planos">
        <button className="shadowGreyButton" type="button">
          {' '}
          {translate('Voltar')}
        </button>
      </Link>
    </div>
  }

  if (productNotFound) {
    return <div className="onlineMessage" style={{ display: 'flex', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>

      <h2 className="defaultMarked">{translate('Erro ao identificar seu pagamento')}</h2>
      <p style={{ color: '#555', textAlign: 'center', marginTop: '20px' }}>
        {translate(
          'Tente recarregar a página, ou entre em contato com a organização.',
        )}
      </p><br /><br />
      <Link to="/planos">
        <button className="shadowGreyButton" type="button">
          {' '}
          {translate('Voltar')}
        </button>
      </Link>
    </div>
  }

  return (
    <>
      {loading && <Loader message={`${translate('Carregando')}...`} />}
      {ready && <AnimatedDiv style={{ flexDirection: 'column', alignItems: 'center' }} visible={true}>
        <aside style={{ width: '100%', maxWidth: '350px', margin: '10px auto' }}>
          <ActiveButton step={4} />

        </aside>
        {responseMessage.active === true && (

          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={<>{responseMessage.description}
            </>}
          />
        )}

      </AnimatedDiv>}
    </>
  );
};

export default PaymentConfirmation;
