import React, { Dispatch, useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
  FiPhone,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/Auth';
import Input from '../Forms/Input';
import MaskedInput from '../Forms/MaskedInput';
import { useToast } from '../../hooks/Toast';
import { AnimatedDiv } from './styles';
import { LoginContent } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';

import Button from '../Button';

import api from '../../services/api';
import { FaArrowLeft } from 'react-icons/fa';
import { useModal } from '../../hooks/Modal';
import { useLanguage } from '../../hooks/Language';





interface LoginProps {

  company: string; 
  phone: string; 
  position: string;
  
  document_number:string;
  birth_date: string;
  company_document_number: string;
}

interface IUpdate {
  phone : string;
  document_number : string;
  company: string;
  position: string;
  birth_date : Date;
}

const LoginContainer: React.FC = () => {
  const { user,token } = useAuth();
  const { addToast } = useToast();
  const {  removeModal} = useModal();
  const formRef = useRef<FormHandles>(null);
  const {translate} = useLanguage();
  const [defaultValue, setDefaultValue] = useState<IUpdate>({} as IUpdate);
  const [reloadKey, setReloadKey] = useState('formUpdate');
   

useEffect( () => {

  const load = async() => {
    if(!user || !token){
      return false;
    }
  const response = await api.get(`student-info`);

  if(response){

 

    setDefaultValue({...response.data, birth_date : response.data.birth_date.toString()
      .replace(/[^0-9]/g, '')});
    setReloadKey(`d${new Date().getMilliseconds()}`);
  }

  }
load();
},[])

if(!user || !token){
  return <></>;
}




  const handleNewLogin = 
    async (data: LoginProps) => {
      try {
        formRef.current?.setErrors({});
     
        data.phone = data.phone.toString().replace(/[^0-9]/g, '');
        data.birth_date.toString()
        .replace(/[^0-9/]/g, '');
        data.document_number.toString()
        .replace(/[^0-9]/g, '');


 
  
        const { company, phone, position, 
          document_number,
        company_document_number,
          birth_date } = data;

        const newUser = await api
          .post(`/updateUser`, {  company, phone, position,
            document_number : document_number.toString()
            .replace(/[^0-9]/g, ''),
            company_document_number,
          
            birth_date })
          

          addToast({
            type: 'success',
            title: 'Atualizado com sucesso!',
            description: '',
          });

          removeModal('userUpdateForm');
     
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          console.log(err);
          formRef.current?.setErrors(errors);
        }
console.log(err);
        addToast({
          type: 'error',
          title: 'Erro na validação',
          description: err?.response?.data?.message || 'Ocorreu um erro ao tentar validar',
        });
      }
    };

  return (
    <AnimatedDiv>
      <LoginContent>
        <div>   <aside>
  

            <Form key={reloadKey} ref={formRef} initialData={defaultValue} onSubmit={handleNewLogin}>
              <h1 style={{color: '#333'}}>{translate('Atualizar Cadastro')}</h1>

              <Input icon={FiMail} name="company" placeholder="Empresa" />
              { user.country === 'BR' ? <MaskedInput

                      mask="99.999.999/9999-99"
                      name="company_document_number"
                      placeholder={`${translate('CNPJ da Empresa / Entidade')}`}
                    /> : 
                    <Input name="company_document_number" 
                placeholder={`${translate('Nº de identificação da Empresa / Entidade') }`}/>}

              <Input icon={FiMail} name="position" placeholder="Cargo" />
              {user && user.country === 'BR' ? <MaskedInput mask="(99) 99999.9999" icon={FiPhone} name="phone" placeholder="Telefone" /> : <Input icon={FiPhone} name="phone" placeholder="Telefone" />}
      
           {user && user.country === 'BR' ?  <MaskedInput
              mask={'999.999.999-99'}
              name="document_number"
              placeholder={'CPF'}
            /> :  <Input   
            name="document_number"
            placeholder={'CPF'}
          />}
    
            <MaskedInput
              mask="99/99/9999"
              name="birth_date"
              placeholder="Data de nascimento (DD/MM/AAAA)"
            />
             
             <aside style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              <button style={{maxWidth: '200px'}} className='defaultButton' type="submit">
               {translate('Atualizar')}

              
              </button>
              </aside>
            </Form>
          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );
};

export default LoginContainer;
