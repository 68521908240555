import React, { useState, createContext, useContext, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { loadFromJson } from '../../services/loadFromJson';


import { IHeader, IFilter, ISearch, IDateFilter, IOrder, IFilterFunctionOptions, ISearchProps, IFilterOptions } from "./interfaces";


export interface ISearchContextData {
    applyApiSearchFull: Function;
    applySearch: Function;
    applyFilter: Function;
    applyDateFilter: Function;
    applyOrder: Function;
    applyLimit: Function;
    changePage: Function;
    getSearchParams: Function;
    updateHeader: Function;
    reloadSearchFilter: Function;
    headerData: IHeader[];
    countPages: number;
    count: number;
    filteredData: Array<Record<string, any>>;
    filterOptions: {
        search: string;
        filters: Array<IFilter>;
        limitItems: number;
        order: IOrder;
        dateFilter?: Array<IDateFilter>;
        project_id?: string;
    },



}


function useSearch({ data = [], header = [], filters = [], search = '', order = { orderBy: 'createdAt', direction: 'desc' }, limit = 20, endpoint = '', staticEndpoint = '', project_id = '', table = '' }: ISearchProps): ISearchContextData {

    const [headerData, setHeaderData] = useState(header);
    const [bodyData, setBodyData] = useState(data);

    const [endpointValue, setEndpointValue] = useState(endpoint);
    const [staticEndpointValue, setStaticEndpointValue] = useState(staticEndpoint);

    const [filteredData, setFilteredData] = useState<Array<Record<string, any>>>([]);
    const [count, setCount] = useState<number>(0);

    const [filterOptions, setFilterOptions] = useState<IFilterOptions>({
        search: search,
        order: order,
        filters: filters,
        limitItems: limit,
        limit,
        page: 1,
        dateFilters: []
    })



    const countPages = (count % filterOptions?.limitItems) > 0 ? (((count - (count % filterOptions?.limitItems)) / filterOptions?.limitItems)) + 1 : (count / filterOptions?.limitItems) < 1 ? 1 : (count / filterOptions?.limitItems);



    const changePage = (page: number) => {

        console.log('pagina', page)
        let newFilters = { ...filterOptions };

        newFilters.page = page;

        setFilterOptions(newFilters)
        reloadSearchFilter(newFilters);


    }

    const updateHeader = (headerOptions: IHeader[]) => {

        setHeaderData(headerOptions)

    }

    const applyFilter = (filter: IFilter) => {

        let newFilters = { ...filterOptions };

        const index = newFilters.filters?.findIndex(item => item.column === filter.column && item.group === filter.group);

        if (index >= 0) {
            newFilters.filters = newFilters.filters.filter(item => item.column !== filter.column && item.group !== filter.group);
        }
        else {


            newFilters.filters = newFilters.filters.filter(item => item.group !== filter.group);

            newFilters.filters.push(filter);
        }

        newFilters.page = 1;

        setFilterOptions(newFilters)
        reloadSearchFilter(newFilters);


    };

    const applyDateFilter = (filter: IDateFilter[]) => {

        let newFilters = { ...filterOptions };

        newFilters.dateFilters = filter;

        newFilters.page = 1;

        setFilterOptions(newFilters)
        reloadSearchFilter(newFilters);


    };
    const applySearch = (text: string) => {

        const newFilterOptions = { ...filterOptions };

        newFilterOptions.search = text;
        newFilterOptions.page = 1;
        setFilterOptions(newFilterOptions)
        reloadSearchFilter(newFilterOptions);

    };
    const applyOrder = (orderBy: string) => {

        const newFilterOptions = { ...filterOptions };

        if (newFilterOptions?.order?.orderBy === orderBy) {
            newFilterOptions.order.direction = newFilterOptions?.order?.direction === 'asc' ? 'desc' : 'asc';
        }
        else {
            newFilterOptions.order = {
                orderBy: orderBy,
                direction: 'asc'
            }
        }




        setFilterOptions(newFilterOptions)
        reloadSearchFilter(newFilterOptions);

    };
    const applyLimit = (limitValue: number) => { };

    const searchData = (headerContent: Array<IHeader>, text: string, data: Array<Record<string, any>>) => {

        const newData: Array<Record<string, any>> = [];
        const searchText = text.toLowerCase();

        data?.map(item => {

            let string = '';

            if (headerContent?.length > 0) {
                headerContent?.map(head => {
                    if (item?.[head?.column]) {
                        string = `${string} ${item?.[head?.column]?.toString()?.toLowerCase()}`;
                    }
                })
            }


            if (string.indexOf(searchText) >= 0) {
                newData.push(item);
            }

        })
        return newData;
    }


    const filterData = (filters: Array<IFilter>, data: Array<Record<string, any>>) => {

        let newData: Array<Record<string, any>> = [...data];

        filters.map(filterItem => {
            newData = newData.filter(item => item?.[filterItem?.column] === filterItem?.value);
        })

        return newData;
    }


    const filterDateData = (filters: Array<IDateFilter>, data: Array<Record<string, any>>) => {

        let newData: Array<Record<string, any>> = [...data];

        filters.map(filterItem => {
            newData = newData.filter(item => {

                if (!item?.[filterItem?.column]) { return false; }
                if (filterItem.type === 'from') {
                    return new Date(item?.[filterItem?.column]) >= filterItem?.value;
                }
                if (filterItem.type === 'to') {
                    return new Date(item?.[filterItem?.column]) < filterItem?.value;
                }
                return false;

            });
        })

        return newData;
    }


    const applySimpleFilter = (options: IFilterOptions) => {
        let newData: Array<Record<string, any>> = bodyData ? [...bodyData] : [];

        if (options?.filters?.length > 0) {
            newData = [...filterData(filters, newData)];
        }


        if (options?.dateFilters && options?.dateFilters?.length > 0) {
            newData = [...filterDateData(options?.dateFilters, newData)];
        }

        if (options?.search?.length > 0) {
            newData = [...searchData(headerData, options.search, newData)];
        }

        if (filterOptions?.order?.orderBy) {
            newData.sort((a, b) => {
                return options?.order?.direction === 'desc' ? (a?.[options?.order?.orderBy] || '') > (b?.[options?.order?.orderBy] || '') ? 1 : (a?.[options?.order?.orderBy] || '') < (b?.[options?.order?.orderBy] || '') ? -1 : 0 : (a?.[options?.order?.orderBy] || '') < (b?.[options?.order?.orderBy] || '') ? 1 : (a?.[options?.order?.orderBy] || '') > (b?.[options?.order?.orderBy] || '') ? -1 : 0;
            })
        }

        setFilteredData(newData);
        setCount(newData?.length);
    }

    const getSearchParams = (options: IFilterOptions) => {

        const params: Record<string, any> = {
            order: options?.order,
            limitItems: options?.limitItems || 20,
            search: options?.search || '',
            page: options?.page || 1,
            where: {},
            limit: options?.limitItems || 0
        }

        const where: Record<string, any> = {};

        const whereFilters: Array<Record<string, any>> = [];

        options?.filters?.map(filterItem => {
            whereFilters.push({ [filterItem?.column]: filterItem?.value });
        })

        options?.dateFilters?.map(dateFilter => {

            if (dateFilter.type === 'from') {
                whereFilters.push({ [dateFilter.column]: { '$gte': dateFilter.value } });
            }

            if (dateFilter.type === 'to') {
                whereFilters.push({ [dateFilter.column]: { '$lt': dateFilter.value } });
            }


        })

        if (project_id) {
            whereFilters.push({ project_id })
        }

        params.where = whereFilters?.length > 0 ? { "$and": [...whereFilters] } : {};;

        return params;
    }

    const applyApiSearch = async (options: IFilterOptions) => {

        const params = getSearchParams(options);


        const response = await api.get(endpointValue, { params });

        const data = response?.data?.rows ? response.data.rows : response.data;

        setFilteredData(data);
        setCount(response?.data?.count ? response.data.count : response.data.length);

    }


    const convertExportData = (header: IHeader[], groups: Array<string>) => {

        const headerExport: Record<string, any> = {}

        header?.map(head => {
            if (groups.findIndex(g => g === head.column) >= 0) {
                headerExport[head.column] = { label: head.title, ref: head.column, column: head.column, search: true, includeColumn: head?.includeColumn || '' };
            }
        })

        return headerExport;

    }


    const applyApiSearchColumn = async (params: Record<string, any>, column: string) => {

        const response = await api.get(`/report-by-column/${table}/${column}`, { params });

        const data = response?.data?.rows ? response.data.rows : response.data;

        return data;


    }

    const applyApiSearchLine = async (params: Record<string, any>) => {

        const response = await api.get(`/report-by-time/${table}`, { params });

        const data = response?.data?.rows ? response.data.rows : response.data;

        return data;


    }

    const applyApiSearchFull = async (options: IFilterOptions, column: string, type = 'column') => {

        const params = getSearchParams(options);


        let data: Array<Record<string, any>> = [];

        if (type === 'column') {
            data = await applyApiSearchColumn(params, column)
        }

        if (type === 'time') {
            data = await applyApiSearchLine(params);
        }

        return data;


    }




    const loadFromStaticApiSearch = async (options: IFilterOptions) => {


        const newData = await loadFromJson({ endpoint: staticEndpointValue });

        if (newData) {

            setBodyData(newData);

        }
        else {
            if (endpoint) {
                await applyApiSearch(options);
            }
        }



    }

    const firstLoad = async () => {
        if (staticEndpoint) {
            await loadFromStaticApiSearch(filterOptions);
        }
        else if (endpoint) {
            await applyApiSearch(filterOptions)
        }
        else {


            setFilteredData([...data])
            setCount(data?.length)
        }
    }

    useEffect(() => {


        firstLoad()

    }, [])

    const reloadSearchFilter = (options: IFilterOptions) => {
        if (staticEndpoint) {
            applySimpleFilter(options)
        }
        else if (endpoint) {
            applyApiSearch(options);
        }
        else {
            applySimpleFilter(options)
        }
    }



    return { applyApiSearchFull, applyFilter, applyDateFilter, applyLimit, reloadSearchFilter, applyOrder, applySearch, filteredData, filterOptions, count, countPages, changePage, getSearchParams, headerData, updateHeader };
}

export { useSearch };
