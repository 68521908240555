/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useCallback, useEffect } from 'react';

import ReactSelect from 'react-select';
import { useField, FormHandles } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles';
import { Error } from '../styles';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface Props {
  name: string;
  label: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  options: Array<IOptions>;
  isMulti?: boolean;
  style?: Record<string, any>;
  index?: number;
  defaultValueItem?: string;
  mandatory?: string;
}


const SelectSimple: React.FC<Props> = ({
  name,
  label,
  options,
  formRef,
  mandatory = 'no',
  isMulti = false,
  style = {},
  index = 100,
  defaultValueItem = '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCallback = (value) => { },
  ...rest
}) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState<IOptions>();



  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  useEffect(() => {
    if (!value) {
      setValue(
        defaultValue &&
        options?.find(
          option => option.value.toString() === defaultValue.toString(),
        ),
      );
    }
  }, [defaultValue, options]);





  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
    setIsFilled(!!value);
  }, [value]);


  //* * Usefull function to call external functions */
  useEffect(() => {
    if (value && value.value) {
      setCallback(value.value, formRef);
    }
  }, [value, setCallback]);


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return value?.value || '';
      },

      setValue: (ref, valueUF) => {
        const validValue = options?.find(option => {
          return option.value === valueUF;
        });

        if (validValue) {
          ref.state.value = validValue;
          setValue(validValue);
        }
      },
    });
  }, [fieldName, registerField, isMulti, value]);

  const activeCallback = (content) => {

    setCallback(content);


  }

  return (
    <Container style={{ ...style, zIndex: 20000 - index }} isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <span>{label} {mandatory === 'yes' ? <span className='mandatoryInfo'  >* Obrigatório</span> : <></>}</span>
      <ReactSelect
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        options={options}
        onChange={selectedValue => {
          if (selectedValue) {
            setValue(selectedValue);
          }

          activeCallback(selectedValue?.value);
        }}

        value={value}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default SelectSimple;
