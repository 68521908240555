import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAvaliateCurriculum as avaliateCurriculum } from './modules/avaliateCurriculum';

import { useAvaliateCurriculum as visualizateCurriculum } from './modules/visualizateCurriculum';

import { useAvaliateCurriculum as visualizateCurriculumRecurse } from './modules/visualizateCurriculumRecurse';
import IframePlayer from '../../../../components/IframePlayer';
import Whatsapp from '../../core/components/Whatsapp';
import { useModal } from '../../../../hooks/Modal';
import { useAuth } from '../../../../hooks/Auth';
import Loader from '../../core/components/Loader';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const Dashboard: React.FC = () => {
  const endpoint = '/selective-process-curriculum';
  const title = 'Avaliação currícular';
  const { projectHash } = useParams<Record<string, any>>()
  const { eventHash } = useConfiguration();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    test_grade: { ref: 'test_grade', column: 'test_grade', label: 'Nota na prova', show: false },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Participante',
    },
    avaliator_id: {
      ref: 'avaliator_id',
      column: 'avaliator_id_response',
      includeColumn: 'name',
      label: 'Avaliador', show: false
    },
    status: { ref: 'status', column: 'status', label: 'Status', show: false },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    area: { ref: 'area', column: 'area', label: 'Área' },
    subarea: { ref: 'subarea', column: 'subarea', label: 'Subárea' },

    photo: { ref: 'photo', column: 'photo', label: 'Foto', show: false },
    dress_type: { ref: 'dress_type', column: 'dress_type', label: 'Gênero do uniforme', show: false },
    dress_size: { ref: 'dress_size', column: 'dress_size', label: 'Tamanho do uniforme', show: false },

    subscription: { ref: 'subscription', column: 'subscription', label: 'Matrícula', show: false },
    vaccines: { ref: 'vaccines', column: 'vaccines', label: 'Vacinas', show: false },
    curriculum: { ref: 'curriculum', column: 'curriculum', label: 'Currículo', show: false },

    curriculum_points: { ref: 'curriculum_points', column: 'curriculum_points', label: 'Pontuação do Currículo', show: true },
    curriculum_avaliation: { ref: 'curriculum_avaliation', column: 'curriculum_avaliation', label: 'Avaliação do Currículo', show: false },
    curriculum_status: { ref: 'curriculum_status', column: 'curriculum_status', label: 'Currículo avalido?', show: false },

    curriculum_recurse_status: { ref: 'curriculum_recurse_status', column: 'curriculum_recurse_status', label: 'Solicitou Recurso?', show: true },
    curriculum_recurse_documents: { ref: 'curriculum_recurse_documents', column: 'curriculum_recurse_documents', label: 'Documentos do Recurso', show: false },
    curriculum_recurse_justification: { ref: 'curriculum_recurse_justification', column: 'curriculum_recurse_justification', label: 'Justificativa do Recurso', show: false },

    curriculum_recurse_avaliation_status: { ref: 'curriculum_recurse_avaliation_status', column: 'curriculum_recurse_avaliation_status', label: 'Recurso foi aprovado?', show: false },
    curriculum_recurse_avaliation_finished: { ref: 'curriculum_recurse_avaliation_finished', column: 'curriculum_recurse_avaliation_finished', label: 'Recurso foi avaliado?', show: false },
    curriculum_recurse_avaliation_justification: { ref: 'curriculum_recurse_avaliation_justification', column: 'curriculum_recurse_avaliation_justification', label: 'Justificativa do avaliador do Recurso', show: false },


    cancelledAt: {
      ref: 'cancelledAt',
      column: 'cancelledAt',
      label: 'Data de Cancelamento',
      mask: 'date', show: false
    },

    reservedAt: {
      ref: 'reservedAt',
      column: 'reservedAt',
      label: 'Data de Reserva',
      mask: 'date', show: false
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date', show: false
    },

  };

  useEffect(() => {

    if (user?.project_id && projectHash && projectHash !== user.project_id) {
      window.location.href = `/app/${user?.project_id}/avaliacao-curricular`;
    }

  }, [projectHash])

  const formSchema = {

    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },

    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',

      options: [
        { label: 'Aguardando envio de documentação', value: 'waiting' },
        { label: 'Aguardando avaliação da documentação', value: 'waiting_curriculum' },
        { label: 'Aguardando matrícula', value: 'register' },
        { label: 'Desclassificado', value: 'disqualified' },

      ]

    },


  };

  const formSchemaUpdate = {

    photo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'photo',
      label: 'photo',
    },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {

  };
  const [loading, setLoading] = useState(false);


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        avaliateCurriculum({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        visualizateCurriculum({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        visualizateCurriculumRecurse({
          lineData,
          loading,
          setLoading

        }),
    ],
  };

  const configRecurse = { ...config };
  configRecurse.endpoint = '/selective-process-curriculum/recurse';

  const [stage, setStage] = useState('home')
  const { addModal } = useModal();
  const { user } = useAuth();
  useEffect(() => {

    if (user && (user?.profile === 1 || user?.profile === 12) && projectHash === 'residencia-medica-ufcspa-2022') {
      addModal({
        title: '', content: <>
          <h2 style={{ color: '#333', fontSize: '24px', maxWidth: '600px', margin: '10px' }}>Observações do edital</h2>
          <p style={{ color: '#333', maxWidth: '600px', margin: '10px' }}>
            a) O candidato deverá providenciar todos os documentos que pretende apresentar para pontuação. A lista de itens/parâmetros fruto de pontuação encontra-se estabelecida no ANEXO V - BAREMA ANÁLISE CURRICULAR do Edital de Abertura das Inscrições, não sendo
            pontuados itens que não constem no respectivo item;
            <br /><br />b) Realizar a digitalização dos documentos, de forma legível e completa (frente e verso, caso houver), em imagem ou PDF, a fim de
            permitir a análise das informações e dos documentos apresentados com clareza;
            <br /><br />c) Os arquivos devem ser anexados nos respectivos itens/parâmetros estabelecidos no BAREMA. Arquivos anexados em campo
            incorreto/diverso serão indeferidos e terão a pontuação zerada. É responsabilidade exclusiva do candidato conferir a correta inclusão
            da documentação na plataforma.
            <br /><br />d) Durante o período de recebimento da documentação, o candidato poderá realizar alterações na documentação incluída, sendo de sua exclusiva responsabilidade as alterações realizadas.
            <br /><br />e) Após anexar os arquivos, o candidato deve clicar em SALVAR para que os documentos sejam efetivamente salvos, sendo de sua
            exclusiva responsabilidade garantir o atendimento desse requisito.
            <br />
            <br /><br />3.1. A Análise Curricular consistirá de avaliação e pontuação da documentação comprobatória entregue pelo candidato. Não serão
            avaliados documentos que não atenderem a forma, o meio de entrega e o período estabelecidos no edital de convocação.
            <br /><br />3.2. Os candidatos convocados que deixarem de apresentar o currículo estarão automaticamente eliminados do processo seletivo.
            <br /><br />3.3. Cada título será considerado e avaliado uma única vez, vedada a cumulatividade de pontos.
            <br /><br />3.4. Apresentar os documentos de forma completa, permitindo a adequada aferição da pontuação, é de responsabilidade do candidato. A avaliação será realizada estritamente com base na documentação apresentada. A Comissão de Avaliação não fará deduções ou pesquisas de caráter complementar.
            <br /><br />3.5. Os candidatos devem entregar apenas os documentos necessários para a devida comprovação da pontuação. Documentos entregues sem necessidade, de forma desorganizada ou que não estiverem corretamente anexados poderão implicar a invalidação da documentação e/ou a não pontuação do respectivo item, conforme o caso, e terão a respectiva pontuação, zerada.
            <br /><br />3.6. Documentos que apresentarem dados imprecisos, incoerentes ou incompletos, comprometendo a aferição da pontuação correta
            segundo os critérios previstos neste edital, serão invalidados, e a respectiva pontuação, zerada.
          </p>

        </>,
        theme: 'whiteModal'

      })
    }

  }, [user])

  if (!user || (user?.profile !== 1 && user?.profile !== 12)) {
    return <h2 style={{ color: '#333', fontSize: '24px', maxWidth: '600px', margin: '10px' }}>Acesso negado</h2>
  }

  return (<>
    {loading ? <Loader message='Carregando' /> : <></>}
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: '800px' }}>
      <button className='defaultButton' style={{ padding: '10px', margin: '10px' }} onClick={() => setStage('home')}>Home</button>
      <button className='defaultButton' style={{ padding: '10px', margin: '10px' }} onClick={() => setStage('avaliation')}>Minhas avaliações</button>
      <button className='defaultButton' style={{ padding: '10px', margin: '10px' }} onClick={() => setStage('avaliation_recurse')}>Avaliar Recursos</button>

    </div>

    <Whatsapp message='Suporte Técnico' text={`Olá, sou um avaliador #${projectHash}`} />
    <Container>


      {stage === 'home' ?
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', maxWidth: '800px', margin: '25px auto', padding: '25px' }}>

          <h2 style={{ color: '#333', fontSize: '24px' }}>Seja bem-vindo(a) avaliador(a).</h2><br />

          <p style={{ color: '#333', marginBottom: '25px' }}>Para ajudá-lo no processo de avaliação, criamos um breve vídeo explicativo de como funciona o processo de avaliação currícular nesta plataforma.<br />
            <br /> Após assistir o vídeo, clique no botão "Minhas avaliações" para iniciar.
          </p>

          { /* <h2 style={{ color: '#333', marginBottom: '10px', }}>Instruções para recursos</h2>
          <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/8d5ec12d-c459-46ed-9d8b-b41bb690b98e' /> */}
          <h2 style={{ marginTop: '20px', marginBottom: '10px', color: '#333' }}>Avaliação de currículos</h2>
          <IframePlayer allow='fullscreen' id='https://iframe.mediadelivery.net/embed/77638/252da0ff-bb35-492b-ad8d-0d6ae3d8def8' />

        </div>
        : <></>}
      {stage === 'avaliation' ? <SearchComponent {...config} /> : <></>}
      {stage === 'avaliation_recurse' ? <SearchComponent {...configRecurse} /> : <></>}
    </Container>
  </>
  );
};

export default Dashboard;
