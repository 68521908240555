import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { ISearchContextData } from "../../../hooks/SearchHook";
import { FaChartLine, FaFileExcel, FaFilter, FaTable } from "react-icons/fa";
import DateFilterBar from "../DateFilterBar";
import FilterBar from "../FilterBar";
import { downloaditPost } from "../../../utils/download";
import { apiUrl } from "../../../config";
import { FiLayout } from "react-icons/fi";

interface IData {
    value: number;
    name: string;
    simpleName?: string;
}

interface IChart {
    title?: string;
    subtitle?: string;
    data: Array<Record<string, any>>;
    category?: string;
    group?: string;
    hook: ISearchContextData,
    table?: string;
}

interface IConverter {
    data: IData[];
    type: string;
    title: string;
}

const ChartLine: React.FC<IChart> = ({ hook, category = '', group = '', data = [], table = '', title = '', subtitle = '' }) => {

    const [renderData, setRenderData] = useState<IData[]>([])
    const [groupColumn, setGroupColumn] = useState(group);
    const [visibility, setVisibility] = useState('chart');
    const [type, setType] = useState('single');
    const [count, setCount] = useState(0);

    const convertToArrayAndExport = ({ data, type = 'xlsx', title }: IConverter) => {

        const content = [[title, 'Quantidade']];

        let cumulated = 0;

        data.map(item => {

            cumulated = cumulated + item?.value;
            const value = type === 'single' ? (item?.value?.toString() || '0') : cumulated?.toString();

            content.push([item.simpleName || '', value])
        })



        return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

    }


    const changeGroup = (value: string) => {
        setGroupColumn(value);
    }

    const renderDataInfo = async (groupInfo: string) => {


        const dataInfo: Array<Record<string, any>> = await hook.applyApiSearchFull(hook.filterOptions, groupInfo, 'time');
        let cumulated = 0;

        if (dataInfo) {
            dataInfo.sort((a, b) => a._id > b._id ? 1 : a._id < b._id ? -1 : 0);
        }

        const dataItems: Array<IData> = dataInfo.reduce((prev: Array<IData>, item: Record<string, any>) => {

            cumulated = cumulated + item?.count;
            const value = type === 'single' ? item.count : cumulated;
            prev.push({ simpleName: item?._id, name: item?._id, value: value })


            return prev;



        }, [] as Array<IData>)



        dataItems?.map(d => {
            d.name = `${d.name} - ${d.value}`;
        })

        setRenderData(dataItems);


    }


    useEffect(() => {
        renderDataInfo(groupColumn);
    }, [groupColumn, hook.filterOptions, type])


    const option = {
        title: {
            text: title,
            subtext: subtitle,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },

        series: [
            {
                name: category,
                type: 'pie',
                radius: '50%',
                data: [
                    ...renderData
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    /*

    const option = {

        yAxis: {
            type: 'category',
            data: renderData.reduce((prev: Array<string>, item: IData) => {
                prev.push(item.name)
                return prev;
            }, [])
        },

        xAxis: {
            type: 'value'
        },

        series: {
            type: 'bar',

            data: renderData.reduce((prev: Array<number>, item: IData) => {
                prev.push(item.value)
                return prev;
            }, []),

        }
    };
    */


    const optionLine = {
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: renderData.reduce((prev: Array<string>, item: IData) => {
                prev.push(item.name)
                return prev;
            }, [])
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: renderData.reduce((prev: Array<number>, item: IData) => {
                    prev.push(item.value)
                    return prev;
                }, []),
                type: 'line',
                areaStyle: {}
            }
        ]
    };


    return <div className="container column">


        <div className="container row flex-wrap gap-sm pad-sm">
            <button className={`button-xxs ${visibility === 'chart' ? 'color-button' : 'soft-button'}`} onClick={() => setVisibility('chart')} ><FaChartLine /></button>
            <button className={`button-xxs ${visibility === 'table' ? 'color-button' : 'soft-button'}`} onClick={() => setVisibility('table')} ><FaTable /></button>

        </div>
        <div className="container row flex-wrap gap-sm pad-sm">
            <DateFilterBar filterFunction={hook.applyDateFilter} filterOptions={hook.filterOptions} />
        </div>
        <div className="container row flex-wrap gap-sm pad-sm">
            <FilterBar filters={hook?.filterOptions.filters} filterFunction={hook?.applyFilter} />
        </div>

        <div className="container row flex-wrap gap-sm pad-sm">
            <button className={`button-xxs ${type === 'single' ? 'color-button' : 'soft-button'}`} onClick={() => setType('single')} >Unitário</button>
            <button className={`button-xxs ${type === 'group' ? 'color-button' : 'soft-button'}`} onClick={() => setType('group')} >Acumulado</button>
            <button className={`button-xxs  color-button `}   >{hook.count}</button>

        </div>
        <div className="container row-to-column row-to-column-start gap-sm pad">


            {visibility === 'chart' || visibility === 'both' ? <ReactEcharts option={optionLine} style={{
                height: '60vh',
                width: '100%',
            }} /> : <></>}
            {visibility === 'table' || visibility === 'both' ? <div className={`container column column-start ${visibility === 'both' ? 'max-400' : ''} overflow-hidden-height`}>
                <table className="container" >
                    <thead>
                        <tr><th className="row-space-between">{hook?.headerData?.find(h => h.column === groupColumn)?.title || ''} <button className="icon-button-xs color-button"> <FaFileExcel onClick={() => convertToArrayAndExport({ data: renderData, type: 'xlsx', title: hook?.headerData?.find(h => h.column === groupColumn)?.title || '' })} /></button></th><th>QTD </th></tr>
                    </thead>
                    <tbody>
                        {renderData?.map(item => <tr key={`render-data-${group}-${item.name}`}><td>{item.simpleName}

                            <FaFilter className="filter-icon-position" onClick={() => hook.applyFilter({ column: groupColumn, value: item.simpleName, group: groupColumn, category: hook?.headerData?.find(h => h.column === groupColumn)?.title || '' })} size={15} />

                        </td><td>{item.value}</td></tr>)}
                    </tbody>
                </table>
            </div> : <></>}
        </div>


    </div >


}

export default ChartLine;