import styled, { keyframes } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const QuestionPage = styled.div`
 
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
 
  flex-direction: column;
 
  padding: 30px;
  background: #fff;
  width: 100%;
  gap: 15px;
border-bottom: 2px solid #000;
  img {
    width: 100%;
  }
 

  p,strong,h2{
    color: #000 !important;
    font-size: 16px !important;
    line-height: 24px;
  }
 
  h2{
    font-size: 18px !important;
    margin-bottom: 15px;
  }

`;

