import api from "../services/api";
import { IHeader } from "../hooks/SearchHook/interfaces";
interface IProps {
  url: string;
  type?: string;

  exportData?: Array<IHeader>;
  params?: Record<string, any>;
}

interface IPropsPost {
  url: string;
  type?: string;
  data?: Array<Array<string>>;
  title?: string;
}

const convertPropsToUrl = (props: Record<string, any>) => {

  const { search = '', order = {}, page = 1, where = {}, limitItems = 10, type = 'xlsx' } = props;

  const string = `?search=${encodeURIComponent(
    search || '',
  )}&order=${encodeURIComponent(
    JSON.stringify(order)
  )}&page=${page}&where=${encodeURIComponent(
    JSON.stringify(where)
  )}&limit=${limitItems}&type=${type}`;


  return string;

}

export const convertExportData = (header: IHeader[]) => {

  const headerExport: Record<string, any> = {}

  header?.map(head => {
    if (head.export === 'yes') {
      headerExport[head.column] = { label: head.title, ref: head.column, column: head.column, search: true, includeColumn: head?.includeColumn || '' };
    }
  })

  return headerExport;

}

const downloadit = async ({ url, type = 'xlsx', params = {}, exportData = [] }: IProps) => {

  const contentType: Record<string, any> = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'application/csv'
  }

  params.type = type;

  if (exportData?.length > 0) {

    const headerExport = convertExportData(exportData);

    if (Object.keys(headerExport).length > 0) {

      params.export_data = headerExport;
    }

  }

  try {
    const response = await api.get(`${url}`, {
      headers:
      {
        'Content-Disposition': `attachment; filename=template.${type}`,
        'Content-Type': contentType?.[type] || ''
      },
      responseType: 'arraybuffer',
      params: params
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `relatorio.${type}`);
      document.body.appendChild(link);
      link.click();
    })
      .catch((error) => console.log(error));





  } catch (err) {


  }


  return true;
}

export const downloaditPost = async ({ url, type = 'xlsx', data, title = '' }: IPropsPost) => {



  const contentType = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'application/csv'
  }

  try {
    const response = await api.post(url, { data }, {
      headers:
      {
        'Content-Disposition': `attachment; filename=template.${type}`,

      },
      responseType: 'arraybuffer',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title || `relatorio.${type}`);
      document.body.appendChild(link);
      link.click();
    })
      .catch((error) => console.log(error));





  } catch (err) {


  }


  return true;
}

export default downloadit;