import { Form } from '@unform/web';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInput';
import Loader from '../../../../../../components/Loader';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import TextareaInput from '../../../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadFormContent from '../../../../../DashboardPages/features/UploadFormDocuments';

interface IProps {
    projectHash: string;
    reload: Function;
    selectiveProcess?: Record<string, any>;
}

const AskSubscriptionRecurse: React.FC<IProps> = ({ selectiveProcess, projectHash, reload }) => {


    const [specialTreatment, setSpecialTreatment] = useState();



    const { addModal, removeModal } = useModal();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);

    const sendSpecialTreatment = async (data) => {





        data.term = data?.term?.[0] ? data?.term?.[0] : '';
        data.documents = data?.documents?.documents ? data?.documents?.documents : [];



        setLoading(true);



        if (!data?.term) {
            addToast({ title: 'Confirme o termo', type: 'error' });
            setLoading(false);
            return;
        }

        try {

            const response = await api.post(`/send-selective-process-subscription-recurse/${projectHash}`, { ...data, project_id: projectHash });
            addModal({ theme: 'whiteModalMedium', title: 'Solicitação de recurso de inscrição enviada', content: <p style={{ width: '100%', fontSize: '16px', color: '#333' }}>Você receberá um e-mail com o protocolo da solicitação</p> });
            removeModal('specialTreatment');
            reload();
            setLoading(false);

        }
        catch (err) {
            addToast({ title: err?.response?.data?.message || err?.response?.data?.message || 'Erro ao enviar recurso', type: 'error' });
            setLoading(false);
        }
    }
    return <>
        {loading ? <Loader message='Carregando...' /> : <></>}
        <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px', marginBottom: '15px' }}>Para solicitar recurso de inscrição, preencha os campos abaixo</p>
        <Form onSubmit={sendSpecialTreatment} style={{ paddingTop: '15px', borderTop: '1px solid #ddd' }}>



            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> DESCREVA O MOTIVO DO RECURSO</p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><TextareaInput name='description' placeholder="" /></div>


            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> ADICIONE OS DOCUMENTOS COMPLEMENTARES</h2>



            < UploadFormContent name="documents" placeholder='Documentação' />

            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> ACEITE DO TERMO</h2>
            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'justify' }}>
                DECLARO, desde já, que as informações aqui prestadas e os documentos apresentados são verdadeiros e que
                estou ciente de que a não apresentação de qualquer documento para comprovar a condição que garante o deferimento da solicitação, ou, ainda, que a
                apresentação dos documentos fora dos padrões, prazo e/ou forma solicitados, implicará indeferimento da solicitação; DECLARO, ainda, que estou ciente de
                que, constatada falsidade em qualquer momento, poderei responder por crime contra a fé pública, nos termos da lei vigente, o que também acarretará
                minha eliminação deste processo seletivo.
            </p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><CheckboxInput unique name="term" placeholder="" options={[
                { id: 'Sim', value: 'Sim', label: 'Sim' },
            ]} /></div>

            <button style={{ margin: '10px auto' }} className="defaultButtonReverse">Enviar</button>
        </Form>

    </>
}

export default AskSubscriptionRecurse;