import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';



import api from '../../../../services/api';
import { FaImage, FaPlusCircle, FaTicketAlt, FaTrash } from 'react-icons/fa';
import { useModal } from '../../../../hooks/Modal';
import { uuid } from 'uuidv4';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAuth } from '../../../../hooks/Auth';
import { useToast } from '../../../../hooks/Toast';
import Loader from '../../core/components/Loader';
import { urlWebsite } from '../../../../config';
import { setTimeout } from 'timers';

interface IProps {
  template: Array<Record<string, any>>;
  currentValue?: Record<string, any>;
  profile?: Record<string, any>;
}


const UploadCurriculumContent: React.FC<IProps> = ({ profile = {}, template, currentValue = {} }) => {
  const { addToast } = useToast();
  const { projectHash, configuration } = useConfiguration();
  const { addModal } = useModal();
  const [curriculumContent, setCurriculumContent] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();


  useEffect(() => {
    console.log(currentValue)
    setCurriculumContent(currentValue);
  }, [currentValue])

  const uploadPhoto = async ({ file, category, subcategory = '' }) => {

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post('/curriculum-upload', formData);
      if (response) {

        const data = { ...curriculumContent };




        if (!data?.[category?.hash]) {
          data[category.hash] = { uploads: [] };
        }

        if (category?.show_subcategories === 'yes') {
          if (!data[category.hash][subcategory]) {
            data[category.hash][subcategory] = [];
          }

          data[category.hash][subcategory].push({ url: response?.data?.message, hash: uuid(), createdAt: new Date() })

        }
        else {

          data[category.hash].uploads.push({ url: response?.data?.message, hash: uuid(), createdAt: new Date() })
        }

        await setTimeout(() => {




          setLoading(false);
          setCurriculumContent({ ...data });






        }, 3000);


      }
      else {

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      return addToast({ title: err.response.data.message || 'Erro ao realizar upload' })
    }
  };


  const saveCurriculum = async () => {
    setLoading(true);
    try {
      console.log(curriculumContent)
      const response = await api.post(`/update-curriculum/${projectHash}`, { curriculum: curriculumContent });

      setLoading(false);

      addModal({
        title: '', theme: 'whiteModalMedium', content: <><h2 style={{ color: '#333', fontSize: '14px' }}>Salvo com sucesso</h2><p style={{ color: '#333', fontSize: '12px' }}>
          Até o encerramento da etapa de submissão, você pode retornar nesta aba para edição ou visualização de seus documentos.

        </p></>
      })
      return addToast({ title: 'Salvo com sucesso', type: 'success' });
    }
    catch (err) {
      setLoading(false);
      return addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao atualizar', type: 'error' });
    }

  }

  const uploadAndShow = async ({ event, category, subcategory = '' }) => {

    const file = event?.target?.files?.[0];

    if (!file) {
      return addToast({ title: 'Arquivo não encontrado', type: 'info' });
    }

    if (file.size > 4999999) {
      return addToast({ title: 'O tamanho máximo aceito para envio é de 5MB', type: 'error' });
    }



    if (file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return addToast({ title: 'São aceitos apenas arquivos em pdf, .png, .jpg e .jpeg', type: 'error' });
    }

    setLoading(true);

    await uploadPhoto({ file, category, subcategory });




  }

  const removeItem = ({ hash, category = '', upload }) => {

    setCurriculumContent(state => {

      const data = { ...state };
      if (category) {

        const itemIndex = data[hash][category].findIndex(i => i.hash === upload);

        if (itemIndex >= 0) {
          data[hash][category].splice(itemIndex, 1);
        }

      }
      else {

        const itemIndex = data[hash].uploads.findIndex(i => i.hash === upload);

        if (itemIndex >= 0) {

          data[hash].uploads.splice(itemIndex, 1);

        }

      }


      return data;
    })


  }

  const showImage = (url) => {

    addModal({ theme: 'whiteModal', title: ' ', content: <img style={{ width: '100%' }} src={`${urlWebsite}/${url}`} /> })

  }


  const placeOptions = (item, index) => {


    return <>
      {item.categories && item.categories.map(cat => {
        return <div key={`curriculum_categories_div-${cat.hash}`} className='curriculum_categories_div'>

          <div><p key={`${cat.title}-${index}`}>{cat.title}  </p>
            {configuration.stage === 'curriculum' && profile?.allow_curriculum_exception !== 'yes' ? <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: item, subcategory: cat.hash }) }} /> <FaImage size={20} /> Adicionar PDF / Imagem
            </button> : <></>}
          </div>

          <div className='uploadsDiv'>
            {curriculumContent?.[item.hash]?.[cat.hash] && curriculumContent?.[item.hash]?.[cat.hash].map(up => {
              return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                {configuration.stage === 'curriculum' && profile?.allow_curriculum_exception !== 'yes' ? <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, category: cat.hash, upload: up.hash })}><FaTrash /></div> : <></>}
              </div> : <div className='uploadItem' key={`uploadItem1-${up.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                {configuration.stage === 'curriculum' && profile?.allow_curriculum_exception !== 'yes' ? <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, category: cat.hash, upload: up.hash })}><FaTrash /></div> : <></>}
              </div>
            })}

          </div>

        </div>


      })}

    </>



  }


  if (configuration.stage !== 'curriculum' && configuration.stage !== 'curriculum_waiting' && configuration.stage !== 'curriculum_avaliated' && user.profile !== '1') {
    return <Container>

      <h2 style={{ color: '#333', padding: '20px', background: '#fff', margin: '0px auto' }}>Etapa não liberada</h2>
    </Container>
  }


  return (
    <Container>



      {template && template.length > 0 ? <>

        {configuration.stage === 'curriculum' ? <>
          <p style={{ background: '#ccc', color: '#333', padding: '10px', fontSize: '14px', margin: '10px auto', maxWidth: '600px' }}>O status "Aguardando documentação" será mantido até o final do período de envio de documentos. Neste período, você poderá visualizar, alterar, remover ou adicionar seus documentos.</p>


          <h2 style={{ color: '#333' }}>Após adicionar seus documentos, clique no botão salvar para confirmar</h2>


          <h2 style={{ color: '#333', fontSize: '14px' }}>O tamanho máximo dos documentos é de 5mb, no formato pdf, png ou jpg</h2>

          <p style={{ color: '#333', fontSize: '14px' }}>Ao salvar, confirmo a veracidade dos documentos inseridos.</p>


          <button onClick={() => { saveCurriculum() }} className='defaultButton'>Salvar</button>
          <p style={{ color: '#333', fontSize: '14px' }}>Os documentos devem ser inseridos de forma individual, cada um em seu arquivo.</p>

        </> :



          <h2 style={{ color: '#333' }}>Você pode visualizar seus documentos, porém não é mais possível alterará-los</h2>
        }



      </>
        :
        <h2>Etapa não configurada</h2>

      }


      {loading && <Loader message='Carregando' />}




      {template && template.length > 0 && template.map((item, index) => {

        return item?.status === 'yes' && <div className='curriculum_div' key={`curriculum-div${item.hash}`}>


          <h3 key={item.title}>{item.title}</h3>

          {item.description && <p dangerouslySetInnerHTML={{ __html: item.description }} />}

          {item.show_subcategories === 'no' && (configuration.stage === 'curriculum' && profile?.allow_curriculum_exception !== 'yes') ? <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: item }) }} /> <FaImage size={20} />Adicionar PDF / Adicionar Imagem</button> : <></>}
          {item.show_subcategories === 'yes' && placeOptions(item, index)}

          {item.show_subcategories === 'no' && <div className='uploadsDiv'>
            {item.show_subcategories === 'no' && curriculumContent?.[item.hash]?.uploads && curriculumContent?.[item.hash]?.uploads.map(up => {


              return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                {configuration.stage === 'curriculum' ? <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div> : <></>}
              </div> : <div className='uploadItem' key={`uploadItem-yes-${item.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                {configuration.stage === 'curriculum' && profile?.allow_curriculum_exception !== 'yes' ? <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div> : <></>}
              </div>
            })}

          </div>}


        </div>


      })}


    </Container>
  );
};

export default UploadCurriculumContent;
