import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaFileMedical, FaTrashAlt } from 'react-icons/fa';
import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();


  const handleSend = async (): Promise<void> => {
    try {
      const response = await api.delete(`/delete-invoices/${lineData.sale_hash}`);

      addToast({ title: 'Pagamento removido!', type: 'success' });
      removeModal('SelectiveProcess')
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const handleGrade = async () => {

    const response = await api.get(`/check-recursive-payment/${lineData.sale_hash}`);

    addModal({
      key: "SelectiveProcess",
      theme: 'whiteModal',
      title: 'Você deseja remover estes parcelamentos?', content: <>

        <button onClick={() => handleSend()} className='defaultButtonReverse'>Remover</button>

        <table>
          <tbody>
            <tr><td>Data</td><td>Produto</td><td>Parcela</td><td>Status PG</td></tr>
            {
              response?.data?.rows?.map(i => <tr>
                <td>{i?.payment_date}</td>
                <td>{i?.product_id_response?.title}</td>
                <td>{i?.installment_value}</td>
                <td>{i?.payment_status_id}</td>
              </tr>)
            }
          </tbody>


        </table>
      </>
    })

  }

  const moduleUpdate = (): JSX.Element => (
    lineData?.invoice_number ? <button className="lineIcon" title="Remover parcelamento de matrícula" type="button" onClick={() => handleGrade()}>
      <FaTrashAlt />
    </button> : <></>
  );

  return moduleUpdate();
}
