import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaChevronCircleRight, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';
import AskSpecialTreatment from '../Home/modules/AskSpecialTreatment';
import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Notifications from '../Notifications';

import { Container, ContainerWhite } from './styles';
import { useModal } from '../../../../hooks/Modal';
import AskSubscriptionRecurse from '../Home/modules/AskSubscriptionRecurse';
import AskProofRecurse from '../Home/modules/AskProofRecurse';
import IsentionPage from '../IsentionPage';

interface IProps {
  changeStatus: Function;
  selectiveProcess?: Record<string, any>;
}

const InformacoesGerais: React.FC<IProps> = ({ changeStatus, selectiveProcess }) => {

  const { user } = useAuth();
  const { configuration, projectHash } = useConfiguration();
  const [profile, setProfile] = useState<Record<string, any>>({});
  const { addModal } = useModal();
  const [payment, setPayment] = useState('');

  const [paymentInfo, setPaymentInfo] = useState<Record<string, any>>()
  const load = async () => {



    const response = await api.get(`/student-info`);

    if (response?.data) {
      setProfile(response.data);
    }

  }

  const askSpecialTreatment = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Atendimento especial',
      key: 'specialTreatment',
      content: <AskSpecialTreatment projectHash={projectHash} />

    })

  }



  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const loadCheckPayment = async () => {


    const responsePayment = await api.get(`/checkPayment/${projectHash}`);

    if (responsePayment?.data && responsePayment?.data?.payment_status_id === 'paid') {
      setPayment('paid');
    }
    else {
      setPayment('waiting');
    }
    if (responsePayment?.data) {
      setPaymentInfo(responsePayment?.data);
    }


  }

  const askSubscriptionRecurse = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Recurso de inscrição',
      key: 'specialTreatment',
      content: <AskSubscriptionRecurse selectiveProcess={selectiveProcess} reload={() => { }} projectHash={projectHash} />

    })

  }

  const askProofRecurse = (saleInfo) => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Recurso da prova',
      key: 'specialTreatment',
      content: <AskProofRecurse selectiveProcess={saleInfo} reload={() => { }} projectHash={projectHash} />

    })

  }

  useEffect(() => {

    if (projectHash) {

      load();
      loadCheckPayment();
    }

  }, [projectHash])



  const askIsention = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: '',
      key: 'isention',
      content: <IsentionPage hash={configuration?.url || ''} />

    })

  }

  return (
    <>
      <Container >

        <div className='user-block  shadow'>

          <div className='content-block rows'>
            <div className='columns'>
              <p className='contentTitle'>Meu Status</p>

              <table>
                <tbody>
                  {selectiveProcess?.status === 'aprovado' && configuration?.registration_product ? <tr><td colSpan={2}> <h2 style={{ color: '#333', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}><p style={{ fontSize: '12px', color: '#333', width: 'auto' }}>O pagamento da matrícula está disponível na aba PAGAMENTOS</p> <FaChevronCircleRight onClick={() => { changeStatus('payments') }} style={{ color: 'green', fontSize: '24px', cursor: 'pointer', marginLeft: '10px' }} /></h2> </td></tr> : <></>}

                  <tr><td><p>Pagamento da inscrição</p></td><td><div><button className={payment} /><p>{getPaymentStatus?.[payment] || 'Aguardando Pagamento'}</p></div></td></tr>
                  {configuration?.allow_special_treatment === 'yes' ? <tr><td colSpan={2}> <button type="button" className="defaultButtonGreen" style={{ height: 'auto' }} onClick={() => askSpecialTreatment()} key={'ask-special-treatment'}  >  Solicitar atendimento especial </button></td></tr> : <></>}
                  {configuration?.allow_isention_recurse === 'yes' && paymentInfo ? <tr><td colSpan={2}>  <button onClick={() => { askIsention() }} style={{ boxShadow: '0px', height: 'auto', maxWidth: '200px', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar isenção de pagamento</button> </td></tr> : <></>}

                  {configuration?.allow_subscription_recurse === 'yes' ? <tr><td colSpan={2}> <button type="button" className="defaultButtonGreen" style={{ height: 'auto' }} onClick={() => askSubscriptionRecurse()} key={'ask-recourses'}  > Solicitar recurso de inscrição </button></td></tr> : <></>}
                  {configuration?.allow_proof_recurse === 'yes' && payment === 'paid' && paymentInfo ? <button className="defaultButtonGreen" style={{ height: 'auto' }} onClick={() => askProofRecurse(paymentInfo)}>Solicitar recurso da Prova</button> : <></>}

                </tbody>
              </table>


            </div>


            { /* <div className='columns'>
              <p className='contentTitle'>Cronograma geral</p>
              <table>
                <tbody>
                  {configuration?.schedule && configuration?.schedule?.map((schedule) => {

                    return schedule.active === 'yes' ? <tr key={`schedule-${schedule.title}`}><td><p>{schedule.title}</p></td><td><p>{schedule?.text_date ? schedule.text_date : 'Em breve'}</p> </td></tr> : <></>

                  })}

                </tbody>
              </table>

                </div> */}


          </div>
        </div>

        <div className='user-block shadow'>

          <div className='content-block'>


            {(configuration?.local_address || configuration?.local_map) && <div className='columns'>
              <p className='contentTitle'>Local da prova</p>

              <table>
                <tbody>
                  {configuration?.local_address && <tr><td><p>Endereço completo:</p><p dangerouslySetInnerHTML={{ __html: configuration?.local_address }} /> </td></tr>}
                  {configuration?.local_map && <tr><td><iframe src={configuration?.local_map} style={{ width: '100%', height: '200px' }} /></td></tr>}
                </tbody>
              </table>





            </div>}
          </div>


        </div>
        <div className='user-block shadow'>
          <div className='content-block'>


            <div className='columns'>
              <p className='contentTitle'>Dúvidas</p>

              <table>
                <tbody>
                  {/*   <tr><td><p>Confira nossa FAQ - <strong style={{ cursor: 'pointer' }} onClick={() => { changeStatus('faq') }}>Clique Aqui</strong></p></td></tr> */}

                  <tr><td><p>Envie e-mail para ensino@fundmed.org.br</p></td></tr>
                </tbody></table>
            </div>
          </div>
        </div>

        <Notifications changeStatus={(value) => changeStatus(value)} limit={5} />
      </Container>
    </>
  );
};
export default InformacoesGerais;
