import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id : number;
  id: number;
  category_response ?: Record<any,string>;
  social ?: Record<any,string>;

}

interface ISpeakers {
  title : string;
  position: string;
  order_type : string;
  speakers : Array<ISpeakerData>;
}
 
const Speakers: React.FC = () => {
  const [speakersData, setSpeakerData] = useState<Array<ISpeakers>>([]);
  const {projectHash, eventHash} = useConfiguration();

  const orderList = ( item ) => {

    const valid = {
      asc : (speakers) => {

        if(speakers.length > 0){
          speakers.sort( (a,b) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
          })
        }

        return speakers;

      },
      desc : (speakers) => {

        if(speakers.length > 0){
          speakers.sort( (a,b) => {
            return a.name < b.name ? 1 : a.name > b.name ? -1 : 0
          })
        }

        return speakers;

      },
    }


    if(valid[item?.order_type]){
      item.speakers = valid[item?.order_type](item?.speakers);
    }
    
    return item.speakers ||[];

  }

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'order_id', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      const speakers : Array<ISpeakers> = [
        {
          title : 'Palestrantes',
          speakers : [],
          position: '1',
          order_type : 'asc'
        },
      
      ];

      const response = await api.get( !eventHash ? `/speakers-list/${projectHash}?${string}&limitItems=50` : `/speakers-list/${projectHash}/${eventHash}?${string}&limitItems=50`);
      
 
      
      if (response) {

        let newSpeakers : Array<ISpeakers> = [ ];

        response.data.map( (a : ISpeakers) => {
     
         a.speakers = orderList(a);

          newSpeakers.push(a);
         
        

        })

        newSpeakers.sort( (a,b) => {
          return a.position > b.position ? 1 : a.position < b.position ? -1 : 0
        })



        setSpeakerData(newSpeakers);
      }
    };
    load();
  }, []);

  return speakersData.length > 0 ? <Container className='shadow'> {speakersData.map( s => <SpeakersContainer speakers={s.speakers} title={s.title} />)}</Container> : <></>;
};

export default Speakers;
