import styled, { css, keyframes } from 'styled-components';
import { Form as FormContainer } from '@unform/web';
import { ICourseMenu } from './interfaces';
import { AnimatedDiv } from '../../pages/SignIn/styles';
import ImportedInput from '../Forms/Input';
import { defaultTheme } from '../../config';

interface IBackgroundContainer {
  background ?: string;
}

export const Container = styled.div<IBackgroundContainer>`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.background ? `url("${props.background}") no-repeat center` : "none"};

  ${props => props.background && css`
  
  background: url("/${props.background}") no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  `};

  position: relative;
  z-index: 10000;

  .listHeader{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    >div{
    
    }
  }

  .selectButton{
    padding: 5px 10px;
      border-radius: 10px;
      background: #fff;
      color: #333;
      margin: 10px;

      display: flex;
      >svg{margin-right: 10px}

  cursor: pointer;
  border: 2px solid #fff;

  background: #333 !important;
  color:  #fff  !important;
  padding:  7px 15px !important;
  font-weight: bold;

  &:hover{
    border: 2px solid #fff !important;


  }

  >option {
    padding: 10px;
  }
  }

  .lessonBack {
    >button{
      padding: 10px 20px;
      border-radius: 10px;
      border: 2px solid #fff;
      color: #fff;
      font-weight: bold;
      background: none;

      &:hover{
        background: rgb(0,150,0) !important;
        color: #fff;
        opacity: 1;
      }
    }
  }

  .lessonFooter{
    position: absolute;
    bottom: 10px;
   
    right: 8px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    flex-direction: column;

    >button{
      border-radius: 10px 0px 0px 10px;
      background: #fff;
      border: 0px;
      width: 30px;
      height: 30px;
      font-size: 24px;
      color: #fff;
      margin: 5px;
      transition: 0.5s all;
    }

    >.lessonStar{
      background: rgba(0,0,0,0.8);
        color:  rgb(250,200,0);
      opacity: 1;
      &:hover{
  
        opacity: 1;
      }
    }

    >.lessonHeart{
      background:   rgba(0,0,0,0.8);
        color:rgb(255,0,0);
        opacity: 1;

      &:hover{
     
        opacity: 1;
      }
    }
  }

  .reloadPlayer {
    background: rgba(0, 0, 0, 0.3);
    color: #333;
    border: 2px solid #fff;
    border-radius: 8px;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
    transition: background 0.4s;

    &:hover {
      background: #fff;
      color: #333;
    }
  }

  .trophy {
    color: #333;
    background: none;
    transition: 0.2s all;
    border: 0;

    &:hover {
      font-size: 24px;
    }

    > svg {
      text-shadow: 2px 2px 2px #111;
    }
  }
`;

export const Header = styled.div`
  min-height: 44px;
  width: 100%;
  background: #555;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: none;
`;

export const Body = styled.div`
  width: 100%;

  background: none;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .notext{
    >h2{
      display: none;
    }
  }

  .videoStatus {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    


  
  }

  .status4 {
    opacity: 0.4 !important;
    transition: 0.4s all;

    &:hover {
      opacity: 0.7 !important;
    }
  }
  

   .onlineMessage {
    min-height: auto;
  
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(50,50,50,0.8);
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    padding: 50px 20px;
    border-radius: 20px;

    @media (max-width: 750px) {
      justify-content: flex-start;
    }

   
    > img {
      width: 300px;
      margin: 30px;
    }

    > h2 {
      font-weight: bold;
      font-size: 18px;
      color: #fff;
    }

    > p {
      font-size: 16px;
      color: #fff;
    }
  }

   div.videoBox,  div.template2 {
    width: 300px;
    position: relative;
    margin: 10px;
    border: 3px solid #eee;
    padding: 0px;
    cursor: pointer;

    @media (max-width: 750px) {
      width: 100%;
    }

    .videoThumbHeader{
      position: absolute;
      top: -15px;
      left: 5%;
      width: 90%;
      background: #fff !important;
      color: #333 !important;
      font-weight: bold;
      border-radius: 32px;
      padding: 5px;
      display: flex;
      align-itens: center;
      justify-content : space-between;
      padding: 5px 15px ;
    }

    .videoThumbHeader2{
      position: absolute;
      top: 35px;
      left: 175px;
      width: 100px;
     
 
      color: #fff !important;
      font-weight: bold;
      border-radius: 32px;
      padding: 5px;
      display: flex;
      align-itens: center;
      justify-content : flex-end;
      padding: 5px 15px ;
    }

    .videoSubtitle {
      font-size: 16px;

    }

.videoIcon{
  width: 50px;
  margin-bottom: 10px;
}

    >.videoSeta {
      position: absolute;
      bottom: 20px;
      right: 20px;
      height: 50px;
      width: 30px;
    }


      > h2 {
        max-width: 90%;
      }


 
    > div.videoBlock {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 50;
      cursor: pointer;
    }

    transition: 0.5s all;

    &:hover {
      border-color:#f16621;
    }



  }

  >.template2 {
    > h2 {
      font-size: 18px !important; 
      line-height: 18px !important; 
    }

    .videoSubtitle {
      font-size: 16px;
      margin-bottom: 10px;

    }
  }

  > div.videoGroup {
    display: flex;
    width: 100%;
    flex-direction: row !important;
  }

  @media (max-width: 1200px) {
    > div.videoGroup {
      flex-direction: column !important;

      > iframe {
        width: 100% !important;
      }
    }
  }
`;

interface ISidebar {
  active: boolean;
}

// Sidebar
export const Sidebar = styled.div<ISidebar>`
  width: 300px;
  min-height: 100vh;
  background: #333;
  margin-left: 0px;
  position: relative;
  transition: margin 0.2s ease-in;
  z-index: 200;

  @media (max-width: 1250px) {
    position: fixed;
    left: 0px;
    top: 0px;
    overflow: auto;
    max-height: 100vh;
    padding-bottom: 50px;
    margin-left: ${props => (props.active ? '0px' : '-300px')};
  }

  > div + div {
    border-top: 1px solid grey;
  }
`;

export const SidebarMobileButton = styled.button`
  width: 100%;
  background: #333;
  color: #333;
  box-shadow: 2px 2px 2px #000;
  justify-content: center;
  padding: 15px;
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 250;

  @media (max-width: 500px) {
    display: flex;
  }

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

export const CourseMenu = styled.div<ICourseMenu>`
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  > nav {
    display: flex;
    width: 100%;
    padding: 15px;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease;
    background: ${defaultTheme.defaultBackground};
    color: white;

    &:hover {
      opacity: 0.9;
    }
  }

  > nav + nav {
    border-top: 1px solid #ccc;
    background: #ddd;
    color: #333;
  }

  > nav.lesson {
    display: ${props => (props.active ? 'flex' : 'none')};
  }

  svg {
    transition: transform 0.2s ease;
    transform: ${props => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const CourseNav = styled.nav`
  background: #eee;
  color: #333;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > div.menuCounter {
      width: 25px;
      height: 25px;
      min-width: 25px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${defaultTheme.defaultBackground};
      background: #fff;
      margin-right: 10px;
    }
  }

  > svg {
    width: 30px;
  }
`;

export const LessonNav = styled.nav`
  justify-content: flex-start;
  background: #aaa;
  color: #444;
  font-size: 14px;
  align-items: center;

  > div.text {
    flex-direction: column;
    color: #444;

    align-items: flex-start !important;
  }

  > div.menuCounter {
    transform: rotate(180deg);
    width: 25px;
    height: 25px;
    min-width: 25px;
    font-size: 20px;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    margin-right: 10px;
  }

  .green {
    color: green !important;
  }
`;

export const HeaderVideo = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 750px) {
    > div,
    > button {
      display: flex;
      align-items: center;

      font-size: 12px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > p {
      font-size: 12px;
    }
  }

  > button {
    width: 150px;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;

    background: none;
    color: white;
    border: 0;
    transition: background 0.2s ease;
    padding: 10px;

    > svg {
      font-size: 34px;

      transition: 0.2s all ease;
    }

    &:hover {
      background: rgba(30, 30, 30, 0.5);

      > svg {
        font-size: 50px;
      }
    }
  }
`;

export const HeaderBox = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div,
  > button {
    width: 100% !important;
    min-width: auto !important;
    min-height: 40px !important;
    padding: 10px !important;
    border-radius: 0px !important;

    @media (max-width: 750px) {
      font-size: 10px;
    }
  }

  > button + button {
    border-left: 1px solid #fff;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const VideoContainer = styled(AnimatedDiv)`
  padding: 0px;
  width: 100%;
  background: none;
  justify-content: flex-start;




  > div,
  > nav {
    width: 90%;
  }

  @media (max-width: 750px) {
    align-items: flex-start;
    > div,
    > nav {
      width: 100%;
      padding: 0px ;
    }
  }

  > div.uploadsDiv {
    > h3 {
      padding: 10px;
      margin: 20px 0;
    }
    > div.uploads {
      max-width: 400px;
      padding: 10px;
      background: #111;
      color: #333;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;

      > svg {
        font-size: 18px;
        min-width: 20px;
        margin-right: 10px;
      }

      > div {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: center;
      }
    }
  }
`;

export const Form = styled(FormContainer)`
  display: flex;
  justify-content: flex-end;

  > input {
    max-width: 200px;
    border-radius: 8px 0px 0px 8px;
  }
  > button {
    border-radius: 0;
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
  }
`;

export const Input = styled(ImportedInput)`
  border-radius: 8px 0px 0px 8px;
  border: 0;
  background: none;
  padding: 0px;
  color: white;
  border: 0px;

  &::placeholder {
    color: #eee;
  }
`;

interface IModal {
  top?: boolean;
  left?: boolean;
  bottom?: boolean;
  right?: boolean;
  fullHeight?: boolean;
}

export const Modal = styled.div<IModal>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: 0.5s all;

  @media (max-width: 1200px) {
    position: fixed;
  }

  ${props =>
    props.top &&
    css`
      justify-content: flex-start !important;
    `}

  ${props =>
    props.bottom &&
    css`
      justify-content: flex-end !important;
    `}

    ${props =>
      props.right &&
      css`
        align-items: flex-end !important;
      `}

    ${props =>
      props.left &&
      css`
        align-items: flex-start !important;
      `}



  > div {
    overflow-y: auto;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    color: #333;
    background: #fff;
    margin: 0;

    @media (max-width: 750px) {
      max-width: 100%;
      width: 100%;
    }

    ${props =>
      props.fullHeight &&
      css`
        min-height: 100vh !important;
        justify-content: flex-start;
      `}

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
    }

    > p {
      color: #333;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #333;
      border: 0px;
    }

    > div {
      color: #333;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;

      > h2 {
        color: ${defaultTheme.defaultBackground};
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }

      > p {
        color: #333;
        font-size: 12px;
      }

      > button {
        padding: 10px;
        margin: 5px auto;
        width: 200px;
        border-radius: 8px;
        background: ${defaultTheme.defaultBackground};
        color: #333;
        border: 0px;
      }
    }
  }
`;

interface IBackground {
  background?: string;
}
export const ButtonColor = styled.div<IBackground>`
  position: absolute;
  top: 5px;
  right: 5px;
  background: ${props => props.background || '#fff'};
  color: #fff;
  border-radius: 10px;
  padding: 5px;
`;

export const Card = styled.div<IBackground>`
  position: relative;
  width: 200px;
  height: 200px;
  background: url(${props => props.background || '#fff'}) no-repeat top center;
  background-size: cover;
  color: #fff;
  border-radius: 10px;
  padding: 5px;
`;

export const AnimatedContainer = styled(AnimatedDiv)`
  padding: 0;
  background: rgba(0, 15, 64,0.8);
  ${props =>
    props.background &&
    css`
      background: url(${props.background}) no-repeat top center;
      background-size: cover;
    `}
    width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  max-height: 300px;

  .videoTitle {
    font-family: 'AkzidenzGrotesk-BoldExtended', Helvetica, sans-serif;
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-size: 38px;

    width: 80%;
    max-width: 80%;
    line-height: 38px;
  }
  &:hover{
    border-color: yellow;
  }


`;



export const ContainerSelector = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-itens: flex-start;
  justify-column: flex-start;

  >div {
    width: 100%;
    max-width: 100%;

    > h2{
      padding: 10px 10px 0 10px;
    }

    >div {
      display: flex;
      width: 100%;
      max-width: 100%;
      overflow-x: auto;

      

    }
  }
  }

  .template2 {
    > h2 {
      font-size: 18px !important; 
      line-height: 18px !important; 
    }

    .videoSubtitle {
      font-size: 16px;
      margin-bottom: 10px;

    }
  }
`;

interface ISelectButton {
  active ?: boolean;
}

export const SelectButton = styled.div<ISelectButton>`
  padding: 5px 10px;
      border-radius: 10px;
      background: #fff;
      color: #333;
      margin: 10px;

      display: flex;
      >svg{margin-right: 10px}

  cursor: pointer;
  border: 2px solid ${props => props.active ? '#fff' : '#fff'};

  background: ${props => props.active ? '#333' : 'none'} !important;
  color: ${props => props.active ? '#fff' : '#fff'} !important;
  padding:  5px 15px !important;
  font-weight: bold;

  &:hover{
    border: 2px solid #fff !important;

    background: #333 !important;
  }
`;

export const SelectButtonFixed = styled.div<ISelectButton>`
  padding: 5px 10px;
      border-radius: 10px;
      background: #fff;
      color: #333;
      margin: 10px;

      display: flex;
      >svg{margin-right: 10px}

  cursor: pointer;
  border: 2px solid ${props => props.active ? 'rgb(0,100,0)' : '#fff'};

  background: ${props => props.active ? 'rgb(0,150,0)' : 'none'} !important;
  color: ${props => props.active ? '#fff' : '#fff'} !important;
  padding:  5px 15px !important;
  font-weight: bold;

  &:hover{
    border: 2px solid rgb(0,100,0) !important;

    background: rgb(0,150,0) !important;
  }

  @media (max-width: 750px) {
    padding: 10px !important;
    position: fixed;
    z-index: 10;
    bottom: 0px;
    left: 0px;
    width: 100%;
    justify-content: center;
    display: flex;
    background: rgba(0,0,0,0.5) !important;
    color: rgb(0,250,0) !important;
    border-radius: 0px;
    border: 0px;
  }
`;

