import styled, { keyframes } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const Container = styled.div`
  //min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  
  padding: 30px;
  background: #ddd;
  width: 100%;

  >h2{
    color: #333;
    margin: 15px auto;
    padding: 10px;
    text-align: center;
    width: 100% ;
  }

  .list-template-quantity{
    width: 130px;
    border-left: 2px solid #fff;
    display: flex ;
   align-items: center;
   justify-content: center;
  

   >svg{
     margin: 5px ;
     min-width: 20px;
   }
   
  }

.template-title-box{
  width: 100% ;
  
  display: flex ;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  margin: 5px auto;
  margin-top: 15px;
  background: rgb(0,14,64);
  border: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  border-radius: 10px;

  >div{
   padding: 10px;
  }

  p{
    font-size: 12px !important;
    color: #fff !important;
  }
}


  
 .list-template, .list-template-dark{
   width: 100% ;
  
   display: flex ;
   align-items: center;
   justify-content: space-between;
  max-width: 500px;
   margin: 5px auto;
   background: #ddd;

   border: 1px solid #333;
   border-radius: 10px;
   font-size: 16px;
   color: #333;
   >div{
    padding: 10px;
   }

   >td{
     padding: 10px ;
   }

   .list-template-quantity{
   
    border-left: 2px solid #333;
   
  }
 }

 .list-template-dark{
   background: #333 ;
   color: #fff;
   margin: 10px auto;

  
 }




h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: 750px) {
        font-size:  28px;
 
    line-height:  28px;
    }
}

h1,h2  {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;

    @media (max-width: 750px) {
        font-size:  24px;
 
    line-height:  32px;
    }
   
}

p, span, label{
    font-size: 18px;
    line-height: 1.5;
}

span{
    font-size: 10px;
}


table {
    border-collapse: collapse;
    width: 100%;
    background: #fff;
    max-width: 1400px !important;
    tr{
        border: 1px solid #ddd;
        background: #fff;


        &:hover{
            background: #efefef;
        }
        th {
            border: 1px solid #ddd;
            color: #fff;
            padding: 15px;
            font-weight: 700;
            text-align: left;
            background: #333 ;
  >div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100% ;
  }
            >svg{
                align-self: flex-end;
            }
        }
        td {
            border: 1px solid #ddd;
            padding: 15px;
            position: relative ;
            color: #333 !important;
        }
    }

    .table-header{
        background: #333;
        border-color: #fff;
        
    }
}

.primary {}

.secondary{}

.container{
    width: 100%;
}

.width-auto{
    width: auto ;
}

.min-100{
    min-width: 100px;
}


.min-200{
    min-width: 200px;
}

.min-400{
    min-width: 400px;
}

.min-600{
    min-width: 600px;
}

.max-200{
    max-width: 200px;
}

.max-400{
    max-width: 400px;
}

.max-800{
    max-width: 800px;
}

.max-1200{
    max-width: 1200px;
}

.max-1400{
    max-width: 1400px;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.gap-sm{
    gap: 15px;
}

.gap{
    gap: 30px;
}

.gap-bg{
    gap: 45px;
}

.border {
    border: 1px solid #ccc;
}

.border-radius-sm{
    border-radius: 7.5px;
}

.border-radius{
    border-radius: 15px;
}

.pad-sm{
    padding: 15px;
}

.pad{
    padding: 30px;
}

.pad-bg{
    padding: 45px;
}

.container-pad{
    padding: 100px 30px;
 

    @media (max-width: 750px) {
        padding:  30px;
    }

}

.full-height {
    min-height: 100vh;
}
.row {
    display: flex;
    align-items: center;
    justify-content: center;
}
.column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.row-to-column {

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
    justify-content: flex-start;
    }

}

.flex-wrap{
    flex-wrap: wrap;
}

.stretch {
    align-self: stretch;
}

.row-start{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.row-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.column-start{
    align-items: center;
    justify-content: flex-start;
}

.column-end{
    align-items: flex-end;
    justify-content: center;
}

 
.row-to-column-start{
    align-items: center;
    justify-content: flex-start;
  

    @media (max-width: 750px) {
        align-items: flex-start;
    justify-content: center;
    }
}

.row-space-between{
    display: flex ;
    align-items: center;
    justify-content: space-between;
}

.overflow-hidden-height{
    overflow-y: auto ;
    max-height: 70vh;
}

.input-xs {
    .inputContainer {
        min-height: 30px !important;
        padding: 0px;

        input{
            padding: 7.5px !important;

        }
    }
}

.icon-button {
    min-width: 46px ;
    min-height: 46px ;
    width: 20px ;
    height: 20px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    &:hover{
        svg{
            transform: scale(1.2) rotate(90deg);
        }
    }

}

.filter-icon-position{
    position: absolute;
    top: 7.5px ;
    right: 7.5px;
    opacity: 0.3;
    width: 10px;
    height: 10px;
    cursor: pointer ;

    &:hover{
        opacity: 1;
        color: #405cf5;
        transform: scale(1.2) ;
    }

}

.icon-button-xs {
    min-width: 30px ;
    min-height: 30px ;
    width: 30px ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    

    &:hover{ 
        svg{
            transform: scale(1.2);
        }
    }

}

.icon-button-xs-width {
    min-width: auto ;
    min-height: 30px ;
    width: auto ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;
    font-size: 14px;
    gap: 7.5px;


    p{
        font-size: 14px; 
    }

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    &:hover{
        svg{
            transform: scale(1.2) ;
        }
    }

}

.soft-button {

    background-color: #fafafa;
    color: #aaa;

    &:hover{
        background-color: #405cf5;
    color: #fff;
    }

}

.color-button {
    background-color: #405cf5;
    color: #fff;
}

.button-xxs {
     
    min-height: 30px ;
    width: auto ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    

    &:hover{ 
        svg{
            transform: scale(1.2);
        }
    }

}
`;

export const Header = styled.div`
  width: 100%;
  background: none;
  color: #333;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    max-width: 400px;
    form {
      display: flex;

      > input {
        border: 0;
        padding: 20px;
        background: #eee;
        color: #333;
      }
      > button {
        border: 0;
        padding: 20px;
        background: rgb(0, 100, 150);
        color: #fff;
      }
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;

  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: auto;
    background: white;
    margin-top: 10px;
    

    tr {
      td,
      th {
        border: 1px solid #ddd;
        text-align: left;
        color: #333;
        padding: 5px;
      }
    }

    tr + tr {
      border-top: 1px solid #999;
    }
  }
`;
