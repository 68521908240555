import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaWhatsapp } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function ContactModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { addModal } = useModal();
  const callWhatsapp = async () => {

    const student = await api.get(`/students/${lineData?.client_id_response?._id}`);

    addModal({
      title: '', content: <>

        <Whatsapp style={{ padding: '5px', display: 'flex', justifyContent: 'center', minWidth: '40px' }} message={`Chamar ${student?.data?.name} no whatsapp`} text={`Olá ${student?.data.name}! Nós somos da FundMed.`} number={`55${student?.data.phone.toString().replace(/[^0-9]/g, '')}`} />


      </>, theme: 'whiteModalMini'
    })


  }


  const moduleUpdate = (data: Record<string, any>): JSX.Element => (
    <button className='lineIcon' title='Contactar via whatsapp' onClick={() => callWhatsapp()}><FaWhatsapp /></button>
  );

  return moduleUpdate(lineData);
}
