import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaUpload, FaIdBadge } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiX } from 'react-icons/fi';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', data.image)
      const response = await api.post(`/update-selective-process-from-file/${projectHash}`, formData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      addToast({ type: 'success', title: 'Salvo com sucesso' });
      await reloadSearchAll();

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={{
        image: {
          model: 'uploadImage',
          type: 'upload',
          name: 'image',
          label: 'Imagem',
        }
      }} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title: 'Atualizar processos', content, key, theme: "whiteModal" });
    }
  };


  const updateData = async (data) => {

    data.map(async (item, index) => {


      await api.put(`/selective-process/${item?._id?.toString()}/status`, { status: 'waiting' })
      addToast({ title: `${item?.client_id_response?.name} - ${index + 1} / ${data?.length}` })
    })
  }


  const transformToWaitingComplete = async () => {

    const items = await api.get('/selective-process', { params: { limitItems: 10000, where: { status: 'waiting_complete', project_id: projectHash } } })


    const content = (
      <>
        <p style={{ color: '#333' }}>Avançar todos para "Em análise documental"</p>
        <p style={{ color: '#333' }}> {items?.data?.rows?.length} registros</p>
        <button className='greenButton2' onClick={() => updateData(items?.data?.rows)}>Confirmar alteração?</button>

        <table className='tableRef'>
          <tbody>
            <tr><td>NOME</td><td>CATEGORIA</td><td>ÁREA</td><td>SUBÁREA</td><td>STATUS</td></tr>
            {items?.data?.rows?.map(i => {
              return <tr><td>{i?.client_id_response?.name}</td><td>{i?.category}</td><td>{i?.area}</td>
                <td>{i?.subarea}</td><td>{i?.status}</td></tr>
            })}
          </tbody>
        </table>

      </>
    );
    if (content) {
      const keyModal = addModal({ title: 'Atualizar processos', content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <><button className="searchButton" type="button" onClick={handleCreate}>
      <FaUpload title='Atualização de Processos' color='yellow' />
    </button>
      <button className="searchButton" type="button" onClick={transformToWaitingComplete}>
        <FaIdBadge title='Avançar os processos para Etapa de avaliação' color='yellow' />
      </button>

    </>
      : <></>
  );

  return moduleCreate();
}
