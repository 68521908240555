import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaCheck, FaPlus, FaTable, FaTabletAlt, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { apiUrl } from '../../../../../../config';
import downloadit, { downloaditPost } from '../../../../../../utils/download';
import { FiDatabase } from 'react-icons/fi';
import { AiFillFileExclamation } from 'react-icons/ai';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();
  const { addLoading, removeLoading } = useLoading();

  const downloadReport = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })
    await downloadit({ url: `selective-process-approved-report/${projectHash}` });
    removeLoading('loading')

  }

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <button className="searchButton" title='Inscritos com chamada para Matrícula' type="button" onClick={downloadReport}>
      <FaCheck />
    </button> : <></>
  );

  return moduleCreate();
}
