import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../config';
import { FaCheckCircle, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { string } from 'yup';
import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { ContentAvaliation } from './styles';
import { useSearch } from '../../../../../../hooks/Search';
import { rgb } from 'polished';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IProps {
  content: Record<string, any>;
  values: Record<string, any>;
  avaliation: Record<string, any>;
  setAvaliation: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentAvaliationIndex: number;
  setCurrentAvaliationIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  globalCount: number;
}

interface IPropsList {
  content: Record<string, any>;
  lineData: Record<string, any>;
}

interface IOption {
  id: string;
  value: string;
  label: string;
  sub_title?: string;
}




export function useAvaliateCurriculum({ lineData, column, title }) {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { configuration } = useConfiguration();



  const gerarRelatorioFinanceiro = async () => {

    const content = [{ hash: 'uploads', title: 'Documents' }];




    addModal({
      title: title,
      content: <>
        <div className='contentAvaliationScore'>
          <h2>Documentos </h2>

        </div>
        <div className='separator' />
        <ContentAvaliation>
          {lineData?.[column]?.uploads?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

          {lineData?.[column]?.uploads?.map((image, imageIndex) => {




            return <div style={{ background: '#eee', padding: '0px 15px', margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px', borderRadius: '10px' }}>
              <p className='title'>{imageIndex + 1} </p>
              {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ cursor: 'pointer', width: '70px', minWidth: '70px', padding: '5px' }} /> </a> : <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`${urlWebsite}/${image.url}`} style={{ width: '100%', cursor: 'pointer', maxWidth: '200px' }} /></a>}

            </div>
          })}
        </ContentAvaliation>

      </>,
      theme: 'whiteModal',
      key: 'curriculum_avaliation',
      closeButton: false
    })


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash ? <button className='defaultButtonMini' style={{ background: lineData?.curriculum_status === 'yes' ? 'rgb(0,150,0)' : 'rgb(250,50,0)', color: '#fff' }} type="button" onClick={() => gerarRelatorioFinanceiro()}>
      {title}
    </button> : <></>
  );

  return moduleUpdate();
}
