import React, { useEffect, useState, useRef } from 'react';

import { useField, FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import { List, FieldSelect, Container } from './styles';

import api from '../../../../../../services/api';
import { useParams } from 'react-router-dom';

interface ISelectApi {
  endpoint: string;
  name: string;
  alias: string;
  label: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  addEmpty?: boolean;
  project_id?: string;
}

interface IOption {
  text: string;
  value: string;
}

const SelectApiDirect: React.FC<ISelectApi> = ({
  endpoint,
  name,
  alias,
  label,
  formRef,
  addEmpty = false,
  project_id = '',
  setCallback = (string: string, form: React.RefObject<FormHandles>) => { },
}) => {
  const [data, setData] = useState<Array<IOption>>([]);
  const [value, setValue] = useState('');
  const [active, setActive] = useState(false);
  const [currentOption, setCurrentOption] = useState<IOption>({} as IOption);

  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  const { projectHash } = useParams<Record<string, any>>();
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return currentValue;
      },
    });
  }, [fieldName, registerField, currentValue]);

  function onChangeSelected(optionValue: IOption): void {
    setCallback(optionValue.value, formRef);
    setValue('');
    setCurrentOption(optionValue);
    setCurrentValue(optionValue.value);
    setActive(false);
  }

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (defaultValue && defaultValue !== '') {
        const response = await api.get(`${endpoint}`, {
          params: { where: { _id: defaultValue } },
        });


        if (response?.data?.rows?.[0]) {
          const item = { value: response?.data?.rows?.[0]?._id, text: response?.data.rows?.[0]?.title };
          onChangeSelected(item);
        }
      }
    };

    load();
  }, []);

  const loadNew = async (string): Promise<void> => {

    if (string && string !== '') {
      const response = await api.get(`${endpoint}`, {
        params: project_id ? { where: { project_id: project_id }, search: string } : { search: string },
      });

      const items: Array<IOption> = [];

      response.data.rows.map(d => {
        const item: IOption = { value: d?._id, text: d?.title };

        items.push(item);
      })


      setData(items);
    }
  };





  async function loadOptions(text: string): Promise<void> {
    const response = await api.get(`${endpoint}`, {
      params: project_id ? { where: { project_id: project_id }, search: text } : { search: text },
    });

    const items: Array<IOption> = [];

    response?.data?.rows.map(d => {
      const item: IOption = { value: d?._id, text: d?.title };

      items.push(item);
    })



    setData(items);
  }


  function activeSelection(): void {
    setActive(true);
    loadOptions(value);
  }

  function handleInative(): void {
    onChangeSelected({ text: '', value: '' });
    setActive(false);
  }

  return (
    <Container isErrored={!!error} >
      <span>{label}</span>
      <FieldSelect active={active}>
        <div>
          <div id={`${name}Select`}>
            <input
              autoComplete="off"
              placeholder="Pesquisar..."
              id={`${name}Input`}
              value={value}
              onChange={e => {

                setValue(e.target.value);
                loadNew(e.target.value || '');
              }}
              onClick={activeSelection}
              type="text"
            />
            <button type="button" onClick={handleInative}>
              <FiXCircle />
            </button>
            <div className="selectDiv">
              <div className="selectHolder" />
              <select ref={selectRef} name={name}>
                <option value={currentOption.value}>
                  {currentOption.text || 'Selecionar'}
                </option>
              </select>
            </div>
          </div>
          <List className="List" active={active}>
            <ul>
              {data && data && data.length > 0 ? (
                data.map(option => (
                  <li value={option.value}>
                    <button
                      onClick={() => onChangeSelected(option)}
                      type="button"
                    >
                      {option.text}
                    </button>
                  </li>
                ))
              ) : (
                <li value=""> Nenhum resultado encontrado </li>
              )}
            </ul>
          </List>
        </div>
      </FieldSelect>
    </Container>
  );
};

export default SelectApiDirect;
