import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';


import { useConfiguration } from '../../../../hooks/Configuration';

import { useAuth } from '../../../../hooks/Auth';
import duplicateModule from './Modules/duplicate';
import { useSearch } from '../../../../hooks/Search';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}



const SaleCoupon: React.FC = () => {
  const endpoint = '/sales-coupon';
  const title = 'Cupom de desconto';
  const { reloadSearchAll } = useSearch();
  const { user } = useAuth();
  const formSchema = {
    hash: { model: 'input', type: 'text', name: 'hash', label: 'Hash' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Referência' },
    products: {
      model: 'jsonListMultipleProducts',
      type: 'text',
      name: 'products',
      label: 'Produtos',
      list: {
        product: { label: 'Produto', name: 'product', type: 'text' },
        title: { label: 'Título', name: 'title', type: 'text' },
        price: { label: 'Preço', name: 'price', type: 'currency' },
      },

    },

    limit_date: { model: 'datePickerHour', type: 'text', name: 'limit_date', label: 'Data de encerramento do cupom' },

  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'Protocolo', show: false },
    project_id: {
      ref: 'project_id',
      column: 'project_id',
      label: 'Projeto',

    },
    event_id: {
      ref: 'event_id',
      column: 'event_id',
      label: 'Evento', show: false,

    },
    hash: {
      ref: 'hash',
      column: 'hash',
      label: 'Hash',
      show: true,
    },
    products: {
      ref: 'products',
      column: 'products',
      label: 'Produtos',
      show: false,
    },
    title: {
      ref: 'title',
      column: 'title',
      label: 'Referência',
      show: true,
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: {},
          endpoint,
        }),

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema,
          validationSchema: {},
          endpoint,
        }),



      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: {},
          endpoint,
        }),

      (lineData: Record<string, any>) =>
        duplicateModule({
          lineData,
          reload: reloadSearchAll

        }),


    ],
  };

  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default SaleCoupon;
