import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { urlWebsite } from '../../../../config';
import { FaDownload } from 'react-icons/fa';
import ExportList from './modules/ExportList';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}



const IsentionRecurse: React.FC = () => {
  const endpoint = '/isention-recurse';
  const title = 'Solicitação de isenção';
  const { projectHash, eventHash } = useConfiguration();


  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'E-mail' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
    document: { ref: 'document', column: 'document', label: 'Documento' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {


  };

  const formSchemaUpdate = {
    ...formSchema,


  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const downloadModule = ({ lineData }) => {

    return <a href={`${urlWebsite}/${lineData?.document}`} download>
      <button className="lineIcon" title="Baixar documento" type="button"><FaDownload /></button>

    </a>
  }

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () => ExportList({ projectHash })
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>

        downloadModule({ lineData })

    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default IsentionRecurse;
