import React from 'react';
import Input from "../../../components/Forms/Input";

import { Form } from "@unform/web";

import { FiSearch } from "react-icons/fi";
import { ISearchBarProps } from "./interfaces";



const SearchBar: React.FC<ISearchBarProps> = ({ className = 'max-400', searchFunction }) => {


    const handleSearch = (data: Record<string, any>) => {

        searchFunction(data.text);

    }

    return <div className="container max-400 width-auto column gap">

        <div className="row container max-400 row-end">
            <Form className="row gap-sm" onSubmit={handleSearch}>
                <Input name="text" placeholder="Pesquisar..." />
                <button className="icon-button" type="submit"><FiSearch /></button>
            </Form>
        </div>

    </div >
}

export default SearchBar;