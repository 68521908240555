
import React from 'react';
import Button from "../../../components/Forms/Button";
import { useLoading } from "../../../hooks/LoadingHook";
import { useModal } from "../../../hooks/Modal";
import { ISearchContextData } from "../../../hooks/SearchHook";
import downloadit from "../../../utils/download";
import { useEffect, useState } from "react";
import { FaDatabase, FaFileExcel, FaPlusCircle } from "react-icons/fa";
import { IconType } from "react-icons/lib";


import { IHeadModuleBarProps } from "./interfaces";
import exportData from "./modules/ExportData";
import manageData from "./modules/ManageData";

interface IAllowAccess {
    profile: number;
    components: Array<string>;
}

interface IModule {
    title: string;
    icon: IconType;
    render: Function;
    allow_access: Array<IAllowAccess>;
}

const HeadModuleBar: React.FC<IHeadModuleBarProps> = ({ className = 'max-400', searchHook, endpoint }) => {

    const modalHook = useModal();

    const [allowedHeadModules, setAllowedHeadModules] = useState<Array<IModule>>([]);
    const { addLoading, removeLoading } = useLoading();


    const checkAllowed = (hook: ISearchContextData) => {

        const availableModules: Array<IModule> = [
            {
                title: 'Gerenciar Dados',
                icon: FaDatabase,
                allow_access: [
                    { profile: 1, components: ['*'] }
                ],
                render: async () => {
                    manageData(searchHook, modalHook)
                }
            },
            {
                title: 'Excel',
                icon: FaFileExcel,
                allow_access: [
                    { profile: 1, components: ['*'] }
                ],
                render: async () => {
                    exportData(endpoint, searchHook, modalHook, { addLoading, removeLoading })
                }
            },
            /* {
                title: 'Adicionar',
                icon: FaPlusCircle,
                allow_access: [
                    { profile: 1, components: ['*'] }
                ],
                render: async () => {
                    addLoading({ title: 'Carregando', key: 'loading' });

                    await downloadit({ url: endpoint, params: searchHook?.getSearchParams({ ...searchHook?.filterOptions, limitItems: 1000000, page: 1 }) })
                    removeLoading('loading')
                }
            } */


        ]

        setAllowedHeadModules(availableModules);


    }

    useEffect(() => {

        checkAllowed(searchHook);

    }, [searchHook])




    return <div className="container width-auto row gap-sm">



        {allowedHeadModules?.length > 0 && allowedHeadModules.map(moduleItem => {
            const IconItem = moduleItem.icon;
            return <Button key={`header-module-${moduleItem.title}`} className="icon-button" onClick={() => moduleItem.render()} type="button"> <IconItem /> </Button>

        })}



    </div >
}

export default HeadModuleBar;