import React, { useState, createContext, useContext, useCallback } from 'react';
import { uuid } from 'uuidv4';
import api from '../services/api';
import mask from '../utils/mask';
import { apiUrl, getToken } from '../config';
import { useAuth } from './Auth';
import downloadit from '../utils/download';
import { useParams } from 'react-router-dom';

interface IObject {
  [key: string]: string;
}

interface ISearchOrder {
  orderBy: string;
  direction?: 'asc' | 'desc';
}

export interface ISearchContainer {
  id: string;
  endpoint: string;
  search?: string;
  order?: ISearchOrder;
  currentPage?: number;
  pages?: number;
  limitItems?: number;
  where?: Record<string, any>;
  schema: Record<string, Record<string, any>>;
}

export interface ISearchPagination {
  pages: number;
  currentPage: number;
  limitItems?: number;
  countItems: number;
}

interface ISearchListPagination {
  [key: string]: ISearchPagination;
}

export interface ISearchRows {
  [key: string]: Record<string, any>;
}

interface IReload {
  type?: string;
  key: string;
  project_id?: string;
  event_id?: string;
  container?: Record<string, any>;
}

interface IAddSearch {
  key: string,
  config: ISearchContainer,
  project_id?: string;
  event_id?: string;

}

interface ISearchContextData {
  addSearch(IAddSearch): void;
  removeSearch(key: string): void;
  reloadSearch(IReload): Promise<void>;
  reloadSearchAll(): Promise<void>;
  changeSearchWhere(key: string, where: Record<string, any>): Promise<void>;
  changeSearchOrder(
    key: string,
    order: string,
    direction: string,
  ): Promise<void>;
  changePage(key: string, page: number): Promise<void>;
  changeSearchText(key: string, text: string): Promise<void>;

  dataRows: ISearchRows;
  paginations: ISearchListPagination;
  containers: Array<ISearchContainer>;
}

const SearchContext = createContext<ISearchContextData>(
  {} as ISearchContextData,
);



const SearchProvider: React.FC = ({ children }) => {
  const [containers, setContainers] = useState<Array<ISearchContainer>>([]);
  const { handleApiErrors } = useAuth();
  const [dataRows, setDataRows] = useState<ISearchRows>({});
  const [paginations, setPaginations] = useState<ISearchListPagination>({});

  const prepareKey = useCallback(() => uuid(), []);

  const addSearch = (
    {
      key,
      project_id,
      event_id,
      config
    }
  ) => {



    const getPaginations = { ...paginations };
    getPaginations[key] = { pages: 1, currentPage: 1, countItems: 0 };




    setPaginations({ ...getPaginations });

    const { id, endpoint, order, where = {}, search, pages, currentPage, limitItems, schema } = config;



    if (project_id) { where.project_id = project_id; }
    if (event_id) { where.event_id = event_id; }

    const newContainer = {
      id: key,
      endpoint,
      order: order ? order : { orderBy: '_id', direction: 'desc' },
      where: { ...where },
      search,
      pages: pages ? pages : 1,
      currentPage: currentPage ? currentPage : 1,
      limitItems: limitItems ? limitItems : 20,
      schema,
    };



    const containersList = [...containers, newContainer];

    setContainers(containersList);

    setDataRows(stateRow => ({ ...stateRow, [key]: [] }));

    reloadSearch({ id: key, container: newContainer })

    return key;
  };

  const removeSearch = useCallback(
    async (id: string) => {
      await setContainers(
        containers.filter((container: ISearchContainer) => container.id !== id),
      );
    },
    [containers],
  );

  const reloadSearch = useCallback(
    async ({ id, type = 'json', project_id = '', event_id = '', container = {} }) => {
      const replaceContainers = [...containers];


      let containerInfo: Record<string, any> = {};

      if (container?.id) {
        containerInfo = container;
      }
      else {
        const index = containers.findIndex(
          (container: ISearchContainer) => container.id === id,
        );

        if (index >= 0) {
          containerInfo = replaceContainers[index]
        }

      }





      if (containerInfo.id) {
        const {
          endpoint,
          search,
          order = [],
          where,
          schema,
        } = containerInfo;

        const currentPage =
          paginations[id] && paginations[id].currentPage
            ? paginations[id].currentPage
            : 1;

        const params = {
          search: search || '',
          order,
          where: where ? where : {},
          page: currentPage,
        };

        if (project_id) {
          params.where.project_id = project_id;
        }
        if (event_id) {
          params.where.event_id = event_id;
        }



        if (type === 'xlsx') {
          const string = `search=${encodeURIComponent(
            search || '',
          )}&order=${encodeURIComponent(
            JSON.stringify(order),
          )}&page=${currentPage}&where=${encodeURIComponent(
            JSON.stringify(where),
          )}`;

          downloadit({ url: `${endpoint}?hash=${getToken()}`, params: { search, order, page: currentPage, where }, type: 'xlsx' });


        } else {
          const display: Array<IObject> = [];


          try {
            const response = await api.get(endpoint, { params });

            const data = response?.data?.rows ? response.data.rows : response.data;
            data.map(
              (items) => {
                const elem: IObject = {};
                Object.keys(schema).map(key => {
                  elem[schema[key].column] = schema[key].mask
                    ? mask(items[schema[key].column], schema[key].mask || '')
                    : items[schema[key].column];
                });

                display.push(elem);

              },
              [],
            );



            calculatePages(id, response.data.count);
          }
          catch (err) {
            handleApiErrors(err);
          }
          await setDataRows(state => ({ ...state, [id]: display }));
        }
      }
    },
    [containers],
  );

  const reloadSearchAll = useCallback(async () => {
    Promise.all(containers.map(container => reloadSearch({ id: container.id, container })));
  }, [containers, reloadSearch]);

  const changeSearchText = useCallback(
    async (id, text) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      replaceContainers[index].search = text;

      setContainers([...replaceContainers]);
      await reloadSearch({ id, container: replaceContainers[index] });
    },
    [containers, reloadSearch],
  );

  const changePage = useCallback(
    async (id, page) => {
      const replacePagination = { ...paginations };

      if (replacePagination[id]) {
        replacePagination[id].currentPage = page;

        setPaginations({ ...replacePagination });
      }
      await reloadSearch({ id });
    },
    [containers, reloadSearch],
  );

  const calculatePages =
    async (id, count) => {
      const replacePagination = { ...paginations };

      if (!replacePagination[id]) {
        replacePagination[id] = { currentPage: 1, pages: 1, limitItems: 20, countItems: 0 };
      }

      if (replacePagination[id]) {
        const limit = (replacePagination[id].pages = Math.ceil(
          count / (replacePagination[id].limitItems || 20),
        ));
        replacePagination[id].countItems = count;


        setPaginations({ ...replacePagination });
      }
    }


  const changeSearchOrder =
    async (id, order, direction) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      if (replaceContainers[index] &&
        replaceContainers[index]?.order &&
        replaceContainers[index]?.order?.orderBy === order
      ) {
        direction =
          replaceContainers[index]?.order?.direction === 'asc' ? 'desc' : 'asc';
      }

      replaceContainers[index].order = { orderBy: order, direction };

      setContainers([...replaceContainers]);
      await reloadSearch({ id });
    }


  const changeSearchWhere = useCallback(
    async (id, where) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      replaceContainers[index].where = where;

      setContainers([...replaceContainers]);
      await reloadSearch({ id });
    },
    [containers, reloadSearch],
  );

  return (
    <SearchContext.Provider
      value={{
        addSearch,
        removeSearch,
        reloadSearch,
        reloadSearchAll,
        changeSearchOrder,
        changePage,

        changeSearchText,
        changeSearchWhere,
        paginations,
        dataRows,
        containers,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch(): ISearchContextData {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return context;
}

export { useSearch, SearchProvider };
