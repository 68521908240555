
import React from 'react';
import Button from "../../../components/Forms/Button";
import DatePicker from "../../../components/Forms/DatePicker2";
import { Form } from "@unform/web";
import { endOfDay, startOfDay, startOfMonth, startOfYear } from "date-fns";
import { addDays } from "date-fns/esm";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { IFilter, IFilterDate, IFilterBarProps } from "./interfaces";



const DateFilterBar: React.FC<IFilterBarProps> = ({ column = 'createdAt', className = 'max-400', filterOptions, filterFunction }) => {



    const [currentFilter, setCurrentFilter] = useState('');
    const [showCustom, setShowCustom] = useState(false);

    const today = endOfDay(new Date());



    const handleFilters = (filter: IFilter, options: IFilter[]) => {

        let newFilters = [...options];

        newFilters = newFilters.filter(item => item.type !== filter.type);
        newFilters.push(filter);


        return newFilters;


    }

    const clearFilter = () => {
        filterFunction([]);
        setCurrentFilter('')
    }

    const handleDate = ({ from, to, filterName }: IFilterDate) => {

        if (filterName === currentFilter && filterName !== 'custom') {
            clearFilter();
            return;
        }


        let options: IFilter[] = filterOptions?.dateFilters && filterOptions?.dateFilters?.length > 0 ? [...filterOptions?.dateFilters] : [];


        if (from?.type) {
            options = handleFilters(from, options)
        }

        if (to?.type) {
            options = handleFilters(to, options)
        }



        filterFunction(options);
        setCurrentFilter(filterName)

    }



    return <div className="container row row-end gap-sm width-auto">
        <div className="container width-auto row gap-sm row-end flex-wrap" style={{ display: !showCustom ? 'flex' : 'none' }}>

            <Button className={`button-xxs ${currentFilter === '' ? 'color-button' : 'soft-button'} `}
                onClick={() => { clearFilter() }}>
                Nenhum
            </Button>
            <Button className={`button-xxs ${currentFilter === '7days' ? 'color-button' : 'soft-button'} `}
                onClick={() => { handleDate({ filterName: '7days', from: { type: 'from', column, value: addDays(today, -7) }, to: { type: 'to', column, value: today } }) }}>
                7 dias
            </Button>
            <Button className={`button-xxs ${currentFilter === '15days' ? 'color-button' : 'soft-button'} `}
                onClick={() => { handleDate({ filterName: '15days', from: { type: 'from', column, value: addDays(today, -15) }, to: { type: 'to', column, value: today } }) }}>
                15 dias
            </Button>
            <Button className={`button-xxs ${currentFilter === '30days' ? 'color-button' : 'soft-button'} `}
                onClick={() => { handleDate({ filterName: '30days', from: { type: 'from', column, value: addDays(today, -30) }, to: { type: 'to', column, value: today } }) }}>
                30 dias
            </Button>

            <Button className={`button-xxs ${currentFilter === 'month' ? 'color-button' : 'soft-button'} `}
                onClick={() => { handleDate({ filterName: 'month', from: { type: 'from', column, value: startOfMonth(today) }, to: { type: 'to', column, value: today } }) }}>
                Mês atual
            </Button>
            <Button className={`button-xxs ${currentFilter === 'year' ? 'color-button' : 'soft-button'} `}
                onClick={() => { handleDate({ filterName: 'year', from: { type: 'from', column, value: startOfYear(today) }, to: { type: 'to', column, value: today } }) }}>
                Ano atual
            </Button>
            <Button className={`icon-button-xs mobileOnly `}
                onClick={() => { setShowCustom(state => !state) }}>
                <FaChevronDown className={showCustom ? 'rotate180' : ''} />
            </Button>
        </div>
        <div className="container width-auto row input-xs " style={{ display: showCustom ? 'flex' : 'none' }}>
            <Form onSubmit={() => { }} className="container  row gap-sm" >
                <DatePicker name="from_date" setCallback={(date: Date) => handleDate({ filterName: 'custom', from: { type: 'from', column, value: startOfDay(date) } })} placeholder="Data inicial" />
                <DatePicker name="to_date" placeholder="Até" setCallback={(date: Date) => handleDate({ filterName: 'custom', to: { type: 'to', column, value: endOfDay(date) } })} />
            </Form>
        </div>
        <Button className={`icon-button-xs computerOnly `}
            onClick={() => { setShowCustom(state => !state) }}>
            <FaChevronDown className={showCustom ? 'rotate180' : ''} />
        </Button>

    </div >
}

export default DateFilterBar;