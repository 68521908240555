import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique'
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IProps {
  lineData: Record<string, any>
}

export const UploadAreas: React.FC<IProps> = ({ lineData }) => {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();

  const confirmate = async (data) => {

    try {

      const response = await api.put(`/products-manager/${lineData?._id}/fields_selective_process`, { fields_selective_process: data });

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal('confirmaArea');

      addToast({ title: 'Atualizado com sucesso', type: 'success' })

    } catch (err) {


      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }

  }

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', data.image)
      const response = await api.post(`/xlsx-to-json`, formData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      const content = <>
        <table className='tableRef'>
          <tbody>
            <tr><td>Categoria</td><td>Área</td><td>Subárea</td></tr>
            {response?.data?.map(item => {
              const itemKey = uuid();
              return <tr key={itemKey} ><td>{item?.type}</td><td>{item?.area}</td><td>{item?.subarea}</td></tr>
            })}
          </tbody>
        </table>
        <button onClick={() => { confirmate(response?.data) }} className='defaultButtonReverse'>Confirmar</button>
      </>

      if (content) {
        const keyModal = addModal({ title: 'Adicionar Áreas', content, key: 'confirmaArea', theme: "whiteModal" });
      }



    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={{
        image: {
          model: 'uploadImage',
          type: 'upload',
          name: 'image',
          label: 'Imagem',
        }
      }} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title: 'Upload de Áreas', content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <button className="searchButton" type="button" onClick={handleCreate}>
      <FaUpload />
    </button> : <></>
  );

  return moduleCreate();
}
