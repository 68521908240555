import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../config';
import { FaCheckCircle, FaFilePdf, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { string } from 'yup';
import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { ContentAvaliation } from './styles';
import { useSearch } from '../../../../../../hooks/Search';
import { rgb } from 'polished';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IProps {
  content: Record<string, any>;
  values: Record<string, any>;
  avaliation: Record<string, any>;
  setAvaliation: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentAvaliationIndex: number;
  setCurrentAvaliationIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  globalCount: number;
}

interface IPropsList {
  content: Record<string, any>;
  lineData: Record<string, any>;
}

interface IOption {
  id: string;
  value: string;
  label: string;
  sub_title?: string;
}


export function useUnifyDocs({ lineData }) {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { configuration } = useConfiguration();




  const confirmUnify = async () => {

    try {

      await api.post(`unifi-selective-documents/${lineData?.project_id}/${lineData?._id}`)
      addToast({ title: 'Documento em unificação', type: 'success' })
      removeModal('modal')
    }
    catch (err) {
      addToast({ title: 'Erro ao unificar', type: 'error' })
    }

  }

  const unifyDocumentsToPDF = async () => {




    addModal({
      key: 'modal',
      title: ' ',
      content: <div style={{ width: '300px', padding: '30px 0px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '30px' }}>

        <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Unificar documentos do candidato?</h2>
        <button style={{ minWidth: '200px' }} onClick={() => confirmUnify()} className='nextAvaliation'>
          Sim</button>



      </div>,
      theme: 'whiteModal',


    })


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash && lineData?.status === 'aprovado' ? <button className='lineIcon' type="button" onClick={() => unifyDocumentsToPDF()}>
      <FaFilePdf />
    </button> : <></>
  );

  return moduleUpdate();
}
