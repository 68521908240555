import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaQrcode } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();

  const handleSend = async (id: string): Promise<void> => {
    try {
      const response = await api.post(`/v4/create-new-pix/${id}`);

      removeModal('modal')

      addToast({ title: 'Criado com sucesso!', type: 'success' });
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const openIt = (id) => {

    addModal({
      theme: 'whiteModalMedium',
      title: '', key: 'modal', content: <>
        <h2 style={{ color: '#333', fontSize: '14px' }}>Emitir novo pix?</h2>
        <button onClick={() => handleSend(id)} className='greenButton2'>Sim</button>
      </>
    })
  }

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Editar" type="button" onClick={() => openIt(id)}>
      <FaQrcode />
    </button> : <></>
  );

  return moduleUpdate(lineData.sale_hash);
}
