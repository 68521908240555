import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const PagamentosGeral: React.FC<IProps> = ({ changeStatus }) => {

  const { user } = useAuth();

  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])
  const [products, setProducts] = useState<Array<Record<string, any>>>([])
  const { projectHash, configuration } = useConfiguration();

  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const getPaymentMethod = {

    boleto: "Boleto",
    credit_card: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }


  const load = async () => {



    const response = await api.get(`/my-tickets`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }

  const loadProducts = async () => {



    const response = await api.get(`/products-list/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setProducts(response.data.rows);
    }

  }

  const printFields = (productList, item) => {

    const list: Array<JSX.Element> = [];
    const product = productList?.find(i => i?._id?.toString() === item?.product_id?.toString());



    if (item?.fields) {

      Object?.keys(item?.fields)?.map(key => {

        const fieldName = product?.fields?.find(p => p?.field === key);

        if (fieldName) {
          list.push(<p>{fieldName?.title}: {item?.fields?.[key]}</p>)
        }



      })



    }

    return list;
  }

  useEffect(() => {



    load();
    loadProducts();

  }, [])

  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Meus Geral</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr><th>Data</th> <th>Pagamento</th></tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}><td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td> <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <strong>{sale?.product_id_response?.title}</strong>
                    <p style={{ margin: '5px 0px' }}>Protocolo de inscrição: {sale?.sale_hash}</p>
                    {sale?.category ? <p>Categoria : {sale?.category}</p> : <></>}
                    {sale?.area ? <p>Área : {sale?.area}</p> : <></>}
                    {sale?.subarea ? <p>Subárea : {sale?.subarea}</p> : <></>}

                    {printFields(products, sale)}
                    <br /> <br />Pagador: {sale?.client_id_response?.name}

                    <br /> Valor: {format(sale.total)}
                    <br />Forma de pagamento: {getPaymentMethod?.[sale?.payment_method_id] || 'Não identificado'}
                    <br />Status de pagamento: {getPaymentStatus?.[sale?.payment_status_id] || 'Erro ao identificar'}
                    {sale?.boleto_url ? <><br /><a href={sale?.boleto_url}><strong>Clique aqui para visualizar o boleto </strong></a></> : <></>}



                    {sale?.payment_status_id === 'paid' && sale?.total > 0 && <><br />
                      <a href={`/app/${sale?.project_id}/recibo/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Visualizar Recibo </button></a></>}


                    {sale?.payment_status_id === 'paid' && sale?.project_id === 'autoavaliacao-hcpa-2022' && <><br />
                      <a href={`/app/${sale?.project_id}/formulario/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Visualizar formulário para impressão </button></a></>}


                  </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>Nenhum pagamento identificado</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default PagamentosGeral;
