import Axios from "axios"

type IProps = {
    endpoint: string,

}

export const loadFromJson = async ({ endpoint }: IProps) => {

    try {
        const date = new Date().getTime();

        const response = await Axios(`${endpoint}.json?t=${date}`);

        return response.data;


    } catch (err) {
        return false;
    }

}
