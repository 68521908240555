import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { logo } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';

interface SignInData {
  email: string;
  password: string;
}

const SignInAvaliador: React.FC = () => {
  const history = useHistory();
  const { signInAdmin } = useAuth();

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const { addModal, removeModal } = useModal();

  useEffect(() => {

    /*  if (projectHash !== 'residencia-medica-hcpa-2022') {
       addModal({
         theme: 'whiteModal',
         key: 'modal',
         title: '', content: <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
           <h2 style={{ color: '#333', fontSize: '24px' }}>Importante</h2>
 
           <h2 style={{ color: '#333', fontSize: '18px', margin: '15px 0px' }}>Se você está avaliando Residência Médica - HCPA, clique no botão abaixo e seja direcionado.</h2>
 
           <Link onClick={() => removeModal('modal')} to="/app/residencia-medica-hcpa-2022/login-avaliador-curriculos"><button className='defaultButtonReverse'>Acessar</button></Link>
 
         </div>
       })
     } */
  }, [])

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signInAdmin({ email, password });
        window.location.href = `/app/${projectHash}/avaliacao-curricular`;
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signInAdmin, addToast, history],
  );

  return (
    <Container>
      <Content>
        <AnimatedDiv>
          <img src={logo} alt="Login" />

          <h2>Área do avaliador</h2>

          {projectHash === 'residencia-medica-ufcspa-2022' ? <h2 style={{ color: '#333', maxWidth: '300px', textAlign: 'center', padding: '10px' }}>Avaliação UFCSPA liberada a partir do dia 28/11 às 13h</h2> : <></>}

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input icon={FiMail} name="email" placeholder="E-mail" />
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="senha"
            />
            <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button style={{ margin: '10px auto' }} type="submit">Entrar</Button>
            </aside>
          </Form>
        </AnimatedDiv>
      </Content>
    </Container>
  );
};
export default SignInAvaliador;
