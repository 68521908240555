import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

 
const EmailTheme: React.FC = () => {
  const endpoint = '/email-theme';
  const title = 'Modelo de e-mail';
    const {projectHash, eventHash } = useConfiguration(); 
    
    const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    hash: { ref: 'hash', column: 'hash', label: 'Identificador' },

    
  };
  const formSchema = {
    
    hash: { model: 'input', type: 'text', name: 'hash', label: 'identificador' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    
   
   
  };

  const formSchemaUpdate = {
    ...formSchema,
    header: {
      model: 'uploadImage',
      type: 'upload',
      name: 'header',
      label: 'Cabeçalho',
    },
    header_text: { model: 'input', type: 'text', name: 'header_text', label: 'Texto abaixo do cabeçalho' },
    footer: {
      model: 'uploadImage',
      type: 'upload',
      name: 'footer',
      label: 'Rodapé',
    },
    footer_text: { model: 'input', type: 'text', name: 'footer_text', label: 'Texto acima do rodapé' },


  };

  

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EmailTheme;
