import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import Input from '../../../../components/Forms/Input';

import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import getValidationErrors from '../../../../utils/getValidationErrors';
import ImageSingleGalleryInput from '../../../DashboardPages/core/components/Forms/ImageSingleGalleryInput';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import MaskedInput from '../../../DashboardPages/core/components/Forms/MaskedInput';
import AskProofRecurse from '../Home/modules/AskProofRecurse';
import AskSubscriptionRecurse from '../Home/modules/AskSubscriptionRecurse';

import { Container, ContainerWhite } from './styles';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../../hooks/Toast';
import Loader from '../../../../components/Loader';
import TextareaInput from '../../../../components/Forms/TextareaInput';

interface IProps {
  changeStatus: Function;
  selectiveProcess: Record<string, any>;
}

interface IProps2 {
  hash?: string;
  id?: string;
  setCallback: Function;
}

const IsentionRecursePage: React.FC<IProps2> = ({ hash, id, setCallback }) => {

  const { user } = useAuth();
  const { configuration } = useConfiguration();

  const { projectHash = hash } = useParams<Record<string, any>>();
  const [listSubscriptionRecurses, setListSubscriptionRecurses] = useState<Array<Record<string, any>>>([]);
  const [listProofRecurses, setListProofRecurses] = useState<Array<Record<string, any>>>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { addModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const { removeModal } = useModal();
  const { addToast } = useToast();

  const sendIsentionRecurse = async (data: Record<string, any>) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        recurse_justification: Yup.string().required('O campo justificativa é obrigatório'),



      });


      await schema.validate(data, { abortEarly: false });






      const newUser = await api
        .post(`/send-isention-recurse-against/${projectHash}/${id}`, {
          ...data,
          project_id: projectHash,


        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao enviar',
          });
          setLoading(false);
          return false;
        });

      removeModal('isention');


      setSuccess(true);
      setLoading(false);
      setCallback();

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  if (!configuration?._id) {

    return <Container >

      <h2>Carregando</h2>



    </Container >

  }


  if (success) {

    return <Container >
      <h3>{configuration?.title}</h3>
      <h2>Recurso isenção enviado com sucesso!</h2>


    </Container >


  }


  return (
    <>   {loading === true && (
      <Loader message="Preparando sua solicitação..." position="fixed" />
    )}
      <Container >
        <h3>{configuration?.title}</h3>
        <h2>Solicitação de recurso de isenção</h2>
        {configuration?.allow_isention_recurse_against === 'yes' ? <>
          <p>Após o envio do recurso, sua tela será atualizada e você poderá visualizar seu recurso junto a solicitação de isenção.</p>

          <Form ref={formRef} onSubmit={sendIsentionRecurse}>

            <TextareaInput name="recurse_justification" placeholder="Justificativa" />
            <p style={{ margin: '15px auto' }}>Caso deseje, envie um documento para apoiar sua justificativa. Tamanho máximo do documento: 5MB</p>
            <ImageSingleGalleryInput list={[{ name: 'url', title: 'PDF' }]} name="recurse_documents" placeholder='PDF único com todos os documentos' />



            <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <button className='defaultButtonReverse'>Enviar</button>
            </aside>
          </Form>

        </> : <>

          <p style={{ color: '#fff', background: 'rgb(0,50,150)', textAlign: 'center', padding: '10px', borderRadius: '10px' }}>O período de solicitação de isenção não está disponível no momento.</p>
          <Link to={`/app/${projectHash}`}><button className='defaultButton'>Voltar para a capa</button></Link>
        </>}


      </Container >
    </>
  );
};
export default IsentionRecursePage;
