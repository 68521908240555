
import styled, { css } from 'styled-components';
import { ContainerRef } from '../styles';

export const Container = styled(ContainerRef)`


 
   


`;

interface IProps {
    status: string;
}

export const ContainerSelectActive = styled.aside<IProps>`

width: 70px;
    display: flex;
    justify-content: center;
    transition: 0.5s all;
    background: #fff;
    padding: 5px;
    border: 2px solid #ddd;
    border-radius: 10px;
    >nav {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: 0.5s all;
        cursor: pointer;
        background: #ccc ;
       
    }

    >.SelectActionInput{
        background: rgb(0,200,0);
     
    }

    >.SelectActionInput{
        background: rgb(200,0,0);
       
    }
 
    ${props => props?.status && props.status === 'yes' && css`
    justify-content: flex-end;
    >nav {
        background: rgb(0,200,0);
    }
    `}

    ${props => props?.status && props.status === 'no' && css`
    justify-content: flex-start;
    >nav {
        background: rgb(200,0,0);
    }
    `}

`;