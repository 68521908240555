import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, Modal } from './styles';
import { useSocket } from '../../hooks/Socket';

import { useToast } from '../../hooks/Toast';
import Button from '../Button';
import Input from '../Forms/Input';
import { useParams } from 'react-router-dom';
import { useVideo } from '../../hooks/Video';
import { useConfiguration } from '../../hooks/Configuration';
interface ICreator {
  room_id?: string;
  module_id?: number;
}
 
export const RoomChangeContainer: React.FC<ICreator> = () => {
  const { socket } = useSocket();
  const {projectHash, eventHash} = useConfiguration();
  const {courses,openVideoId} = useVideo();
  useEffect(() => {
    socket.on('getRoomChange', state => {
    
      if(state.id === 'reload' && state.current_room){
        window.location.href = `/app/${projectHash}/dashboard/${state.current_room}`;
      }
      else if (state.id) {
      
        openVideoId(state.id);
    
     //   
      }
    });

    socket.on('getRoomChangeAll', state => {

      if(state.id === 'reload' && state.current_room){
        window.location.href = `/app/${projectHash}/dashboard/${state.current_room}`;
      }

      else if (state.id) {
        openVideoId(state.id);
        //window.location.href = `/app/${projectHash}/dashboard/${state.id}`;
      }
    });
  }, [courses,openVideoId]);

  return <></>;
};

export const RoomChangeCreator: React.FC<ICreator> = ({
  room_id = 0,
  module_id = 1,
}) => {
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [creatorActive, setCreatorActive] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleChange = data => {
    if (!data.destination_room) {
      addToast({ type: 'error', title: 'Defina uma sala de destino' });
      return;
    }

    if (data.destination_room) {
      socket.emit('sendRoomChange', { ...data });
      addToast({ type: 'success', title: 'Enviado com sucesso' });
      setCreatorActive(false);
    }
  };

  return (
    <>
      {!creatorActive && (
        <Button onClick={() => setCreatorActive(true)} type="button">
          Redirecionar usuários da sala
        </Button>
      )}
      {creatorActive && (
        <Modal top right fullHeight>
          <div>
            <button type="button" onClick={() => setCreatorActive(false)}>
              Fechar
{' '}
            </button>
            <Form
              ref={formRef}
              onSubmit={handleChange}
              initialData={{ current_room: room_id, destination_room: room_id }}
            >
              <Input
                name="current_room"
                placeholder="Hash Sala Atual (Zero para Global)"
              />
              <Input name="destination_room" placeholder="Hash de destino" />
              <Button type="submit">Enviar</Button>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};
