import React, { useEffect, useState } from 'react';
import Input from "../../../../components/Forms/Input";
import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useLanguage } from '../../../../hooks/Language';
import ArrayInputIncluderJoin from '../../../DashboardPages/core/components/Forms/ArrayInputIncluderJoin';
import TextareaInput from '../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadCurriculumContent from '../../../DashboardPages/core/components/Forms/UploadCurriculumContent';

interface IFields {
    type: string;
    area: string;
    subarea: string;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string | number;
}



interface IProps {
    fields: Array<IFields>;
    setCallBack?: Function;
    category?: Record<string, any>;


}

interface IAreas {
    title: string;
    subareas: Array<string>,

}

interface IType {
    title: string;
    areas: Array<IAreas>,

}

const CompleteSaleFields: React.FC<IProps> = ({ category, fields, setCallBack = () => { } }) => {
    const { translate } = useLanguage();

    const [fieldsOptions, setFieldsOptions] = useState<Array<IType>>([]);


    const categories = fieldsOptions?.reduce((prev, item) => {

        prev.push({ value: item.title, label: item.title });

        return prev;


    }, [] as Array<IOptions>)

    const categoryGlobalIndex = category?.category ? fieldsOptions?.findIndex(i => i.title === category?.category) : -1;


    const areas = categoryGlobalIndex >= 0 ? fieldsOptions[categoryGlobalIndex]?.areas?.reduce((prev, item) => {

        prev.push({ value: item.title, label: item.title });

        return prev;


    }, [] as Array<IOptions>) : [];

    const areaGlobalIndex = category?.area ? fieldsOptions[categoryGlobalIndex]?.areas?.findIndex(i => i.title === category?.area) : -1;


    const subareas = areaGlobalIndex >= 0 ? fieldsOptions[categoryGlobalIndex]?.areas?.[areaGlobalIndex]?.subareas?.reduce((prev, item) => {

        prev.push({ value: item, label: item });

        return prev;


    }, [] as Array<IOptions>) : [];

    useEffect(() => {

        const items: Array<IType> = [];

        fields?.map(field => {

            const indexType = items?.findIndex(i => i.title === field?.type);

            if (indexType >= 0) {

                if (field?.area) {
                    const indexArea = items[indexType]?.areas?.findIndex(i => i.title === field?.area);

                    if (indexArea >= 0) {

                        if (field?.subarea) {
                            items?.[indexType]?.areas?.[indexArea]?.subareas.push(field?.subarea);
                        }

                    }
                    else {

                        items?.[indexType]?.areas.push({
                            title: field?.area,
                            subareas: field?.subarea ? [field?.subarea] : []
                        })


                    }




                }

            }
            else {

                if (field?.area) {


                    if (field?.subarea) {
                        items.push({
                            title: field?.type, areas: [
                                { title: field.area, subareas: [field?.subarea] }
                            ]
                        })
                    }
                    else {
                        items.push({
                            title: field?.type, areas: [
                                { title: field.area, subareas: [] }
                            ]
                        })
                    }


                }
                else {
                    items.push({ title: field?.type, areas: [] })
                }


            }


        })


        setFieldsOptions(items);

    }, [fields])




    return <>
        <h2 style={{
            marginBottom: '10px',
            fontSize: '14px'
        }}>Selecione sua área de interesse</h2>
        <SelectSimple key={`type-field-selection`} setCallback={(value) => { setCallBack({ column: 'category', columnValue: value }) }} index={1} defaultValueItem={category?.category} options={categories} name="category" label="Categoria" />
        {category?.category && areas?.length > 0 ? <SelectSimple key={`area-${category?.category}`} setCallback={(value) => { setCallBack({ column: 'area', columnValue: value }) }} index={2} defaultValueItem={category?.area} options={areas} name="area" label="Área" /> : <></>}
        {category?.area && subareas?.length > 0 ? <SelectSimple key={`subarea-${category?.area}`} setCallback={(value) => setCallBack({ column: 'subarea', columnValue: value })} index={3} defaultValueItem={category?.subarea} options={subareas} name="subarea" label="Subarea" /> : <></>}

    </>;

}


export default CompleteSaleFields;