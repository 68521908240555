import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';

import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { usePages } from '../../../hooks/Pages';
import MainLayout from '../header';


const DefaultLayout: React.FC = ({ children }) => {
  const { token, user, handleApiErrors, signOut } = useAuth();
  const { menu } = usePages();
  useEffect(() => {

    /* if (user && user.email !== 'guilherme@encontrodigital.com.br') {
         signOut();
         window.location.href = '/';
       } */

  }, [user])

  return (
    <MainLayout>
      <Wrapper> <Menu menuList={menu} fixedMenu={false} />
        {children}


      </Wrapper>

    </MainLayout>
  );
};

export default DefaultLayout;
