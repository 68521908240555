import React from 'react';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import Input from "../../../../components/Forms/Input";
import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useLanguage } from '../../../../hooks/Language';
import ArrayInputIncluderJoin from '../../../DashboardPages/core/components/Forms/ArrayInputIncluderJoin';
import TextareaInput from '../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadCurriculumContent from '../../../DashboardPages/core/components/Forms/UploadCurriculumContent';

interface IFields {
    field: string;
    limit: number;
    label: string;
    title: string;
    description: string;
    type: string;
    options?: Array<string>;
    mask?: string;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string | number;
}

interface IOptionsCheckbox {
    text?: string;
    label: string;
    value: string;
    id: string;
}


interface IProps {
    fields: Array<IFields>;
    setCallBack?: Function;


}

const CompleteSaleFields: React.FC<IProps> = ({ fields, setCallBack = () => { } }) => {
    const { translate } = useLanguage();

    const readOptionsCheckbox = (texts) => {
        const options: Array<IOptionsCheckbox> = [];

        if (texts && texts.length > 0) {

            texts.map(option => {
                options.push({ label: option, value: option, id: option });
            })



        }

        return options;

    }
    const readOptions = (texts) => {
        const options: Array<IOptions> = [];

        if (texts && texts.length > 0) {

            texts.map(option => {
                options.push({ label: option, value: option });
            })



        }

        return options;

    }

    const readFields = (field: IFields, index: number) => {

        const valid = {
            checkbox: <><p style={{ margin: '10px auto', fontSize: '12px' }}>{translate(field?.description)}</p><CheckboxInput name={field?.field} options={readOptionsCheckbox(field?.options)} preText={<>{field?.title || ''}</>} /></>,
            select: <><p style={{ margin: '10px auto', fontSize: '12px' }}>{translate(field?.description)}</p><SelectSimple index={4 - index} name={field?.field} options={readOptions(field?.options)} label={field?.title} /></>,
            input: <><p style={{ margin: '10px auto', fontSize: '12px' }}>{translate(field.description)}</p><Input name={field.field} placeholder={field.title} /></>,
            textarea: <TextareaInput name={field.field} placeholder={field.title} />,
            arrayInputIncluder: <><p>{field.title}</p><p style={{ fontSize: '12px' }}>{field.description}</p><ArrayInputIncluderJoin mask={field?.mask || ''} label={field.label} limit={field.limit} name={field.field} placeholder={field.title} /></>,
            image: <><p style={{ fontSize: '12px' }}>{field.title}</p><p style={{ fontSize: '12px' }}>{field.description}</p><UploadCurriculumContent label={field.label} name={field.field} /></>
        };

        return valid[field?.type] ? valid[field?.type] : <></>;




    }

    return <>{fields?.length > 0 ? <aside style={{ width: '100%', zIndex: 1000, position: 'relative', margin: '10px auto', background: '#ddd', height: '2px' }} /> : <></>}
        {
            fields.map((f, index) => {
                return readFields(f, index);
            })
        }
        {fields.length > 0 ? <aside style={{ width: '100%', margin: '10px auto', background: '#ddd', height: '2px' }} /> : <></>}
    </>;

}


export default CompleteSaleFields;