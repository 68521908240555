import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { useToast } from '../../../../../../hooks/Toast';

interface IListInput {
  label: string;
  name: string;
  value ?: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list?: Array<IListInput>;
  placeholder: string;
  limit : number;
  label : string;
  mask ?: string;
}

const ArrayInputIncluderJoin: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  list,
  limit = 100,
  label = "Adicionar",
  mask = '1000',
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const {addToast} = useToast();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);


  const [value, setNewValue] = useState(defaultValue || [{id : uuid(), value: ''}]);
 

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);


  const transform = {
    "1000" : (valueContent) => {
      const length = valueContent.toString().length;
      if(length === 1){ return  `000${valueContent}`}
      if(length === 2){ return  `00${valueContent}`}
      if(length === 3){ return  `0${valueContent}`}
      return valueContent;
    }
  }


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {

        const valueArray : Array<string> = [];
        value.map( v => {

          let arrayValue = v.value;

          if(mask && transform?.[mask]){
            arrayValue = transform[mask](arrayValue);
          }

          valueArray.push(arrayValue);
        })

        

        return valueArray.join(';');
      },
      setValue: (ref: any,valueN : string) => {
        setNewValue( valueN ? JSON.parse(valueN) : [{id : uuid(), value: ''}]);
      }, 
    });
  }, [fieldName, value, registerField]);

  const addInput = () => {

    if(value.length >= limit){ 

      addToast({ title: 'Número máximo alcançado', type: 'info'});
      return;}
    const newHash = `q${new Date().getMilliseconds()}`;
    setNewValue([...value,{ id : uuid(), value : ''}]);
  }

  const removeItem = (id) => {
    let newValue : Array<Record<string,any>> = [...value];
 
    const index = newValue.findIndex( v => v.id === id);

     newValue.splice(index, 1);
 
    setNewValue([...newValue]);

  }



  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button style={{maxWidth: '300px', background: '#fff', color: '#333', borderRadius: '15px', padding: '10px', margin: '10px auto'}} type="button" onClick={addInput}>
        <FaPlus/> {label}
      </button>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />

     
      {value && value.map((v,index) => {
        let newValueItem = value?.[index]?.value;
        if(mask === '1000'){
         const itemValue = value?.[index]?.value ?  value?.[index]?.value.replace(/[^0-9]/g, '') : '00000';
         const startLength =  itemValue.toString().length - 4 <= 0 ? 0 :itemValue.toString().length - 4;
       
          newValueItem = itemValue.substring( startLength,5);
        }
       
          
        return (
          <Container
            key={v.id}
            {...rest}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocus}
          >
      
            <input
              placeholder={"Clique para preencher"}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={ v.value}
              value = {  newValueItem}
              onChange={e => {
                const newValue = [...value];
             

                  newValue[index].value = e.target.value;
           
                
               
                  setNewValue([...newValue ]);     }
              }
              name={v.id}
            /><FaTrash size={20} style={{marginLeft:'10px', cursor:'pointer'}} onClick={() => removeItem(v.id)}/>
            {error && (
              <Error title={error}>
                <FiAlertCircle />
              </Error>
            )}
          </Container>
        );
      })}

    </div>
  );
};

export default ArrayInputIncluderJoin;
