import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { background } from '../../../../config';


const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2{
    margin-top: 30px;
    margin-bottom: 15px;

    width: 100%;
    text-align:center;
    font-size: 24px;
    color: #fff;
  }


  .uploadsDiv{
    min-height: 200px;
    background: #eee;
    margin: 10px 0px;
    padding: 10px;
    width: 100%;

    display: flex ;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .uploadItem {
    position: relative;
    width: 200px;
    height: 200px;
   
    margin: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 150px !important;
      cursor: pointer;
    }
    

  }

  .uploadDelete{
    width: 30px;
    
    height: 30px ;
    background: rgba(0,0,0,0.3);
    display: flex ;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    border-radius: 50%;
    border: 1px solid #ddd;
    cursor: pointer ;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    

    svg{
      min-width: 20px;
    }


  }
  
  .uploadButton{
    width: 250px;
    padding: 5px 10px; 
    background: #fff;
    border: 2px solid #333;
    border-radius: 15px;
    display: flex ;
    align-items: center;
    justify-content: center;
    height: 33px;
    position: relative;
    color: #333;
    cursor: pointer;
    font-size: 12px;
    margin: 10px 0px;

    svg{
      color: #333 !important;
    }

    >input {
      opacity: 0.01;
      position: absolute ;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100% ;
      height: 100% ;
    }
  }

  .curriculum_div{
    display: flex ;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;

    h3{
      font-size: 14px;
      color: #333 !important;
      margin: 0px;
   
    }

    .marginBottom {
      margin-bottom: 10px;
    }

    p{
      font-size: 12px; 
      color: #333 !important ;
    }
  }

  .curriculum_categories_div{
    margin: 5px 0px;
    background: #eee;
    width: 100%;
    padding: 10px;
  }

  
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255,255,255,0.9);
  padding: 20px 20px;
  margin: 10px 0px;
  animation: ${fadeFromRight} 1s;
  border-radius: 10px;

  form {
    margin: 30px 0;
    width: 100%;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px 20px;
      width: 100%;
      max-width: 100%;
    }

    h2 {
      margin: 30px 0;
      color: ${'#fff'};
      font-size: 42px;

    }

    a {
      color: ${'#fff'};
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  @media (max-width: 750px) {
    width: 100%;
  }

  img {
    width: 300px;
    max-width: 100%;
    margin: 10px;


  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top left;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 0;
`;
