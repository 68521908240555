import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../hooks/Language';

import { Container } from './styles';

const Logo: React.FC = () => {

  const { language } = useLanguage();
  const [logoValue, setLogoValue] = useState("/apoio/logo-padrao.png");


  useEffect(() => {

    const validLogo = {
      ptBr: "/apoio/logo-padrao.png",
      en: "/apoio/logo-padrao.png",
      es: "/apoio/logo-padrao.png",
    }


    setLogoValue(validLogo?.[language] ? validLogo[language] : logoValue);

  }, [language])

  return (
    <Container>
      <img src={logoValue} />
    </Container>
  );
};
export default Logo;
