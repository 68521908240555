import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';

import { ModalProvider } from './Modal';
import { SearchProvider } from './Search';
import { ScrollProvider } from './Scroll';
import { SocketProvider } from './Socket';
import { PagesProvider } from './Pages';
import { InteractionProvider } from './Interactions';
import { LanguageProvider } from './Language';
import { ConfigurationProvider } from './Configuration';
import { LoadingProvider } from './LoadingHook';
const AppProvider: React.FC = ({ children }) => {
  return (<LoadingProvider>
    <AuthProvider>
      <LanguageProvider>
        <ConfigurationProvider>
          <PagesProvider>
            <SocketProvider>

              <InteractionProvider>
                <ToastProvider>
                  <SearchProvider>
                    <ModalProvider>

                      <ScrollProvider>{children}</ScrollProvider>

                    </ModalProvider>
                  </SearchProvider>
                </ToastProvider>
              </InteractionProvider>

            </SocketProvider>
          </PagesProvider>
        </ConfigurationProvider>
      </LanguageProvider>

    </AuthProvider>
  </LoadingProvider>
  );
};

export default AppProvider;
