import React, { useEffect, useState } from "react"
import SelectSimple from "../../../../../../components/Forms/SelectSimple";
import { Form } from "@unform/web";
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../DashboardPages/core/components/Forms/MaskedInput';
import api from "../../../../../../services/api";
import { useModal } from "../../../../../../hooks/Modal";
import pagarme from 'pagarme';
import { gateKey } from "../../../../../../config";
import { useLoading } from "../../../../../../hooks/LoadingHook";
import { useConfiguration } from "../../../../../../hooks/Configuration";
import { formatReal } from "../../../../../../utils/format";
interface IProps {
    project: Record<string, any>;
    reloadAll: Function;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string | number;
}


const PaymentRegistration: React.FC<IProps> = ({ project, reloadAll }) => {
    const { addModal, removeModal } = useModal();
    const { configuration } = useConfiguration();
    const [paymentOptions, setPaymentOptions] = useState<Record<string, any>>({});
    const [currentPaymentOption, setCurrentPaymentOption] = useState('');
    const [currentInstallment, setCurrentInstallment] = useState('0');
    const { addLoading, removeLoading } = useLoading();

    const [discountContent, setDiscountContent] = useState({ discount: '0', hash: '' })


    const tryDiscount = async (data) => {


        try {


            const discountInfo = await api.post(`/check-recurrence-payment-discount/${configuration?.registration_product}`, { hash: data?.hash })
            removeModal('discount');
            setDiscountContent({ discount: discountInfo?.data?.discount, hash: data?.hash })

        }
        catch (err) {

            addModal({ theme: 'whiteModalMini', key: 'discountError', title: '', content: <p style={{ color: '#333', padding: '15px', textAlign: 'center', width: '100%' }}>{err?.response?.data?.message || 'Erro ao validar código de desconto'}</p> })

        }


    }


    const addDiscountCoupon = () => {


        addModal({
            title: '', key: 'discount', theme: 'whiteModalMini', content: < Form onSubmit={tryDiscount} >

                <p style={{ color: '#333', padding: '15px', textAlign: 'center', width: '100%' }}>Insira o código de desconto </p>
                <Input name="hash" placeholder="" />
                <aside style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <button className="defaultButtonReverse">Enviar</button>
                </aside>
            </Form >
        })



    }

    const types = {
        credit_card: 'Cartão de crédito',
        boleto: 'Boleto',
        credit_card_recurring: 'Cartão de crédito - cobrança mensal',
        boleto_recurring: 'Boleto',
    }
    const payment_options: Array<IOptions> = paymentOptions?.payment_options?.reduce((prev: Array<IOptions>, item: Record<string, any>) => {

        prev.push({ label: types?.[item?.payment_method], value: item?.payment_method })
        return prev;
    }, [] as Array<IOptions>)



    const currentPayment = paymentOptions?.payment_options?.find(i => i?.payment_method === currentPaymentOption);
    const total = discountContent?.hash && discountContent?.discount ? (parseInt(currentPayment?.options?.find(i => parseInt(i?.installments, 10) === parseInt(currentInstallment, 10))?.total?.replace(',', ''), 10) * ((100 - parseInt(discountContent?.discount, 10)) / 100)) : currentPayment?.options?.find(i => parseInt(i?.installments, 10) === parseInt(currentInstallment, 10))?.total;

    const options: Array<IOptions> = currentPayment?.options?.reduce((prev: Array<IOptions>, item) => {


        const installmentValue = discountContent?.discount && discountContent?.hash ? parseInt((parseInt(item?.installment_value?.replace(',', ''), 10) * ((100 - parseInt(discountContent?.discount, 10)) / 100)).toString(), 10) : parseInt(item?.installment_value?.replace(',', ''), 10);



        if (discountContent?.hash && discountContent?.discount) {
            prev.push({ label: `${item?.installments}x de R$ ${formatReal(installmentValue)}`, value: item?.installments });
        }
        else {
            prev.push({ label: `${item?.installments}x de R$ ${formatReal(installmentValue)}`, value: item?.installments });
        }
        return prev;

    }, [] as Array<IOptions>) || [];



    const load = async () => {

        const product = await api.get(`/product-registration/${project?.url}`);
        setPaymentOptions(product?.data);

    }


    const requestPayment = async ({ discountContent, paymentMethod, installments, credit_card_hash = '', card_number = '', card_expiration_date = '', card_holder_name = '' }) => {


        try {
            const pay = await api.post(`/auto-payment/${paymentOptions?.project_id}/${paymentOptions?._id?.toString()}`, { paymentMethod, installments, credit_card_hash, card_number, card_expiration_date, card_holder_name, discountContent })
            reloadAll();
            addModal({ title: 'Solicitação de pagamento realizada com sucesso.', theme: 'whiteModalMini', content: <p>Confira as faturas na aba de pagamentos</p>, key: 'success' })
            removeLoading('loading');
        }
        catch (err) {
            addModal({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao finalizar pagamento', theme: 'whiteModalMini', content: <p>Confira as faturas na aba de pagamentos</p>, key: 'success' })
            removeLoading('loading');
        }



    }


    const sendPayment = async (data) => {
        addLoading({ title: 'Validando...', key: 'loading' })
        const { payment_method, installments, card_number, card_expiration_date, card_cvv, card_holder_name } = data;

        if (!payment_method) {
            addModal({
                title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                    Selecione uma forma de pagamento</p>
            });
            removeLoading('loading');
            return;
        }

        if (!installments) {
            addModal({
                title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                    Selecione um parcelamento válido</p>
            });
            removeLoading('loading');
            return;
        }

        if (payment_method?.indexOf('credit') >= 0) {
            if (!card_number) {
                addModal({
                    title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                        Informe o número do cartão</p>
                });
                return;
            }
            if (!card_expiration_date) {
                addModal({
                    title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                        Informe a data de validade do cartão</p>
                });
                removeLoading('loading');
                return;
            }
            if (!card_cvv) {
                addModal({
                    title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                        Informe o CVV do cartão</p>
                });
                removeLoading('loading');
                return;
            }
            if (!card_holder_name) {
                addModal({
                    title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                        Informe o nome do titular do cartão</p>
                });
                removeLoading('loading');
                return;
            }
        }

        if (payment_method?.indexOf('credit') >= 0) {


            const card = { card_number, card_expiration_date, card_cvv, card_holder_name };

            await pagarme.client
                .connect({ encryption_key: gateKey })
                .then(async (client: Record<string, any>) => {
                    const card_hash = await client.security.encrypt(card);

                    return card_hash;
                })
                .then(async (card_hash: string) => {
                    if (!card_hash) {
                        addModal({
                            title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                                Cartão inválido. Revise os dados informados</p>
                        });
                        removeLoading('loading');
                        return false;
                    }

                    await requestPayment({ discountContent, paymentMethod: payment_method, installments, credit_card_hash: card_hash, card_number, card_expiration_date, card_holder_name })

                });
        }
        else {
            await requestPayment({ discountContent, paymentMethod: payment_method, installments })

        }

    }

    useEffect(() => {

        load();

    }, [project]);

    return <div style={{ width: '100%', minHeight: '70vh' }}>  <h2 style={{ color: '#333', padding: '15px 10px' }}>Selecione a forma de pagamento</h2>

        <Form onSubmit={sendPayment} style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>



            <SelectSimple index={1} style={{ width: '100%' }} name="payment_method" label="Forma de pagamento" setCallback={(value) => setCurrentPaymentOption(value)} options={payment_options} />


            {currentPaymentOption === 'credit_card' || currentPaymentOption === 'credit_card_recurring' ? <>
                <Input style={{ width: '100%' }} name="card_number" placeholder={'Número do Cartão'} />
                <Input style={{ width: '100%' }}
                    name="card_holder_name"
                    placeholder={'Nome no Cartão'}
                />
                <MaskedInput style={{ width: '100%' }}
                    mask="99/99"
                    name="card_expiration_date"
                    placeholder={'Validade'}
                />
                <Input style={{ width: '100%' }} name="card_cvv" placeholder="CVV" />

            </>
                : <></>}
            {currentPaymentOption ? <SelectSimple index={2} style={{ width: '100%' }} name="installments" label="Parcelas" setCallback={(value) => setCurrentInstallment(value)} options={options} /> : <></>}
            {discountContent?.hash && discountContent?.discount ? <p style={{ color: '#333', width: '100%', textAlign: 'center' }}>Você recebeu {discountContent?.discount}% de desconto</p> : <></>}


            {total ? <h2 style={{ color: '#333', width: '100%', textAlign: 'center', padding: '15px 10px' }}>Total: R${formatReal(total)}</h2> : <></>}
            <button className="defaultButton" style={{ alignSelf: 'center', border: '2px solid rgb(0,15,64)', padding: '10px 15px' }}>Finalizar</button>

            <p style={{ cursor: 'pointer', color: '#333', padding: '15px', textAlign: 'center', width: '100%' }}
                onClick={() => { addDiscountCoupon() }}>Tenho um cupom de desconto</p>
        </Form>

    </div>

}

export default PaymentRegistration;