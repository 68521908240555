import styled from 'styled-components';


export const ContentAvaliation = styled.div`

border: 2px solid #ddd;
border-radius: 15px;

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

width: 100%;
max-width: 600px;
padding: 15px;

>div,h2,img{
    width: 100% ;
}

img,a{
    margin: 15px 5px;
}
.contentAvaliationScore{
    display: flex ;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    >nav{
        padding: 15px; 
        font-weight: bold;
    }
}

.separator{
    border-bottom: 2px solid #ddd;
    margin: 10px auto;
    width: 100%;
}

p.title{
    background: #333;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    margin: 10px 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size:16px;
    display: flex ;
    align-items: center;
    justify-content: center;
}

button.nextAvaliation{
    background: #41517E;
    color: #fff;
    padding: 15px;
    font-size: 12px;
    margin: 10px auto;
    border: 0px;
    border-radius: 10px;
}

select{
    padding: 15px;
    width: 100%;
    min-width: 100%;
    margin: 10px 0px;
}

.hugeImage{
    position: absolute;
    background: rgba(0,0,0,0.5);
    display : flex ;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    top: 0px;
    left: 0px;
  
    width: 100%;
    height: 100vh;

    >div {
        width: 100%;
        overflow-y: auto;
         
    }
    
}

`;