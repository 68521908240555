import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { authTitle } from '../config';
import { useSocket } from './Socket';
import { useAuth } from './Auth';
import { usePages } from './Pages';


interface ConfigurationState {
  token: string;
  user: Record<string, any>;
}

interface IOption {
  type: string;
  id: string;
  subtype: string;
  value: string;
}

interface ISignInAdmin {
  email: string;
  password?: string;
}

interface IConfiguration {
  _id?: string;
  title?: string;
  color?: string;
  program_title?: string;
  program_subtitle?: string;
  logo?: string;
  logo_adicional?: string;
  upper_text?: string;
  header_image?: string;
  data_image?: string;
  lower_text?: string;
  next_date?: Date;
  header_video?: string;
  fixed_image_header?: string;
  background?: string;
  background_color?: string;
  certificates?: string;
  theme_data?: Record<string, any>;
  current_event_id_response?: Record<string, any>;
  active_pages?: Array<Record<string, any>>;
  schedule?: Array<Record<string, any>>;
  local_address?: string;
  local_map?: string;
  curriculum_configuration?: Array<Record<string, any>>;
  curriculum_configuration_pre_requisitos?: Array<Record<string, any>>;
  curriculum_configuration_especial?: Array<Record<string, any>>;
  allow_medical_subscription_bank?: Array<Record<string, any>>;
  documents_configuration?: Array<Record<string, any>>;
  documents_configuration_pre_requisitos?: Array<Record<string, any>>;
  vaccine_configuration?: Array<Record<string, any>>;

  subscription_configuration?: Array<Record<string, any>>;
  register_steps?: Array<Record<string, any>>;

  stage?: string;
  bank?: string;
  allow_curriculum_avaliation?: string;
  allow_curriculum_avaliation_show?: string;
  allow_boleto?: string;
  allow_pix?: string;
  allow_credit_card?: string;
  allow_cortesy?: string;
  allow_curriculum_recurse?: string;
  allow_curriculum_recurse_show?: string;
  allow_special_treatment?: string;
  allow_subscription_recurse?: string;
  allow_proof_recurse?: string;
  allow_cancel?: string;
  allow_reserve?: string;
  allow_isention_recurse?: string;
  proof_books?: string;
  proof_books_pre_requisitos?: string;
  proof_questions?: string;
  proof_questions_pre_requisitos?: string;
  proof_recurses_questions_pre_requisitos?: Array<Record<string, any>>;
  proof_recurses_questions?: Array<Record<string, any>>;
  url?: string;
  registration_product?: string;
  allow_isention_recurse_against?: string;
}

interface IGame {
  reference?: string;
  points?: number;
  id?: number;
}

interface ConfigurationContextData {

  configuration: IConfiguration;
  setConfiguration(data: IConfiguration): void;
  projectHash: string;
  setProjectHash(data: string): void;
  eventHash: string;
  setEventHash(data: string): void;

}

const ConfigurationContext = createContext<ConfigurationContextData>({} as ConfigurationContextData);

const ConfigurationProvider: React.FC = ({ children }) => {
  const { socket } = useSocket();
  const { user, handleApiErrors } = useAuth();
  const { prepareMenu } = usePages();
  const [configuration, setConfiguration] = useState<Record<string, any>>({});
  const [projectHash, setProjectHash] = useState('');
  const [eventHash, setEventHash] = useState('');



  const load = async () => {
    // setLoading(true);

    if (projectHash) {

      try {
        const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);

        setConfiguration(response.data);



        if (response?.data?.active_pages?.length > 0) {

          prepareMenu({ eventHash, projectHash, active_pages: response?.data?.active_pages });
        }



        /*   setLoading(false);
          setReady(true); */

      }
      catch (err) {

        handleApiErrors(err);
        /*  setLoading(false); */
      }

    }
  }

  useEffect(() => {

    load();
  }, [projectHash, eventHash])

  return (
    <ConfigurationContext.Provider
      value={{
        configuration,
        setConfiguration,
        eventHash,
        setEventHash,
        projectHash,
        setProjectHash

      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

function useConfiguration(): ConfigurationContextData {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error('useConfiguration must be used within an ConfigurationProvider');
  }

  return context;
}

export { ConfigurationProvider, useConfiguration };
