import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { useInteraction } from '../../../../hooks/Interactions';
import { useSocket } from '../../../../hooks/Socket';
import uniqueHash from '../../../../pages/DashboardPages/core/components/UniqueHash';

import {LessonFooter,EmojiElem} from './styles';
interface IProps {
  room ?: string | number;
  reference ?: string;
};

const LessonEmoji: React.FC<IProps> = ({ reference , room }) => {
  const {socket} = useSocket();
  const [emojis,setEmojis] = useState<Array<JSX.Element>>([]);
  const {addGame} = useInteraction();
  useEffect(() => {
    socket.on(`emojiReceive`,(data) => {
    const {emoji} = data;
    if(emojiRef[emoji]){
      setEmojis( state => [...state,emojiRef[emoji]]);
    }
    })
  },[])

  const emojiRef = {
    happy : () => <img key={uniqueHash()} src="/emojis/happy.png"/>,
    love : () => <img key={uniqueHash()} src="/emojis/love.png"/>,
    smile : () => <img key={uniqueHash()} src="/emojis/smile.png"/>,
    sad: () => <img key={uniqueHash()} src="/emojis/sad.png"/>,
    party : () => <img key={uniqueHash()} src="/emojis/party.png"/>
  }

  const getRandomInt = () => {
    return Math.floor((30 - 1) * Math.random())
  }

  const emojiRefAnimated = {
    happy : () =>  <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/happy.png"/>,
    love : () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/love.png"/>,
    smile : () => <EmojiElem key={uniqueHash()}  left={getRandomInt()} src="/emojis/smile.png"/>,
    sad: () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/sad.png"/>,
    party : () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/party.png"/>
  }


  const appendEmoji = (item) => {
     
    if(emojiRefAnimated[item]){
      setEmojis( state => { 
        let items = [...state];
    
        return [emojiRefAnimated[item](),...items];
        
       //return  state.length > 20 ? [emojiRefAnimated[item]()] : [emojiRefAnimated[item](),...items];
      
      });

      socket.emit('emojiSend', { room_id : room, emoji: item, reference} );

      const getRoom = room ? parseInt(room?.toString(),10) : 0

  
      addGame({reference: 'emoji', id: parseInt(room ? room?.toString() : '',10), points: 1 })
      
    }
  }



  return <LessonFooter>
    {Object.keys(emojiRef).map( key => {
      return <div key={key} onClick={() => appendEmoji(key)}>{emojiRef[key]()}</div>
    })}
  <aside>
    {emojis}
  </aside>
    </LessonFooter>;
};

export default LessonEmoji;
