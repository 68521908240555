import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../config';
import { FaCheckCircle, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { string } from 'yup';

import { ContentAvaliation } from './styles';
import { useSearch } from '../../../../../../hooks/Search';
import { rgb } from 'polished';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IProps {
  content: Record<string, any>;
  values: Record<string, any>;
  avaliation: Record<string, any>;
  setAvaliation: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentAvaliationIndex: number;
  setCurrentAvaliationIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  globalCount: number;
}

interface IPropsList {
  content: Record<string, any>;
  lineData: Record<string, any>;
}

interface IOption {
  id: string;
  value: string;
  label: string;
  sub_title?: string;
}


const VisualizeCurriculum: React.FC<IProps> = ({ content, values, index, globalCount, avaliation, setAvaliation, currentAvaliationIndex, setCurrentAvaliationIndex }) => {



  const [hugeImage, setHugeImage] = useState('')


  const optionsList = content?.categories ? [...content?.categories] : [];


  const calculateSub = (data, sub, index = 0, url) => {





    const points = parseFloat(content?.categories?.[index]?.points);
    let value = 0;
    let currentPoints = 0;

    /* key === url */
    Object.keys(data.images).map(key => {



      currentPoints = currentPoints + parseFloat(data.images[key]);

      if (currentPoints > points) {
        currentPoints = points;
      }

    });





    return currentPoints > points ? points.toFixed(2) : currentPoints.toFixed(2);


  }


  const calculate = (data, sub, index = 0) => {



    const points = parseFloat(content.points);
    let value = 0;



    Object.keys(data.avaliations).map(key => {

      const content = data.avaliations[key]?.avaliations || {};
      const points = parseFloat(data.avaliations[key]?.points);



      let currentPoints = 0;

      Object.keys(content).map(k => {
        const imgValue = content[k];


        currentPoints = currentPoints + parseFloat(imgValue);

        if (currentPoints > points) {
          currentPoints = points;
        }

      });

      value = value + currentPoints;


    })


    return value > points ? points.toFixed(2) : value.toFixed(2);


  }

  const updateAvaliation = (value) => {




    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }



    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value.value
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    const calculateValue = calculate(getAvaliation[content.hash], false);

    getAvaliation[content.hash].current_value = calculateValue;


    setAvaliation({ ...getAvaliation })


  }



  const updateAvaliationSub = (value, index) => {




    /* Qual a subcategoria */

    /* Qual o valor da opção selecionada */

    /* Gravar na avaliação */

    /* Atualizar o valor parcial */


    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }



    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value.value
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    if (!getAvaliation[content.hash].sub_values) {
      getAvaliation[content.hash].sub_values = [];
    }



    if (!getAvaliation[content.hash].sub_values[index]) {
      getAvaliation[content.hash].sub_values[index] = {

        points: content?.categories?.[index]?.points,
        value: 0,
        images: {
          [value.url]: value.value
        }
      };
    }
    else {
      getAvaliation[content.hash].sub_values[index].images[value.url] = value.value
    }


    getAvaliation[content.hash].sub_values[index].value = calculateSub(getAvaliation[content.hash].sub_values[index], true, index, value.url);





    let finalPoint = 0;

    getAvaliation[content.hash].sub_values.map(p => {
      finalPoint = finalPoint + parseFloat(p?.value || '0')
    })



    getAvaliation[content.hash].current_value = content.points <= finalPoint ? content.points : finalPoint;


    setAvaliation({ ...getAvaliation })


  }


  if (content.show_subcategories === 'no') {

    return <ContentAvaliation style={{ color: '#333', display: 'flex' }} >
      <div className='contentAvaliationScore'>
        <h2>{content.title} </h2><nav style={{ minWidth: '100px' }}>Nota : {avaliation?.[content?.hash]?.current_value ? parseFloat(avaliation?.[content?.hash]?.current_value).toFixed(2) : "0"}</nav>

      </div>
      <div className='separator' />

      {values?.uploads?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

      {values?.uploads?.map((image, imageIndex) => {

        const options: Array<IOption> = [];

        {
          content?.categories?.map((av, index) => {
            options.push({ value: index, id: index, label: av.title });
          })
        }

        return <div style={{ background: '#eee', padding: '0px 15px', margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px', borderRadius: '10px' }}>
          <p className='title'>{imageIndex + 1} </p>
          {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ cursor: 'pointer', width: '70px', minWidth: '70px', padding: '5px' }} /> </a> : <a href={`${urlWebsite}/${image.url}`} download><img onClick={() => setHugeImage(`${urlWebsite}/${image.url}`)} key={image.url} src={`${urlWebsite}/${image.url}`} style={{ width: '100%', cursor: 'pointer', maxWidth: '200px' }} /></a>}

          <nav style={{ minWidth: '200px', background: '#ddd', padding: '10px' }}><strong></strong>Avaliação:

            {options.map(opt => {



              return avaliation?.[content?.hash]?.avaliations?.[opt.label]?.avaliations?.[image.url] ? <p style={{ color: '#333', fontSize: '12px' }}>{opt.label}<br /><br />Pontuação do doc.: {avaliation[content.hash].avaliations[opt.label].avaliations[image.url]} </p> : <></>;
            })}
          </nav>
        </div>
      })}

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>



      </div>
      <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
        <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
        <div>


          <img src={hugeImage} style={{ width: '100%' }} />
        </div>

      </div>
    </ContentAvaliation>
  }



  return <ContentAvaliation style={{ color: '#333', display: 'flex' }} >
    <div className='contentAvaliationScore'>
      <h2>{content.title} </h2><nav style={{ minWidth: '100px' }}>Nota : {avaliation?.[content?.hash]?.current_value ? parseFloat(avaliation?.[content?.hash]?.current_value).toFixed(2) : "0"}</nav>

    </div>
    <div className='separator' />

    {content.categories.map((category, categoryIndex) => {


      const key = category.hash;
      return <>
        {values?.[key]?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

        {values?.[key]?.map((image, imgIndex) => {

          const options: Array<IOption> = [];


          category?.subcategories?.map((av, index) => {
            options.push({ value: index, id: index, label: av.title });
          })



          const checkValue = (options) => {

            let item = <p style={{ color: '#333', fontSize: '12px' }}> Inválido <br /><br />Pontuação do doc.: 0.00  </p>


            options.map(opt => {

              if (avaliation?.[content?.hash]?.avaliations?.[opt.label]?.avaliations?.[image.url]) {

                item = <p style={{ color: '#333', fontSize: '12px' }}>{opt.label || 'Inválido'}<br /><br />Pontuação do doc.:  {avaliation?.[content.hash]?.sub_values?.[categoryIndex].images?.[image.url] || '0'} </p>;

              }
            })

            return item;

          }


          return <>
            {imgIndex < 1 ? <p style={{ padding: '10px', fontSize: '12px' }}><strong> Subcategoria:</strong><br />{category.title} </p> : <></>}
            <div style={{ background: '#eee', padding: '0px 15px', margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px', borderRadius: '10px' }}>


              <p className='title'>{imgIndex + 1} </p>

              {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ cursor: 'pointer', width: '70px', minWidth: '70px' }} /></a> : <a href={`${urlWebsite}/${image.url}`} download> <img key={image.url} src={`${urlWebsite}/${image.url}`} style={{ cursor: 'pointer', minWidth: '50px', width: '50px' }} /></a>}




              <nav style={{ minWidth: '200px', background: '#ddd', padding: '10px' }}><p>Avaliação:</p>
                {checkValue(options)}

              </nav>
            </div>

          </>
        })}

      </>
    })}





    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>




    </div>
    <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
      <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
      <div>


        <img src={hugeImage} style={{ width: '100%', }} />
      </div>

    </div>
  </ContentAvaliation >

}


const VisualizeCurriculumList: React.FC<IPropsList> = ({ content, lineData }) => {
  const [avaliation, setAvaliation] = useState<Record<string, any>>(lineData?.curriculum_avaliation || {});

  const [currentAvaliationIndex, setCurrentAvaliationIndex] = useState(0);
  const { addToast } = useToast();
  const { removeModal } = useModal();
  const { reloadSearchAll } = useSearch();



  const globalValue = Object.keys(avaliation).reduce((prev, key) => {


    prev = prev + parseFloat(avaliation?.[key]?.current_value);


    return prev;

  }, 0)

  const finishAvaliation = async () => {

    try {
      const response = await api.post(`/finish_curriculum_avaliation/${lineData._id}`, {
        curriculum_points: parseFloat(globalValue.toFixed(2)),
        avaliation: avaliation,
      });

      addToast({ title: 'Atualizado com sucesso' });
      removeModal('curriculum_avaliation');
      reloadSearchAll();
      return;
    }
    catch (err) {
      console.log(err);
      addToast({ title: err?.response?.data?.message || 'Erro ao salvar avaliação' })
    }

  }

  const fields: Array<JSX.Element> = [];

  content.map((item, index) => {

    const items = lineData?.curriculum?.[item.hash] || [];



    fields.push(<VisualizeCurriculum key={item.hash} globalCount={content.length} index={index} setCurrentAvaliationIndex={setCurrentAvaliationIndex} currentAvaliationIndex={currentAvaliationIndex} setAvaliation={setAvaliation} avaliation={avaliation} content={item} values={items} />);

  })

  return <>
    <div style={{ width: '100%', maxWidth: '500px', margin: '10px auto' }}>
      <h2 style={{ background: '#41517E', color: '#fff', textAlign: 'left', borderRadius: '10px', width: '100%', padding: '15px', margin: '0px auto' }}>Nota Global: {
        globalValue.toFixed(2)
      }</h2>
    </div>

    {fields}


  </>
}

interface IPropsVisualization {
  lineData: Record<string, any>;
}


const CurriculumVisualization: React.FC<IPropsVisualization> = ({ lineData }) => {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { configuration } = useConfiguration();



  const gerarRelatorioFinanceiro = async () => {

    const content = lineData?.area === 'ORTOPEDIA E TRAUMATOLOGIA' && lineData?.project_id === 'hmv-residencia-medica' ? [...configuration?.curriculum_configuration_especial || []] : lineData?.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? configuration.curriculum_configuration_pre_requisitos ? [...configuration.curriculum_configuration_pre_requisitos] : [] : configuration.curriculum_configuration ? [...configuration.curriculum_configuration] : [];




    addModal({
      title: 'Visualizar Currículo',
      content: <>{lineData?.curriculum_avaliation_observation ? <> <h2 style={{ color: '#333' }}>Observação:</h2><p style={{ color: '#fff', background: 'rgb(150,0,0)', padding: '15px 30px', marginTop: '15px', marginBottom: '30px' }} dangerouslySetInnerHTML={{ __html: lineData?.curriculum_avaliation_observation }} /> </> : <></>}<VisualizeCurriculumList content={content} lineData={lineData} /></>,
      theme: 'whiteModal',
      key: 'curriculum_avaliation',
      closeButton: false
    })


  }



  const moduleUpdate = (): JSX.Element => {

    const content = lineData?.area === 'ORTOPEDIA E TRAUMATOLOGIA' && lineData?.project_id === 'hmv-residencia-medica' ? [...configuration?.curriculum_configuration_especial || []] : lineData?.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? configuration.curriculum_configuration_pre_requisitos ? [...configuration.curriculum_configuration_pre_requisitos] : [] : configuration.curriculum_configuration ? [...configuration.curriculum_configuration] : [];

    return projectHash && lineData.curriculum_status === 'yes' ? <>{lineData?.curriculum_avaliation_observation ? <> <h2 style={{ color: '#333' }}>Observação:</h2><p style={{ color: '#fff', background: 'rgb(150,0,0)', padding: '15px 30px', marginTop: '15px', marginBottom: '30px' }} dangerouslySetInnerHTML={{ __html: lineData?.curriculum_avaliation_observation }} /> </> : <></>}<VisualizeCurriculumList content={content} lineData={lineData} /></> : <></>
  }


  return moduleUpdate();
}


export default CurriculumVisualization;