import React, { useEffect, useState } from 'react';
import { useConfiguration } from '../../../../hooks/Configuration';
import Program from '../../../../pages/Program';
import ProgramClean from '../../../../pages/ProgramClean';

 
const ProgramTheme : React.FC = () => {

    const {configuration} = useConfiguration();

    const [currentProgram,setCurrentProgram] = useState('');
    
    const [validPrograms,setValidPrograms] = useState(
        {
            default : <Program/>,
            clean : <ProgramClean/>
          }
    )


    useEffect(() => {

        console.log(configuration?.theme_data?.['program-theme']);

        setCurrentProgram(configuration?.theme_data?.['program-theme'] || 'default')

    },[configuration])
 
     if(currentProgram === ''){
         return '';
     }

    return   validPrograms[currentProgram] ? validPrograms[currentProgram] :  <Program/>
    
}

export default ProgramTheme;