import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import { date, simpleDate } from '../../../../utils/date';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const ProfileContent: React.FC<IProps> = ({ changeStatus }) => {


  const [profile, setProfile] = useState<Record<string, any>>({})

  const { projectHash, configuration } = useConfiguration();

  const eventProfile = profile?.event_profile?.[projectHash] || {};

  const load = async () => {



    const response = await api.get(`/student-info`);

    if (response?.data) {
      setProfile(response.data);
    }

  }

  useEffect(() => {

    if (projectHash) {

      load();
    }

  }, [projectHash])


  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Dados Gerais</p>
            <table><tbody>
              <tr><td>Nome</td><td>{profile?.name}</td></tr>
              <tr><td><p>E-mail</p></td><td><p>{profile?.email}</p></td></tr>
              <tr><td><p>Telefone</p></td><td><p>{profile?.phone}</p></td></tr>
              <tr><td><p>Data de nascimento</p></td><td><p>{date(profile?.birth_date)}</p></td></tr>

              <tr><td><p>Genêro</p></td><td><p>{profile?.gender_id}</p></td></tr>
              <tr><td><p>Etnia</p></td><td><p>{profile?.etnia}</p></td></tr>

              <tr><td><p>Naturalidade</p></td><td><p>{profile?.born_in}</p></td></tr>
              <tr><td><p>Nacionalidade</p></td><td><p>{profile?.nationality}</p></td></tr>


            </tbody></table>
          </div>

        </div>
        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Documentos informados na inscrição</p>
            <table><tbody>


              <tr><td><p>CPF</p></td><td><p>{profile?.document_number}</p></td></tr>
              <tr><td><p>RG</p></td><td><p>{profile?.rg}</p></td></tr>
              <tr><td><p>RG - Data de Emissão</p></td><td><p>{profile?.rg_date}</p></td></tr>
              <tr><td><p>RG - Orgão Emissor</p></td><td><p>{profile?.rg_emissor}</p></td></tr>
              <tr><td><p>RG - Estado do Orgão Emissor</p></td><td><p>{profile?.rg_uf}</p></td></tr>
              <tr><td><p>Título de eleitor</p></td><td><p>{profile?.voter_registration}</p></td></tr>

              rg_uf
            </tbody></table>
          </div>

        </div>
        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Instituição de graduação</p>
            <table><tbody>
              <tr><td><p>Universidade de Graduação</p></td><td><p>{profile?.instituicao}</p></td></tr>

            </tbody></table>
          </div>

        </div>

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Familiares</p>
            <table><tbody>


              <tr><td><p>Nome completo da mãe</p></td><td><p>{profile?.mother_name}</p></td></tr>
              <tr><td><p>Nome completo do Pai</p></td><td><p>{profile?.father_name}</p></td></tr>
            </tbody></table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default ProfileContent;
