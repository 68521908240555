import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';



import api from '../../../../../../services/api';
import { FaImage, FaPlusCircle, FaTicketAlt, FaTrash } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';
import { uuid } from 'uuidv4';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useAuth } from '../../../../../../hooks/Auth';
import { useToast } from '../../../../../../hooks/Toast';
import Loader from '../../../../core/components/Loader';
import { urlWebsite } from '../../../../../../config';
import { setTimeout } from 'timers';
import { useField } from '@unform/core';

interface IProps {
  placeholder: string;

  name: string;
}


const UploadFormContent: React.FC<IProps> = ({ name, placeholder }) => {
  const { addToast } = useToast();
  const { projectHash, configuration } = useConfiguration();
  const { addModal } = useModal();
  const [curriculumContent, setCurriculumContent] = useState<Record<string, any>>({ documents: { uploads: [] } });
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      setValue: (value, ref: any) => {
        return setCurriculumContent(value);
      },
      getValue: (ref: any) => {
        return curriculumContent;
      },
    });
  }, [fieldName, registerField]);


  useEffect(() => {
    if (defaultValue) {
      setCurriculumContent({ documents: defaultValue });
    }
  }, [defaultValue])

  const uploadPhoto = async ({ file, category, subcategory = '' }) => {

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post('/curriculum-upload', formData);
      if (response) {

        const data = { ...curriculumContent };




        if (!data?.[category]) {
          data[category] = { uploads: [] };
        }



        data[category].uploads.push({ url: response?.data?.message, hash: uuid(), createdAt: new Date() })


        await setTimeout(() => {




          setLoading(false);
          setCurriculumContent({ ...data });






        }, 3000);


      }
      else {

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      return addToast({ title: err.response.data.message || 'Erro ao realizar upload' })
    }
  };


  const uploadAndShow = async ({ event, category, subcategory = '' }) => {

    const file = event?.target?.files?.[0];

    if (!file) {
      return addToast({ title: 'Arquivo não encontrado', type: 'info' });
    }

    if (file.size > 4999999) {
      return addToast({ title: 'O tamanho máximo aceito para envio é de 5MB', type: 'error' });
    }



    if (file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return addToast({ title: 'São aceitos apenas arquivos em pdf, .png, .jpg e .jpeg', type: 'error' });
    }

    setLoading(true);

    await uploadPhoto({ file, category, subcategory });




  }

  const removeItem = ({ hash, category = '', upload }) => {

    setCurriculumContent(state => {

      const data = { ...state };
      if (category) {

        const itemIndex = data[hash][category].findIndex(i => i.hash === upload);

        if (itemIndex >= 0) {
          data[hash][category].splice(itemIndex, 1);
        }

      }
      else {

        const itemIndex = data[hash].uploads.findIndex(i => i.hash === upload);

        if (itemIndex >= 0) {

          data[hash].uploads.splice(itemIndex, 1);

        }

      }


      return data;
    })


  }

  const showImage = (url) => {

    addModal({ theme: 'whiteModal', title: ' ', content: <img style={{ width: '100%' }} src={`${urlWebsite}/${url}`} /> })

  }


  const placeOptions = (item, index) => {


    return <>
      {item.categories && item.categories.map(cat => {
        return <div key={`curriculum_categories_div-${cat.hash}`} className='curriculum_categories_div'>

          <div><p key={`${cat.title}-${index}`}>({cat.title}  </p>
            <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: 'documents', subcategory: cat.hash }) }} /> <FaImage size={20} /> Adicionar PDF / Imagem
            </button>
          </div>

          <div className='uploadsDiv'>
            {curriculumContent?.[item.hash]?.[cat.hash] && curriculumContent?.[item.hash]?.[cat.hash].map(up => {
              return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, category: cat.hash, upload: up.hash })}><FaTrash /></div>
              </div> : <div className='uploadItem' key={`uploadItem1-${up.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, category: cat.hash, upload: up.hash })}><FaTrash /></div>
              </div>
            })}

          </div>

        </div >


      })}

    </>



  }




  return (
    <Container>








      <h2 style={{ color: '#333', fontSize: '14px' }}>O tamanho máximo dos documentos é de 5mb, no formato pdf, png ou jpg</h2>








      {loading ? <Loader message='Carregando' /> : <></>}






      <div className='curriculum_div' key={`docs-div${'documents-list'}`}>


        <h3 key={placeholder}>{placeholder}  <strong title='Obrigatório' style={{ color: 'red' }}>*</strong> </h3>



        <button type="button" className='uploadButton' style={{ width: '100%' }}> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: 'documents' }) }} /> <FaImage size={20} />Adicionar PDF / Adicionar Imagem</button>


        <div className='uploadsDiv'>
          {curriculumContent?.['documents']?.uploads && curriculumContent?.['documents']?.uploads.map(up => {


            return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

              <div className='uploadDelete' onClick={() => removeItem({ hash: 'documents', upload: up.hash })}><FaTrash /></div>
            </div> : <div className='uploadItem' key={`uploadItem-yes-${'documents'}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

              < div className='uploadDelete' onClick={() => removeItem({ hash: 'documents', upload: up.hash })}><FaTrash /></div>
            </div>
          })}

        </div>



      </div>



    </Container >
  );
};

export default UploadFormContent;
