export const banks = [
    { "value": "246", "label": "Banco ABC Brasil S.A." },
    { "value": "121", "label": "Banco Agibank S.A." },
    { "value": "025", "label": "Banco Alfa S.A." },
    { "value": "065", "label": "Banco Andbank (Brasil) S.A." },
    { "value": "096", "label": "Banco B3 S.A." },
    { "value": "024", "label": "Banco BANDEPE S.A." },
    { "value": "318", "label": "Banco BMG S.A." },
    { "value": "752", "label": "Banco BNP Paribas Brasil S.A." },
    { "value": "107", "label": "Banco BOCOM BBM S.A." },
    { "value": "063", "label": "Banco Bradescard S.A." },
    { "value": "036", "label": "Banco Bradesco BBI S.A." },
    { "value": "394", "label": "Banco Bradesco Financiamentos S.A." },
    { "value": "237", "label": "Banco Bradesco S.A." },
    { "value": "218", "label": "Banco BS2 S.A." },
    { "value": "208", "label": "Banco BTG Pactual S.A." },
    { "value": "626", "label": "Banco C6 S.A." },
    { "value": "473", "label": "Banco Caixa Geral - Brasil S.A." },
    { "value": "040", "label": "Banco Cargill S.A." },
    { "value": "739", "label": "Banco Cetelem S.A." },
    { "value": "233", "label": "Banco Cifra S.A." },
    { "value": "745", "label": "Banco Citibank S.A." },
    { "value": "756", "label": "Banco Cooperativo Sicoob S.A." },
    { "value": "748", "label": "Banco Cooperativo Sicredi S.A." },
    { "value": "222", "label": "Banco Credit Agricole Brasil S.A." },
    { "value": "505", "label": "Banco Credit Suisse (Brasil) S.A." },
    { "value": "003", "label": "Banco da Amazônia S.A." },
    { "value": "083", "label": "Banco da China Brasil S.A." },
    { "value": "707", "label": "Banco Daycoval S.A." },
    { "value": "654", "label": "Banco Digimais S.A." },
    { "value": "001", "label": "Banco do Brasil S.A." },
    { "value": "047", "label": "Banco do Estado de Sergipe S.A." },
    { "value": "037", "label": "Banco do Estado do Pará S.A." },
    { "value": "041", "label": "Banco do Estado do Rio Grande do Sul S.A." },
    { "value": "004", "label": "Banco do Nordeste do Brasil S.A." },
    { "value": "224", "label": "Banco Fibra S.A." },
    { "value": "094", "label": "Banco Finaxis S.A." },
    { "value": "125", "label": "Banco Genial S.A." },
    { "value": "612", "label": "Banco Guanabara S.A." },
    { "value": "012", "label": "Banco Inbursa S.A." },
    { "value": "604", "label": "Banco Industrial do Brasil S.A." },
    { "value": "077", "label": "Banco Inter S.A." },
    { "value": "249", "label": "Banco Investcred Unibanco S.A." },
    { "value": "184", "label": "Banco Itaú BBA S.A." },
    { "value": "029", "label": "Banco Itaú Consignado S.A." },
    { "value": "479", "label": "Banco ItauBank S.A" },
    { "value": "376", "label": "Banco J. P. Morgan S.A." },
    { "value": "074", "label": "Banco J. Safra S.A." },
    { "value": "217", "label": "Banco John Deere S.A." },
    { "value": "630", "label": "Banco Letsbank S.A." },
    { "value": "600", "label": "Banco Luso Brasileiro S.A." },
    { "value": "243", "label": "Banco Master S.A." },
    { "value": "389", "label": "Banco Mercantil do Brasil S.A." },
    { "value": "370", "label": "Banco Mizuho do Brasil S.A." },
    { "value": "746", "label": "Banco Modal S.A." },
    { "value": "456", "label": "Banco MUFG Brasil S.A." },
    { "value": "212", "label": "Banco Original S.A." },
    { "value": "623", "label": "Banco PAN S.A." },
    { "value": "611", "label": "Banco Paulista S.A." },
    { "value": "643", "label": "Banco Pine S.A." },
    { "value": "747", "label": "Banco Rabobank International Brasil S.A." },
    { "value": "633", "label": "Banco Rendimento S.A." },
    { "value": "120", "label": "Banco Rodobens S.A." },
    { "value": "422", "label": "Banco Safra S.A." },
    { "value": "033", "label": "Banco Santander (Brasil) S.A." },
    { "value": "743", "label": "Banco Semear S.A." },
    { "value": "276", "label": "Banco Senff S.A." },
    { "value": "366", "label": "Banco Société Générale Brasil S.A." },
    { "value": "299", "label": "Banco Sorocred S.A. - Banco Múltiplo (AFINZ)" },
    { "value": "464", "label": "Banco Sumitomo Mitsui Brasileiro S.A." },
    { "value": "082", "label": "Banco Topázio S.A." },
    { "value": "634", "label": "Banco Triângulo S.A." },
    { "value": "653", "label": "Banco Voiter S.A." },
    { "value": "655", "label": "Banco Votorantim S.A." },
    { "value": "610", "label": "Banco VR S.A." },
    { "value": "119", "label": "Banco Western Union do Brasil S.A." },
    { "value": "348", "label": "Banco XP S.A." },
    { "value": "021", "label": "BANESTES S.A. Banco do Estado do Espírito Santo" },
    { "value": "755", "label": "Bank of America Merrill Lynch Banco Múltiplo S.A." },
    { "value": "250", "label": "BCV - Banco de Crédito e Varejo S.A." },
    { "value": "144", "label": "BEXS Banco de Câmbio S.A." },
    { "value": "017", "label": "BNY Mellon Banco S.A." },
    { "value": "070", "label": "BRB - Banco de Brasília S.A." },
    { "value": "104", "label": "Caixa Econômica Federal" },
    { "value": "320", "label": "China Construction Bank (Brasil) Banco Múltiplo S.A." },
    { "value": "477", "label": "Citibank N.A." },
    { "value": "487", "label": "Deutsche Bank S.A. - Banco Alemão" },
    { "value": "062", "label": "Hipercard Banco Múltiplo S.A." },
    { "value": "269", "label": "HSBC Brasil S.A. - Banco de Investimento" },
    { "value": "341", "label": "Itaú Unibanco S.A." },
    { "value": "488", "label": "JPMorgan Chase Bank, National Association" },
    { "value": "399", "label": "Kirton Bank S.A. - Banco Múltiplo" },
    { "value": "128", "label": "MS Bank S.A. Banco de Câmbio" },
    { "value": "254", "label": "Paraná Banco S.A." },
    { "value": "751", "label": "Scotiabank Brasil S.A. Banco Múltiplo" },
    { "value": "014", "label": "State Street Brasil S.A. - Banco Comercial" },
    { "value": "095", "label": "Travelex Banco de Câmbio S.A." },
    { "value": "129", "label": "UBS Brasil Banco de Investimento S.A." }
];