import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import AskProofRecurse from '../Home/modules/AskProofRecurse';
import AskSubscriptionRecurse from '../Home/modules/AskSubscriptionRecurse';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;
  selectiveProcess: Record<string, any>;
}

const Recurses: React.FC<IProps> = ({ changeStatus, selectiveProcess }) => {

  const { user } = useAuth();
  const { configuration } = useConfiguration();

  const { projectHash } = useParams<Record<string, any>>();
  const [listSubscriptionRecurses, setListSubscriptionRecurses] = useState<Array<Record<string, any>>>([]);
  const [listProofRecurses, setListProofRecurses] = useState<Array<Record<string, any>>>([]);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState('');

  const [paymentInfo, setPaymentInfo] = useState<Record<string, any>>()
  const { addModal } = useModal();


  const loadCheckPayment = async () => {


    const responsePayment = await api.get(`/checkPayment/${projectHash}`);

    if (responsePayment?.data && responsePayment?.data?.payment_status_id === 'paid') {
      setPayment('paid');
    }
    else {
      setPayment('waiting');
    }
    if (responsePayment?.data) {
      setPaymentInfo(responsePayment?.data);
    }


  }

  const load = async () => {
    setLoading(true);
    const subscription = await api.get(`my-selective-process-subscription-recurse/${projectHash}`);
    if (subscription) {
      setListSubscriptionRecurses(subscription?.data?.rows || []);
    }

    const proof = await api.get(`my-selective-process-proof-recurse/${projectHash}`);
    if (proof) {
      setListProofRecurses(proof?.data?.rows || []);
    }

    setLoading(false);
  }


  useEffect(() => {
    load();
    loadCheckPayment();

  }, [])


  const reloadOptions = () => {
    load();
  }

  const askSubscriptionRecurse = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Recurso de inscrição',
      key: 'specialTreatment',
      content: <AskSubscriptionRecurse selectiveProcess={selectiveProcess} reload={reloadOptions} projectHash={projectHash} />

    })

  }

  const askProofRecurse = (saleInfo) => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Recurso da prova',
      key: 'specialTreatment',
      content: <AskProofRecurse selectiveProcess={saleInfo} reload={reloadOptions} projectHash={projectHash} />

    })

  }


  return (
    <>
      <Container >
        {configuration?.allow_subscription_recurse === 'yes' ? <button className='defaultButton' onClick={() => askSubscriptionRecurse()} >Solicitar recurso de Inscrição</button> : <></>}
        {configuration?.allow_proof_recurse === 'yes' && payment === 'paid' && paymentInfo ? <button className='defaultButton' onClick={() => askProofRecurse(paymentInfo)}>Solicitar recurso da Prova</button> : <></>}


        {!loading && listProofRecurses?.length < 1 && listSubscriptionRecurses?.length < 1 ?
          <div className='user-block shadow' >
            <div className='content-block' style={{ padding: '10px' }}>

              <p className='contentTitle'>Nenhum recurso encontrado</p>
            </div></div>
          : <></>}

        {listProofRecurses?.length >= 1 ?
          <div className='user-block shadow' >
            <div className='content-block' style={{ padding: '10px' }}>

              <p className='contentTitle'>Recursos de Prova</p>

              {listProofRecurses.map(proofItem => {

                return <aside key={proofItem._id} style={{ background: '#efefef', gap: '5px', width: '100%', maxWidth: '600px', color: '#333', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: '10px', marginTop: '15px', padding: '15px' }}>
                  <nav> <strong style={{ marginRight: '10px' }}>Protocolo:</strong>{proofItem.protocol}<br /></nav>
                  <nav> <strong style={{ marginRight: '10px' }}>Status:</strong>{proofItem.status}<br /></nav>
                  {proofItem?.book ? <nav> <strong style={{ marginRight: '10px' }}>Caderno:</strong>{proofItem.book}<br /></nav> : <></>}
                  {proofItem?.question ? <nav> <strong style={{ marginRight: '10px' }}>Questão:</strong>{proofItem.question}<br /></nav> : <></>}
                  <nav><strong style={{ marginRight: '10px' }}>Justificativa:</strong><br />{proofItem.description}<br /> <br /></nav>

                  {proofItem.answer ? <><nav><strong style={{ marginRight: '10px' }} > Resposta</strong>{proofItem.answer}<br /></nav></> : <></>}
                </aside >

              })}

            </div >

          </div >


          : <></>}

        {
          listSubscriptionRecurses?.length >= 1 ?
            <div className='user-block shadow' style={{ marginTop: '25px' }} >
              <div className='content-block' style={{ padding: '10px' }}>

                <p className='contentTitle'>Recursos de Inscrição</p>

                {listSubscriptionRecurses.map(subcriptionItem => {

                  return <aside key={subcriptionItem._id} style={{ background: '#efefef', gap: '5px', width: '100%', maxWidth: '600px', color: '#333', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: '10px', marginTop: '15px', padding: '15px' }}>
                    <nav> <strong style={{ marginRight: '10px' }}>Protocolo:</strong>{subcriptionItem.protocol}<br /></nav>
                    <nav> <strong style={{ marginRight: '10px' }}>Status:</strong>{subcriptionItem.status}<br /></nav>
                    {subcriptionItem?.book ? <nav> <strong style={{ marginRight: '10px' }}>Caderno:</strong>{subcriptionItem.book}<br /></nav> : <></>}
                    {subcriptionItem?.question ? <nav> <strong style={{ marginRight: '10px' }}>Questão:</strong>{subcriptionItem.question}<br /></nav> : <></>}
                    <nav>  <strong style={{ marginRight: '10px' }}>Justificativa:</strong><br />{subcriptionItem.description}<br /> <br /></nav >

                    {subcriptionItem.answer ? <nav>   <><strong style={{ marginRight: '10px' }} > Resposta</strong>{subcriptionItem.answer}<br /></></nav > : <></>
                    }
                  </aside >

                })}

              </div >

            </div >


            : <></>}


        {configuration?.allow_curriculum_recurse_show === 'yes' && selectiveProcess?.curriculum_recurse_status === 'yes' ?
          <div className='user-block shadow' style={{ marginTop: '25px' }} >
            <div className='content-block' style={{ padding: '10px' }}>

              <p className='contentTitle'>Recursos de Análise currícular</p>
              <aside style={{ background: '#efefef', gap: '5px', width: '100%', maxWidth: '600px', color: '#333', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: '10px', marginTop: '15px', padding: '15px' }}>

                <nav> <strong style={{ marginRight: '10px' }}>Recurso:</strong>{selectiveProcess?.curriculum_recurse_justification}<br /></nav>
                <nav> <strong style={{ marginRight: '10px' }}>Resultado:</strong>{selectiveProcess?.curriculum_recurse_avaliation_status === 'yes' ? 'Aprovado' : 'Recurso negado'}<br /></nav>

                <nav> <strong style={{ marginRight: '10px' }}>Justificativa:</strong>{selectiveProcess?.curriculum_recurse_avaliation_justification}<br /></nav>


              </aside >
            </div></div>
          : <></>}

      </Container >
    </>
  );
};
export default Recurses;
