import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaIdBadge, FaTrash } from 'react-icons/fa';
import { FiImage, FiMessageCircle } from 'react-icons/fi';
import { useParams } from 'react-router';
import ChatComponent from '../../components/ChatCopy';
import api from '../../services/api';
import Input from '../DashboardPages/core/components/Forms/Input';

import { Container, Grid} from './styles';


interface IProps {
  id : string;
}


const GreenRoom: React.FC = () => {
  const [currentClassName,setCurrentClassName] = useState('fadeIn');
  const [message,setMessage] = useState<JSX.Element | null>();
  const [type,setType] = useState('message');
  const {id} = useParams<IProps>();
  const [lowers,setLowers] = useState<Array<Record<string,any>>>([]);

  const load = async () => {

    const response = await api.get(`green-room/${id}`);


      setLowers(response?.data?.rows || [])
    

  }

  useEffect(() => {
    load();
  },[])


  const sendToScreen = (type, content) => {

    const valid = {
      'lower' : <div className={ ` lower`}> 
          <h2 style={{textTransform: 'uppercase'}}>{content?.title}</h2> 
          <strong>{content?.company}</strong>
          <p>{content?.position}</p>
      </div>,
      'message' : <div className={ ` lower`}> 
          <h2>{content?.message}</h2> 
          <strong>{content?.user_name}</strong>
     
      </div>
      
    }

    if(valid[type]){
      setMessage(valid[type]);


    }

  }

  const Lower : React.FC<IProps> = ({id}) => {

    const [option,setOption] = useState('list');

    const submitLower = async (data) => {

      data.room_id = id;

      await api.post('/green-room', {...data});

      setLowers( state => [...state,{...data}])
    }

    const removeLower = async(idLower) => {
      await api.delete(`/green-room/${idLower}`);
      load();
    }

    return (<div style={{minWidth: '400px', border: '1px solid #fff', padding: '10px', borderRadius:'10px'}}>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <button className="SimpleButton" onClick={() => setOption('list')}> Listar </button>
        <button className="SimpleButton" onClick={() => setOption('form')}> + Adicionar</button>
        </div>
        { option === 'form' && <Form onSubmit={submitLower}>
          <Input name="title" placeholder="Nome"/>
          <Input name="company" placeholder="Empresa"/>
          <Input name="position" placeholder="Cargo"/>

          <button type="submit" className="SimpleButton">Salvar</button>

        </Form> }
   
      {option === 'list' && 
      <div style={{height: '45vh', overflowY: 'scroll'}}>
        {lowers.map( (lower,index) => {
          return (<div style={{display:'flex', width:'100%', alignItems:'center'}}><div style={{width:'100%'}} className="lowerExample" onClick={() => { resetMessage('lower', lower ) }} id={`lower-${lower?.hash}`}>
              <strong>{lower?.title}</strong>
              <p>{lower?.company}</p>
              <p>{lower?.position}</p>
            </div><button style={{padding: '5px', width: '30px', height:'30px', borderRadius:'50%', border:'0px', background:'#fff', color: '#333'}} onClick={() => removeLower(lower._id)}><FaTrash/></button></div>)
        })}
      </div>}

    </div>);

  }
  
  const resetMessage = (type,content ) => {

    setCurrentClassName('fadeOut');


    
setTimeout(() => {
  setMessage(null);
  setCurrentClassName('fadeIn');
  if(type){
    sendToScreen(type,content);
  }
},1000);
    

  }


  return (
    <>
      <Container >
          <div className="holder">
            { message && <div className={currentClassName}>{message}</div> }
          </div>

          <div style={{margin: '5px'}}>
            <Grid>
              <div onClick={() => setType('message')}> <FiMessageCircle/> </div>
              <div onClick={() => setType('lower')}> <FaIdBadge/> </div>
              <div onClick={() => setType('logo')}> <FiImage/> </div>
              <div>
              <button className="SimpleButton" onClick={() => resetMessage('',{})}>Limpar Tela</button>
            </div>
            </Grid>
         
           { type === 'message' && <div style={{height:'45vh', overflowY:'scroll'}}><ChatComponent setCallback={(content) => resetMessage('message',content) } id={id}/></div>}
           {type === 'lower' && <Lower id={id}/>}
          </div>

      </Container>
    </>
  );
};
export default GreenRoom;
