import React, { useEffect, useState } from "react"
import SelectSimple from "../../../../../../components/Forms/SelectSimple";
import { Form } from "@unform/web";
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../DashboardPages/core/components/Forms/MaskedInput';
import api from "../../../../../../services/api";
import { useModal } from "../../../../../../hooks/Modal";
import pagarme from 'pagarme';
import { gateKey } from "../../../../../../config";
import { useLoading } from "../../../../../../hooks/LoadingHook";
import { useConfiguration } from "../../../../../../hooks/Configuration";
import { formatReal } from "../../../../../../utils/format";
import { CardDetails, CardholderName, CardLogo, CardNumber, CreditCardContainer, ExpiryDate, MagneticStrip } from "./CreditCardTemplate/styles";

interface IProps {
    project: Record<string, any>;
    reloadAll: Function;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string | number;
}


const CreditCardExchange: React.FC<IProps> = ({ project, reloadAll }) => {
    const { addModal, removeModal } = useModal();
    const { configuration } = useConfiguration();
    const [userData, setUserData] = useState<Record<string, any>>({});

    const { addLoading, removeLoading } = useLoading();

    const [finished, setFinished] = useState(false);









    const load = async () => {

        const product = await api.get(`/my-information`);
        setUserData(product?.data);

    }


    const requestPayment = async ({ credit_card_hash = '', card_number = '', card_expiration_date = '', card_holder_name = '' }) => {


        try {
            const pay = await api.post(`/auto-payment-credit-card`, { credit_card_hash, card_number, card_expiration_date, card_holder_name })
            await load();
            addModal({ title: 'Solicitação de alteração realizada com sucesso.', theme: 'whiteModalMini', content: <p></p>, key: 'success' })
            setFinished(true);
            removeLoading('loading');
        }
        catch (err) {
            addModal({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao alterar cartão', theme: 'whiteModalMini', content: <p></p>, key: 'success' })
            removeLoading('loading');
        }



    }


    const sendPayment = async (data) => {
        addLoading({ title: 'Validando...', key: 'loading' })
        const { payment_method, installments, card_number, card_expiration_date, card_cvv, card_holder_name } = data;





        if (!card_number) {
            addModal({
                title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                    Informe o número do cartão</p>
            });
            return;
        }
        if (!card_expiration_date) {
            addModal({
                title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                    Informe a data de validade do cartão</p>
            });
            removeLoading('loading');
            return;
        }
        if (!card_cvv) {
            addModal({
                title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                    Informe o CVV do cartão</p>
            });
            removeLoading('loading');
            return;
        }
        if (!card_holder_name) {
            addModal({
                title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                    Informe o nome do titular do cartão</p>
            });
            removeLoading('loading');
            return;
        }




        const card = { card_number, card_expiration_date, card_cvv, card_holder_name };

        await pagarme.client
            .connect({ encryption_key: gateKey })
            .then(async (client: Record<string, any>) => {
                const card_hash = await client.security.encrypt(card);

                return card_hash;
            })
            .then(async (card_hash: string) => {
                if (!card_hash) {
                    addModal({
                        title: '', key: 'warning', theme: 'whiteModalMini', content: <p style={{ width: '100%', textAlign: 'center', color: '#333' }}>
                            Cartão inválido. Revise os dados informados</p>
                    });
                    removeLoading('loading');
                    return false;
                }

                await requestPayment({ credit_card_hash: card_hash, card_number, card_expiration_date, card_holder_name })

            });


    }

    useEffect(() => {

        load();

    }, [project]);

    return userData ? <div style={{ width: '100%', minHeight: '70vh' }}>  <h2 style={{ color: '#333', padding: '15px 10px' }}>Atualização do cartão de crédito</h2>

        <Form onSubmit={sendPayment} style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>

            {userData?.credit_card?.last_digits ?
                <div style={{ width: '100%', padding: '10px 0px' }}>
                    <h2 style={{ color: '#333', padding: '10px' }}>Cartão atual:</h2>
                    <div className="row" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <CreditCardContainer>
                            <MagneticStrip />
                            <CardLogo>{userData?.credit_card?.brand}</CardLogo> {/* Fictício, pode personalizar */}
                            <CardNumber>
                                **** **** **** {userData?.credit_card?.last_digits}
                            </CardNumber>
                            <CardDetails>
                                <CardholderName>{userData?.credit_card?.holder_name}</CardholderName>
                                <ExpiryDate>
                                    {userData?.credit_card?.expiring_date}
                                </ExpiryDate>
                            </CardDetails>
                        </CreditCardContainer>
                    </div>
                </div>
                : <div style={{ width: '100%', padding: '10px 0px' }}><h2 style={{ color: '#333', padding: '10px' }}>Nenhum cartão cadastrado</h2></div>}

            {!finished ? <>
                <h2 style={{ color: '#333', padding: '10px' }}>Adicionar novo cartão de crédito:</h2>

                <Input style={{ width: '100%' }} name="card_number" placeholder={'Número do Cartão'} />
                <Input style={{ width: '100%' }}
                    name="card_holder_name"
                    placeholder={'Nome no Cartão'}
                />
                <MaskedInput style={{ width: '100%' }}
                    mask="99/99"
                    name="card_expiration_date"
                    placeholder={'Validade'}
                />
                <Input style={{ width: '100%' }} name="card_cvv" placeholder="CVV" />

                <button className="defaultButton" style={{ alignSelf: 'center', border: '2px solid rgb(0,15,64)', padding: '10px 15px' }}>Substituir cartão</button>
            </> : <></>}

        </Form>

    </div> : <p>Carregando...</p>

}

export default CreditCardExchange;