import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';


import { useAvaliateCurriculum as visualizateCurriculum } from './modules/visualizateCurriculum';



interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const SelectiveProccessSubscriptionRecurse: React.FC = () => {
  const endpoint = '/selective-process-subscription-recurse';
  const title = 'Recurso de inscrição - Processo seletivo';
  const { projectHash, eventHash } = useConfiguration();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },

    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },

    description: { ref: 'description', column: 'description', label: 'Descritivo' },
    documents: { ref: 'documents', column: 'documents', label: 'Documentos anexos', show: false },
    answer: { ref: 'answer', column: 'answer', label: 'Resposta' },
    status: { ref: 'status', column: 'status', label: 'Status' },
    term: { ref: 'term', column: 'term', label: 'Termo' },

  };

  const formSchema = {

    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },
    status: {
      model: 'selectSimple', options: [
        { label: 'Aguardando', value: 'Aguardando' },
        { label: 'Aprovado', value: 'Aprovado' },
        { label: 'Negado', value: 'Negado' },
      ],
      type: 'text', name: 'status', label: 'Status'
    },
    description: { model: 'textareaInput', type: 'text', name: 'description', label: 'Descritivo' },
    documents: { model: 'uploadForm', name: 'documents', label: 'Documentos anexados', placeholder: 'Documentos' },

    answer: { model: 'textareaInput', type: 'text', name: 'answer', label: 'Resposta da organização' },
  };

  const formSchemaUpdate = {

    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),


      (lineData: Record<string, any>) =>
        visualizateCurriculum({
          lineData,
          column: 'documents',
          title: 'Documentos anexos'

        }),
      /* (lineData: Record<string, any>) =>
        downloadRegister({
          lineData,


        }), */
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default SelectiveProccessSubscriptionRecurse;
