import React from 'react';
import { FaChalkboardTeacher, FaFileExcel, FaList } from 'react-icons/fa';

import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit, { downloaditPost } from '../../../../../utils/download';
import api from '../../../../../services/api';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../hooks/Configuration';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ExportList({ projectHash }): JSX.Element {

  const { user } = useAuth();
  const { configuration } = useConfiguration();

  const download = async () => {

    const recurses = await api.get(`${apiUrl}/selective-process-special-treatment`, { params: { limitItems: 100000, where: { project_id: projectHash } } })


    const studentsEmails: Record<string, any>[] = [];

    recurses?.data?.rows?.map(item => {
      studentsEmails.push({ _id: item?.client_id })
    })


    const students = studentsEmails?.length < 1 ? { data: { rows: [] } } : await api.get(`${apiUrl}/students`, { params: { limitItems: 100000, where: { "$or": studentsEmails } } })

    const salesList: Record<string, any>[] = [];

    students?.data?.rows?.map(item => {
      salesList.push({ client_id: item?._id?.toString() })
    })

    const sales = salesList?.length < 1 ? { data: { rows: [] } } : await api.get(`${apiUrl}/sales`, { params: { limitItems: 100000, where: { "$and": [{ project_id: projectHash }, { "$or": salesList }] } } })




    const excel = [
      [
        'Projeto',
        'Categoria',
        'Área',
        'Subárea',
        'Nome',
        'E-mail',
        'Telefone',
        'CPF',
        'Tipo',
        'Descritivo',
        'Documento',
        'Número de Inscrição',
        'Data de Nascimento',
        'Sexo',
        'Número RG',
        'Órgão RG',
        'UF RG',
        'Logradouro',
        'Número',
        'Complemento',
        'Bairro',
        'Cidade',
        'UF',
        'CEP '
      ]
    ];

    recurses?.data?.rows?.map(item => {

      const student = students?.data?.rows?.find(i => i?.email === item?.email);
      const sale = sales?.data?.rows?.find(i => i?.client_id === student?._id?.toString());

      excel.push([
        configuration?.title,
        sale?.category,
        sale?.area,
        sale?.subarea,

        item?.name,
        item?.email,
        item?.phone,
        item?.document_number,
        item?.type,
        item?.description,
        `${urlWebsite}/${item?.documents?.uploads?.[0]?.url}`,
        sale?.sale_hash || 'Não efetuou inscrição',
        student?.birth_date,
        student?.gender_id,
        student?.rg,
        student?.rg_emissor,
        student?.rg_uf,
        student?.address,
        student?.address_number,
        student?.address_type,
        student?.neighborhood,
        student?.city,
        student?.state,
        student?.zipcode,




      ])

    })



    await downloaditPost({ url: `${apiUrl}/converter/xlsx/`, data: excel, type: 'xlsx' });
  }

  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className='searchButton' type='button' title="Exportar Lista completa" onClick={() => download()}><FaList /> </button> : <></>}
    </>;
  }



  return moduleList();
}
