import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPaperPlane, FaPen, FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const key = uuid();

  const setCallback = async (
    data: Record<string, any>[],

  ): Promise<void> => {

    let x = 0;
    while (x < data?.length) {

      const item = data?.[x];
      console.log(item)

      await api.post(`/send-password-residencia/${item?.project_id}/${item?._id?.toString()}`);
      addToast({ title: `${x} de ${data?.length} - ${item?.name}` })
      x += 1;
    }

  }


  const handleCreate = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })
    const items = await api.get('/students', { params: { limitItems: 10000, where: { profile: 12, project_id: projectHash } } })
    removeLoading('loading')
    const content: Array<JSX.Element> = [];



    items?.data?.rows?.map(item => {

      content.push(<tr><td>{item?.name}</td><td>{item?.email}</td><td>{item?.type}</td><td>{item?.category_id}</td><td>{item?.subarea}</td> </tr>);

    })

    if (content) {
      const keyModal = addModal({
        title: 'Adicionar avaliadores', content: <>
          <button className='greenButton2' onClick={() => setCallback(items?.data?.rows)}>Enviar para todos</button>

          <table className='tableRef'><tbody>{content}</tbody></table></>, key, theme: "whiteModal"
      });
    }
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <button className="searchButton" title='Enviar e-mail para avaliadores' type="button" onClick={handleCreate}>
      <FaPaperPlane />
    </button> : <></>
  );

  return moduleCreate();
}
