import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaMoneyBill } from 'react-icons/fa';
import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useSearch } from '../../../../../../hooks/Search';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { addLoading, removeLoading } = useLoading();
  const { reloadSearchAll } = useSearch();


  const handleSend = async (data): Promise<void> => {
    addLoading({ title: 'Preparando pagamento', key: 'loading' })
    try {
      const response = await api.post(`/emit-invoice-payment/${lineData.invoice_hash}`, { additional_days: data?.additional_days || '' });

      addToast({ title: 'Pagamento realizado com sucesso!', type: 'success' });
      removeModal('SelectiveProcess');
      removeLoading('loading');
      reloadSearchAll();
    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao emitir', type: 'error' })
      handleApiErrors(err);
      removeLoading('loading')
    }
  };

  const handleGrade = () => {

    addModal({
      key: "SelectiveProcess",
      theme: 'whiteModalMini',
      title: '', content: <><p style={{ color: '#333', padding: '10px' }}> Você deseja emitir esta cobrança?</p>
        <Form onSubmit={handleSend}>
          <SelectSimple name='additional_days' label='Emissão especial com vencimento em dias' options={[
            { label: '01', value: 1 },
            { label: '02', value: 2 },
            { label: '03', value: 3 },
            { label: '04', value: 4 },
            { label: '05', value: 5 },
            { label: '06', value: 6 },
            { label: '07', value: 7 },
            { label: '08', value: 8 },
            { label: '09', value: 9 },
            { label: '10', value: 10 },
            { label: '11', value: 11 },
            { label: '12', value: 12 },
            { label: '13', value: 13 },
            { label: '14', value: 14 },
            { label: '15', value: 15 },
            { label: '16', value: 16 },
            { label: '17', value: 17 },
            { label: '18', value: 18 },
            { label: '19', value: 19 },
            { label: '20', value: 20 },
            { label: '21', value: 21 },
            { label: '22', value: 22 },
            { label: '23', value: 23 },
            { label: '24', value: 24 },
            { label: '25', value: 25 },
            { label: '26', value: 26 },
            { label: '27', value: 27 },
            { label: '28', value: 28 },
            { label: '29', value: 29 },
            { label: '30', value: 30 },
          ]} />
          <aside style={{ display: 'flex', justifyContent: 'center', padding: '10px', width: '100%' }}>
            <button className='defaultButtonMini' style={{ minWidth: '200px', padding: '10px' }}>Confirmar emissão</button>
          </aside>
        </Form>


      </>
    })

  }

  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" title="Emitir cobrança" type="button" onClick={() => handleGrade()}>
      <FaMoneyBill />
    </button>
  );

  return moduleUpdate();
}
