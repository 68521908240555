import React, { useState, useRef } from 'react';

import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaPrint, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';

import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';

import { useToast } from '../../../../../../hooks/Toast';


import { usePrinter } from '../../../../../../hooks/PrinterHook';

import { FiSend } from 'react-icons/fi';
import api from '../../../../../../services/api';


interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}


export default function ImprimirQuestao({ lineData }): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();




  const print = () => {
    printIt();
  };




  const sendPassword = async () => {
    try {
      await api.post(`/send-password-user/${lineData?.project_id}/${lineData?._id?.toString()}`);
      removeModal('sendPassword');
      addToast({ title: 'Enviado com sucesso', type: 'success' });
    }
    catch (err) {
      addToast({ title: 'Erro ao enviar', type: 'error' });
    }

  }

  const handleQR = async (): Promise<void> => {

    const content = <>  <h2 style={{ color: '#333' }}>Enviar um novo acesso para o usuário?</h2>
      <p style={{ color: '#333' }}>Ao encaminhar este e-mail, uma nova senha será encaminhada para o usuário.</p><br />

      <button onClick={() => sendPassword()} className='defaultButtonReverse'>Confirmar</button>
    </>;

    if (content) {
      const keyModal = addModal({ theme: 'whiteModal', title: '', content, key: 'sendPassword' });
    }



  };



  const moduleQRCode = (): JSX.Element => (


    lineData?.profile === 2 ? <button className="lineIcon" title="Enviar nova senha por e-mail" type="button" onClick={handleQR}>
      <FiSend color='rgb(0,100,150)' />
    </button> : <></>
  );

  return moduleQRCode();
}
