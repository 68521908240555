import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const SignIn: React.FC = () => {

  const videos = [
    { video_id : '588944625', type_id : '2'},
    {video_id : '588944578', type_id : '2'},
    {video_id : '588944528', type_id : '2'}
  ];

  const gallery = [
    { url : 'mostra/1.png'},
    { url : 'mostra/2.png'},
    { url : 'mostra/3.png'},
    { url : 'mostra/4.png'},
    { url : 'mostra/5.png'},
    { url : 'mostra/6.png'},
    { url : 'mostra/7.png'},
    { url : 'mostra/8.png'},
    { url : 'mostra/9.png'},
    { url : 'mostra/10.png'},
    { url : 'mostra/11.png'},
    { url : 'mostra/12.png'},
    { url : 'mostra/13.png'},
    { url : 'mostra/14.png'},
  ]

  const kids = [
    { url : 'mostra/kids1.jpg'},
    { url : 'mostra/kids2.jpg'},
  ]

  return (
    <>

<h1 style={{width:'100%', margin: '40px 10px', textAlign:'center'}}>MOSTRA CULTURAL</h1>
      <Container >
        <h2>
          VÍDEOS
        </h2>

        <VideoGallery data={videos}/>
      </Container>
      <Container >
        <h2>
          MOSTRA KIDS
        </h2>

        <ImageGallery data={kids}/>
      </Container>
      <Container >
        <h2>
          FOTOS
        </h2>

        <ImageGallery data={gallery}/>
      </Container>

      <Container >
        <h2>
          POESIAS
        </h2>

        <Speakers />
      </Container>
    </>
  );
};
export default SignIn;
