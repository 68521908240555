import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,
 
}: ICreateModule): JSX.Element {
  const {handleApiErrors} = useAuth();
  const { addToast } = useToast();
    

  const handleSend = async (id: string): Promise<void> => {
    try{
    const response = await api.post(`/send-sale-mail/${id}`);


    addToast({title:'Enviado com sucesso!', type:'success'});
  }
  catch(err){
    handleApiErrors(err);
  }
  };

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Editar" type="button" onClick={() => handleSend(id)}>
      <FiSend/>
    </button> : <></>
  );

  return moduleUpdate(lineData.sale_hash);
}
