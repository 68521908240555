import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInput';
import SelectSimple from '../../../../../../components/Forms/SelectSimple';
import Loader from '../../../../../../components/Loader';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import TextareaInput from '../../../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadFormContent from '../../../../../DashboardPages/features/UploadFormDocuments';

interface IProps {
    projectHash: string;
    reload: Function;
    selectiveProcess: Record<string, any> | undefined;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string | number;
}

const AskProofRecurse: React.FC<IProps> = ({ selectiveProcess, projectHash, reload }) => {


    const [specialTreatment, setSpecialTreatment] = useState();
    const { configuration } = useConfiguration();

    const [books, setBooks] = useState<Array<Record<string, any>>>();
    const [currentBook, setCurrentBook] = useState('');

    const book = books?.find(b => b.category === currentBook);


    const { addModal, removeModal } = useModal();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setBooks(selectiveProcess?.category === 'PRÉ-REQUISITOS' ? configuration?.proof_recurses_questions_pre_requisitos : configuration?.proof_recurses_questions)


    }, [configuration])

    const getBooks = (booksList) => {

        const options: Array<IOptions> = [];

        booksList?.map(item => {

            options.push({ label: item?.category, value: item?.category, text: item?.category })

        })

        return options;

    }

    const getQuestions = (bookItem) => {

        const questionNumber = parseInt(bookItem?.quantity, 10);

        const options: Array<IOptions> = [];

        let x = 1;

        while (x <= questionNumber) {
            options.push({ label: x.toString(), value: x });

            x = x + 1;
        }

        return options;

    }

    const sendSpecialTreatment = async (data) => {





        data.term = data?.term?.[0] ? data?.term?.[0] : '';



        setLoading(true);

        if (!data?.book) {
            addToast({ title: 'Informe o caderno', type: 'error' });
            setLoading(false);
            return;
        }

        if (!data?.question) {
            addToast({ title: 'Informe o número da questão', type: 'error' });
            setLoading(false);
            return;
        }

        if (!data?.description) {
            addToast({ title: 'A justificativa é obrigatória', type: 'error' });
            setLoading(false);
            return;
        }


        if (!data?.term) {
            addToast({ title: 'Confirme o termo', type: 'error' });
            setLoading(false);
            return;
        }

        try {

            const response = await api.post(`/send-selective-process-proof-recurse/${projectHash}`, { ...data, project_id: projectHash });
            addModal({ theme: 'whiteModalMedium', title: 'Solicitação de recurso da prova enviada', content: <p style={{ width: '100%', fontSize: '16px', color: '#333' }}>Você receberá um e-mail com o protocolo da solicitação</p> });
            removeModal('specialTreatment');
            reload();
            setLoading(false);

        }
        catch (err) {
            addToast({ title: err?.response?.data?.message || err?.response?.data?.message || 'Erro ao enviar recurso', type: 'error' });
            setLoading(false);
        }
    }
    return <>
        {loading ? <Loader message='Carregando...' /> : <></>}
        <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px', marginBottom: '15px' }}>Para solicitar recurso de inscrição, preencha os campos abaixo</p>
        <Form onSubmit={sendSpecialTreatment} style={{ paddingTop: '15px', borderTop: '1px solid #ddd' }}>

            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> CADERNO</p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><SelectSimple index={95} label='' setCallback={(value) => setCurrentBook(value)} options={getBooks(books)} name='book' /></div>

            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> QUESTÃO</p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><SelectSimple key={book?.category} index={98} label='' options={getQuestions(book)} name='question' /></div>


            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> DESCREVA O MOTIVO DO RECURSO</p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><TextareaInput name='description' placeholder="" /></div>


            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> ACEITE DO TERMO</h2>
            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'justify' }}>
                DECLARO, desde já, que as informações aqui prestadas e os documentos apresentados são verdadeiros e que
                estou ciente de que a não apresentação de qualquer documento para comprovar a condição que garante o deferimento da solicitação, ou, ainda, que a
                apresentação dos documentos fora dos padrões, prazo e/ou forma solicitados, implicará indeferimento da solicitação; DECLARO, ainda, que estou ciente de
                que, constatada falsidade em qualquer momento, poderei responder por crime contra a fé pública, nos termos da lei vigente, o que também acarretará
                minha eliminação deste processo seletivo.
            </p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><CheckboxInput unique name="term" placeholder="" options={[
                { id: 'Sim', value: 'Sim', label: 'Sim' },
            ]} /></div>

            <button style={{ margin: '10px auto' }} className="defaultButtonReverse">Enviar</button>
        </Form>

    </>
}

export default AskProofRecurse;