import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { authTitle } from '../config';
import { useSocket } from './Socket';
import { useAuth } from './Auth';


interface InteractionState {
  token: string;
  user: Record<string, any>;
}

interface IOption {
  type: string;
  id : string;
  subtype: string;
  value: string;
}

interface ISignInAdmin {
  email: string;
  password?: string;
}

interface IInteraction {
  type : string;
  id : string | number;
  subtype : string;
  value : string | number;
}

interface IGame {
  reference ?: string;
  points ?: number;
  id ?: number;
}

interface InteractionContextData {
  interactions: Record<string, any>;
  addInteraction(interaction: IInteraction): void;
  addGame(data : IGame) : void;
 
}

const InteractionContext = createContext<InteractionContextData>({} as InteractionContextData);

const InteractionProvider: React.FC = ({ children }) => {
  const {socket} = useSocket();
  const {user} = useAuth();
  const [data, setData] = useState<Record<string,any>>({});
  
  
const load = async () => {
    const interactions = localStorage.getItem(`@${authTitle}:interactions`);
  

    if (interactions) {
      setData(JSON.parse(interactions));
    }

    const response = await api.get('/interactions');

    

    if(response){
      localStorage.setItem(`@${authTitle}:interactions`, JSON.stringify(response?.data?.interactions || {}));
      setData(response?.data || {});
    }
  }

  useEffect(() => {
    localStorage.setItem(`@${authTitle}:interactions`, JSON.stringify(data));
  },[data])

  useEffect(() => {
    load();
  },[])

  const addGame = ({ reference ='', points = 0, id = 0 }) => {

    if(user){
    socket.emit('sendGaming',{ reference , points , id  } );
    }
  
  }


  const addInteraction = useCallback(
    async ({ type, id, subtype, value }: IOption) => {
      
      const currentData = {...data};

      if(currentData && !currentData[type]){
        console.log('v1');
        currentData[type] = {[id] : { [subtype] : value}}
      }
      else if(currentData && currentData[type] && !currentData[type][id]){
        currentData[type][id] = { [subtype] : value};
        console.log('v2');
      }
      else if(currentData && currentData[type] && currentData[type][id]) {
        console.log('v3');
        if(currentData[type][id][subtype]){
          console.log('v3.1');
          delete currentData[type][id][subtype];
          if(Object.keys(currentData[type][id]).length < 1){
            delete currentData[type][id];
            if(Object.keys(currentData[type]).length < 1){
              delete currentData[type];
            }
          }
          
        }
        else{
          console.log('v3.2');
          currentData[type][id][subtype] = value;
        }

      }

      api.post('/interactions',{type, id, subtype});

    
      setData(currentData);
      
    },
    [data],
  );



  

  return (
    <InteractionContext.Provider
      value={{
        addGame,
        interactions: data,
        addInteraction
       
      }}
    >
      {children}
    </InteractionContext.Provider>
  );
};

function useInteraction(): InteractionContextData {
  const context = useContext(InteractionContext);

  if (!context) {
    throw new Error('useInteraction must be used within an InteractionProvider');
  }

  return context;
}

export { InteractionProvider, useInteraction };
