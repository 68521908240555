import styled from 'styled-components';
import { darken } from 'polished';

interface ILink {
  fontSize: string;
  iconSize: string;
}

export const Whats = styled.a<ILink>`
  z-index: 1;
  border-radius: 10px;
  background: rgb(50, 150, 26) !important;
  min-width: 200px;
  width: auto;
  max-width: 100%;
  padding: 10px;
 
  font-size: 12px;
  font-weight: bold;
  color: #fff !important;
  cursor: pointer;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
   
  border: 2px solid #18ae00;
  text-decoration: none;

  transition: 0.2s all;
  cursor: pointer;
  box-shadow: rgb(7 13 28 / 25%) 0px 9px 90px;

  &:hover {
    background: ${darken(0.03, '#18ae00')};
    font-weight: bolder;
    color: #fff !important;
  }

  .icon {
    width: ${props => (props.iconSize ? props.iconSize : '20px')};
    height: ${props => (props.iconSize ? props.iconSize : '20px')};
    margin-right: 10px;
  }

  @media (max-width: 760px) {
    width: 200px;
  }
`;
