import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();


  const update = async (items) => {


    let x = 0;

    while (x < items?.length) {
      const item = items?.[x];

      if (item?.selective_process) {
        const newSubscription = { ...item?.subscription, residencia: item?.residencia, vinculo: item?.vinculo, residencia_matricula: item?.residencia_matricula }

        //  console.log(newSubscription)

        const response = await api.put(`/selective-process/${item?.selective_process}/subscription`, { subscription: newSubscription });


      }
      x = x + 1;
    }


  }

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', data.image)
      const response = await api.post(`/xlsx-to-json`, formData);

      const IDs: Array<Record<string, any>> = [];

      response.data.map(item => {
        IDs.push({ document_number: item?.document_number });
      })

      const students = await api.get(`/students`, { params: { limitItems: 10000 } });
      const selective_processes = await api.get(`/selective-process`, { params: { where: { project_id: projectHash }, limitItems: 10000 } });

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      // removeModal(key);


      const items: Array<Record<string, any>> = [];

      response.data.map(item => {

        if (item?.document_number) {

          const student = students?.data?.rows.find(i => item?.document_number ? i?.document_number?.trim() === item?.document_number?.toString()?.trim() : false)

          const selective_process = selective_processes?.data?.rows.find(i => i?.client_id === student?._id?.toString())



          const newItem = {
            selective_process: selective_process?._id,
            name_student: student?.name,
            email: student?.email,
            name: item?.nome,
            document_number: item?.document_number,

            vinculo: item?.vinculo,
            residencia: item?.residencia,
            residencia_matricula: item?.residencia_matricula,
            subscription: selective_process?.subscription

          }

          items.push(newItem);

        }

      })





      const keys = Object.keys(items?.[0]);

      const html = <><button className='defaultButton' onClick={() => update(items)}>Atualizar</button> <table className='tableRef'>
        <thead>
          <tr>


            {keys.map(keyValue => <td>{keyValue}</td>)}
          </tr>
        </thead>
        <tbody>
          {items.map(item => {




            return <tr>

              {keys.map(
                keyValue => <td>{keyValue === 'subscription' ? '' : item?.[keyValue]}</td>
              )}</tr>


          })}
        </tbody>
      </table>

      </>


      addModal({ title: '', content: html, theme: 'whiteModal' })



    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }
      console.log(err)
      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={{
        image: {
          model: 'uploadImage',
          type: 'upload',
          name: 'image',
          label: 'Imagem',
        }
      }} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title: 'Listar dados', content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <button title='Atualizar subscription' className="searchButton" type="button" onClick={handleCreate}>
      <FaUpload color='purple' />
    </button> : <></>
  );

  return moduleCreate();
}
