import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { FaCreditCard, FaObjectGroup, FaReceipt } from 'react-icons/fa';
import format from '../../../../utils/format';
import { useAuth } from '../../../../hooks/Auth';
import { UploadAreas } from './Modules/UploadAreas';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const ProductsAdmin: React.FC = () => {
  const endpoint = '/products-manager';
  const title = 'Produtos';
  const { projectHash, eventHash } = useConfiguration();

  const { user } = useAuth();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    hash_link: { ref: 'hash_link', column: 'hash_link', label: 'Link' },

    title: { ref: 'title', column: 'title', label: 'Título' },
    price: { ref: 'price', column: 'price', label: 'Preço', mask: 'currency' },
    before_price: {
      ref: 'before_price',
      column: 'before_price',
      label: 'Preço Cheio',
      mask: 'currency',
    },
    limited_quantities: {
      ref: 'limited_quantities',
      column: 'limited_quantities',

      label: 'Vagas Limitadas?',

    },

    total_quantities: {
      ref: 'total_quantities',
      column: 'total_quantities',
      label: 'Total de Vagas',
    },

    available_quantities: {
      ref: 'available_quantities',
      column: 'available_quantities',
      label: 'Vagas Disponíveis',
    },

    sold_quantities: {
      ref: 'sold_quantities',
      column: 'sold_quantities',
      label: 'Ingressos Vendidos',
    },

    waiting_quantities: {
      ref: 'waiting_quantities',
      column: 'waiting_quantities',
      label: 'Ingressos Aguardando Pg.',
    },

    status_id: {
      ref: 'status_id',
      column: 'status_id',
      label: 'Status',
    },
    valid_id: {
      ref: 'valid_id',
      column: 'valid_id',

      label: 'Valido?',

    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };
  const formSchema = {
    position: {
      model: 'selectSimple',
      name: 'position',
      label: 'Posição',

      default: '1',

      options: [


        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },


      ]

    },

    status_id: {
      model: 'selectSimple',
      name: 'status_id',
      label: 'Status',

      default: 'Não',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },
    category_id: {
      model: 'selectSimple',
      name: 'category_id',
      label: 'Categoria',

      default: 'local_no_qrcode',

      options: [


        { label: 'Presencial', value: 'local' },
        { label: 'Presencial sem QRCode', value: 'local_no_qrcode' },
        { label: 'Online', value: 'online' },

      ]

    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Pago ou Gratuíto?',

      default: 'paid',

      options: [


        { label: 'Pago', value: 'paid' },
        { label: 'Gratuíto', value: 'free' },


      ]

    },

    valid_id: {
      model: 'selectSimple',
      name: 'valid_id',
      label: 'Link Válido?',

      default: 'Não',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },

    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    hash_link: { model: 'input', type: 'text', name: 'hash_link', label: 'Link' },
    description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo' },
    price: {
      model: 'currencyInput',
      name: 'price',
      label: 'Preço',
      defaultValue: '0',
    },
    before_price: {
      model: 'currencyInput',
      name: 'before_price',
      label: 'Preço Cheio',
      defaultValue: '0',
    },

    limited_quantities: {
      model: 'selectSimple',
      name: 'limited_quantities',
      label: 'Tem Limite de vagas?',

      default: 'Não',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },
    total_quantities: {
      model: 'input',
      type: 'text',
      name: 'total_quantities',
      label: 'Total de Vagas',
      defaultValue: 0,
    },
    courses: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong style={{ fontSize: '10px' }}>
            (
            {data.id})
            {' '}
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data.id})
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/courses`,
      name: 'courses',
      label: 'Cursos',
    },
  };

  const formSchemaUpdate = {

    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'logo',
    },
    email_header: {
      model: 'uploadImage',
      type: 'upload',
      name: 'email_header',
      label: 'Cabeçalho de Email',
    },
    email_footer: {
      model: 'uploadImage',
      type: 'upload',
      name: 'email_footer',
      label: 'Rodapé de Email',
    },
    email_full_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'email_full_image',
      label: 'Email de imagem completa',
    },
    ...formSchema
  };


  const paymentFields = {

    payment_options: {
      model: 'jsonInputList', show: ['payment_method', 'first_payment'], type: 'text', name: 'payment_options', label: 'Formas de pagamento', list: [
        {
          name: 'payment_method', label: 'Forma de pagamento', type: 'select', options: [
            { label: 'Boleto a vista', value: 'boleto' },
            { label: 'Boleto recorrente', value: 'boleto_recurring' },
            { label: 'Cartão de crédito padrão', value: 'credit_card' },
            { label: 'Cartão de crédito recorrente', value: 'credit_card_recurring' },
            { label: 'Pix', value: 'pix' },
          ]
        },
        {
          name: 'options', label: 'Opções', type: 'jsonInputList', show: ['installments', 'installment_value'], list: [
            {
              name: 'installments', label: 'Parcelas', type: 'select', options: [
                { label: '01', value: '1' },
                { label: '02', value: '2' },
                { label: '03', value: '3' },
                { label: '04', value: '4' },
                { label: '05', value: '5' },
                { label: '06', value: '6' },
                { label: '07', value: '7' },
                { label: '08', value: '8' },
                { label: '09', value: '9' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' },
                { label: '46', value: '46' },
                { label: '47', value: '47' },
                { label: '48', value: '48' },
                { label: '49', value: '49' },
                { label: '50', value: '50' },
                { label: '51', value: '51' },
                { label: '52', value: '52' },
                { label: '53', value: '53' },
                { label: '54', value: '54' },
                { label: '55', value: '55' },
                { label: '56', value: '56' },
                { label: '57', value: '57' },
                { label: '58', value: '58' },
                { label: '59', value: '59' },
                { label: '60', value: '60' },
              ]
            },
            {
              name: 'installment_value', label: 'Valor da parcela', type: 'currency',
            },
            {
              name: 'total', label: 'Valor total', type: 'currency',
            },
          ]
        },


        {
          name: 'first_payment', label: 'Data da primeira parcela', type: 'date',
        },
      ]
    },


    payment_discount: {
      model: 'jsonInputList', show: ['hash', 'discount'], type: 'text', name: 'payment_discount', label: 'Descontos', list: [



        {
          name: 'hash', label: 'Código', type: 'input',
        },
        {
          name: 'discount', label: 'Desconto', type: 'input'
        },
      ]
    },


  }


  const fields = {
    mail_instructions: { model: 'input', type: 'text', name: 'mail_instructions', label: 'Instruções adicionais de e-mail' },
    boleto_instructions: { model: 'input', type: 'text', name: 'boleto_instructions', label: 'Instruções dos campos adicionais (sai no Boleto) ' },
    fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'fields',
      label: 'Campos',
      show: 'title',
      list: [
        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'ACESSO DIRETO', text: 'ACESSO DIRETO', value: 'ACESSO DIRETO' },
            { label: 'PRÉ-REQUISITOS', text: 'PRÉ-REQUISITOS', value: 'PRÉ-REQUISITOS' },
          ]
        },
        { label: 'Campo', name: 'field', type: 'input', default: 'area' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'input' },
        {
          label: 'Mascara', name: 'mask', type: 'select', options: [

            { label: 'Nenhuma', text: 'Nenhuma', value: '' },
            { label: '0000', text: '0000', value: '1000' },
          ]
        },
        {
          label: 'Tipo', name: 'type', type: 'select', options: [
            { label: 'Estrelas', text: 'Estrelas', value: 'star' },
            { label: '1 a 10', text: '1 a 10', value: 'range' },
            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Lista de Textos', text: 'Lista de Textos', value: 'arrayInputIncluder' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Caixa de Seleção', text: 'Caixa de Seleção', value: 'checkbox' },
            { label: 'Documento', text: 'Documento', value: 'image' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },
        { label: 'Limite', name: 'limit', type: 'input' },
        { label: 'Label', name: 'label', type: 'input' },

      ]
    },



    fields_selective_process: {
      model: 'jsonInputList',
      type: 'text',
      name: 'fields_selective_process',
      label: 'Campos para Processos Seletivos',
      show: ['type', 'area', 'subarea'],
      list: [
        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'ACESSO DIRETO', text: 'ACESSO DIRETO', value: 'ACESSO DIRETO' },
            { label: 'PRÉ-REQUISITOS', text: 'PRÉ-REQUISITOS', value: 'PRÉ-REQUISITOS' },
            { label: 'UNIPROFISSIONAL', text: 'UNIPROFISSIONAL', value: 'UNIPROFISSIONAL' },
            { label: 'MULTIPROFISSIONAL', text: 'MULTIPROFISSIONAL', value: 'MULTIPROFISSIONAL' },
          ]
        },

        { label: 'Área', name: 'area', type: 'input' },
        { label: 'Subárea', name: 'subarea', type: 'input' },




      ]
    },

  }


  const formSchemaUpdateGroupProducts = {
    group_products: {
      model: 'JsonInput',
      where: { project_id: projectHash },
      bodyRender: (data: Record<string, any>) => (
        <nav style={{ fontSize: '10px' }}>
          <strong style={{ fontSize: '10px' }}>
            (

            {data.title}
          </strong>
          <br />
          {format(data.price)}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong style={{ fontSize: '10px' }}>
            (

            {data.title}
            <br />
            {format(data.price)}
          </strong>

        </>
      ),
      storageType: 'id',
      endpoint: `/products`,
      name: 'group_products',
      label: 'Produtos',
    }
  }


  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules:


      user.id === '62c28de66b3d93a218cdb960' ?

        [(lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaUpdate,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: fields,
            validationSchema: {},
            endpoint,
            Icon: FaReceipt
          }),
        (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaUpdateGroupProducts,
            validationSchema: {},
            endpoint,
            Icon: FaObjectGroup
          }),]


        :
        [
          (lineData: Record<string, any>) =>
            UploadAreas({
              lineData,

            }),
          (lineData: Record<string, any>) =>
            lineModules.updateUnique({
              lineData,
              title,
              formSchema: formSchemaUpdate,
              validationSchema: {},
              endpoint,
            }),
          (lineData: Record<string, any>) =>
            lineModules.delete({
              lineData,
              title,
              formSchema,
              validationSchema: formValidation,
              endpoint,
            }),

          (lineData: Record<string, any>) =>
            lineModules.updateUnique({
              lineData,
              title,
              formSchema: formSchemaUpdateGroupProducts,
              validationSchema: {},
              endpoint,
              Icon: FaObjectGroup
            }),
          (lineData: Record<string, any>) =>
            lineModules.updateUnique({
              lineData,
              title,
              formSchema: fields,
              validationSchema: {},
              endpoint,
              Icon: FaReceipt
            }),
          (lineData: Record<string, any>) =>
            lineModules.updateUnique({
              lineData,
              title,
              formSchema: paymentFields,
              validationSchema: {},
              endpoint,
              Icon: FaCreditCard
            }),

        ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default ProductsAdmin;
