import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { ISearchContextData } from "../../../hooks/SearchHook";
import { FaFileExcel, FaFilter } from "react-icons/fa";
import DateFilterBar from "../DateFilterBar";
import FilterBar from "../FilterBar";
import { downloaditPost } from "../../../utils/download";
import { apiUrl } from "../../../config";

interface IData {
    value: number;
    name: string;
    simpleName?: string;
}

interface IChart {
    title?: string;
    subtitle?: string;
    data: Array<Record<string, any>>;
    category?: string;
    group?: string;
    hook: ISearchContextData,
    table?: string;
    project_id?: string;
}

interface IConverter {
    data: IData[];
    type: string;
    title: string;
}

const Chart: React.FC<IChart> = ({ hook, category = '', group = '', data = [], title = '', subtitle = '', table }) => {

    const [renderData, setRenderData] = useState<IData[]>([])
    const [groupColumn, setGroupColumn] = useState(group);
    const [type, setType] = useState('pie');

    const convertToArrayAndExport = ({ data, type = 'xlsx', title }: IConverter) => {

        const content = [[title, 'Quantidade']];

        data.map(item => {
            content.push([item.simpleName || '', item?.value?.toString() || '0'])
        })



        return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

    }


    const changeGroup = (value: string) => {
        setGroupColumn(value);
    }

    const renderDataInfo = async (groupInfo: string) => {


        const dataInfo = await hook.applyApiSearchFull(hook.filterOptions, groupInfo);


        const dataItems: Array<IData> = dataInfo.reduce((prev: Array<IData>, item: Record<string, any>) => {



            prev.push({ simpleName: item?._id, name: item?._id, value: item.count })


            return prev;



        }, [] as Array<IData>)

        if (dataItems?.length > 0) {
            dataItems.sort((a, b) => a.value < b.value ? 1 : a.value > b.value ? -1 : 0);
        }

        dataItems?.map(d => {
            d.name = `${d.name} - ${d.value}`;
        })



        setRenderData(dataItems);


    }


    useEffect(() => {
        renderDataInfo(groupColumn);
    }, [groupColumn, hook.filterOptions])


    const option = {
        title: {
            text: title,
            subtext: subtitle,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        notMerge: true,
        series: [
            {

                name: category,
                type: 'pie',
                radius: '50%',

                data: [
                    ...renderData
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    /*

    const option = {

        yAxis: {
            type: 'category',
            data: renderData.reduce((prev: Array<string>, item: IData) => {
                prev.push(item.name)
                return prev;
            }, [])
        },

        xAxis: {
            type: 'value'
        },

        series: {
            type: 'bar',

            data: renderData.reduce((prev: Array<number>, item: IData) => {
                prev.push(item.value)
                return prev;
            }, []),

        }
    };
    */



    return <div className="container column">


        <div className="container row flex-wrap gap-sm pad-sm">
            {hook.headerData.map(head => {
                return <button key={`chart-group-selector-${head.column}`} className={`button-xxs ${groupColumn === head.column ? 'color-button' : 'soft-button'}`} onClick={() => changeGroup(head.column)} >{head.title}</button>
            })}
        </div>
        <div className="container row flex-wrap gap-sm pad-sm">
            <DateFilterBar filterFunction={hook.applyDateFilter} filterOptions={hook.filterOptions} />
        </div>
        <div className="container row flex-wrap gap-sm pad-sm">
            <FilterBar filters={hook?.filterOptions.filters} filterFunction={hook?.applyFilter} />
        </div>
        <div className="container row-to-column row-to-column-start gap-sm pad">
            <ReactEcharts option={option} style={{
                height: '100vh',
                width: '100%',
            }} />


            <div className="container column column-start max-400 overflow-hidden-height">
                <table className="container" >
                    <thead>
                        <tr><th className="row-space-between">  Total</th><th>{renderData.length} </th></tr>

                        <tr><th className="row-space-between">{hook?.headerData?.find(h => h.column === groupColumn)?.title || ''} <button className="icon-button-xs color-button"> <FaFileExcel onClick={() => convertToArrayAndExport({ data: renderData, type: 'xlsx', title: hook?.headerData?.find(h => h.column === groupColumn)?.title || '' })} /></button></th><th>QTD </th></tr>
                    </thead>
                    <tbody>
                        {renderData?.map(item => <tr key={`render-data-${group}-${item.name}`}><td>{item.simpleName}

                            <FaFilter className="filter-icon-position" onClick={() => hook.applyFilter({ column: groupColumn, value: item.simpleName, group: groupColumn, category: hook?.headerData?.find(h => h.column === groupColumn)?.title || '' })} size={15} />

                        </td><td>{item.value}</td></tr>)}
                    </tbody>
                </table>
            </div>
        </div>


    </div >


}

export default Chart;