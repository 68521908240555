const points = [
    { value: '0.00', text: '0.00', label: '0.00' },
    { value: '0.01', text: '0.01', label: '0.01' },
    { value: '0.02', text: '0.02', label: '0.02' },
    { value: '0.03', text: '0.03', label: '0.03' },
    { value: '0.04', text: '0.04', label: '0.04' },
    { value: '0.05', text: '0.05', label: '0.05' },
    { value: '0.06', text: '0.06', label: '0.06' },
    { value: '0.07', text: '0.07', label: '0.07' },
    { value: '0.08', text: '0.08', label: '0.08' },
    { value: '0.09', text: '0.09', label: '0.09' },


    { value: '0.10', text: '0.10', label: '0.10' },
    { value: '0.15', text: '0.15', label: '0.15' },
    { value: '0.20', text: '0.20', label: '0.20' },
    { value: '0.25', text: '0.25', label: '0.25' },
    { value: '0.30', text: '0.30', label: '0.30' },
    { value: '0.35', text: '0.35', label: '0.35' },
    { value: '0.40', text: '0.40', label: '0.40' },
    { value: '0.45', text: '0.45', label: '0.45' },
    { value: '0.50', text: '0.50', label: '0.50' },
    { value: '0.55', text: '0.55', label: '0.55' },
    { value: '0.60', text: '0.60', label: '0.60' },
    { value: '0.65', text: '0.65', label: '0.65' },
    { value: '0.70', text: '0.70', label: '0.70' },
    { value: '0.75', text: '0.75', label: '0.75' },
    { value: '0.80', text: '0.80', label: '0.80' },
    { value: '0.85', text: '0.85', label: '0.85' },

    { value: '0.90', text: '0.90', label: '0.90' },
    { value: '0.95', text: '0.95', label: '0.95' },
    { value: '1.00', text: '1.00', label: '1.00' },
    { value: '1.05', text: '1.05', label: '1.05' },
    { value: '1.10', text: '1.10', label: '1.10' },
    { value: '1.15', text: '1.15', label: '1.15' },
    { value: '1.20', text: '1.20', label: '1.20' },
    { value: '1.25', text: '1.25', label: '1.25' },
    { value: '1.30', text: '1.30', label: '1.30' },
    { value: '1.35', text: '1.35', label: '1.35' },
    { value: '1.40', text: '1.40', label: '1.40' },
    { value: '1.45', text: '1.45', label: '1.45' },
    { value: '1.50', text: '1.50', label: '1.50' },
    { value: '1.55', text: '1.55', label: '1.55' },
    { value: '1.60', text: '1.60', label: '1.60' },
    { value: '1.65', text: '1.65', label: '1.65' },
    { value: '1.70', text: '1.70', label: '1.70' },
    { value: '1.75', text: '1.75', label: '1.75' },
    { value: '1.80', text: '1.80', label: '1.80' },
    { value: '1.85', text: '1.85', label: '1.85' },
    { value: '1.90', text: '1.90', label: '1.90' },
    { value: '1.95', text: '1.95', label: '1.95' },
    { value: '2.00', text: '2.00', label: '2.00' },
    { value: '2.05', text: '2.05', label: '2.05' },
    { value: '2.10', text: '2.10', label: '2.10' },
    { value: '2.15', text: '2.15', label: '2.15' },
    { value: '2.20', text: '2.20', label: '2.20' },
    { value: '2.25', text: '2.25', label: '2.25' },

    { value: '2.30', text: '2.30', label: '2.30' },
    { value: '2.35', text: '2.35', label: '2.35' },
    { value: '2.40', text: '2.40', label: '2.40' },
    { value: '2.45', text: '2.45', label: '2.45' },
    { value: '2.50', text: '2.50', label: '2.50' },
    { value: '2.55', text: '2.55', label: '2.55' },
    { value: '2.60', text: '2.60', label: '2.60' },
    { value: '2.70', text: '2.70', label: '2.70' },
    { value: '2.80', text: '2.80', label: '2.80' },
    { value: '2.90', text: '2.90', label: '2.90' },
    { value: '3.00', text: '3.00', label: '3.00' },
    { value: '3.10', text: '3.10', label: '3.10' },
    { value: '3.20', text: '3.20', label: '3.20' },
    { value: '3.30', text: '3.30', label: '3.30' },
    { value: '3.40', text: '3.40', label: '3.40' },
    { value: '3.50', text: '3.50', label: '3.50' },
    { value: '3.60', text: '3.60', label: '3.60' },
    { value: '3.70', text: '3.70', label: '3.70' },
    { value: '3.80', text: '3.80', label: '3.80' },
    { value: '3.90', text: '3.90', label: '3.90' },
    { value: '4.00', text: '4.00', label: '4.00' },
    { value: '4.10', text: '4.10', label: '4.10' },
    { value: '4.20', text: '4.20', label: '4.20' },
    { value: '4.30', text: '4.30', label: '4.30' },
    { value: '4.40', text: '4.40', label: '4.40' },
    { value: '4.50', text: '4.50', label: '4.50' },
    { value: '4.60', text: '4.60', label: '4.60' },
    { value: '4.70', text: '4.70', label: '4.70' },
    { value: '4.80', text: '4.80', label: '4.80' },
    { value: '4.90', text: '4.90', label: '4.90' },
    { value: '5.00', text: '5.00', label: '5.00' },
    { value: '5.10', text: '5.10', label: '5.10' },
    { value: '5.20', text: '5.20', label: '5.20' },
    { value: '5.30', text: '5.30', label: '5.30' },
    { value: '5.40', text: '5.40', label: '5.40' },
    { value: '5.50', text: '5.50', label: '5.50' },
    { value: '5.60', text: '5.60', label: '5.60' },
    { value: '5.70', text: '5.70', label: '5.70' },
    { value: '5.80', text: '5.80', label: '5.80' },
    { value: '5.90', text: '5.90', label: '5.90' },
    { value: '6.00', text: '6.00', label: '6.00' },
    { value: '6.10', text: '6.10', label: '6.10' },
    { value: '6.20', text: '6.20', label: '6.20' },
    { value: '6.30', text: '6.30', label: '6.30' },
    { value: '6.40', text: '6.40', label: '6.40' },
    { value: '6.50', text: '6.50', label: '6.50' },
    { value: '6.60', text: '6.60', label: '6.60' },
    { value: '6.70', text: '6.70', label: '6.70' },
    { value: '6.80', text: '6.80', label: '6.80' },
    { value: '6.90', text: '6.90', label: '6.90' },
    { value: '7.00', text: '7.00', label: '7.00' },
    { value: '7.10', text: '7.10', label: '7.10' },
    { value: '7.20', text: '7.20', label: '7.20' },
    { value: '7.30', text: '7.30', label: '7.30' },
    { value: '7.40', text: '7.40', label: '7.40' },
    { value: '7.50', text: '7.50', label: '7.50' },
    { value: '7.60', text: '7.60', label: '7.60' },
    { value: '7.70', text: '7.70', label: '7.70' },
    { value: '7.80', text: '7.80', label: '7.80' },
    { value: '7.90', text: '7.90', label: '7.90' },
    { value: '8.00', text: '8.00', label: '8.00' },
    { value: '8.10', text: '8.10', label: '8.10' },
    { value: '8.20', text: '8.20', label: '8.20' },
    { value: '8.30', text: '8.30', label: '8.30' },
    { value: '8.40', text: '8.40', label: '8.40' },
    { value: '8.50', text: '8.50', label: '8.50' },
    { value: '8.60', text: '8.60', label: '8.60' },
    { value: '8.70', text: '8.70', label: '8.70' },
    { value: '8.80', text: '8.80', label: '8.80' },
    { value: '8.90', text: '8.90', label: '8.90' },
    { value: '9.00', text: '9.00', label: '9.00' },
    { value: '9.10', text: '9.10', label: '9.10' },
    { value: '9.20', text: '9.20', label: '9.20' },
    { value: '9.30', text: '9.30', label: '9.30' },
    { value: '9.40', text: '9.40', label: '9.40' },
    { value: '9.50', text: '9.50', label: '9.50' },
    { value: '9.60', text: '9.60', label: '9.60' },
    { value: '9.70', text: '9.70', label: '9.70' },
    { value: '9.80', text: '9.80', label: '9.80' },
    { value: '9.90', text: '9.90', label: '9.90' },
    { value: '10.00', text: '10.00', label: '10.00' },
    { value: '10.10', text: '10.10', label: '10.10' },
    { value: '10.20', text: '10.20', label: '10.20' },
    { value: '10.30', text: '10.30', label: '10.30' },
    { value: '10.40', text: '10.40', label: '10.40' },
    { value: '10.50', text: '10.50', label: '10.50' },
    { value: '10.60', text: '10.60', label: '10.60' },
    { value: '10.70', text: '10.70', label: '10.70' },
    { value: '10.80', text: '10.80', label: '10.80' },
    { value: '10.90', text: '10.90', label: '10.90' },
    { value: '11.00', text: '11.00', label: '11.00' },
    { value: '11.10', text: '11.10', label: '11.10' },
    { value: '11.20', text: '11.20', label: '11.20' },
    { value: '11.30', text: '11.30', label: '11.30' },
    { value: '11.40', text: '11.40', label: '11.40' },
    { value: '11.50', text: '11.50', label: '11.50' },
    { value: '11.60', text: '11.60', label: '11.60' },
    { value: '11.70', text: '11.70', label: '11.70' },
    { value: '11.80', text: '11.80', label: '11.80' },
    { value: '11.90', text: '11.90', label: '11.90' },
    { value: '12.00', text: '12.00', label: '12.00' },
    { value: '12.10', text: '12.10', label: '12.10' },
    { value: '12.20', text: '12.20', label: '12.20' },
    { value: '12.30', text: '12.30', label: '12.30' },
    { value: '12.40', text: '12.40', label: '12.40' },
    { value: '12.50', text: '12.50', label: '12.50' },
    { value: '12.60', text: '12.60', label: '12.60' },
    { value: '12.70', text: '12.70', label: '12.70' },
    { value: '12.80', text: '12.80', label: '12.80' },
    { value: '12.90', text: '12.90', label: '12.90' },
    { value: '13.00', text: '13.00', label: '13.00' },
    { value: '13.10', text: '13.10', label: '13.10' },
    { value: '13.20', text: '13.20', label: '13.20' },
    { value: '13.30', text: '13.30', label: '13.30' },
    { value: '13.40', text: '13.40', label: '13.40' },
    { value: '13.50', text: '13.50', label: '13.50' },
    { value: '13.60', text: '13.60', label: '13.60' },
    { value: '13.70', text: '13.70', label: '13.70' },
    { value: '13.80', text: '13.80', label: '13.80' },
    { value: '13.90', text: '13.90', label: '13.90' },
    { value: '14.00', text: '14.00', label: '14.00' },
    { value: '14.10', text: '14.10', label: '14.10' },
    { value: '14.20', text: '14.20', label: '14.20' },
    { value: '14.30', text: '14.30', label: '14.30' },
    { value: '14.40', text: '14.40', label: '14.40' },
    { value: '14.50', text: '14.50', label: '14.50' },
    { value: '14.60', text: '14.60', label: '14.60' },
    { value: '14.70', text: '14.70', label: '14.70' },
    { value: '14.80', text: '14.80', label: '14.80' },
    { value: '14.90', text: '14.90', label: '14.90' },
    { value: '15.00', text: '15.00', label: '15.00' },
]

export const oneHundredNumbers = () => {
    let items: Array<Record<string, any>> = [];

    let x = 1;
    while (x < 101) {
        items.push({ value: x, text: x, label: x })
    }

    return items;
}

export default points;