import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';


import { useAvaliateCurriculum as visualizateCurriculum } from './modules/visualizateCurriculum';
import ExportList from './modules/ExportList';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const SelectiveProccessSpecialTreatment: React.FC = () => {
  const endpoint = '/selective-process-special-treatment';
  const title = 'Processo Seletivo - Solicitação de atendimento especial';
  const { projectHash, eventHash } = useConfiguration();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },

    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    type: { ref: 'type', column: 'type', label: 'Tipo' },
    type_description: { ref: 'type_description', column: 'type_description', label: 'Extra do tipo' },
    description: { ref: 'description', column: 'description', label: 'Descritivo' },
    documents: { ref: 'documents', column: 'documents', label: 'Documentos anexos', show: false },
    term: { ref: 'term', column: 'term', label: 'Termo' },

  };

  const formSchema = {

    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },
    type: { model: 'input', type: 'text', name: 'type', label: 'Tipo' },
    type_description: { model: 'textareaInput', type: 'text', name: 'type_description', label: 'Extra do tipo' },
    description: { model: 'textareaInput', type: 'text', name: 'description', label: 'Descritivo' },
    documents: { model: 'uploadForm', name: 'documents', label: 'Documentos anexados', placeholder: 'Documentos' },
  };

  const formSchemaUpdate = {

    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () => ExportList({ projectHash })
    ],
    bodyModules: [

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),



      /* (lineData: Record<string, any>) =>
        downloadRegister({
          lineData,


        }), */
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default SelectiveProccessSpecialTreatment;
