import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import { Error } from '../styles';
import { FaChevronDown, FaChevronUp, FaEdit, FaPlus, FaTable, FaTrash, FaUpload } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { Form } from '@unform/web';
import Input from '../Input';
import SelectSimple from '../SelectSimple';
import RichText from '../RichText';
import { useModal } from '../../../../../../hooks/Modal';
import JsonList from '../JsonListMultiple';
import ArrayIncluder from '../ArrayInputIncluder';
import JsonInputList from '../JsonInputList';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import FormComponent from '../../FormComponent';
interface IListInput {
  label: string;
  name: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  defaultItem?: Array<Record<string, any>>;
  placeholder: string;
  show?: Array<string> | string;
}

/*
The concept is simple.
Create a form with multiple inputs

*/

type IData = Record<string, any>;

type IPrintLines = {
  currentValue?: Array<IData>
}

type IValue = {
  header: Array<IData>,
  data: Array<IData>
}

const JsonInputListWithHeader: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  placeholder,
  defaultItem,
  list,

  ...rest
}): JSX.Element => {

  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<IValue>(defaultValue || defaultItem || { header: [], data: [] });
  const dataItems = value?.data || [];
  const show = value?.header ? value?.header?.reduce((prev, item) => {
    prev.push(item?.name)
    return prev;
  }, [] as Array<string>) : [];

  useEffect(() => {


    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      setValue: (ref: any, value: any) => {
        setValue(value);
      },
      getValue: (ref: any) => {
        return value;
      },
    });
  }, [fieldName, registerField, value]);


  const goUp = index => {
    const indexup = index - 1;

    if (value?.data?.[indexup]) {
      const newCurrent = [...value?.data];

      newCurrent[index] = value?.data?.[indexup];
      newCurrent[indexup] = value?.data?.[index];

      setValue({ header: value?.header, data: [...newCurrent] })

    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (value?.data?.[indexup]) {
      const newCurrent = [...value.data];

      newCurrent[index] = value?.data?.[indexup];
      newCurrent[indexup] = value?.data?.[index];

      setValue({ header: value?.header, data: [...newCurrent] })
    }
  };

  const defaultValueList = {
    input: '',
    select: '',
    textList: '',
    jsonList: [],
    arrayIncluder: []
  }

  useEffect(() => { console.log(value) }, [value])

  const addRow = (currentValue) => {
    const newValue = currentValue || [];



    const newLine = Object.keys(value?.header)?.reduce((prev, key) => {
      prev[value?.header[key].name] = defaultValueList[value?.header?.[key]?.type || 'input'];
      return prev;
    }, {});

    newValue.push(newLine);

    setValue({ ...value, data: newValue });
  };

  const removeItem = (index) => {

    const item = [...value.data];
    item.splice(index, 1);

    setValue(state => {
      state.data = [...item]

      return state;
    });

  }

  const getShow = (item, showValues) => {

    if (typeof showValues === 'object') {
      let text = ``;

      showValues?.map(showValue => {
        text = `${text}${item?.[showValue] ? ` - ${item?.[showValue]}` : ''}`
      })

      return text;

    }
    else if (typeof showValues === 'string') {
      return item?.[showValues] ? item?.[showValues] : 'Campo não identificado'
    }
    else {
      return 'Campo'
    }


  }

  const PrintLines: React.FC<IPrintLines> = ({ currentValue }) => {

    const items: Array<JSX.Element> = [];
    {
      currentValue?.map((v, index) => {

        const key = uuid();
        items.push(<div key={key} className='display'>
          <p> {getShow(v, show)}</p>
          <div className='listModules'>
            <div><FaEdit onClick={() => openInputs(index)} size={15} style={{ cursor: 'pointer' }} /></div>

            <div><FaChevronUp onClick={() => goUp(index)} style={{ cursor: 'pointer' }} /></div>
            <div><FaChevronDown onClick={() => goDown(index)} style={{ cursor: 'pointer' }} /></div>
            <div><FaTrash onClick={() => removeItem(index)} style={{ cursor: 'pointer' }} /></div>
          </div>
        </div>)

      })

      return <>{items}</>;

    }
  }


  const validTypes = (listInput) => {

    if (!listInput?.type) {
      listInput.type = 'input';
    }

    const valid = {
      input: (item) => <Input name={item.name} placeholder={item.label} />,
      select: (item) => <SelectSimple name={item.name} label={item.label} options={item.options} />,
      richText: (item) => <RichText name={item.name} placeholder={item.label} />,
      jsonList: (item) => <JsonList list={item.list} name={item.name} placeholder={item.label} />,
      arrayIncluder: (item) => <ArrayIncluder name={item.name} placeholder={item.label} />
    }

    return valid[listInput.type] ? valid[listInput.type](listInput) : <></>;

  }

  const openInputs = (index) => {

    const updateItem = (data) => {

      const valuesItems = [...value?.data];
      valuesItems[index] = { ...data };

      setValue(state => {
        state.data = [...valuesItems]

        return state;
      });
      removeModal('updateForm')

    }



    addModal({
      title: 'Atualizar', theme: "whiteModal", key: "updateForm", content: <Form onSubmit={updateItem} initialData={{ ...value?.data?.[index] }}>

        {value?.header?.map((item => {
          return validTypes(item);
        }))}

        <aside className='displayCenter'>
          <button className='defaultButton' style={{ width: '200px', margin: '10px auto' }}>Atualizar</button>
        </aside>

      </Form>
    }
    )

  }

  const updateHeader = (data, currentValue) => {

    const newValue = { ...currentValue };

    newValue.header = [...data.header];

    setValue({ ...newValue });
    removeModal('headerModal')

  }

  const changeHeader = (currentValue) => {

    addModal({
      key: 'headerModal',
      theme: 'whiteModalMedium', title: 'Configurar cabeçalho', content: <>
        <Form initialData={{ header: currentValue?.header || [] }}
          onSubmit={(data) => { updateHeader(data, currentValue) }}>
          <JsonInputList show={['label', 'name']} name='header' placeholder='Cabeçalho' list={[
            { name: 'label', label: 'Nome da coluna', type: 'input' },
            { name: 'name', label: 'Hash da coluna', type: 'input' }
          ]} />
          <aside className='displayCenter'>
            <button className='defaultButton' style={{ width: '200px', margin: '10px auto' }}>Salvar</button>
          </aside>
        </Form></>
    })

  }

  const addContent = (items, replace) => {


    setValue({ header: value?.header, data: replace ? [...items] : [...value.data, ...items] });
    addToast({ title: 'Adicionados com sucesso', type: 'success' });
    removeModal('saveConvertedData');

  }

  const showConvertedData = (uploadedData) => {

    const keys = value?.header?.reduce((prev, item) => {

      prev.push(item?.name);

      return prev;

    }, [] as Array<string>)

    const items: Array<Record<string, any>> = [];

    uploadedData.map(jsonContent => {

      const line = {};

      keys.map(key => {
        line[key] = jsonContent?.[key];
      })

      items.push(line);

    });

    const content = <>
      <button className='defaultButtonReverse' onClick={() => addContent(items, false)}>Adicionar ao conteúdo</button>
      <button className='defaultButtonReverse' onClick={() => addContent(items, true)}>Substituir o conteúdo atual</button>
      <table className='table tableRef'>
        <tbody>
          <tr>{value?.header?.map(header => {
            return <td key={`upload-content-header-${header?.name}`} >{header?.label}</td>
          })}</tr>
          {items?.map((item, indexLine) => {
            return <tr key={`upload-content-body-row-${indexLine}`}>
              {value?.header?.map((header, index) => {
                return <td key={`upload-content-body-${index}-${header?.name}`} >{item?.[header?.name]}</td>
              })}
            </tr>
          })}
        </tbody>
      </table>
    </>

    addModal({ title: 'Conferir dados', content, key: 'saveConvertedData', theme: "whiteModal" });


  }


  const convertContent = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', data.image)
      const response = await api.post(`/converter/xlsx-to-json`, formData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }
      showConvertedData(response?.data?.rows);
      removeModal('uploadModal');



    } catch (err) {


      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleUpload = (): void => {
    const content = (
      <FormComponent schema={{
        image: {
          model: 'uploadImage',
          type: 'upload',
          name: 'image',
          label: 'Imagem',
        }
      }} setCallback={convertContent} />
    );
    if (content) {
      const keyModal = addModal({ title: 'Realizar upload de dados', content, key: 'uploadModal', theme: "whiteModal" });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 10px', border: '2px solid #eee' }}>
      <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{placeholder}

        <div className="iconMod">

          <FaTable onClick={() => changeHeader(value)} size={15} style={{ margin: '0px 3px', cursor: 'pointer' }} />
          <FaUpload onClick={() => handleUpload()} size={15} style={{ margin: '0px 3px', cursor: 'pointer' }} />
          <FaPlus onClick={() => addRow(value?.data)} size={15} style={{ margin: '0px 3px', cursor: 'pointer' }} />

        </div>
      </h2>

      <Container> <PrintLines currentValue={dataItems} /> </Container>

    </div>
  );
};

export default JsonInputListWithHeader;
