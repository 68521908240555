import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { authTitle } from '../config';
import { AxiosError } from 'axios';
import { useToast } from './Toast';

interface AuthState {
  token: string;
  user: Record<string, any>;
}

interface SignInCredentials {
  email: string;
  password?: string;
  name?: string;
  product_id?: string;
  project_id?: string;
  event_id?: string;
}

interface ISignInAdmin {
  email: string;
  password?: string;
}

interface IAuthIntegration {
  token: string;
  user: Record<string, any>;
}

interface AuthContextData {
  user: Record<string, any>;
  token: string;
  signOut(): void;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInAdmin(credentials: ISignInAdmin): Promise<void>;
  AuthIntegration(credentials: IAuthIntegration): Promise<void>;
  updateImage(image: string): void;
  handleApiErrors(error: AxiosError): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const [data, setData] = useState<AuthState>(() => {

    const token = localStorage.getItem(`@${authTitle}:token`);
    const user = localStorage.getItem(`@${authTitle}:user`);

    if (!token || token === 'undefined') {

      localStorage.removeItem(`@${authTitle}:token`);
      localStorage.removeItem(`@${authTitle}:user`);
      localStorage.removeItem(`@${authTitle}:interactions`);

    }

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const handleApiErrors = (error: AxiosError) => {

    if (error?.response?.status === 403) {
      addToast({ title: 'Você não tem permissão para acessar este conteúdo' });
      //  signOut();
      return;
    }

    if (error?.response?.status === 429) {
      addToast({ title: 'Você atingiu o número limite de requisições / minuto', description: 'Você pode realizar até 60 requisições por minuto' });
      return;
    }

  }

  const updateImage = (image) => {
    const { user, token } = data;
    user.image = image;
    localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
    setData(state => ({ ...state, user }));

  }

  const signIn =
    async ({ email, password = '', name = '', product_id = '', project_id = '', event_id = '' }: SignInCredentials) => {
      const response = await api.post('/sessions', { email, password, name, product_id, project_id, event_id });

      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(`@${authTitle}:token`, token);
      localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData({ token, user });

    }

  const AuthIntegration = useCallback(
    async ({ token, user }: IAuthIntegration) => {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(`@${authTitle}:token`, token);
      localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData({ token, user });
    },
    [],
  );

  const signInAdmin = useCallback(
    async ({ email, password }: SignInCredentials) => {
      const response = await api.post('/sessionsAdmin', { email, password });

      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(`@${authTitle}:token`, token);
      localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));
      setData({ token, user });
    },
    [],
  );

  const signOut = useCallback(() => {
    console.log('Deslogou');
    localStorage.removeItem(`@${authTitle}:token`);
    localStorage.removeItem(`@${authTitle}:user`);

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        updateImage,
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        signInAdmin,
        AuthIntegration,
        handleApiErrors
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
