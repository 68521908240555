import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import { useConfiguration } from '../../../hooks/Configuration';
interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}
 
interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {projectHash,eventHash} = useConfiguration();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();
  const {translate} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');

  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready,setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('');

  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };

 

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
          position: Yup.string().required('O Profissão é obrigatório'),
          company: Yup.string().required('Informe de qual operação Dasa você faz parte'),

        country: Yup.string().required('País é obrigatório'),
        state: Yup.string().required('Estado é obrigatório'),

        know_about: Yup.string().required('Pedimos que informe: como ficou sabendo do curso?'),
        extra_5: Yup.string().required('Pedimos que informe: Gostaria de receber futuras comunicações sobre eventos?'),
        term: Yup.string().required('Informe se você autoriza o compartilhamento de seus dados.'),
      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');

    
    

    if(data.position === 'Médico'){

      if(!data.crm && countryISO === 'BR'){
        addToast({title: 'O campo CRM é obrigatório para médicos brasileiros', type: 'error'});
        setLoading(false);
        return;
      }

      if(!data.cpf_familiar && countryISO === 'BR'){
        addToast({title: 'O estado do CRM é obrigatório para médicos brasileiros', type: 'error'});
        setLoading(false);
        return;
      }

      if(!data.category_id && countryISO === 'BR'){
        addToast({title: 'Por favor, informe sua especialidade', type: 'error'});
        setLoading(false);
        return;
      }

    }


    if(data.company === 'Outro? Qual?' && !data.extra_4){
      addToast({title: 'Por favor, informe o nome da sua organização', type: 'error'});
      setLoading(false);
      return;
    }

    data.email = data?.email?.trim()?.toLowerCase() || '';
    
      await schema.validate(data, { abortEarly: false });

      


      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;




      const newUser = await api
        .post('/signup', {
          ...data,
          project_id: projectHash,
          event_id : eventHash ? eventHash : '',
          captcha : captchaValue,
          term : data.term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }
 
      if(window?.fbq){
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try{
      const confirm = await api.post(`/confirm-subscriber`, { email : currentEmail, project_id : projectHash, event_id : eventHash ? eventHash : '' });

      if(confirm){
        setSuccess(true);
        setStatusConfirmation('finished');
      
      }
      setLoading(false);
    }
    catch(err){
      addToast({type:'error', title:'Erro ao confirmar inscrição'});
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || ''; 

    try{
    const checkUser = await api.post(`/check-subscriber`, { email : data.email, project_id : projectHash, event_id : eventHash || '' });

    if(checkUser){
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber')
        setReady(true);
    
    }

    }
    catch(err){
      addToast({type:'error', title: 'Erro ao checar usuário, por favor, tente novamente'});
      return
    }
    

  }


  if(!ready){
  
    return (
    <Form style={{width:'100%', maxWidth: '350px'}} ref={formRefConfirmation} onSubmit={handleConfirmation} initialData={{country: 'BR', email: currentEmail}}>
      <h1 style={{color: 'rgb(0,15,64)', fontSize:'18px',marginBottom: '10px'}}>Novo cadastro</h1>
    <p style={{color: 'rgb(0,15,64)', fontSize:'14px',marginBottom: '20px'}}>Para iniciar, informe seu email</p>
      <Input name="email" placeholder="E-mail" />
     <aside style={{width:'100%', display:'flex', justifyContent:'center'}}> <button className="defaultButton" type="submit">
                       Continuar
      </button></aside>
    </Form>)
  }


  if( statusConfirmation === 'confirmated'){

    return (
      <>

      <h1 style={{color: 'rgb(0,15,64)',marginBottom: '20px'}}>Olá participante!</h1>

      <p style={{color: 'rgb(0,15,64)',marginBottom: '20px'}}>Identificamos o seu cadastro na nossa plataforma. A sua inscrição no próximo simpósio está confirmada.</p>
    
      <Link to={ `/app/${projectHash}/login`}><button className="defaultButton" type="button">
                      Acessar
      </button></Link>
      </>
   )
  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{color: 'rgb(0, 14, 64)'}}>
                <h2 className="titleH2" style={{color: 'rgb(0, 14, 64)'}}> Cadastro realizado com sucesso!</h2>
                <br />
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Link to={`/app/${projectHash}/login/`} >
                  <button className="defaultButton" type="button">
                    Acessar
                  </button>
                </Link>
                </div>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text={`Olá! Acabei de me inscrever no ${authTitle}. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: ${urlWebsite}`}
                  number=""
                  message="Compartilhe!"
                />
              </p>
            }
          /> 
        ) : (<>
           
                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                    <h1 style={{color: 'rgb(0,15,64)',marginBottom: '20px'}}>Novo cadastro</h1>

                    <Input name="name" placeholder="Nome completo" />
                    <Input name="email" placeholder="E-mail" />

              
                    <SelectSimple setCallback={(value) => setPosition(value)}
                      options={[
                        { value: 'Médico', label: 'Médico' },
                        { value: 'Estudante', label: 'Estudante' },
                        { value: 'Profissional da área da saúde', label: 'Profissional da área da saúde' },
                     
                        { value: 'Outro? Qual?', label: 'Outra?' },
                      ]}
                      name="position"
                      formRef={formRef}
                      label="Profissão"
                    />
{position === 'Médico' && <>
                    <Input
                      name="crm"
                      placeholder="CRM"
                    />

<SelectSimple 
                      options={states}
                      name="cpf_familiar"
                      formRef={formRef}
                      label={'Estado do CRM'}
                    />

                   <SelectSimple
                      options={[

                        { value: 'Clínica Médica', label: 'Clínica Médica'},
                        { value: 'Ginecologia e Obstetrícia', label: 'Ginecologia e Obstetrícia'},
                        { value: 'Gastroenterologista', label: 'Gastroenterologista'},
                        
                        { value: 'Cirurgia Geral/Especialidade cirúrgica', label: 'Cirurgia Geral/Especialidade cirúrgica'},
                        { value: 'Medicina da Família', label: 'Medicina da Família'},
                        { value: 'Genética', label: 'Genética'},
                        { value: 'Anatomia Patológica', label: 'Anatomia Patológica'},
                        { value: 'Patologia Clínica', label: 'Patologia Clínica'},
                        { value: 'Radiologia', label: 'Radiologia'},
                        { value: 'Endocrinologia', label: 'Endocrinologia'},
                        { value: 'Hematologia', label: 'Hematologia'},
                        { value: 'Geriatria', label: 'Geriatria'},
                        { value: 'Anestesiologia', label: 'Anestesiologia'},
                        { value: 'Gestão/Administração', label: 'Gestão/Administração'},
                        { value: 'Infectologia', label: 'Infectologia'},
                        { value: 'Outras', label: 'Outras'}
              
                      ]}
                      name="category_id"
                      formRef={formRef}
                      label="Especialidade"
                    /> </> }

      

            {(position === 'Estudante' || position === 'Profissional da área da saúde' || position === 'Outro? Qual?') &&
  <Input
  name="especialidade"
  placeholder="Especifique"
/>
              }


<SelectSimple setCallback={(value) => setOtherCompany(value)}
                options={[
                  { value: 'Dasa', label: 'Dasa' },
                  { value: 'Outro? Qual?', label: 'Outra?' },
                ]}
                name="company"
                formRef={formRef}
                label="Organização"
              />  

{otherCompany === 'Outro? Qual?' &&

<Input
name="extra_4"
placeholder="Informe o nome da organização"
/>
}
               

<SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={'Pais'}/>





           { withPassword &&         <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Cadastre sua senha de acesso')}
                    />}



{ countryISO === 'BR' ?     <SelectSimple setCallback={ (value) => {
  setStateValue(value);
}} options={states} name="state" label="Estado"/> : 

<Input  name="state" placeholder={'Província / Região da Fundação'} />}  



{ countryISO === 'BR' ? <MaskedInput
                      mask="(99) 999999999"
                      name="phone"
                      placeholder="Telefone com DDD"
                    /> : <Input  name="phone" placeholder={'Telefone com DDD'} />}

 <SelectSimple

                options={[
                  { label: 'WhatsApp', value: 'WhatsApp' },
                  { label: 'Indicação', value: 'Indicação' },
                  { label: 'Instagram', value: 'Instagram' },
                  { label: 'Facebook', value: 'Facebook' },
                  { label: 'Linkedin', value: 'Linkedin' },
                  { label: 'E-mail marketing', value: 'E-mail marketing' },
                  { label: 'SMS', value: 'SMS' },
                  { label: 'Workplace', value: 'Workplace' },
                  { label: 'Google', value: 'Google' },
                  { label: 'Outros', value: 'Outros' },
            
                         
                 
                ]}
                name="know_about"
                formRef={formRef}
                label="Como ficou sabendo do evento?"
              />

<SelectSimple

options={[
  { label: 'Sim', value: 'Sim' },
  { label: 'Não', value: 'Não' },

 
]}
name="extra_5"
formRef={formRef}
label="Gostaria de receber futuras comunicações sobre eventos?"
/>

<div style={{marginBottom: '10px', padding: '15px', borderRadius: '10px'}}>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou ciente de que estou fornecendo à {authTitle} e seus parceiros meus
                  contatos gerais, os quais são dados pessoais protegidos pela
                  Lei Geral de Proteção de Dados brasileira, para gestão de
                  relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                  value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                },
                {
                  id: `Não autorizo o compartilhamento dos meus dados`,
                  label: 'Não autorizo o compartilhamento dos meus dados',
                  value: `Não autorizo o compartilhamento dos meus dados`,
                },
              ]}
            />

<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {'Ao me cadastrar, afirmo que li a'}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
{' '}
                  </Link>
{' '}
                </strong>
                {'desta plataforma.'}
              </p>
              </div>
                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      Finalizar inscrição

                  
                    </button>
                    </aside>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
