import React from 'react';
import Button from "../../../../../components/Forms/Button";
import InOut from "../../../../../components/Forms/InOut";
import { ILoadingContextData, ILoadingMessage } from "../../../../../hooks/LoadingHook";
import { IModalContextData, useModal } from "../../../../../hooks/Modal"
import { ISearchContextData } from "../../../../../hooks/SearchHook"
import { IHeader } from "../../../../../hooks/SearchHook/interfaces";
import downloadit from "../../../../../utils/download";
import { Form } from "@unform/web";


export default function exportData(endpoint: string, hook: ISearchContextData, modal: IModalContextData, loading: ILoadingContextData) {
    const { addModal, removeModal } = modal;

    const exportData = async () => {
        loading.addLoading({ title: 'Carregando', key: 'loading' });

        await downloadit({ url: endpoint, exportData: hook?.headerData, params: hook?.getSearchParams({ ...hook?.filterOptions, limitItems: 1000000, page: 1 }) })
        loading.removeLoading('loading')
    }

    const updateData = (column: string, property: string, value: string) => {

        const newHeader: IHeader[] = [...hook.headerData];

        const index = newHeader.findIndex(head => head.column === column);

        if (index >= 0) {
            if (newHeader?.[index]) {
                newHeader[index][property] = value;
            }
        }

        hook.updateHeader(newHeader);

    }

    addModal({
        theme: 'whiteModalG',
        title: '', key: 'manager-data', content: <>

            <table>
                <thead>
                    <tr><th>Coluna</th><th ><Button className="button-xs width-auto soft-button" onClick={() => exportData()}>Exportar</Button>  </th></tr>
                </thead>
                <tbody>
                    {hook?.headerData?.map(header => {

                        return <>{/* <tr key={`manager-data-column-${header.column}`}>
                            <td>{header.title}</td>
                            <td> <InOut value={header.export} setCallback={(value: string) => { updateData(header?.column, 'export', value) }} name={'export'} /></td>
                    </tr> */}</>
                    })}
                </tbody>
            </table>



        </>
    })
}