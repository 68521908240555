import React from 'react';
import { useSearch } from "../../../hooks/SearchHook";
import api from "../../../services/api";
import { useEffect, useState } from "react";
import { FaChartArea, FaChartLine, FaChevronCircleDown, FaChevronCircleUp, FaChevronDown, FaChevronUp, FaFilter, FaSortAlphaDown, FaSortAlphaUp, FaStar, FaTable } from "react-icons/fa";
import ChartLine from "../ChartLineModule";
import Chart from "../ChartModule";
import DateFilterBar from "../DateFilterBar";
import FilterBar from "../FilterBar";
import HeadModuleBar from "../HeadModulesBar";
import PaginationBar from "../PaginationBar";
import SearchBar from "../Searchbar";
import { ITableProps, IHeader } from "./interfaces";



const Table: React.FC<ITableProps> = ({ component_id = '', resources = {}, className = 'max-1400', type = 'default', sort = false, search = '', theme = {}, data = [], header = [], orderBy = 'createdAt', orderDirection = 'desc', filters = [], filtersApplied = [], headerModules = [], bodyModules = [], limit = 20, table = '', endpoint = '', staticEndpoint = '', project_id = '' }) => {

    const searchHook = useSearch({ data, header, filters: filtersApplied, search, order: { orderBy, direction: orderDirection }, limit, endpoint, staticEndpoint, table, project_id });
    const { headerData, count, countPages, changePage, filteredData, applyFilter, applyDateFilter, applyLimit, applyOrder, applySearch, filterOptions } = searchHook;

    const [renderOption, setRenderOption] = useState('table');


    const updateCredentiated = async (id: string) => {
        await api.put(`/students/${id}/reference`, { reference: 'yes' });

        searchHook.reloadSearchFilter(searchHook.filterOptions);
    }


    const organizeByColumn = (headerContent: Array<IHeader>, row: Record<string, any>) => {

        const elements: Array<JSX.Element> = [];

        headerContent?.map((item, index) => {

            const { includeColumn, column, title, search } = item;

            if (search === 'yes') {
                elements.push(<td key={`column-td-${row?._id ? row?._id : row?.title}-${column}-${index}`}>{includeColumn ? row?.[`${column}_response`]?.[includeColumn] || '' : row?.[column] || ''}

                    {resources?.filters === 'yes' ? <FaFilter className="filter-icon-position" onClick={() => applyFilter({ column, value: includeColumn ? row?.[column]?.[includeColumn] || '' : row?.[column] || '', group: column, category: title })} size={15} /> : <></>}
                </td>)
            }

        })

        return elements;


    }

    const organizeByColumnMobile = (headerContent: Array<IHeader>, row: Record<string, any>) => {

        const elements: Array<JSX.Element> = [];

        headerContent?.map((item, index) => {

            const { includeColumn, column, title, search } = item;

            if (search === 'yes') {
                elements.push(<div className="row row-start gap-sm" key={`column-mobile-td-${row?._id ? row?._id : row?.title}-${column}-${index}`}><strong style={{ minWidth: '90px' }}>{title}:</strong> <p style={{ fontSize: '13px' }}>{includeColumn ? row?.[column]?.[includeColumn] || '' : row?.[column] || ''}

                    {resources?.filter === 'yes' ? <FaFilter className="filter-icon-position" onClick={() => applyFilter({ column, value: includeColumn ? row?.[column]?.[includeColumn] || '' : row?.[column] || '', group: column, category: title })} size={15} /> : <></>}
                </p>
                </div>)
            }

        })

        return <div className="column column-start border-radius pad-sm " style={{ background: row?.reference === 'yes' ? 'rgb(0,225,0)' : '#eee', cursor: 'pointer' }}>{elements}</div>;


    }

    return <div className="container max-1400 column gap-sm">
        <div className="row width-auto gap-sm">
            <button className={`icon-button ${renderOption === 'table' ? 'color-button' : 'soft-button'}`} onClick={() => setRenderOption('table')}><FaTable /></button>
            <button className={`icon-button ${renderOption === 'chart' ? 'color-button' : 'soft-button'}`} onClick={() => setRenderOption('chart')}><FaChartArea /></button>
            <button className={`icon-button ${renderOption === 'chart-line' ? 'color-button' : 'soft-button'}`} onClick={() => setRenderOption('chart-line')}><FaChartLine /></button>
        </div>
        {renderOption === 'table' && <>
            <div className="container row row-end gap">
                {resources?.head_modules === 'yes' ? <HeadModuleBar endpoint={endpoint} searchHook={searchHook} /> : <></>}


                {resources?.search === 'yes' ? <SearchBar searchFunction={applySearch} /> : <></>}
            </div>
            {resources?.filters === 'yes' ? <div className="container row row-end gap-sm">
                {resources?.filters === 'yes' && filters?.length > 0 ? <FilterBar filters={filterOptions.filters} filterFunction={applyFilter} /> : <></>}

            </div> : <></>}
            {resources?.pagination === 'yes' ? <><div className="container row-to-column gap-sm row-space-between">
                <DateFilterBar filterFunction={applyDateFilter} filterOptions={filterOptions} />
                <PaginationBar count={count} changePage={changePage} countPages={countPages} filterOptions={filterOptions} />



            </div>
                <div className="container row-to-column gap-sm row-space-between"><FilterBar filters={filterOptions.filters} filterFunction={applyFilter} /></div>
            </> : <></>}
            <div className="column container computerOnly">
                <table className={`${className} `}>
                    {headerData?.length > 0 && <thead><tr className="table-header">{headerData?.map(column => column?.search === 'yes' ? <th onClick={() => applyOrder(column.column)} key={`column-title-${column?.title}-${column?.column}`}><div>{column.title} {filterOptions?.order?.orderBy === column?.column ? filterOptions?.order?.direction === 'desc' ? <FaSortAlphaDown /> : <FaSortAlphaUp /> : <FaSortAlphaUp opacity={0.3} />}</div></th> : <></>)}</tr>
                    </thead>}
                    <tbody>
                        {filteredData?.length > 0 &&
                            filteredData?.map((row, index) => <tr key={`column-field-${row?._id ? row?._id : row?.title}-${index}`}>{organizeByColumn(headerData, row)}</tr>)}

                    </tbody>

                </table >
            </div>
            <div className={`${className} mobileOnly column gap-sm`}>
                {filteredData?.length > 0 &&
                    filteredData?.map((row, index) => organizeByColumnMobile(headerData, row))}

            </div >


            <div className="container row row-end gap">
                <PaginationBar count={count} changePage={changePage} countPages={countPages} filterOptions={filterOptions} />
            </div></>}
        {renderOption === 'chart' && <Chart table={table} hook={searchHook} data={[]} group="state" />}
        {renderOption === 'chart-line' && <ChartLine table={table} hook={searchHook} data={[]} />}
    </div>
}

export default Table;