import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';

import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import MainLayout from '../header';

interface IParams {
  projectHash: string;
}


const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, prepareMenu } = usePages();
  const { token, user, handleApiErrors, signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(true);

  useEffect(() => {
    /* if (user && user.email !== 'guilherme@encontrodigital.com.br') {
         signOut();
         window.location.href = '/';
       } */

  }, [user])

  return (
    <MainLayout>

      <Wrapper>

        <Menu menuList={menu} fixedMenu={false} />
        {ready && <>
          {children}

          <PopupComponent />


        </>}
      </Wrapper>

    </MainLayout>
  );
};

export default DefaultLayout;
