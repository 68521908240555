import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBarcode, FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';
import { isArray } from 'util';
import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';
import IsentionPage from '../IsentionPage';
import IsentionRecursePage from '../IsentionRecursePage';
import PaymentRegistration from './modules/PaymentRegistration';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;
  myMedicSubscription?: Record<string, any>;
}

const Isencoes: React.FC<IProps> = ({ myMedicSubscription, changeStatus }) => {

  const { user } = useAuth();
  const { addModal, removeModal } = useModal();
  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])
  const [invoices, setInvoices] = useState<Array<Record<string, any>>>([])
  const [products, setProducts] = useState<Array<Record<string, any>>>([])
  const { configuration } = useConfiguration();
  const { projectHash } = useParams<Record<string, any>>();
  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }
  const [paymentInfo, setPaymentInfo] = useState<Record<string, any>>()
  const getPaymentMethod = {

    boleto: "Boleto",
    boleto_recurring: "Boleto",
    credit_card: "Cartão de Crédito",
    credit_card_recurring: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }



  const loadCheckPayment = async () => {


    const responsePayment = await api.get(`/checkPayment/${projectHash}`);


    if (responsePayment?.data) {
      setPaymentInfo(responsePayment?.data);
    }


  }

  const load = async () => {



    const response = await api.get(`/my-isentions/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }




  useEffect(() => {

    if (projectHash) {
      loadCheckPayment();
      load();

    }

  }, [projectHash])

  const askIsention = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: '',
      key: 'isention',
      content: <IsentionPage hash={configuration?.url || ''} />

    })

  }


  const askIsentionRecurse = (item) => {



    addModal({
      theme: 'whiteModalMedium',
      title: '',
      key: 'isention',
      content: <IsentionRecursePage hash={configuration?.url || ''} id={item?._id} setCallback={() => {
        load();
      }} />

    })

  }


  return (
    <>
      <Container >
        {configuration?.allow_isention_recurse === 'yes' && paymentInfo ? <button onClick={() => { askIsention() }} style={{ boxShadow: '0px', height: 'auto', maxWidth: '200px', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar isenção de pagamento</button> : <></>}

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Solicitações de isenção</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr><th>Data</th> <th>Pagamento</th></tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}><td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td> <td style={{ fontSize: '14px', lineHeight: '16px' }}>


                    {paymentInfo?.category ? <p>Categoria : {sale?.category || paymentInfo?.category}</p> : <></>}
                    {paymentInfo?.area ? <p>Área : {sale?.area || paymentInfo?.area}</p> : <></>}
                    {paymentInfo?.subarea ? <p>Subárea : {sale?.subarea || paymentInfo?.subarea}</p> : <></>}

                    <br />  Participante: {sale?.name}
                    <br />E-mail: {sale?.email}
                    <br />Telefone: {sale?.phone}
                    <br />CPF: {sale?.document_number}

                    <br />Documentos anexados <br /><br />


                    {sale?.document ? <a href={`${urlWebsite}/${sale?.document}`} download><button style={{ margin: '0px' }} className="defaultButtonMini">Download</button></a> : <></>}


                    {sale?.have_recurse === 'Sim' || sale?.recurse_justification ? <><br /><strong>Recurso solicitado</strong> <br />  </> : ''}
                    {sale?.have_recurse === 'Sim' || sale?.recurse_justification ? <> <strong>Justificativa do recurso</strong><br />  </> : ''}
                    {sale?.have_recurse === 'Sim' || sale?.recurse_justification ? <> {sale?.recurse_justification}<br />  </> : ''}

                    {sale?.have_recurse === 'Sim' || sale?.recurse_documents ? <><br /><strong>Documentos anexados <br /></strong><br /></> : <></>}


                    {sale?.recurse_documents ? <a href={`${urlWebsite}/${sale?.recurse_documents}`} download><button style={{ margin: '0px' }} className="defaultButtonMini">Download dos documentos do recurso</button></a> : <></>}

                    {configuration?.allow_isention_recurse_against === 'yes' && (sale?.have_recurse !== 'Sim' || sale?.recurse_allow_resend === 'Sim') ? <button onClick={() => { askIsentionRecurse(sale) }} style={{ boxShadow: '0px', height: 'auto', maxWidth: '200px', width: '100%', padding: '15px' }} className='defaultButtonGreen'>Solicitar recurso</button> : <></>}

                  </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>Nenhuma isenção solicitada</td></tr>}
            </tbody>
            </table>
          </div>

        </div>



      </Container>
    </>
  );
};
export default Isencoes;
