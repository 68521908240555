import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';



import api from '../../../../services/api';
import { FaImage, FaPlusCircle, FaTicketAlt, FaTrash } from 'react-icons/fa';
import { useModal } from '../../../../hooks/Modal';
import { uuid } from 'uuidv4';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAuth } from '../../../../hooks/Auth';
import { useToast } from '../../../../hooks/Toast';
import Loader from '../../core/components/Loader';
import { urlWebsite } from '../../../../config';
import { setTimeout } from 'timers';

interface IProps {
  template: Array<Record<string, any>>;
  currentValue?: Record<string, any>;
  profile?: Record<string, any>;
}


const UploadCurriculumContent: React.FC<IProps> = ({ profile = {}, template, currentValue = {} }) => {
  const { addToast } = useToast();
  const { projectHash, configuration } = useConfiguration();
  const { addModal } = useModal();
  const [curriculumContent, setCurriculumContent] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();


  const naturalizado = [{
    hash: 'certidao_naturalizacao',
    title: 'Certidão Positiva de Naturalização'
  }
  ]


  const estrangeiro = [{
    hash: 'visto',
    title: 'Visto de permanência no Brasil que autoriza o candidato a exercer as atividades do programa de Residência Médica'
  },
  {
    hash: 'diploma_mec',
    title: 'Diploma do curso de graduação em Medicina devidamente revalidado e registrado pelo Ministério da Educação (MEC)'
  },
  {
    hash: 'rg_estrangeiro',
    title: 'Cédula de identidade para estrangeiros'
  },
  {
    hash: 'visto_permanencia',
    title: 'Apresentação do visto de permanência no Brasil e Certificado de Proficiência em Língua Portuguesa (CELPE-BRAS) emitido segundo as normas legais vigentes'
  }
  ]


  useEffect(() => {
    console.log(currentValue)
    setCurriculumContent(currentValue);
  }, [currentValue])

  const uploadPhoto = async ({ file, category, subcategory = '' }) => {

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post('/curriculum-upload', formData);
      if (response) {

        const data = { ...curriculumContent };




        if (!data?.[category?.hash]) {
          data[category.hash] = { uploads: [] };
        }

        if (category?.show_subcategories === 'yes') {
          if (!data[category.hash][subcategory]) {
            data[category.hash][subcategory] = [];
          }

          data[category.hash][subcategory].push({ url: response?.data?.message, hash: uuid(), createdAt: new Date() })

        }
        else {

          data[category.hash].uploads.push({ url: response?.data?.message, hash: uuid(), createdAt: new Date() })
        }

        await setTimeout(() => {




          setLoading(false);
          setCurriculumContent({ ...data });






        }, 3000);


      }
      else {

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      return addToast({ title: err.response.data.message || 'Erro ao realizar upload' })
    }
  };


  const saveCurriculum = async () => {
    setLoading(true);

    let docs = '';



    template.map(option => {



      if (option.mandatory === 'yes' && (curriculumContent?.[option.hash]?.uploads?.length || 0) < 1) {
        docs = `${docs}${option.title} é obrigatório;`;


      }

    })



    if (docs) {
      setLoading(false);
      addToast({ title: 'Adicione todos os documentos obrigatórios', description: docs, type: 'error' });
      return;
    }


    try {

      const response = await api.post(`/update-my-medic-documents/${profile._id}`, { documents: curriculumContent });

      setLoading(false);
      return addToast({ title: 'Salvo com sucesso', type: 'success' });
    }
    catch (err) {
      setLoading(false);
      return addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao atualizar', type: 'error' });
    }

  }

  const uploadAndShow = async ({ event, category, subcategory = '' }) => {

    const file = event?.target?.files?.[0];

    if (!file) {
      return addToast({ title: 'Arquivo não encontrado', type: 'info' });
    }

    if (file.size > 4999999) {
      return addToast({ title: 'O tamanho máximo aceito para envio é de 2MB', type: 'error' });
    }



    if (file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return addToast({ title: 'São aceitos apenas arquivos em pdf, .png, .jpg e .jpeg', type: 'error' });
    }

    setLoading(true);

    await uploadPhoto({ file, category, subcategory });




  }

  const removeItem = ({ hash, category = '', upload }) => {

    setCurriculumContent(state => {

      const data = { ...state };
      if (category) {

        const itemIndex = data[hash][category].findIndex(i => i.hash === upload);

        if (itemIndex >= 0) {
          data[hash][category].splice(itemIndex, 1);
        }

      }
      else {

        const itemIndex = data[hash].uploads.findIndex(i => i.hash === upload);

        if (itemIndex >= 0) {

          data[hash].uploads.splice(itemIndex, 1);

        }

      }


      return data;
    })


  }

  const showImage = (url) => {

    addModal({ theme: 'whiteModal', title: ' ', content: <img style={{ width: '100%' }} src={`${urlWebsite}/${url}`} /> })

  }


  const placeOptions = (item, index) => {


    return <>
      {item.categories && item.categories.map(cat => {
        return <div key={`curriculum_categories_div-${cat.hash}`} className='curriculum_categories_div'>

          <div><p key={`${cat.title}-${index}`}>({cat.title} - (Frente e verso quando houver em todos os documentos)
          </p>
            <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: item, subcategory: cat.hash }) }} /> <FaImage size={20} /> Adicionar PDF / Imagem
            </button>
          </div>

          <div className='uploadsDiv'>
            {curriculumContent?.[item.hash]?.[cat.hash] && curriculumContent?.[item.hash]?.[cat.hash].map(up => {
              return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, category: cat.hash, upload: up.hash })}><FaTrash /></div>
              </div> : <div className='uploadItem' key={`uploadItem1-${up.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, category: cat.hash, upload: up.hash })}><FaTrash /></div>
              </div>
            })}

          </div>

        </div >


      })}

    </>



  }


  if (profile.status !== 'register') {
    return <Container>

      <h2 style={{ color: '#333', padding: '20px', background: '#fff', margin: '0px auto' }}>Etapa não liberada</h2>
    </Container>
  }



  return (
    <Container>



      {template && template.length > 0 ? <>

        {profile.status === 'register' ? <>


          <h2 style={{ color: '#333' }}>Após adicionar seus documentos, clique no botão salvar para confirmar</h2>


          <h2 style={{ color: '#333', fontSize: '14px' }}>O tamanho máximo dos documentos é de 2mb, no formato pdf, png ou jpg</h2>

          <p style={{ color: '#333', fontSize: '14px' }}>Ao salvar, confirmo a veracidade dos documentos inseridos.</p>


          <button onClick={() => { saveCurriculum() }} className='defaultButton'>Salvar</button><br />
          <p style={{ color: '#333', fontSize: '14px' }}>Os documentos devem ser inseridos de forma individual, cada um em seu arquivo.</p>

        </> :



          <h2 style={{ color: '#333' }}>Você pode visualizar seus documentos, porém não é mais possível alterará-los</h2>
        }



      </>
        :
        <h2>Etapa não configurada</h2>

      }


      {loading && <Loader message='Carregando' />}




      {template && template.length > 0 && template.map((item, index) => {

        return <div className='curriculum_div' key={`curriculum-div${item.hash}`}>


          <h3 key={item.title}>{item.title} (Frente e verso quando houver em todos os documentos)
            {item?.mandatory === 'yes' ? <strong title='Obrigatório' style={{ color: 'red' }}>*</strong> : <></>}</h3>

          {item.description && <p dangerouslySetInnerHTML={{ __html: item.description }} />}

          <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: item }) }} /> <FaImage size={20} />Adicionar PDF / Adicionar Imagem</button>


          <div className='uploadsDiv'>
            {curriculumContent?.[item.hash]?.uploads && curriculumContent?.[item.hash]?.uploads.map(up => {


              return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div>
              </div> : <div className='uploadItem' key={`uploadItem-yes-${item.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                < div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div>
              </div>
            })}

          </div>



        </div>


      })}

      {profile?.subscription?.country_type === 'ESTRANGEIRO NATURALIZADO' ? <>

        {naturalizado?.map(item => {

          return <div className='curriculum_div' key={`curriculum-div${item.hash}`}>


            <h3 key={item.title}>{item.title} (Frente e verso quando houver em todos os documentos)
              <strong title='Obrigatório' style={{ color: 'red' }}>*</strong> </h3>


            <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: item }) }} /> <FaImage size={20} />Adicionar PDF / Adicionar Imagem</button>


            <div className='uploadsDiv'>
              {curriculumContent?.[item.hash]?.uploads && curriculumContent?.[item.hash]?.uploads.map(up => {


                return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                  <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div>
                </div> : <div className='uploadItem' key={`uploadItem-yes-${item.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                  < div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div>
                </div>
              })}

            </div>



          </div>


        })}

      </> : <></>}

      {profile?.subscription?.country_type === 'ESTRANGEIRO SEM NATURALIZAÇÃO' ? <>


        {estrangeiro?.map(item => {

          return <div className='curriculum_div' key={`curriculum-div${item.hash}`}>


            <h3 key={item.title}>{item.title} (Frente e verso quando houver em todos os documentos)
              <strong title='Obrigatório' style={{ color: 'red' }}>*</strong> </h3>


            <button type="button" className='uploadButton'> <input type={'file'} onChange={(e) => { uploadAndShow({ event: e, category: item }) }} /> <FaImage size={20} />Adicionar PDF / Adicionar Imagem</button>


            <div className='uploadsDiv'>
              {curriculumContent?.[item.hash]?.uploads && curriculumContent?.[item.hash]?.uploads.map(up => {


                return up?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <div className='uploadItem' key={`uploadItem1-${up.hash}`}><a href={`${urlWebsite}/${up.url}`} download  ><img key={up.url} style={{ width: '250px' }} src={`/apoio/pdf.png`} /></a>

                  <div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div>
                </div> : <div className='uploadItem' key={`uploadItem-yes-${item.hash}`}><img onClick={() => showImage(up.url)} key={up.url} style={{ width: '250px' }} src={`${urlWebsite}/${up.url}`} />

                  < div className='uploadDelete' onClick={() => removeItem({ hash: item.hash, upload: up.hash })}><FaTrash /></div>
                </div>
              })}

            </div>



          </div>


        })}



      </> : <></>}
      {profile.status === 'register' ? <>


        <button onClick={() => { saveCurriculum() }} className='defaultButton'>Salvar</button><br />

      </> : <></>
      }


    </Container >
  );
};

export default UploadCurriculumContent;
