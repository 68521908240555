import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';

import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaEye, FaFilePdf, FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import { useAuth } from '../../../../../../hooks/Auth';
import { QuestionPage } from './styles';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
    lineData: Record<string, any>;

}

interface IParams {
    projectHash: string;
    eventHash: string;
}

interface IProps {
    question: Record<string, any>;
    questionNumber: number;
    protocol: string;
}


const PrintQuestionModule: React.FC<IProps> = ({ question, questionNumber, protocol }) => {
    return <> <QuestionPage key={question?._id?.toString()}>
        <br /><br />
        <h2 style={{ margin: '0px' }}>{questionNumber}.</h2>
        <p style={{ margin: '0px' }}>Caderno {question?.book}:</p>
        <p>Questão: {question.question}</p>
        <p style={{ margin: '0px' }}>Protocolo {question?.protocol}:</p>
        <p style={{ margin: '0px' }}>Inscrito: {question?.client_id_response?.name}:</p>
        <br />
        <p style={{ margin: '0px' }}>Recurso:</p>
        {question?.description ? <p style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: question?.description }} /> : <></>}



    </QuestionPage><div style={{ pageBreakBefore: 'always', height: '2px', width: '100%', background: '#000' }} /></>
}




export default function CreateModule(): JSX.Element {

    const { addToast } = useToast();
    const { addPrint, printIt } = usePrinter();
    const { configuration } = useConfiguration();
    const { user } = useAuth();
    const { addModal } = useModal();
    const { addLoading, removeLoading } = useLoading();
    const key = uuid();




    const handleCreate = async () => {

        addLoading({ title: 'Carregando solicitação', key: 'loading' })
        /* take book info */
        const bookInfo = await api.get(`/selective-process-proof-recurse`, { params: { limitItems: 10000, where: { project_id: configuration?.url } } })

        const content: Array<JSX.Element> = [<><h2 style={{ color: '#333' }}>Recursos de questão:</h2><h2 style={{ color: '#333' }}>{configuration?.title}</h2></>];

        bookInfo?.data?.rows?.sort((a, b) => `${a.book}-${a.question < 10 ? `0${a.question}` : a.question}` > `${b.book}-${b.question < 10 ? `0${b.question}` : b.question}` ? 1 : `${a.book}-${a.question < 10 ? `0${a.question}` : a.question}` < `${b.book}-${b.question < 10 ? `0${b.question}` : b.question}` ? -1 : 0)


        bookInfo?.data?.rows?.map((question, index) => {

            content.push(<PrintQuestionModule protocol={question?.protocol} question={question} questionNumber={index + 1} />)


        })
        addModal({ title: '', content: <>{content}</>, theme: 'whiteModal' });
        removeLoading('loading');
        // printIt();
        //addToast({ title: 'Exportado com sucesso', type: 'success' });


    };

    const callModalToCreate = () => {


        addModal({
            theme: 'whiteModalMedium', title: '', content: <>
                <h2 style={{ color: '#333' }}>Você deseja exportar as questões?</h2><br />\g
                <p style={{ color: '#333' }}>Sua solicitação será registrada em nosso sistema.</p>
                <br />
                <button onClick={() => handleCreate()} className='defaultButtonReverse'>Confirmar</button>
            </>
        })


    }

    return <>

        {user?.profile === 1 || (user?.profile === 21 && user?.all_categories === 'Sim') ?
            <button className="lineIcon" style={{ background: 'purple', border: '1px solid purple' }} title='Gerar Listagem' type="button" onClick={() => handleCreate()}>

                <FaFilePdf style={{ minWidth: '25px', minHeight: '25px', color: '#fff' }} />
            </button> : <></>}</>
}
