import styled from 'styled-components';
import {ContainerRef} from '../styles';
import theme from '../../../template';

export const Container = styled(ContainerRef)`
  textarea {
    width: 100%;
    min-height: 200px;
    border: 0;
    color: ${theme.input.color};
    padding: 16px;
    background: transparent;

    &::placeholder {
      color: ${theme.input.placeholder};
    }
  }

`;
