import React from 'react';
import FaqFeature from '../DashboardPages/features/Faq';
 
 
 

const Faq: React.FC = () => {


  return (
    <>

<FaqFeature/>
      
    </>
  );
};
export default Faq;
