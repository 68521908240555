import Create from './Create';
import Delete from './Delete';
import Update from './Update';
import UpdateUnique from './UpdateUnique';
const lineModules = {
    create : Create,
    delete : Delete,
    update : Update,
    updateUnique : UpdateUnique
}

export default lineModules;