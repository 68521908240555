import { format, parseISO, addHours, getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR/index.js';
import es from 'date-fns/locale/es/index.js';
import en from 'date-fns/locale/en-US/index.js';

export const date = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    const zonedDate = utcToZonedTime(dateValue, 'America/Sao_Paulo');
    formattedDate = format(addHours(zonedDate, 3), 'dd/MM/yyyy', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateSimpleMonth = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "MMMM' de 'yyyy", { locale: pt });
  } catch (err) {
    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');
  return formattedDate;
};

export const completeDate = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd/MM' - 'HH'h'mm", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateSimple = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const hour = (dateValue: string): string => {

  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "HH'h'mm", { locale: pt });

    formattedDate = formattedDate.replace('h00', 'h');
  } catch (err) {

    return '';
  }

  return formattedDate;
};

export const day = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const month = (dateValue: string | Date): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "LLL", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const monthFull = (dateValue: string | Date): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "LLLL", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const dateLanguageHour = (dateValue: string | Date, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');

    if (language === 'en') {
      formattedDate = format(zonedDate, "HH'h'mm - ' EEEE", {
        locale: en,
      });
    }
    else if (language === 'es') {
      formattedDate = format(zonedDate, "HH'h'mm '-' EEEE", {
        locale: es,
      });
    }
    else {
      formattedDate = format(zonedDate, "HH'h'mm '-' EEEE", {
        locale: pt,
      });
    }
  } catch (err) {
    return '';
  }



  return formattedDate;
};


export const fullDate = (dateValue: string | Date, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');


    formattedDate = format(zonedDate, "dd 'de' MMMM 'de' yyyy", {
      locale: pt,
    });

  } catch (err) {

    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');

  formattedDate = formattedDate.replace('segunda', 'Segunda');
  formattedDate = formattedDate.replace('terça', 'Terça');
  formattedDate = formattedDate.replace('quarta', 'Quarta');
  formattedDate = formattedDate.replace('quinta', 'Quinta');
  formattedDate = formattedDate.replace('sexta', 'Sexta');
  formattedDate = formattedDate.replace('sábado', 'Sábado');
  formattedDate = formattedDate.replace('domingo', 'Domingo');

  return formattedDate;
};

export const dateLanguage = (dateValue: string | Date, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');

    if (language === 'en') {
      formattedDate = format(zonedDate, "MMMM','dd' - ' EEEE", {
        locale: en,
      });
    }
    else if (language === 'es') {
      formattedDate = format(zonedDate, "dd 'de' MMMM '-' EEEE", {
        locale: es,
      });
    }
    else {
      formattedDate = format(zonedDate, "dd 'de' MMMM '-' EEEE", {
        locale: pt,
      });
    }
  } catch (err) {
    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');

  formattedDate = formattedDate.replace('segunda', 'Segunda');
  formattedDate = formattedDate.replace('terça', 'Terça');
  formattedDate = formattedDate.replace('quarta', 'Quarta');
  formattedDate = formattedDate.replace('quinta', 'Quinta');
  formattedDate = formattedDate.replace('sexta', 'Sexta');
  formattedDate = formattedDate.replace('sábado', 'Sábado');
  formattedDate = formattedDate.replace('domingo', 'Domingo');

  return formattedDate;
};


export const dateFullWithHour = (dateValue: string | Date, language = 'ptBr'): string => {

  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');



    formattedDate = format(zonedDate, "dd 'de' MMMM 'de' yyyy 'as' HH'h'mm ", {
      locale: pt,
    });
    console.log(formattedDate);
  } catch (err) {
    console.log(err);
    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');

  formattedDate = formattedDate.replace('segunda', 'Segunda');
  formattedDate = formattedDate.replace('terça', 'Terça');
  formattedDate = formattedDate.replace('quarta', 'Quarta');
  formattedDate = formattedDate.replace('quinta', 'Quinta');
  formattedDate = formattedDate.replace('sexta', 'Sexta');
  formattedDate = formattedDate.replace('sábado', 'Sábado');
  formattedDate = formattedDate.replace('domingo', 'Domingo');

  return formattedDate;
};

export const simpleDate = (dateValue: string): string => {
  console.log(dateValue)
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'.'MM", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const simpleDateUS = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "yyyy'-'MM-dd", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const makeText = (date) => {
  let formattedDate = '';
  try {
    const parsedDate = typeof date === 'object' ? new Date(date) : parseISO(date);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');

    const hour = zonedDate.getHours();


    formattedDate = hour > 0 ? format(zonedDate, "dd' de 'MMMM' de 'yyyy', 'hh'h", {
      locale: pt,
    }) : format(zonedDate, "dd' de 'MMMM' de 'yyyy", {
      locale: pt,
    });
  }
  catch (err) {

  }
  return formattedDate;
}


export const mergeDateText = (dateValueStart: string, dateValueEnd: string = ''): string => {

  let start_date = '';
  let end_date = '';

  if (dateValueStart) {
    start_date = makeText(dateValueStart);
  }

  if (dateValueEnd) {
    end_date = makeText(dateValueEnd);
  }

  let newDateText = ``;
  if (start_date && end_date) {
    newDateText = `${start_date} a ${end_date}`;
  }
  if (start_date && !end_date) {
    newDateText = `A partir de ${start_date}`;
  }
  if (!start_date && end_date) {
    newDateText = `Até ${end_date}`;
  }

  return newDateText;
};


export const simpleDateBRWithTrace = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'-'MM'-'yyyy", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const simpleDateBR = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'/'MM'/'yyyy", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};
