import { Form } from '@unform/web';
import { userInfo } from 'os';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import { date, simpleDate, simpleDateUS } from '../../../../utils/date';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Loader from '../../../DashboardPages/core/components/Loader';
import MedicalRegistration from '../../../DashboardPages/features/MedicalRegistration';
import { banks } from '../../../DashboardPages/features/MedicalRegistration/FormContainer/banks';
import VisualizateDocuments from './modules/visualizateDocuments';
import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const ConfirmateRegistration: React.FC<IProps> = ({ changeStatus }) => {


  const [profile, setProfile] = useState<Record<string, any>>({})

  const { projectHash, configuration } = useConfiguration();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const { addToast } = useToast();

  const confirmRegistration = async (data) => {

    setLoading(true);


    if (!data?.term?.[0]) {
      setLoading(false);
      addToast({ title: 'Por favor, confirme o termo ao final da listagem', type: 'error' });
      return;
    }

    try {
      const response = await api.post(`/selective-process-confirm-registration/${profile._id.toString()}`, { term: data?.term?.[0] });
      addToast({ title: 'Confirmação realizada com sucesso', type: 'success' });

      setTimeout(() => {
        window.location.reload();
      }, 2000)
      setLoading(false);
      return;



    }
    catch (err) {
      setLoading(false);
      addToast({ title: err?.response?.data?.message || 'Erro ao realizar envio', type: 'error' });
      return;
    }





  }

  const load = async () => {



    const response = await api.get(`/my-medic-subscription/${projectHash}`);

    if (response?.data) {
      setProfile(response.data);
    }

  }

  useEffect(() => {

    if (projectHash) {

      load();
    }

  }, [projectHash])

  if (!profile?.client_id_response) {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#333', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Carregando ...</nav>

      </div></div>
    </Container>
  }

  if (profile?.event_profile?.[projectHash]?.allow_registration === 'no') {
    return <Container >

      <div className='user-block shadow' style={{ overflow: 'hidden' }}>
        <div className='content-block' style={{ padding: '10px' }}>

          <p className='contentTitle'>Matrícula não autorizada.</p>

        </div>
      </div>
    </Container>
  }


  const getBank = (value) => {

    const item = banks.find(bank => bank.value === value);


    if (item) {

      return `(${item.value}) ${item.label}`;
    }

    return ''


  }

  if (profile.status !== 'register' && profile.status !== 'waiting_complete' && profile.status !== 'aprovado') {
    return <Container>
      <div className='user-block shadow'  >
        <h2 style={{ color: "#333", padding: '20px', margin: '0px auto' }}>Etapa de Matrícula não liberada</h2>
      </div>
    </Container>
  }

  if (profile?.register_position !== 'Classificado') {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#ddd', padding: '30px', margin: '20px auto', color: '#333', borderRadius: '15px' }}>Sua matrícula não está liberada<br />

          <strong>Seu status: {profile?.register_position}</strong>
        </nav>

      </div></div>
    </Container>
  }

  return <Container>

    {loading === true && (
      <Loader message="Preparando sua matrícula..." position="fixed" />
    )}

    <div className='user-block shadow'  >
      <div className='content-block' style={{ padding: '10px' }}>

        <p className='contentTitle'>Revisão de dados</p>
        {profile.status === 'waiting_complete' || profile.status === 'aprovado' ? <nav style={{ background: 'rgb(0,200,0)', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Envio Confirmado</nav> : <></>}
        <table>
          <tbody>

            {configuration && configuration?.register_steps && configuration?.register_steps.findIndex(item => item.hash === 'subscription') >= 0 ? <>
              <tr><td>NOME COMPLETO</td><td>{profile?.client_id_response?.name}</td></tr>
              <tr><td>E-MAIL</td><td>{profile?.client_id_response?.email}</td></tr>
              <tr><td>CPF</td><td>{profile?.client_id_response?.document_number}</td></tr>
              <tr><td>NOME QUE DESEJA USAR NO CRACHÁ</td><td>{profile?.subscription?.badge_name}</td></tr>
              <tr><td>DATA DE NASCIMENTO</td><td>{profile?.subscription?.birth_date}</td></tr>

              <tr><td>BANCO</td><td>{getBank(profile?.subscription?.bank_name)}</td></tr>
              <tr><td>AGÊNCIA</td><td>{profile?.subscription?.bank_agency}</td></tr>
              <tr><td>DÍGITO VERIFICADOR AGÊNCIA</td><td>{profile?.subscription?.bank_agency_dv}</td></tr>
              <tr><td>CONTA CORRENTE</td><td>{profile?.subscription?.bank_account}</td></tr>
              <tr><td>DÍGITO VERIFICADOR CONTA CORRENTE</td><td>{profile?.subscription?.bank_account_dv}</td></tr>


              <tr><td>ESTADO CIVIL</td><td>{profile?.subscription?.marital_status}</td></tr>
              <tr><td>PIS/NIT/NIS</td><td>{profile?.subscription?.work_number}</td></tr>

              <tr><td>TIPO SANGUÍNEO</td><td>{profile?.subscription?.blood_type}</td></tr>
              <tr><td>FATOR RH SANGUÍNEO</td><td>{profile?.subscription?.blood_type_factor}</td></tr>

              <tr><td>CARTEIRA DE IDENTIDADE - RG</td><td>{profile?.subscription?.rg}</td></tr>
              <tr><td> DATA DE EXPEDIÇÃO DO RG</td><td>{profile?.subscription?.rg_date}</td></tr>
              <tr><td> ORGÃO EXPEDIDOR DO RG</td><td>{profile?.subscription?.rg_emissor}</td></tr>
              <tr><td> ESTADO EXPEDIDOR DO RG</td><td>{profile?.subscription?.rg_uf}</td></tr>


              <tr><td> RAÇA / COR</td><td>{profile?.subscription?.race}</td></tr>
              <tr><td> GÊNERO</td><td>{profile?.subscription?.gender_id}</td></tr>

              <tr><td> PAÍS DE NASCIMENTO</td><td>{profile?.subscription?.born_in}</td></tr>
              <tr><td> NACIONALIDADE</td><td>{profile?.subscription?.nationality}</td></tr>


              <tr><td> NOME COMPLETO DA MÃE</td><td>{profile?.subscription?.mother_name}</td></tr>
              <tr><td> NOME COMPLETO DO PAI</td><td>{profile?.subscription?.father_name}</td></tr>

              <tr><td> CEP</td><td>{profile?.subscription?.zipcode}</td></tr>
              <tr><td> ENDEREÇO</td><td>{profile?.subscription?.address}</td></tr>
              <tr><td> NÚMERO</td><td>{profile?.subscription?.address_number}</td></tr>
              <tr><td> COMPLEMENTO</td><td>{profile?.subscription?.address_number_extra}</td></tr>
              <tr><td> BAIRRO</td><td>{profile?.subscription?.neighborhood}</td></tr>
              <tr><td> CIDADE</td><td>{profile?.subscription?.city}</td></tr>
              <tr><td> ESTADO</td><td>{profile?.subscription?.state}</td></tr>

              <tr><td> DDD DO TELEFONE RESIDENCIAL</td><td>{profile?.subscription?.ddd_home}</td></tr>
              <tr><td> TELEFONE RESIDENCIAL</td><td>{profile?.subscription?.phone}</td></tr>
              <tr><td> DDD DO CELULAR</td><td>{profile?.subscription?.ddd_mobile}</td></tr>
              <tr><td> TELEFONE CELULAR</td><td>{profile?.subscription?.mobile}</td></tr>


              <tr><td>NÚMERO DE REGISTRO PROFISSIONAL</td><td>{profile?.subscription?.profissional_number}</td></tr>
              <tr><td>DATA DO REGISTRO PROFISSSIONAL</td><td>{profile?.subscription?.profissional_number_date}</td></tr>

              <tr><td>GRAU DE INSTRUÇÃO</td><td>{profile?.subscription?.graduation}</td></tr>
              <tr><td>UNIVERSIDADE DE GRADUAÇÃO</td><td>{profile?.subscription?.instituicao}</td></tr>
              <tr><td>DATA DE INGRESSO NA UNIVERSIDADE</td><td>{profile?.subscription?.university_entry_date}</td></tr>
              <tr><td>DATA DE CONCLUSÃO DA GRADUAÇÃO</td><td>{profile?.subscription?.university_finish_date}</td></tr>

              <tr><td>RAÇA/COR</td><td>{profile?.subscription?.race}</td></tr>

              <tr><td>TÍTULO DE ELEITOR</td><td>{profile?.subscription?.voter_registration}</td></tr>
              <tr><td>DATA DE EMISSÃO DO TITULO DE ELEITOR</td><td>{profile?.subscription?.votation_date}</td></tr>
              <tr><td>ZONA DO TITULO DE ELEITOR</td><td>{profile?.subscription?.votation_zone}</td></tr>
              <tr><td>SEÇÃO DO TITULO DE ELEITOR</td><td>{profile?.subscription?.votation_section}</td></tr>

              <tr><td>NÚMERO DO RA - CERTIFICADO DE RESERVISTA</td><td>{profile?.subscription?.reservation_number}</td></tr>
              <tr><td>UF DE NASCIMENTO</td><td>{typeof profile?.subscription?.birth_uf === 'string' ? profile?.subscription?.birth_uf : ''}</td></tr>
              <tr><td>NATURALIDADE</td><td>{typeof profile?.subscription?.born_at === 'string' ? profile?.subscription?.born_at : ''}</td></tr>

              <tr><td>SITUAÇÃO DA NACIONALIDADE</td><td>{typeof profile?.subscription?.country_type === 'string' ? profile?.subscription?.country_type : ''}</td></tr>



            </> : <></>}

            {configuration && configuration?.register_steps && configuration?.register_steps.findIndex(item => item.hash === 'subscription_especializacao') >= 0 ? <>
              <tr><td>NOME COMPLETO</td><td>{profile?.client_id_response?.name}</td></tr>
              <tr><td>E-MAIL</td><td>{profile?.client_id_response?.email}</td></tr>
              <tr><td>CPF</td><td>{profile?.client_id_response?.document_number}</td></tr>
              <tr><td>DATA DE NASCIMENTO</td><td>{profile?.subscription?.birth_date}</td></tr>



              <tr><td>ESTADO CIVIL</td><td>{profile?.subscription?.marital_status}</td></tr>


              <tr><td> ORIGEM ÉTNICA</td><td>{profile?.subscription?.race}</td></tr>
              <tr><td> SEXO</td><td>{profile?.subscription?.gender_id}</td></tr>


              <tr><td> NACIONALIDADE</td><td>{profile?.subscription?.nationality}</td></tr>


              <tr><td> NOME COMPLETO DA MÃE</td><td>{profile?.subscription?.mother_name}</td></tr>
              <tr><td> NOME COMPLETO DO PAI</td><td>{profile?.subscription?.father_name}</td></tr>

              <tr><td> CEP</td><td>{profile?.subscription?.zipcode}</td></tr>
              <tr><td> ENDEREÇO</td><td>{profile?.subscription?.address}</td></tr>
              <tr><td> NÚMERO</td><td>{profile?.subscription?.address_number}</td></tr>
              <tr><td> COMPLEMENTO</td><td>{profile?.subscription?.address_number_extra}</td></tr>
              <tr><td> TIPO DE LOGRADOURO</td><td>{profile?.subscription?.address_extra}</td></tr>
              <tr><td> BAIRRO</td><td>{profile?.subscription?.neighborhood}</td></tr>
              <tr><td> CIDADE</td><td>{profile?.subscription?.city}</td></tr>
              <tr><td> ESTADO</td><td>{profile?.subscription?.state}</td></tr>
              <tr><td> COUNTRY</td><td>{profile?.subscription?.country}</td></tr>

              <tr><td> DDD DO CELULAR</td><td>{profile?.subscription?.ddd_mobile}</td></tr>
              <tr><td> TELEFONE CELULAR</td><td>{profile?.subscription?.mobile}</td></tr>



              <tr><td>UNIVERSIDADE DE GRADUAÇÃO</td><td>{profile?.subscription?.instituicao}</td></tr>

              <tr><td>NATURALIDADE</td><td>{typeof profile?.subscription?.born_at === 'string' ? profile?.subscription?.born_at : ''}</td></tr>
              <tr><td>FORMA DE PAGAMENTO</td><td>{profile?.subscription?.payment_form}</td></tr>

            </> : <></>}

            {configuration && configuration?.register_steps && configuration?.register_steps.findIndex(item => item.hash === 'jaleco') >= 0 ? <>   <tr><td>TIPO DE JALECO</td><td>{profile?.dress_type}</td></tr>
              <tr><td>TAMANHO DO JALECO</td><td>{profile?.dress_size}</td></tr></> : <></>}

            {configuration && configuration?.register_steps && configuration?.register_steps.findIndex(item => item.hash === 'photo') >= 0 ? <tr><td>FOTO DO CRACHÁ</td><td><img src={`${urlWebsite}/${profile.photo}`} style={{ width: '200px' }} /></td></tr> : <>
            </>}
            {configuration && configuration?.register_steps && configuration?.register_steps.findIndex(item => item.hash === 'vaccine') >= 0 ? <>
              <tr><td>VACINA: Duas doses de MMR (sarampo, caxumba e rubéola)</td><td>{profile?.vaccines?.mmr}</td></tr>
              <tr><td>VACINA: Três doses de vacina contra hepatite B</td><td>{profile?.vaccines?.hepatiteb}</td></tr>
              <tr><td>VACINA: Antitetânica</td><td>{profile?.vaccines?.antitetanica}</td></tr>
              <tr><td>VACINA: Vacina contra Covid-19 (Esquema de duas doses, exceto Janssen que é dose única)</td><td>{profile?.vaccines?.covid19}</td></tr>
            </> : <></>}


          </tbody>
        </table>

        <VisualizateDocuments lineData={profile} />

        {profile.status === 'register' ? <Form onSubmit={confirmRegistration}>
          <CheckboxInput
            style={{ display: 'flex' }}
            name="term"
            unique={true}

            options={[
              {
                id: `DECLARO que apresento, neste momento, os documentos necessários para a MATRÍCULA. DECLARO que as informações aqui prestadas e os documentos apresentados são verdadeiros. DECLARO ciência  de que a comprovação da autenticidade poderá ser averiguada a qualquer momento, mesmo após a matrícula e que, se constatada falsidade ou divergência das informações, poderei responder por crime contra a fé pública, nos termos da legislação vigente, o que também acarretará a perda da vaga. Em cumprimento à Lei Federal nº 12.527/2011 e nos termos da Lei Federal nº 13.709/2018, fica expressamente autorizada a ampla divulgação de informações, tais como nome, número de inscrição, pedidos protocolados e suas respostas, resultados, pontuações e classificação, descabendo solicitações de exclusão da publicidade oficial. Bem como, divulgação das informações de pré-matrícula entre FundMed e a instituição promotora para garantia de matrícula do candidato`,
                label: 'DECLARO que apresento, neste momento, os documentos necessários para a MATRÍCULA. DECLARO que as informações aqui prestadas e os documentos apresentados são verdadeiros. DECLARO ciência  de que a comprovação da autenticidade poderá ser averiguada a qualquer momento, mesmo após a matrícula e que, se constatada falsidade ou divergência das informações, poderei responder por crime contra a fé pública, nos termos da legislação vigente, o que também acarretará a perda da vaga. Em cumprimento à Lei Federal nº 12.527/2011 e nos termos da Lei Federal nº 13.709/2018, fica expressamente autorizada a ampla divulgação de informações, tais como nome, número de inscrição, pedidos protocolados e suas respostas, resultados, pontuações e classificação, descabendo solicitações de exclusão da publicidade oficial. Bem como, divulgação das informações de pré-matrícula entre FundMed e a instituição promotora para garantia de matrícula do candidato',
                value: `DECLARO que apresento, neste momento, os documentos necessários para a MATRÍCULA. DECLARO que as informações aqui prestadas e os documentos apresentados são verdadeiros. DECLARO ciência  de que a comprovação da autenticidade poderá ser averiguada a qualquer momento, mesmo após a matrícula e que, se constatada falsidade ou divergência das informações, poderei responder por crime contra a fé pública, nos termos da legislação vigente, o que também acarretará a perda da vaga.  Em cumprimento à Lei Federal nº 12.527/2011 e nos termos da Lei Federal nº 13.709/2018, fica expressamente autorizada a ampla divulgação de informações, tais como nome, número de inscrição, pedidos protocolados e suas respostas, resultados, pontuações e classificação, descabendo solicitações de exclusão da publicidade oficial. Bem como, divulgação das informações de pré-matrícula entre FundMed e a instituição promotora para garantia de matrícula do candidato`,
              },

            ]}
          />
          <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
            <button className='defaultButton'>SALVAR E FINALIZAR</button>

            <button onClick={() => changeStatus('registration')} className='defaultButton' type='button'>VOLTAR PARA EDIÇÃO</button>
          </aside>    </Form> : <></>}
      </div>

    </div>




  </Container>


};
export default ConfirmateRegistration;
