import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaTable, FaTabletAlt, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { apiUrl } from '../../../../../../config';
import { downloaditPost } from '../../../../../../utils/download';
import { FiDatabase } from 'react-icons/fi';
import { AiFillFileExclamation } from 'react-icons/ai';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();
  const { addLoading, removeLoading } = useLoading();

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', data.image)
      const response = await api.post(`/upload-selective-process/${projectHash}/${projectHash}`, formData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      addToast({ type: 'success', title: 'Salvo com sucesso' });
      await reloadSearchAll();

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const exportData = async (exportContent) => {
    addLoading({ title: 'Carregando', key: 'loading2' });
    downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: exportContent, type: 'xlsx' })
    removeLoading('loading2')
  }

  const handleCreate = async () => {


    addLoading({ title: 'Carregando', key: 'loading' });
    const items = await api.get('/selective-process', {
      params: {
        limitItems: 10000, where: {

          "$and": [
            { project_id: projectHash },
            { "$or": [{ 'status': 'waiting' }, { 'status': 'waiting_complete' }] },
            { curriculum_recurse_status: 'yes' },

          ]



        }
      }
    })

    items?.data?.rows?.sort((a, b) => `${a?.category}${a?.area}${a?.subarea}${a?.client_id_response?.name}` > `${b?.category}${b?.area}${b?.subarea}${b?.client_id_response?.name}` ? 1 : `${a?.category}${a?.area}${a?.subarea}${a?.client_id_response?.name}` < `${b?.category}${b?.area}${b?.subarea}${b?.client_id_response?.name}` ? -1 : 0)

    const itemsToExport: Array<string>[] = [['Categoria', 'Área', 'Subarea', 'Participante', 'Recurso avaliado?', 'Currículo reavaliado?']];
    {
      items?.data?.rows?.map(processInfo => {
        itemsToExport.push([processInfo?.category, processInfo?.area, processInfo?.subarea, processInfo?.client_id_response?.name, processInfo?.curriculum_recurse_avaliation_status === 'no' ? 'Recurso negado' : processInfo?.curriculum_recurse_avaliation_status === 'yes' ? 'Aprovado' : 'Não avaliado', processInfo?.curriculum_recurse_avaliation_status === 'no' ? '' : processInfo?.curriculum_recurse_avaliation_status === 'yes' && processInfo?.curriculum_status === 'no' ? 'Não' : 'Sim'])

      })
    }


    const content: JSX.Element = <>
      <h2 style={{ color: '#333' }}>Currículos com recurso solicitado</h2>
      <h2 style={{ color: '#333' }}>Total: {items?.data?.rows?.length}</h2>
      <button className='defaultButton' onClick={() => exportData(itemsToExport)}>Baixar</button>
      <table className='tableRef'>
        <tbody>
          <tr>
            <td>Categoria</td>
            <td>Área</td>
            <td>Subárea</td>
            <td>Participante</td>
            <td>Status do recurso</td>
            <td>Currículo reavaliado?</td>
          </tr>
          {items?.data?.rows?.map(processInfo => {

            return <tr>
              <td>{processInfo?.category}</td>
              <td>{processInfo?.area}</td>
              <td>{processInfo?.subarea}</td>
              <td>{processInfo?.client_id_response?.name}</td>
              <td>{processInfo?.curriculum_recurse_avaliation_status === 'no' ? 'Recurso negado' : processInfo?.curriculum_recurse_avaliation_status === 'yes' ? 'Aprovado' : 'Não avaliado'}</td>
              <td>{processInfo?.curriculum_recurse_avaliation_status === 'no' ? '' : processInfo?.curriculum_recurse_avaliation_status === 'yes' && processInfo?.curriculum_status === 'no' ? 'Não' : 'Sim'}</td>
            </tr>
          })}
        </tbody>
      </table>
    </>

    if (content) {
      const keyModal = addModal({ title: ' ', content, key, theme: "whiteModal" });
    }
    removeLoading('loading')
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <button className="searchButton" title='Currículos com recurso solicitado' type="button" onClick={handleCreate}>
      <AiFillFileExclamation />
    </button> : <></>
  );

  return moduleCreate();
}
