import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

interface IProps {
    url : string;
    keyName : string;
}

const VideoPlayer : React.FC<IProps> = ({url, keyName}) => {

  const video = useRef<HTMLVideoElement>(null);
  useEffect(( ) => {

    const config = {
      liveDurationInfinity : true,
      autoStartLoad : true,
      maxStarvationDelay: 2,
      maxLoadingDelay:2,
      maxLiveSyncPlaybackRate: 2,
   
    }
    const hls = new Hls(config);


    const videoPlayer = video.current;
    hls.loadSource(`https://1998719290.rsc.cdn77.org${url}`);

   if(videoPlayer){ hls.attachMedia(videoPlayer);
    
   }
   hls.on(Hls.Events.MANIFEST_PARSED, function() { video.current?.play(); console.log(hls.levels)});
  })
     
      
  
 
    return (
          <video key={keyName} style={{position: 'absolute', width: '100%', height: '100%', left: 0, top: 0, right: 0, bottom: 0}}
            className="videoCanvas"
            controls={true}
            ref={video}
            autoPlay={true}
          />
    );
  
}

export default VideoPlayer;