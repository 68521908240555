import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle, FiXCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import { Error } from '../styles';
import { urlWebsite } from '../../../../../../config';
import api from '../../../../../../services/api';
import {
  FaArrowUp,
  FaArrowDown,
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaArrowCircleUp,
  FaArrowCircleDown,
} from 'react-icons/fa';
import { MdRemoveCircle } from 'react-icons/md';
import { useToast } from '../../../../../../hooks/Toast';

interface IImage {
  name?: string;
  url?: string;
  title?: string;
  description?: string;
  link_url?: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IImage>;
  placeholder: string;
}



const ImageSingleGalleryInput: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  list,
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const { addToast } = useToast();
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [valueImage, setValueImage] = useState<Array<IImage>>(
    defaultValue ? [{ url: defaultValue }] : [],
  );

  useEffect(() => {
    console.log(defaultValue);
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const uploadPhoto = async (ref, e) => {
    setLoadingUpload(true);
    const file = ref.files[0];

    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];

    if (!allowedTypes.includes(file.type)) {
      addToast({ title: 'Somente são aceitos PDFs, PNGs e JPEGs', type: 'error' });
      if (inputRef2?.current) {
        inputRef2.current.value = '';
      }
      setLoadingUpload(false);
    }


    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post('/uploadImage', formData);

      const data = [{ url: response?.data?.message }];
      setValueImage([...data]);
      if (inputRef2?.current) {
        inputRef2.current.value = '';
      }
      setLoadingUpload(false);
    }
    catch (err) {
      setLoadingUpload(false);
      if (inputRef2?.current) {
        inputRef2.current.value = '';
      }
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao realizar upload', type: 'error' })
    }
  };

  const removeImage = index => {
    const data = [...valueImage];
    data.splice(index, 1);
    setValueImage([...data]);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return valueImage?.[0]?.url ? valueImage?.[0]?.url : '';
      },
      setValue: (ref: any, value) => {

        setValueImage([{ url: value }]);
      },
    });
  }, [fieldName, registerField, valueImage]);

  const removeFromSalesCourses = (index: number): void => {


    const SalesCoursesValues = valueImage?.filter(
      (item, indexSalesCourses, array) => {
        return array.indexOf(item) !== index;
      },
    );


    setValueImage([...SalesCoursesValues]);
  };

  const goUp = index => {
    const indexup = index - 1;

    if (valueImage[indexup]) {
      const newCurrent = [...valueImage];

      newCurrent[index] = valueImage[indexup];
      newCurrent[indexup] = valueImage[index];

      setValueImage([...newCurrent]);

    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (valueImage[indexup]) {
      const newCurrent = [...valueImage];

      newCurrent[index] = valueImage[indexup];
      newCurrent[indexup] = valueImage[index];


      setValueImage([...newCurrent]);
    }
  };

  const goAllUp = index => {
    if (valueImage[index]) {
      const newCurrent = [...valueImage];
      const content = { ...newCurrent[index] };

      newCurrent.splice(index, 1);

      newCurrent.unshift(content);

      setValueImage([...newCurrent]);
    }
  };

  const goAllDown = index => {
    if (valueImage[index]) {
      const newCurrent = [...valueImage];

      const content = newCurrent[index];

      newCurrent.splice(index, 1);

      newCurrent.push(content);

      setValueImage([...newCurrent]);
    }
  };

  const printLine = (name, index, list) => {

    const inputs: Array<JSX.Element> = [];

    Object.keys(list).map((key) => {

      const url = valueImage?.[index]?.url || '';
      inputs.push(list[key].name === 'image' || list[key].name === 'photo' || list[key].name === 'url' ?



        <a href={`${urlWebsite}/${valueImage?.[index]?.[list?.[key]?.name]}`} target="_BLANK"><img
          alt="Foto"
          src={url?.indexOf('pdf') >= 0 ? `${urlWebsite}/apoio/pdf.png` : `${urlWebsite}/${valueImage?.[index]?.url}`}
          style={{ width: '100px' }}
        /> </a> :
        <Field>
          <span>{list[key].label}</span>
          <input
            placeholder={list[key].label}

            defaultValue={
              valueImage && valueImage[index] && valueImage[index][list[key].name]
                ? valueImage[index][list[key].name]
                : ''
            }
            onChange={e => {

              let newValue = [...valueImage];

              if (!newValue[index]) { newValue[index] = {}; }
              if (!newValue[index]) { newValue[index][list[key].name] = ''; }

              newValue[index][list[key].name] = e.target.value;
              setValueImage([...newValue]);
            }}
            name={`${name}_${index}`}
          />

        </Field>


      )

    })

    return inputs;

  }


  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Container>
        <input
          ref={inputRef}
          style={{ display: 'none' }}
          name={name}
          value={JSON.stringify(valueImage)}
        />
        <span>{rest.placeholder}</span>
        {Icon && <Icon />}
        <input
          {...rest}
          type="file"
          onChange={e => {
            setCallback(e.target.value, formRef);
            uploadPhoto(inputRef2.current, e)
          }}
          ref={inputRef2}
        />

        {error && (
          <Error title={error}>
            <FiAlertCircle />
          </Error>
        )}
      </Container>
      {loadingUpload ? <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        <p style={{ fontSize: '12px', padding: '10px', textAlign: 'center', width: '100%', background: 'orange', color: '#fff' }}>Upload em andamento...</p>
      </div> : <></>}
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        {valueImage?.map((line, index) => (
          <div style={{ width: '100%', margin: '5px', position: 'relative' }}>


            <Container>     <div
              style={{
                width: '20px',
                height: '20px',

                cursor: 'pointer',
              }}
              onClick={() => removeImage(index)}
            >
              <FiXCircle />
            </div>{printLine(name, index, list)}
              <div className="modules">
                <MdRemoveCircle
                  title="Remover"
                  onClick={() => removeFromSalesCourses(index)}
                />

              </div>
            </Container>



          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSingleGalleryInput;
