import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { useAuth } from '../../../hooks/Auth';
import MainLayout from '../header';

const PageLayout: React.FC = ({ children }) => {
  const { token, user } = useAuth();



  return <MainLayout><Wrapper>{children}</Wrapper></MainLayout>;
};

export default PageLayout;
