import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import Input from '../../../../components/Forms/Input';

import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import getValidationErrors from '../../../../utils/getValidationErrors';
import ImageSingleGalleryInput from '../../../DashboardPages/core/components/Forms/ImageSingleGalleryInput';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import MaskedInput from '../../../DashboardPages/core/components/Forms/MaskedInput';
import AskProofRecurse from '../Home/modules/AskProofRecurse';
import AskSubscriptionRecurse from '../Home/modules/AskSubscriptionRecurse';

import { Container, ContainerWhite } from './styles';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../../hooks/Toast';
import Loader from '../../../../components/Loader';

interface IProps {
  changeStatus: Function;
  selectiveProcess: Record<string, any>;
}

interface IProps2 {
  hash?: string;
}

const IsentionPage: React.FC<IProps2> = ({ hash }) => {

  const { user } = useAuth();
  const { configuration } = useConfiguration();

  const { projectHash = hash } = useParams<Record<string, any>>();
  const [listSubscriptionRecurses, setListSubscriptionRecurses] = useState<Array<Record<string, any>>>([]);
  const [listProofRecurses, setListProofRecurses] = useState<Array<Record<string, any>>>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { addModal } = useModal();
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const sendIsentionRecurse = async (data: Record<string, any>) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
        document_number: Yup.string()
          .required('CPF é obrigatório'),
        document: Yup.string()
          .required('O PDF com a documentação é obrigatório'),


      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');
      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });






      const newUser = await api
        .post(`/send-isention-recurse/${projectHash}`, {
          ...data,
          project_id: projectHash,


        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao enviar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }


      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  if (!configuration?._id) {

    return <Container >

      <h2>Carregando</h2>



    </Container >

  }


  if (success) {

    return <Container >
      <h3>{configuration?.title}</h3>
      <h2>Solicitação de isenção enviada com sucesso!</h2>


    </Container >


  }


  return (
    <>   {loading === true && (
      <Loader message="Preparando sua solicitação..." position="fixed" />
    )}
      <Container >
        <h3>{configuration?.title}</h3>
        <h2>Solicitação de isenção</h2>
        {configuration?.allow_isention_recurse === 'yes' ? <>
          <p>Após o envio da solicitação de isenção, você receberá um e-mail com a confirmação do seu envio.</p>

          <Form ref={formRef} onSubmit={sendIsentionRecurse}>
            <Input name="name" placeholder="Nome completo" />
            <Input name="email" placeholder="E-mail" />
            <MaskedInput mask='(99) 999999999' name="phone" placeholder="Telefone celular" />
            <MaskedInput mask='999.999.999-99' name="document_number" placeholder="CPF" />
            <p style={{ margin: '15px auto' }}>Selecione o PDF em seu dispositivo e faça o upload. Tamanho máximo do documento: 5MB</p>
            <ImageSingleGalleryInput list={[{ name: 'url', title: 'PDF' }]} name="document" placeholder='PDF único com todos os documentos' />



            <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <button className='defaultButton'>Enviar</button>
            </aside>
          </Form>

        </> : <>

          <p style={{ color: '#fff', background: 'rgb(0,50,150)', textAlign: 'center', padding: '10px', borderRadius: '10px' }}>O período de solicitação de isenção não está disponível no momento.</p>
          <Link to={`/app/${projectHash}`}><button className='defaultButton'>Voltar para a capa</button></Link>
        </>}


      </Container >
    </>
  );
};
export default IsentionPage;
