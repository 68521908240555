import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaMoneyBill } from 'react-icons/fa';
import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule(): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { addLoading, removeLoading } = useLoading();


  const handleSend = async (): Promise<void> => {
    addLoading({ title: 'Preparando pagamento', key: 'loading' })
    try {
      const response = await api.post(`/emit-invoice-payments-all`);

      addToast({ title: 'Pagamentos emitidos!', type: 'success' });
      removeModal('SelectiveProcess');
      removeLoading('loading')
    }
    catch (err) {
      handleApiErrors(err);
      removeLoading('loading')
    }
  };

  const handleGrade = () => {

    addModal({
      key: "SelectiveProcess",
      theme: 'whiteModal',
      title: '', content: <><p> Emitir todos os boletos?</p>
        <button className='defaultButtonReverse' onClick={() => handleSend()}>Sim</button>

      </>
    })

  }

  const moduleUpdate = (): JSX.Element => (
    <button className="searchButton" title="Emitir cobranças de boleto" type="button" onClick={() => handleGrade()}>
      <FaMoneyBill />
    </button>
  );

  return moduleUpdate();
}
