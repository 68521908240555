import styled from 'styled-components';
export const CreditCardContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  padding: 20px;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: #ecf0f1;
  width: 320px;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
`;

export const CardLogo = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ecf0f1;
  text-transform: uppercase;
`;

export const MagneticStrip = styled.div`
  width: 100%;
  height: 40px;
  background: #3a3a3a;
  position: absolute;
  top: 15px;
  left: 0;
  border-radius: 8px 8px 0 0;
`;

export const CardNumber = styled.div`
  font-size: 1.4em;
  letter-spacing: 2px;
  margin-top: 60px;
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1em;
`;

export const CardholderName = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

export const ExpiryDate = styled.div`
  font-style: italic;
`;