import React from 'react';
import { Menu } from '../../../components/Menu';
import { Wrapper, Content } from './styles';
import { usePages } from '../../../hooks/Pages';
 
import PopupComponent from '../../../components/PopupContainer';
import MainLayout from '../header';
 

const AuthLayout: React.FC = ({ children }) => {
  const { menu } = usePages();
  return (<MainLayout>
    <Wrapper>
      <Menu menuList={menu} fixedMenu={false} />
      <Content>{children}</Content>
     
      <PopupComponent />
 
    </Wrapper>
 
    </MainLayout>
  );
};

export default AuthLayout;
