import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';




import FormContainer from './FormContainer';

import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface IProps {
  changeStatus: Function;
}

const MedicalRegistration: React.FC<IProps> = ({ changeStatus }) => {

  const { projectHash, eventHash, configuration } = useConfiguration();
  const { translate } = useLanguage();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])



  return (
    <Container>
      <Content>
        <AnimatedDiv>



          <FormContainer changeStatus={changeStatus} />
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default MedicalRegistration;
