import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaFileMedical } from 'react-icons/fa';
import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();


  const handleSend = async (data): Promise<void> => {
    try {
      const response = await api.post(`/create-medic-subscription-from-sale/${lineData.sale_hash}`, { ...data });

      addToast({ title: 'Enviado com sucesso!', type: 'success' });
      removeModal('SelectiveProcess')
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const handleGrade = () => {

    addModal({
      key: "SelectiveProcess",
      theme: 'whiteModal',
      title: 'Informe a Nota na Prova', content: <Form initialData={{ grade: '00.00', status: 'register', register_position: 'Suplente', register_position_number: '2º' }} onSubmit={handleSend}>

        <Input name="grade" placeholder="Nota na Prova" />
        <Input name="register_position_number" placeholder="Posição na classificação" />
        <SelectSimple label="Status de Classificação" name="register_position" options={[
          { label: 'Classificado', value: 'Classificado' },
          { label: 'Suplente', value: 'Suplente' },
          { label: 'Desclassificado', value: 'Desclassificado' },
          { label: 'Não classificado', value: 'Não classificado' },
          { label: 'Cancelado pelo participante', value: 'Cancelado pelo participante' },
          { label: 'Reservado', value: 'Reservado' },
          { label: 'Aguardando etapas anteriores', value: 'Aguardando etapas anteriores' },
        ]} />

        <SelectSimple label="Etapa" name="status" options={[
          { label: 'Aguardando envio de documentação', value: 'waiting' },
          { label: 'Aguardando avaliação da documentação', value: 'waiting_curriculum' },
          { label: 'Aguardando matrícula', value: 'register' },
          { label: 'Aguardando deferimento', value: 'waiting_complete' },
          { label: 'Desclassificado', value: 'disqualified' },
        ]} />

        <button className='defaultButton'>Enviar</button>

      </Form>
    })

  }

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Liberar Pré-matricula" type="button" onClick={() => handleGrade()}>
      <FaFileMedical />
    </button> : <></>
  );

  return moduleUpdate(lineData.sale_hash);
}
