import { Form } from '@unform/web';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInput';
import Loader from '../../../../../../components/Loader';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import TextareaInput from '../../../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadFormContent from '../../../../../DashboardPages/features/UploadFormDocuments';

interface IProps {
    projectHash: string;
}

const AskSpecialTreatment: React.FC<IProps> = ({ projectHash }) => {


    const [specialTreatment, setSpecialTreatment] = useState();



    const { addModal, removeModal } = useModal();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);

    const sendSpecialTreatment = async (data) => {




        data.type = data?.type?.[0] ? data?.type?.[0] : '';
        data.term = data?.term?.[0] ? data?.term?.[0] : '';
        data.documents = data?.documents?.documents ? data?.documents?.documents : [];



        setLoading(true);

        if (!data?.type) {
            addToast({ title: 'Informe o seu tipo de necessidade', type: 'error' });
            setLoading(false);
            return;
        }

        if (!data?.documents?.uploads || data?.documents.uploads?.length < 1) {
            addToast({ title: 'Adicione os documentos solicitados no edital', type: 'error' });
            setLoading(false);
            return;
        }


        if (!data?.term) {
            addToast({ title: 'Confirme o termo', type: 'error' });
            setLoading(false);
            return;
        }




        try {

            const response = await api.post(`/send-selective-process-special-treatment/${projectHash}`, { ...data, project_id: projectHash });
            addModal({ theme: 'whiteModalMini', title: 'Solicitação de atendimento especial enviada', content: <p style={{ width: '100%', fontSize: '16px', color: '#333' }}>Você receberá um e-mail com o protocolo da solicitação</p> });
            removeModal('specialTreatment');
            setLoading(false);

        }
        catch (err) {
            addToast({ title: err?.response?.data?.message || err?.response?.data?.message || 'Erro ao enviar recurso', type: 'error' });
            setLoading(false);
        }
    }
    return <>
        {loading ? <Loader message='Carregando...' /> : <></>}
        <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px', marginBottom: '15px' }}>Para solicitar atendimento especial, preencha os campos abaixo</p>
        <Form onSubmit={sendSpecialTreatment} style={{ paddingTop: '15px', borderTop: '1px solid #ddd' }}>
            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}>TIPO DE ATENDIMENTO ESPECIAL DE QUE NECESSITA</h2>

            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><CheckboxInput setCallBack={(value) => { setSpecialTreatment(value) }} unique name="type" placeholder="" options={[
                { id: 'Acessibilidade no local de provas (Candidato com dificuldade de locomoção).', value: 'Acessibilidade no local de provas (Candidato com dificuldade de locomoção).', label: 'Acessibilidade no local de provas (Candidato com dificuldade de locomoção).' },
                { id: 'Prova com letra ampliada (Candidato com deficiência visual).', value: 'Prova com letra ampliada (Candidato com deficiência visual).', label: 'Prova com letra ampliada (Candidato com deficiência visual).' },
                { id: 'Auxílio para preenchimento do cartão de resposta (Candidato com deficiência visual).', value: 'Auxílio para preenchimento do cartão de resposta (Candidato com deficiência visual).', label: 'Auxílio para preenchimento do cartão de resposta (Candidato com deficiência visual).' },
                { id: 'Auxílio para preenchimento do cartão de resposta (Candidato com deficiência motora que impeça o preenchimento do cartão).', value: 'Auxílio para preenchimento do cartão de resposta (Candidato com deficiência motora que impeça o preenchimento do cartão).', label: 'Auxílio para preenchimento do cartão de resposta (Candidato com deficiência motora que impeça o preenchimento do cartão).' },
                { id: 'Tempo adicional de 1 hora', value: 'Tempo adicional de 1 hora', label: 'Tempo adicional de 1 hora' },
                { id: 'Sala para amamentação (Candidata lactante).', value: 'Sala para amamentação (Candidata lactante).', label: 'Sala para amamentação (Candidata lactante).' },
                { id: 'Acessibilidade no local de provas (Candidato cadeirante).', value: 'Acessibilidade no local de provas (Candidato cadeirante).', label: 'Acessibilidade no local de provas (Candidato cadeirante).' },
                { id: 'Prova em Braille (Candidato com deficiência visual)', value: 'Prova em Braille (Candidato com deficiência visual)', label: 'Prova em Braille (Candidato com deficiência visual)' },
                { id: 'Intérprete de Libras (Candidato com deficiência auditiva).', value: 'Intérprete de Libras (Candidato com deficiência auditiva).', label: 'Intérprete de Libras (Candidato com deficiência auditiva).' },
                { id: 'Ledor (Candidato com deficiência visual).', value: 'Ledor (Candidato com deficiência visual).', label: 'Ledor (Candidato com deficiência visual).' },
                { id: 'Aplicação da prova para sabatista', value: 'Aplicação da prova para sabatista', label: 'Aplicação da prova para sabatista' },
                { id: 'Outro', value: 'Outro', label: 'Outro' },
            ]} /></div>

            {specialTreatment === 'Outro' || specialTreatment === 'Prova com letra ampliada (Candidato com deficiência visual).' ?
                <><h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}>{specialTreatment === 'Outro' ? 'DESCREVA' : 'TAMANHO DA FONTE (em pt):'}</h2>
                    <div style={{ border: '1px solid #333', borderRadius: '15px' }}>
                        <TextareaInput name='type_description' />
                    </div>
                </>

                : <></>}

            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> DADOS ESPECIAIS PARA APLICAÇÃO DAS PROVAS </h2>
            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> (Discriminar abaixo qual o tipo de prova necessário e/ou tratamento especial):</p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><TextareaInput name='description' placeholder="" /></div>


            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> ADICIONE OS DOCUMENTOS APONTADOS NO EDITAL</h2>



            < UploadFormContent name="documents" placeholder='Documentação' />

            <h2 style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'center', fontSize: '16px' }}> ACEITE DO TERMO</h2>
            <p style={{ color: '#333', marginTop: '15px', width: '100%', textAlign: 'justify' }}>
                Eu, acima qualificado(a) VENHO REQUERER atendimento especial no dia de realização da prova do Processo Seletivo Suplementar e DECLARO, desde já, que
                preencho os requisitos necessários para deferimento da solicitação, que as informações aqui prestadas e os documentos apresentados são verdadeiros e que
                estou ciente de que a não apresentação de qualquer documento para comprovar a condição que garante o deferimento da solicitação, ou, ainda, que a
                apresentação dos documentos fora dos padrões, prazo e/ou forma solicitados, implicará indeferimento da solicitação; DECLARO, ainda, que estou ciente de
                que, constatada falsidade em qualquer momento, poderei responder por crime contra a fé pública, nos termos da lei vigente, o que também acarretará
                minha eliminação deste processo seletivo.
            </p>
            <div style={{ border: '1px solid #333', borderRadius: '15px' }}><CheckboxInput unique name="term" placeholder="" options={[
                { id: 'Sim', value: 'Sim', label: 'Sim' },
            ]} /></div>

            <button style={{ margin: '10px auto' }} className="defaultButtonReverse">Enviar</button>
        </Form>

    </>
}

export default AskSpecialTreatment;