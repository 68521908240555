import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { dateLanguage, dateSimple, simpleDateUS } from '../../../../utils/date';
import { addHours } from 'date-fns';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import downloadit, { downloaditPost } from '../../../../utils/download';
import { apiUrl } from '../../../../config';
import { useToast } from '../../../../hooks/Toast';
import { date } from 'yup';
import Loader from '../../core/components/Loader';
import { FaChartBar, FaChartPie, FaChevronCircleDown, FaChevronCircleUp, FaChevronRight, FaEdit, FaFileCsv, FaFileExcel, FaInfo, FaSpinner } from 'react-icons/fa';
import ChartBar from '../../core/components/Charts/Bar';
import PieChart from '../../core/components/Charts/Pie';
import { useConfiguration } from '../../../../hooks/Configuration';
import Table from '../../../../styles/components/table';
import format from '../../../../utils/format';


interface IGroup {
  search: Array<Record<string, any>>;
  data: Array<Record<string, any>>;
  where?: Record<string, any>;
  prefix?: string;
}




interface IDetails {
  title: string;
  column?: string;
  id?: string;
  count: number;
  label?: string;
  value?: number;
}


interface IResume {
  data: Array<IDetails>;
  title: string;
  column: string;
  setCallback?: Function;
  active?: boolean;
}

interface IResumeList {
  data: Array<IResume>
}


interface IPropsReadDetails {
  data: Array<IDetails>;
  type: string;
}



interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IProps {
  hash?: string;
}

const SalesReportByProject: React.FC<IProps> = ({ hash = '' }) => {

  const [projects, setProject] = useState<Array<Record<string, any>>>([]);

  const [loading, setLoading] = useState(false);


  const { projectHash = hash, eventHash } = useParams<IParams>();


  const load = async () => {
    setLoading(true);
    const projectReport = await api.get(`/sales-by-projects`);

    setProject(projectReport.data);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [])


  return <Container>
    {loading ? <Loader message='Carregando' /> : <></>}
    <table>
      <tbody>
        <tr><th>Projeto</th><th>Pagos</th><th>Aguardando</th><th>Total</th><th>R$ Bruto - Pago</th><th>R$ Líquido - Pago</th></tr>
        {projects?.map(project => {

          return <tr key={`sale-report-${project?.url}`}><td>{project?.title}</td><td>{project?.paid}</td><td>{project?.waiting}</td><td>{project?.quantity}</td><td style={{ textAlign: 'right' }}>{`R$ ${format(project?.total)}`}</td><td style={{ textAlign: 'right' }}>{`R$ ${format(project?.net)}`}</td></tr>

        })}
      </tbody>
    </table>

  </Container >

};

export default SalesReportByProject;
