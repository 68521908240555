import React, { FC, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../DashboardPages/core/components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useModal } from '../../../hooks/Modal';
import { useToast } from '../../../hooks/Toast';
import StarInput from '../../DashboardPages/core/components/Forms/StarInput';
import { FaChevronCircleLeft } from 'react-icons/fa';
import RangeInput from '../../DashboardPages/core/components/Forms/RangeInput';
import { info } from 'console';
import CheckboxInput from '../../../components/Forms/CheckboxInput';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}




/*

Bloco de perguntas [
  {
  title : '',
  description : '',
  questions : [{
    title : '',
    description : '',
    type : '',
    image : '',

  }]
  ]
}

*/

interface IOptions {
  id: string;
  
  label: string;
  value: string ;
}

interface IQuestion {
  title ?: string;
  description ?: string;
  image ?: string;
  type ?: string;
  options ?: Array<string>;
  mandatory?: string;
}

interface IQuestions {
  title ?: string;
  description ?: string;
  questions : Array<IQuestion>
}

interface IPropsQuestion {
  questionsList : Array<IQuestions>;
  information : Record<string,any>;
}

interface IProps {
  id : number;
  survey_id : string;
  handle ?: Function;
  content : {
    title: string;
    description: string;
    thanks_title: string;
    thanks_description : string;
    questions : Array<IQuestions>;
    image ?: string;
   
  }
  
}

 
const Questions : React.FC<IPropsQuestion> = ({information, questionsList}) => {
const {addToast} = useToast();
  const [position,setPosition] = useState(0);

  let index = 0;
  let quest = 0;



  const limit = questionsList.length + 1;

  const itemChange = () => {
    addToast({title:'Adicionado', type: 'success'})
    setTimeout(() => {
      setPosition(state => state + 1);
    },1000)
  }

 


  const mandatory = (items) => {
    return  items.mandatory !== 'yes' ? <aside style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: '15px'}}>

<button className='defaultButtonMini' type='button' style={{ cursor:'pointer'}} onClick={() => setPosition(position +1)}>PULAR</button>
</aside> : <></>
  }

  return   <>  
   <div style={{display: position === 0 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'center'}}><br/><br/><h2 style={{width:'100%', textAlign:'center', fontSize:'24px', color: "#333"}}>PESQUISA DE SATISFAÇÃO</h2>

<br/><br/><p style={{margin: '10px auto', width:'100%', maxWidth: '500px', textAlign:'center', color: "#333"}} dangerouslySetInnerHTML={{__html: information.title}}/>

<p style={{margin: '10px auto', width:'100%', maxWidth: '500px',textAlign:'center', color: "#333"}} dangerouslySetInnerHTML={{__html: information.description}}/>

  <button className='defaultButtonMini' style={{minWidth: '150px', padding: '20px', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff',  margin: '10px auto'}} type='button' onClick={() => setPosition(1)}>Iniciar</button>

  </div>
  
   {questionsList.map( (question,indexQuestion) => {
    quest = quest + 1;
 
    
    return <div key={`question${indexQuestion}`} className="fade" style={{      boxShadow:'0px 0px 0px #fff', border: '0px', display: position === quest ? 'flex' : 'none', flexDirection:'column',  margin: '20px auto', maxWidth: '650px'}}>
    
<p style={{color: '#ccc', textAlign:'center', width: '100%'}}>{position} / {limit - 1}</p>
    
     {question.title ?  <h2 style={{fontSize: '18px',textAlign:'center', width: '100%',padding: '15px'}} dangerouslySetInnerHTML={{__html: question.title}}/> : <></>}
      {question.description ? <p style={{fontSize: '14px', padding: '15px', width: '100%', textAlign:'center'}} dangerouslySetInnerHTML={{__html: question.description}}/> : <></>}

      {question.questions.map( (items,itemsIndex) => {

        index++;
       if(items.type === 'star') {return <><StarInput setCallback={() => setPosition(position + 1)} key={`questionItem${itemsIndex}`} image={items.image} name={`p${index}`} placeholder={ ''}/>{mandatory(items)}</>}
       if(items.type === 'range') {return <><RangeInput setCallback={() => setPosition(position + 1)} key={`questionItem${itemsIndex}`} image={items.image} name={`p${index}`} placeholder={''}/>{mandatory(items)}</>}
      
       if(items.type === 'input') {return <><Input key={`questionItem${itemsIndex}`}  name={`p${index}`}  /><br/><br/> <button type="button" className='defaultButtonMini' style={{width: '100%', maxWidth: '150px', margin: '10px auto', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', padding: '10px'}} onClick={() => setPosition(position + 1)}>Próximo</button>{mandatory(items)}</>}
       if(items.type === 'textarea') {return <><aside style={{border:'2px solid #ddd', width:'100%', borderRadius: '15px'}}><TextareaInput key={`questionItem${itemsIndex}`}  name={`p${index}`}  /></aside><br/><br/> <button type="button" className='defaultButtonMini' style={{width: '100%', maxWidth: '150px', margin: '10px auto', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', padding: '10px'}} onClick={() => setPosition(position + 1)}>Próximo</button>{mandatory(items)}</>}
       if(items.type === 'select') {

        const data : Array<IOptions> = [];

        const options : Array<string> = items.options ? [...items.options ] : [];

        options.map((opt) => {
          data.push({ label: opt, value: opt, id : opt});
        })
        
        return <><CheckboxInput unique setCallBack={() => {   itemChange()}} placeholder='' key={`questionItem${itemsIndex}`}  options={data || []} name={`p${index}`}/><br/><br/><button type="button" className='defaultButtonMini' style={{width: '100%', maxWidth: '150px', margin: '10px auto', background: 'rgb(0,100,150)', padding: '10px', border: '2px solid #fff', color: '#fff'}} onClick={() => setPosition(position + 1)}>Próximo</button>{mandatory(items)}</>
        }
       return <></>;
     })}


     <aside style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: '15px'}}>
<FaChevronCircleLeft size={25} style={{color: '#777', marginRight: '10px'}} onClick={() => setPosition(position -1)}/>
<p style={{color: '#777', cursor:'pointer'}} onClick={() => setPosition(position -1)}>VOLTAR</p>
</aside> 

    </div>


  })}
          <aside style={{display: position ===  limit ? 'flex' : 'none', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
          <p style={{margin: '10px 0px', textAlign:'center', maxWidth: '500px', color: "#333", fontWeight:'bold'}} dangerouslySetInnerHTML={{__html: information.thanks_title}}/>

<p style={{margin: '10px 0px',textAlign:'center', maxWidth: '500px', color: "#333"}} dangerouslySetInnerHTML={{__html: information.thanks_description}}/>

<aside style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
<FaChevronCircleLeft size={30} style={{color: '#777', marginRight: '10px'}} onClick={() => setPosition(position -1)}/>
            <button className='defaultButtonMini' style={{ minWidth: '150px', padding: '10px', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', margin: 'auto' }} type="submit">
              Finalizar
            </button>
            </aside>
            </aside>
  
  </>
}

const FormContainer : React.FC<IProps> = ({ id, content, survey_id, handle }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [knowAbout, setKnowAbout] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const {removeModal} = useModal();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const [information,setInformation] = useState<Record<string,any>>({});
  const [question,setQuestion] = useState<Array<IQuestions>>([]);

  useEffect(() => {

    const newItem : Array<IQuestions> = [];

    content.questions.map( q => {
      newItem.push({ title: q.title, description: q.description, questions: [q]});
    })

    
    setInformation(content);
    setQuestion(newItem);

  },[content])


  const handleNewLogin = async (data: IRecoverFormData) => {
    
   
    setLoading(true);

   

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});
      

      const shape = {
   
      };

      let x = 1;

      Object.keys(data).map( key => {
        if( Array.isArray(data[key])){
          data[key] = data[key][0] || '';
        }
      })


      question.map( quest => {
        quest.questions.map( q => {
          if(q.mandatory === 'yes'){
          shape[`p${x}`] =  Yup.string().required(`Por favor, avalie a pergunta: ${q.title}`);
          }
          x = x+1;
        })
        
      })
 
      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      
      const response = await api.post(`/send-research`, {
        satisfaction_survey_id : survey_id,
         answers : data,
        client_id : id
      });

      removeModal('research');
      if(handle){
        handle();
      }
      addToast({ title: 'Pesquisa de satisfação enviada', description: 'Você já pode acessar seu certificado!', type: 'success'})
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }
 
      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao cadastrar sua pesquisa',
      ]);
    }
    setLoading(false);
  };

  {/* 
  
              <SelectSimple name="p1" label="" options={[
              { label: '0', value: '0'},
              { label: '1', value: '1'},
              { label: '2', value: '2'},
              { label: '3', value: '3'},
              { label: '4', value: '4'},
              { label: '5', value: '5'},
              { label: '6', value: '6'},
              { label: '7', value: '7'},
              { label: '8', value: '8'},
              { label: '9', value: '9'},
              { label: '10', value: '10'},
            ]}/>
 
 
          */         }



  return (
    <>
      {loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}

      <aside style={{width: '100%', maxWidth: '600px'}}>
     
          <Form style={{padding: '0px', borderRadius:'15px'}} ref={formRef} onSubmit={handleNewLogin} initialData={{}}>
         

          {question.length > 0 && <Questions information={information}  questionsList={question}/>}

{ /*
            <p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Qualidade técnica do estúdio:</p>
            <SelectSimple name="p1" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Site do evento:</p>
            <SelectSimple name="p2" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>


<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Ficha de inscrição do evento:</p>
            <SelectSimple name="p3" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>
 
 <p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Qualidade do conteúdo apresentado:</p>
            <SelectSimple name="p4" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Clareza do conteúdo apresentado:</p>
            <SelectSimple name="p5" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Aplicabilidade do conteúdo para você:</p>
            <SelectSimple name="p6" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Seu nível de conhecimento adquirido:</p>
            <SelectSimple name="p7" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>
<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Sua nota geral para o evento:</p>
            <SelectSimple name="p8" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>2) Se o evento fosse cobrado, você consideraria um valor justo a ser pago:
</strong>

<SelectSimple name="p9" label="" options={[
              { label: 'de 10 a 30 reais', value: 'de 10 a 30 reais'},
              { label: 'de 30 a 50 reais', value: 'de 30 a 50 reais'},
              { label: 'de 50 a 80 reais', value: 'de 50 a 80 reais'},
              { label: '80 a 100 reais', value: '80 a 100 reais'},
              { label: 'mais de 100 reais', value: 'mais de 100 reais'},
 
            ]}/>


<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>3) Como você ficou sabendo do evento?
</strong>


<SelectSimple name="p10" label=""
setCallback={(value) => {setKnowAbout(value)}}
options={[
              { label: 'E-mail marketing', value: 'e-mail marketing'},
              { label: 'Redes sociais', value: 'redes sociais'},
              { label: 'Indicação de um amigo', value: 'indicação de um amigo'},
              { label: 'Pela imprensa: site, blog, jornais ou revistas (impressas ou digitais)', value: 'pela imprensa: site, blog, jornais ou revistas (impressas ou digitais)'},
              { label: 'Outro', value: 'Outro'},
 
            ]}/>

{ (knowAbout === 'Outro' || knowAbout === 'redes sociais') ? <Input name="p11" placeholder='Qual?'/> : <> </>}

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>4) Você indicaria para alguém o Workshop dos Melhores Eventos?
</strong>

<SelectSimple name="p12" label="" options={[
              { label: 'Sim', value: 'Sim'},
              { label: 'Não', value: 'Não'},
        
            ]}/>

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>5) Qual o melhor turno para a realização do evento na sua opinião:
</strong>

<SelectSimple name="p13" label="" options={[
              { label: 'manhã', value: 'manhã'},
              { label: 'tarde', value: 'tarde'},
              { label: 'noite', value: 'noite'},
            ]}/>

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>6) Qual o melhor dia para a realização do evento, na sua opinião:
</strong>

<SelectSimple name="p14" label="" options={[
              { label: '2ª', value: '2ª'},
              { label: '3ª', value: '3ª'},
              { label: '4ª', value: '4ª'},
              { label: '5ª', value: '5ª'},
              { label: '6ª', value: '6ª'},
              { label: 'Sábado', value: 'Sábado'},
              { label: 'Domingo', value: 'Domingo'},
            ]}/>


<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>7) Gostaríamos de saber qual a sua sugestão ou tema de interesse para tratarmos nas próximas edições: </strong>
            <TextareaInput name="p15"/>


            <br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>8) Espaço aberto para ouvirmos a sua opinião ou considerações em geral: </strong>
            <TextareaInput name="p16"/>

            <br/><br/>
           <h2 style={{color: "#333"}} > A equipe do Workshop dos Melhores Eventos agradece a sua participação.</h2>
           <br/><br/>
          */}
            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
    
          </Form>
        
      </aside>
    </>
  );
};

export default FormContainer;
