import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { useLanguage } from '../../../hooks/Language';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  replace?: string;
  mandatory?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  replace,
  mandatory = 'no',
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState(defaultValue);
  const { translate } = useLanguage();

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({ name: fieldName, ref: inputRef.current, path: 'value' });
  }, [fieldName, registerField]);

  return (
    <Container
      {...rest}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocus}
    >
      <span>{translate(rest.placeholder)} {mandatory === 'yes' ? <span className='mandatoryInfo'  >* Obrigatório</span> : <></>}</span>
      {Icon && <Icon />}
      <input
        {...rest}
        placeholder={translate(rest.placeholder)}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        onChange={e => {
          console.log(replace)
          if (replace) {
            if (replace === 'number') {
              setValue(e?.target?.value.replace(/[^0-9]/g, "")?.toUpperCase());
            }
            else if (replace === 'letter') {
              setValue(e?.target?.value?.toUpperCase().replace(/[^A-Za-z ÃÁÀÂÊẼËÉÈÍÍÏÔÕÓÒÖŨÛÜÚÙÇ]/g, "")?.toUpperCase());
            }
            else if (replace === 'letter22') {
              setValue(e?.target?.value?.toUpperCase().replace(/[^A-Za-z ÃÁÀÂÊẼËÉÈÍÍÏÔÕÓÒÖŨÛÜÚÙÇ]/g, "")?.toUpperCase().substring(0, 21));
            }
            else {
              setValue(e.target.value);
            }
          }
          else {
            setValue(e.target.value);
          }

          setCallback(e.target.value, formRef);
        }}
        key={`input-${name}`}
        value={value}
        name={name}
        ref={inputRef}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};

export default Input;
