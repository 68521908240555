import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../core/components/Forms/Input';
import Button from '../../../core/components/Forms/Button';
import Loader from '../../../../../components/Loader';
import MaskedInput from '../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Error } from './styles';
import {AnimatedDiv} from '../styles';

import api from '../../../../../services/api';


import { useToast } from '../../../../../hooks/Toast';
import CheckboxInput from '../../../../../components/Forms/CheckboxInput';

import { useLanguage } from '../../../../../hooks/Language';
import { useModal } from '../../../../../hooks/Modal';

interface ICaptcha {
  getValue(): string;
}

interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

interface IProps {
  hash : string;
  setCallBack : Function;
}

const FormContainer : React.FC<IProps> = ({ hash , setCallBack}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const {removeModal} = useModal();

  const [failure, setFailure] = useState(false);
  const [ready, setReady] = useState(false);

  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

const [term,setTerm] = useState({
  hash : '',
  header_text : '',
  term: ''
})

 
 

  const {addToast} = useToast();
  const {translate} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');





  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string().required('Telefone é obrigatório'),
     
      
      });
 

      data.email = data.email.toString().trim().toLowerCase();
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');
    
      await schema.validate(data, { abortEarly: false });

       



      const newUser = await api
        .put(`/update-partner/${hash}`, {
          partner : {...data},
      
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao enviar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }


      setCallBack();
      removeModal('partner');
      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }
 

  return (
    <>
  {loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}

      <aside>
    
                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                  <h2 style={{width: '100%', color: '#333', margin: '0px 0px', textAlign:'center', fontSize:'18px'}}>Cadastrar acompanhante</h2>

<h2 style={{ textAlign: 'center', width: '100%', color: '#333', fontSize: '18px', maxWidth: '400px'}}>{term.header_text}</h2>

<p style={{ textAlign: 'justify', width: '100%', fontSize: '14px',color: '#333', maxWidth: '400px'}} dangerouslySetInnerHTML={{__html: term.term}}/>           
<br/><br/>
<Input name="name" placeholder={translate("Nome completo")} />
<Input name="email" placeholder={translate("E-mail")} />
<MaskedInput mask={'(99) 99999.9999'} name="phone" placeholder={translate('Telefone')} />
 <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      Enviar

                  
                    </button>
                    </aside>
                  </Form>  
        
      </aside>
    </>
  );
};

export default FormContainer;
