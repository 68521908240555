import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { uuid } from 'uuidv4';
import { RiFileExcel2Line } from 'react-icons/ri';
import {
  FaChevronLeft,
  FaArrowLeft,
  FaChevronRight,
  FaArrowRight,
  FaFilter,
  FaFileCsv,
} from 'react-icons/fa';
import Input from '../../components/Forms/Input';

import { Container, Body, Header, Navigation, Field } from './styles';

import tableRender from './templates/TableRender';
import { useSearch } from '../../../../../hooks/Search';
import SelectApi from '../../components/Forms/SelectApi';
import { useAuth } from '../../../../../hooks/Auth';
import { useParams } from 'react-router-dom';
import SelectSimple from '../Forms/SelectSimple';

/** Gerar uma estrutura pesquisável */

interface IObject {
  [key: string]: string;
}

export interface ISearchComponent {
  idKey: string;
  title?: string;
  endpoint: string;
  schema: Record<string, Record<string, any>>;
  autoStart?: boolean;
  headModules?: Array<Function>;
  bodyModules?: Array<Function>;
  renderFunction?: Function;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export function SearchComponent({
  idKey,
  title = '',
  endpoint,
  schema,
  autoStart = false,
  headModules = [],
  bodyModules = [],
  renderFunction = tableRender,
}: ISearchComponent): JSX.Element {
  const { user } = useAuth();
  /*
  const [schemaLayer, setSchemaLayer] = useState(schema);
  const [dataRows, setDataRows] = useState([]);

  const apiRequest = async (
    text: string,
    order: Record<string, any>,
  ): Promise<void> => {
    const params = { search: text || '', order };
    const response = await api.get(endpoint, { params });

    const display = response.data.reduce(
      (prev: Array<Record<string, any>>, items: IObject) => {
        const elem: IObject = {};
        Object.keys(schema).map(key => {
          elem[schema[key].column] = schema[key].mask
            ? mask(items[schema[key].column], schema[key].mask || '')
            : items[schema[key].column];
        });

        prev = [...prev, elem];
        return prev;
      },
      [],
    );

    setDataRows(display);
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (autoStart === true) {
        await apiRequest('', []);
      }
    };
    load();
  }, [autoStart]);

  const handleSearch = async (data: Record<string, any>): Promise<void> => {
    await apiRequest(data.search, []);
  };
  */



  const { projectHash, eventHash } = useParams<IParams>();



  const [startAuto, setStartAuto] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const {
    addSearch,
    reloadSearch,
    changeSearchText,
    dataRows,

    changePage,
    paginations,
    containers,
  } = useSearch();

  const container = containers.find(cont => cont.id === idKey);

  useEffect(() => {

    addSearch({ key: idKey, config: { id: idKey, endpoint, schema }, project_id: projectHash, event_id: eventHash });
    if (autoStart) {

      //setStartAuto(true);
    }
  }, [idKey]);

  useEffect(() => {

    if (startAuto === true) {

      reloadSearch({ id: idKey, project_id: projectHash, event_id: eventHash });
    }
  }, [startAuto, idKey]);

  const handleSearch = async (data: Record<string, any>): Promise<void> => {
    await changeSearchText(idKey, data.search);
  };

  const addToWhere = (key, column, value) => {

    const container = containers.find(cont => cont.id === key);
    if (container) {
      const where = container.where ? { ...container.where } : {};
      if (value === '0' || value === 0) {
        if (where[column]) {
          delete where[column];
        }
      } else {
        where[column] = value;
      }

      container.where = { ...where };
    }
    reloadSearch({ id: idKey, project_id: projectHash, event_id: eventHash });
  };

  const renderFilter = (): JSX.Element => {
    return (
      <Form onSubmit={() => { }} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(schema).map(key => {
          if (schema[key].filter && schema[key].filter === true) {
            return (
              <Field style={{ minWidth: '200px', margin: '5px', padding: '10px' }}>
                <label style={{ color: '#333' }}>{schema[key].label}</label>

                <div className='' style={{ width: '100%', maxWidth: '1200px', gap: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                  {schema[key].options?.map(i => {
                    return <button className={container?.where?.[key] === i?.value ? 'filterButton filterActive' : 'filterButton '} onClick={() => addToWhere(idKey, key, i?.value)}>{i?.label}</button>
                  })}

                </div>

              </Field>
            );
          }
        })}
      </Form>
    );
  };

  const renderPagination = () => {
    const items: Array<JSX.Element> = [];

    const container = paginations[idKey];
    if (container) {
      const { currentPage = 1, pages = 1, countItems = 0 } = container;

      items.push(<span> {countItems || '0'}
        {' '}
        registros
      </span>);
      if (currentPage > 2) {
        items.push(
          <button type="button" onClick={() => changePage(idKey, 1)}>
            <FaChevronLeft />
          </button>,
        );
      }

      if (currentPage > 1) {
        items.push(
          <button
            type="button"
            onClick={() => changePage(idKey, currentPage - 1)}
          >
            <FaArrowLeft />
          </button>,
        );
      }

      for (
        let x = currentPage > 1 ? currentPage - 1 : currentPage;
        x <= pages && x <= currentPage + 3;
        x += 1
      ) {
        items.push(
          <button
            type="button"
            className={currentPage === x ? 'active' : ''}
            onClick={() => changePage(idKey, x)}
          >
            {x}
          </button>,
        );
      }

      if (currentPage < pages) {
        items.push(
          <button
            type="button"
            onClick={() => changePage(idKey, currentPage + 1)}
          >
            <FaArrowRight />
          </button>,
        );
      }

      if (currentPage < pages) {
        items.push(
          <button type="button" onClick={() => changePage(idKey, pages)}>
            <FaChevronRight />
          </button>,
        );
      }
    }
    return items;
  };

  const render = (): JSX.Element => {
    return (
      <Container>
        <Header>
          {title ? <h2>{title}</h2> : ''}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
            {user &&
              (user.profile === 1 || user.profile === 4) &&
              headModules.map(headModule => headModule())}
            {user && user.profile === 1 && (
              <button
                className="searchButton"
                type="button"
                onClick={() => reloadSearch({ id: idKey, type: 'xlsx', project_id: projectHash, event_id: eventHash })}
              >
                <RiFileExcel2Line />
              </button>
            )}

            {user && user.profile === 1 && (
              <button
                className="searchButton"
                type="button"
                onClick={() => reloadSearch({ id: idKey, type: 'csv', project_id: projectHash, event_id: eventHash })}
              >
                <FaFileCsv />
              </button>
            )}
            <button
              className="searchButton"
              type="button"
              onClick={() => setActiveFilter(!activeFilter)}
            >
              <FaFilter />
            </button>
            <Form onSubmit={handleSearch}>

              <Input
                style={{ borderRadius: '10px 0px 0px 10px', minWidth: '200px' }}
                name="search"
                placeholder="Pesquise Aqui"
                type="text"
              />

              <button className="dashboardButton" style={{ borderRadius: '0px 10px 10px 0px' }} type="submit">Pesquisar</button>

            </Form>
          </div>
        </Header>
        {activeFilter && renderFilter()}
        <Navigation>{renderPagination()}</Navigation>
        <Body>
          {renderFunction(idKey, dataRows[idKey], schema, bodyModules)}
        </Body>
      </Container>
    );
  };

  return render();
}
