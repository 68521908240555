import React from 'react';
import { FaChalkboardTeacher, FaFileExcel, FaList } from 'react-icons/fa';

import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit, { downloaditPost } from '../../../../../utils/download';
import api from '../../../../../services/api';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../hooks/Configuration';
import { useLoading } from '../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ExportList({ lineData }): JSX.Element {

  const { user } = useAuth();
  const { configuration } = useConfiguration();
  const { addLoading, removeLoading } = useLoading();

  const download = async () => {
    addLoading({ title: 'Preparando relatório', key: 'loading' })
    const recurses = await api.get(`${apiUrl}/isention-list/${lineData?.url}`)




    await downloaditPost({ url: `${apiUrl}/converter/xlsx/`, data: recurses?.data?.rows, type: 'xlsx', title: `Isenções: ${lineData?.title}.xlsx` });

    removeLoading('loading')
  }

  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className='lineIcon' type='button' title="Exportar Lista completa" onClick={() => download()}><FaList /> </button> : <></>}
    </>;
  }



  return moduleList();
}
