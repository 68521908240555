import React from 'react';
import InOut from "../../../../../components/Forms/InOut";
import { IModalContextData, useModal } from "../../../../../hooks/Modal"
import { ISearchContextData } from "../../../../../hooks/SearchHook"
import { IHeader } from "../../../../../hooks/SearchHook/interfaces";
import { Form } from "@unform/web";


export default function manageData(hook: ISearchContextData, modal: IModalContextData) {
    const { addModal, removeModal } = modal;

    const updateData = (column: string, property: string, value: string) => {

        const newHeader: IHeader[] = [...hook.headerData];

        const index = newHeader.findIndex(head => head.column === column);

        if (index >= 0) {
            if (newHeader?.[index]) {
                newHeader[index][property] = value;
            }
        }

        hook.updateHeader(newHeader);

    }

    addModal({
        theme: 'whiteModalG',
        title: '', key: 'manager-data', content: <>

            <table>
                <thead>
                    <tr><th>Coluna</th><th>Pesquisar</th><th>Inserir</th><th>Atualizar</th><th>Exportar</th></tr>
                </thead>
                <tbody>
                    {hook?.headerData?.map(header => {
                        console.log(header)
                        return <tr key={`manager-data-column-${header.column}`}>
                            <td>{header.title}</td>
                            <td> <InOut value={header.search} setCallback={(value: string) => { updateData(header?.column, 'search', value) }} name={'search'} /></td>
                            {/* <td> <InOut value={header.insert} setCallback={(value: string) => { updateData(header?.column, 'insert', value) }} name={'insert'} /></td> */}
                            {/* <td> <InOut value={header.update} setCallback={(value: string) => { updateData(header?.column, 'update', value) }} name={'update'} /></td>*/}
                            {/* <td> <InOut value={header.export} setCallback={(value: string) => { updateData(header?.column, 'export', value) }} name={'export'} /></td>*/}
                        </tr>
                    })}
                </tbody>
            </table>



        </>
    })
}