import React, {
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../../../core/components/Loader';
import { ResponseMessage } from '../../../../core/components/ResponseMessage';
import api from '../../../../../../services/api';
import { useAuth } from '../../../../../../hooks/Auth';
import { Button } from '../../../../core/components/Forms/Button/styles';
import Whatsapp from '../../../../core/components/Whatsapp';

interface IParams {
  hash : string;
}

const ValidateIntegration: React.FC = () => {
  const { hash } = useParams<IParams>();
console.log(hash);
  const [loading, setLoading] = useState(true);
  const [invalidHash, setInvalidHash] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [systemError, setSystemError] = useState(false);

  const { AuthIntegration } = useAuth();

  const redirectInvalid = () => {
    if (!validToken) {
      setInvalidHash(true);
      setLoading(false);
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  };

  useEffect(() => {
    if (!hash) {
      redirectInvalid();
      return;
    }

    const load = async () => {
      try {
        const response = await api.get(`/validate/${hash}`);

        if (response.status === 200) {
          if (response.data.valid === 'true' || response.data.valid === true) {
            setValidToken(true);
            AuthIntegration(response.data);
            setTimeout(() => {
             window.location.href = '/dashboard';
            }, 3000);
            setLoading(false);
            return;
          }

          redirectInvalid();
        } else {
          redirectInvalid();
        }
      } catch (err) {
        setSystemError(true);
        setLoading(false);
      }
    };

    load();
  }, []);

  if (loading) {
    return <Loader message="Validando seu acesso" />;
  }

  if (!hash) {
    return (
      <ResponseMessage
        type="error"
        title="Código de acesso inválido"
        description={
          <p>Você será redirecionado para o login novamente em 5 segundos.</p>
        }
      />
    );
  }

  return (
    <>
      {invalidHash && (
        <ResponseMessage
          type="error"
          title="Código de acesso inválido"
          description={
            <p>Você será redirecionado para o login novamente em 3 segundos.</p>
          }
        />
      )}

      {validToken && (
        <ResponseMessage
          type="success"
          title="Você foi autenticado"
          description={
            <p>Você será redirecionado para o evento em 3 segundos.</p>
          }
        />
      )}

      {systemError && (
        <ResponseMessage
          type="error"
          title="Erro de sistema"
          description={(
            <>
              <p>
                Ocorreu um erro de conexão, tente novamente, ou entre em contato
                com o suporte
              </p>
              <Button onClick={() => window.location.reload()} type="button">
                Clique aqui para tentar novamente
              </Button>
              <Whatsapp
                text="Suporte Técnico"
                message="Erro ao validar login - Cobem 2020"
              />
            </>
          )}
        />
      )}

      {!loading && !systemError && !validToken && !invalidHash && (
        <ResponseMessage
          type="error"
          title="Erro não identificado"
          description={(
            <>
              <p>
                Ocorreu um erro não identificado, tente novamente, ou entre em
                contato com o suporte
              </p>
              <Button onClick={() => window.location.reload()} type="button">
                Clique aqui para tentar novamente
              </Button>
              <Whatsapp
                text="Suporte Técnico"
                message="Erro nao identificado no login - Cobem 2020"
              />
            </>
          )}
        />
      )}
    </>
  );
};

export default ValidateIntegration;
