import React, { useState, useEffect } from 'react';

import {
  FaPlus,
  FaTrash,
  FaQuestion,
  FaList,
  FaChartPie,
  FaEye,
} from 'react-icons/fa';
import { FiX, FiSend } from 'react-icons/fi';
import { Container, Field, Modal, QuizzHeader } from './styles';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import { logo } from '../../config';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';

import { useSocket } from '../../hooks/Socket';

interface IAnswers {
  name: string;
  value?: string;
  label?: string;
}

interface IQuestions {
  id?: string;
  title: string;
  type: number;
  questions: Array<Record<string, any>>;
}

const PopupComponent: React.FC = () => {
  const { socket } = useSocket();

  const [value, setValue] = useState({
    title: '',
    message: '',
    room_id: '',
    button: '',
    button_url: '',
  });

  const [activePopup, setActivePopup] = useState(false);
  const [popup, setPopup] = useState(<> </>);

  const [active, setActive] = useState(false);
  const { addModal } = useModal();

  /* EXCLUI UMA ENQUETE */

  const read = async item => {
    console.log(item);
    setPopup(
      <div style={{ textAlign: 'center' }}>
        {item.title && <h2>{item.title}</h2>}
        {item.message && (
          <p dangerouslySetInnerHTML={{ __html: item.message }} />
        )}
        {item.button && item.button_url && (
          <a href={item.button_url}>
            <button
              style={{ margin: '10px auto' }}
              type="button"
              className="defaultButton"
            >
              {item.button}
            </button>
          </a>
        )}
      </div>,
    );

    setActivePopup(true);
  };

  useEffect(() => {
    socket.on('showPopup', data => {
      if (data) {
        read(data);
      }
    });
  }, []);

  return (
    <Container>
      {activePopup && (
        <Modal
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',

            zIndex: 1000000000,
            paddingTop: '30px',
          }}
        >
          {popup}
          <button
            className="closeButton"
            type="button"
            onClick={() => setActivePopup(false)}
          >
            Fechar
          </button>
        </Modal>
      )}
    </Container>
  );
};

export default PopupComponent;
