import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';

import { Container, ContainerWhite } from './styles';
 
 interface IProps {
   changeStatus : Function;
   limit : number
 }

const Notifications: React.FC<IProps> = ({ changeStatus, limit = 5 }) => {

  const {user} = useAuth();

 
  const {projectHash} = useParams<Record<string,any>>();
  const [list, setList] = useState<Array<Record<string,any>>>([]);

  const load = async () => {
    const fileData = await api.get(`my-notifications/${projectHash}`);
    if(fileData){

      fileData.data.rows.sort((a,b) => a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0);

      setList(fileData.data.rows);
    }
  }
 

  useEffect(() => {
    load();
 
  },[])

 

  return (
    <>
      <Container >
 
      <div className='user-block shadow' style={{overflow:'hidden'}}>
          <div className='content-block' style={{padding: '10px'}}>
           
                <p className='contentTitle'>Últimas notificações</p>
                  <table>
                  <tbody>
                    <tr><th>Data</th><th>Mensagem</th></tr>
                   {list.length > 0 && list.map(item =>  <tr><td>{dateFullWithHour(item.createdAt)} </td><td><strong>{item.title}</strong><br/>
                   <p>{item.description}</p></td></tr>)}

                   {list.length < 1 && <tr><td colSpan={2}>Nenhum notificação encontrada</td></tr>}
                  </tbody>
                  </table>
                  </div>  
               
                  </div>
      </Container>
    </>
  );
};
export default Notifications;
