import React, { useState, useEffect, useRef } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR/index.js';
import { Form } from '@unform/web';
import { FiMessageSquare } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import TextPopper from '../TextPopper';
import {
  Container,
  Body,
  Message,
  Footer,
  OnlineUser,
  Info,
  Faces,
  Gift,
  Pulsing
} from './styles';
import { useSocket } from '../../hooks/Socket';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import { useLanguage } from '../../hooks/Language';
import { FaGift } from 'react-icons/fa';
import api from '../../services/api';
import Button from '../Button';
import userEvent from '@testing-library/user-event';

interface IChatRoom {
  id: string | number;
}

const SorteioRoom: React.FC<IChatRoom> = ({ id }) => {
const [winner,setWinner] = useState({ name : '', email : ''});
const [loading,setLoading] = useState(false);
const { user} = useAuth();
const {addToast} = useToast();

  const getRandom = (min,max) => {
   return min + Math.floor((max - min) * Math.random());
  }


  const doSorteio = async () => {
    setLoading(true);
    const response = await api.post(`/sorteio/${id}`);

    if(response){
      setTimeout(() => {
        const {name, email } = response.data;
        setWinner({name, email});
        setLoading(false);
        return;
      },5000);
    }
    else{
      addToast({ title : 'Erro',type:"error"});
      setLoading(false);
      return;
    }



  }


  const animation = () => {

    const colors = [
      'rgb(200,0,0)',
      'rgb(200,200,0)',
      'rgb(200,0,200)',
      'rgb(0,100,200)'
    ];
    const items : Array<JSX.Element> = [];
    let x = 1;
    while (x < 60) {
      items.push(<Pulsing speed={x / 15} background={colors[x % 3]} top={getRandom(0,100)} left={getRandom(0,100)} scale={getRandom(0,20)}/>);
      x++;
    }

    return items;
  }

let x = 1;
if(!id || id < 1){return <></>;}
if(!user){return <></>;}
if(user.profile === 2){return <></>; }

if(winner && winner.name){
  return <Container>
  <Gift>
    <div>


      <div className="body" style={{background: 'rgba(0,100,20,0.7)', borderRadius: '10px',padding: '30px'}}>
     <h1>Ganhador(a):</h1>
        <h2 style={{marginTop: '25px', fontSize: '28px'}}>{winner.name}</h2> 

        <button className="defaultButton" onClick={() => {setWinner({ name : '', email : ''})}}>Fechar</button>
 
      </div>
   
    </div>
    
    </Gift>
</Container>
}


  if(loading){
  return <Container>
    <Gift>
      <div>
      {animation()}

        <div className="body">
          <FaGift/>
          <h2 style={{marginTop: '25px'}}>Realizando sorteio!</h2> 
          <div style={{position: 'relative', fontSize: '24px'}}>
          <TextPopper time={1000} messages={['.','..','...']}/>
          </div>
        </div>
     
      </div>
      
      </Gift>
  </Container>
  }

  if(!loading){
    return <Button onClick={() => doSorteio()}>Realizar Sorteio</Button>
  }

  return <></>;
  
};

export default SorteioRoom;
