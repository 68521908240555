import styled from 'styled-components';


export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 1;
  > div {
    display: flex;
    flex-direction: row;

    @media (max-width: 750px) {
      flex-direction: column;
      padding: 10px;
    }

    > aside {
      display: flex;
      flex-direction: column;
      width: calc(100% - 20px);
      justify-content: center;
      flex-wrap: wrap;
      max-width: 600px;
      min-width: 400px;
      >button {
        margin: 10px auto;
      }
>form{
  
      >h1 {
        color: #fff;
      }

      >h2 {
        color: #fff;
      }
}
      @media (max-width: 750px) {
        max-width: 100%;
        min-width: 100%;
       
      }
    }
  }
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 10px 0px;

  transition: display 0.2s;
  color: #fff;

  form {
    margin: 10px 0;
    width: 100%;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #333;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

    
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

  
  }
`;
