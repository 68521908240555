import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  InputHTMLAttributes,
} from 'react';

import { useField, FormHandles } from '@unform/core';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';
import { Error } from '../styles';

import format from '../../../utils/format';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  mask?: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}
const MaskedCurrencyInput: React.FC<Props> = ({
  name,
  icon: Icon,
  setCallback = (string: string, form: React.RefObject<FormHandles>) => { },
  mask,
  formRef,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState(format(defaultValue || '0'));

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFilled(!!inputRef.current);

    const length = setIsFocus(false);
  }, [inputRef]);

  const applyFilter = (inputValue: string) => {
    setValue(format(inputValue));
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        setValue(format(value));
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <label>R$ </label>
        <input
          onChange={e => {
            applyFilter(e.target.value);
            setCallback(e.target.value, formRef);
          }}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          value={value}
          {...rest}
        />
      </div>
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default MaskedCurrencyInput;
