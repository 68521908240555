import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlWebsite } from '../../config';
import { useConfiguration } from '../../hooks/Configuration';
import api from '../../services/api';
import themeDefault from '../../styles/ThemeComponents';
import {Container, Sponsor, Apoio, SponsorGrid, ApoioGrid } from './styles';


interface ISponsor {
  url : string;
  link_url ?: string;
  
}
interface IParamsURL{
  projectHash ?: string;
  eventHash ?: string;
}
interface ISponsors {
  title : string;
  position: number;
  sponsors : Array<ISponsor>;
  size ?: string;
}

const FooterLayout: React.FC = ({ children }) => {
const { configuration,projectHash, eventHash} = useConfiguration();
  const [sponsors,setSponsors] = useState<Array<ISponsors>>([])
 
  useEffect(() => {
    
    const load = async() => {
    const response = await api.get( eventHash ? `sponsors-list/${projectHash}/${eventHash}`: `sponsors-list/${projectHash}`);
   
   
    if(response) {
      setSponsors(response.data.rows);
    }
    return;
    }

load();

  },[])

  return (
<>


{ sponsors && sponsors.length > 0 && <Container id="patrocinadores" style={{margin: '20px 0px' }}>
<h2 style={{color: configuration?.color || 'rgb(0,14,64)', fontSize: '32px', marginTop: '10px', marginBottom: '25px'}} >Patrocinadores</h2>

  { sponsors && sponsors.map( lists => (


<div style={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'flex-start', minWidth: '350px', maxWidth: '1000px', margin: '10px 0px'}}>
<h2 style={{color: configuration?.color || 'rgb(0,14,64)', fontSize: '24px', marginTop: '10px', marginBottom: '0px'}}>{lists.title}</h2>
  <ApoioGrid size={lists.size}>
  {lists.sponsors && lists.sponsors.map( apoio => ( apoio.link_url ? <a href={apoio.link_url} target="_BLANK" style={{background: '#fff'}}><Apoio background={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></a> : <div style={{background: '#fff'}} ><Apoio background={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></div>) )}

  </ApoioGrid>
  </div>

  ))


  }
    
        </Container>}

        </>
  );
};

export default FooterLayout;
