import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  value?: string;
}

const InOut: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  value = 'no',
  setCallback = (value: string) => { },
  ...rest
}) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(value || 'no');


  useEffect(() => {
    console.log(inputValue)
    setCallback(inputValue);
  }, [inputValue])

  return (
    <Container style={{ width: '60px', border: '0px', boxShadow: '0px', minHeight: 'auto', marginTop: '5px', padding: '0px', minWidth: 'auto' }}
      {...rest}

    >
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}
      <div onClick={() => setInputValue((state) => {
        const newState = state === 'no' ? 'yes' : 'no';

        return newState;
      })} className={`${inputValue === 'no' ? 'cursor noValue' : 'cursor yesValue'}`}>
        <div />
      </div>


      <input
        style={{ display: 'none' }}


        defaultValue={value}

        name={name}
        ref={inputRef}
      />

    </Container>
  );
};

export default InOut;
