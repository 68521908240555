import React from 'react';
import { Background, Loader } from "./styles"

const LoaderComponent = ({ title = '', content = <></> }) => {


    return <Background>
        <div>
            <img src="/apoio/logo-padrao.png" style={{ width: '200px' }} />
        </div>
        <h2>{title}</h2>
    </Background>



}

export default LoaderComponent;