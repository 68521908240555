
import React from 'react';
import Button from "../../../components/Forms/Button";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { IPagesBarProps } from "./interfaces";



const PaginationBar: React.FC<IPagesBarProps> = ({ className = 'max-400', countPages = 1, changePage, count, filterOptions }) => {

    const [currentPage, setCurrentPage] = useState<number>(filterOptions?.page || 1);
    const [pages, setPages] = useState<number>(countPages);
    const [documents, setDocuments] = useState(count);

    useEffect(() => {

        if (filterOptions?.page !== currentPage) {
            setCurrentPage(filterOptions?.page);
        }

    }, [filterOptions])

    useEffect(() => {

        if (pages !== count) {
            setDocuments(count);
        }

    }, [count])

    useEffect(() => {

        if (pages !== countPages) {
            setPages(countPages);
        }

    }, [countPages])

    const handlePages = (page: number) => {

        changePage(page);


    }

    const printPagination = () => {



        let x = currentPage;

        const items: Array<JSX.Element> = [];


        if (pages > 1) {
            items.push(<Button type="button" key={`pagination-bar-start`} onClick={() => handlePages(1)} className="icon-button-xs soft-button"><FaChevronLeft /></Button>)
        }

        if ((currentPage - 2) > 0) {
            items.push(<Button type="button" key={`pagination-bar-${currentPage - 2}`} onClick={() => handlePages(currentPage - 2)} className={`icon-button-xs soft-button `}>{(currentPage - 2) < 10 ? `0${currentPage - 2}` : (currentPage - 2)}</Button>)
        }

        if ((currentPage - 1) > 0) {
            items.push(<Button type="button" key={`pagination-bar-${currentPage - 1}`} onClick={() => handlePages(currentPage - 1)} className={`icon-button-xs soft-button `}>{(currentPage - 1) < 10 ? `0${currentPage - 1}` : (currentPage - 1)}</Button>)
        }


        items.push(<Button type="button" key={`pagination-bar-${currentPage}`} onClick={() => handlePages(x)} className={`icon-button-xs color-button `}>{(currentPage) < 10 ? `0${currentPage}` : (currentPage)}</Button>)

        if ((currentPage + 1) < pages) {
            items.push(<Button type="button" key={`pagination-bar-${currentPage + 1}`} onClick={() => handlePages(currentPage + 1)} className={`icon-button-xs soft-button `}>{(currentPage + 1) < 10 ? `0${currentPage + 1}` : (currentPage + 1)}</Button>)
        }

        if ((currentPage + 2) < pages) {
            items.push(<Button type="button" key={`pagination-bar-${currentPage + 2}`} onClick={() => handlePages(currentPage + 2)} className={`icon-button-xs soft-button `}>{(currentPage + 2) < 10 ? `0${currentPage + 2}` : (currentPage + 2)}</Button>)
        }

        if (pages > 1) {
            items.push(<Button type="button" key={`pagination-bar-end`} onClick={() => handlePages(pages)} className="icon-button-xs soft-button"><FaChevronRight /></Button>)

        }

        return items;

    }



    return <div className="container width-auto row gap-sm">

        <>{printPagination()}<Button className="icon-button-xs-width ">{documents} <p className="computerOnly">registros</p></Button></>

    </div >
}

export default PaginationBar;