
import React from 'react';
import Button from "../../../components/Forms/Button";
import { useEffect, useState } from "react";

import { IFilter, IFilterBarProps } from "./interfaces";



const FilterBar: React.FC<IFilterBarProps> = ({ className = 'max-400', filters, filterFunction }) => {

    const [appliedFilters, setAppliedFilters] = useState<Array<IFilter>>(filters);

    useEffect(() => {

        setAppliedFilters(filters);
    }, [filters])


    const handleFilters = (filter: IFilter) => {

        let newFilters = [...appliedFilters];

        const index = newFilters?.findIndex(item => item.column === filter.column && item.group === filter.group);

        if (index >= 0) {
            newFilters = newFilters.filter(item => item.column !== filter.column && item.group !== filter.group);
        }
        else {


            newFilters = newFilters.filter(item => item.group !== filter.group);

            newFilters.push(filter);
        }
        filterFunction(filter);
        setAppliedFilters(newFilters);


    }

    return <div className="container width-auto row gap-sm">



        {filters?.length > 0 && filters.map(filter => <Button key={`filter-column-${filter?.group}-${filter?.column}`} className=" gap-sm row-start filterButton button-xxs" onClick={() => handleFilters(filter)} type="button"><input readOnly className="checkboxIcon" checked={appliedFilters?.findIndex(item => item.column === filter.column && item.group === filter.group) >= 0} type={'checkbox'} />{filter?.category}: {filter?.value}</Button>)}



    </div >
}

export default FilterBar;