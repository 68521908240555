import React, { useEffect, useState } from 'react';
import { Capa, Photo } from './styles';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import SliderComponent from '../SliderComponent';
import api from '../../../../services/api';
import { urlWebsite } from '../../../../config';
import { FaArrowCircleRight, FaArrowRight, FaBrain, FaChevronRight, FaDownload } from 'react-icons/fa';
import { useAuth } from '../../../../hooks/Auth';
import downloadit from '../../../../utils/download';
import sendTrack from '../../../../utils/sendTracking';
import { useLanguage } from '../../../../hooks/Language';
import { Form } from '@unform/web';
import { useConfiguration } from '../../../../hooks/Configuration';
import { dateFullWithHour, makeText } from '../../../../utils/date';
 

interface IParams {
  projectHash : string;
  eventHash : string;
}

const FileGallery : React.FC = () => {
 const {projectHash, eventHash} = useParams<IParams>();
  const {user} = useAuth();
  const [currentCategory,setCurrentCategory] = useState('files');
  const [filesList,setFilesList] = useState<Array<Record<string,any>>>([]);
  const [podcastsList,setPodcastsList] = useState<Array<Record<string,any>>>([]);
  const [slides, setSlides] = useState<Array<JSX.Element>>([] as Array<JSX.Element> );
  const [search,setSearch] = useState('');
  const {translate} = useLanguage();
  const {configuration} = useConfiguration();

  const load = async () => {
    const fileData = await api.get(`file-gallery-list/${projectHash}`);
    if(fileData){

      fileData.data.rows.sort((a,b) => a.title > b.title ? 1 : a.title < b.title ? -1 : 0);

      setFilesList(fileData.data.rows);
    }
  }


  const load2 = async () => {
    const fileData = await api.get(`podcast-list/${projectHash}`);
    if(fileData){

      fileData.data.rows.sort((a,b) => a.title > b.title ? 1 : a.title < b.title ? -1 : 0);

      setPodcastsList(fileData.data.rows);
    }
  }


  useEffect(() => {
    load();
    load2();
  },[])

  const downloadItem =async ({category, title, url ,user_name = '', user_id = '' }) => {
sendTrack({category, title, url ,user_name, user_id, project_id: projectHash, event_id: eventHash})

  
  }




  useEffect(( ) => {
    const l : Array<JSX.Element> = [];
     filesList?.length > 0 && filesList.map( (event,index)  => {
 
       
      l.push(
      
      
       <div className='downloadItem' key={event._id} onClick={() => { downloadItem({ category : 'material-de-apoio', title: event.title,url : `${urlWebsite}/${event.url}`, user_name : user?.name || '', user_id : user?.id ||''})}} > <a href={`${urlWebsite}/${event.url}`}  target="_BLANK"  className='event-container'>
        {event.image ? <img src={`${urlWebsite}/${event.image}`} /> : <></>}
      
        <div className='eventTime'>
          <FaDownload color={"#333"} size={15}/>
        </div>
      
      <div className='event-content'>
       <strong className='event-title' >{event.title}</strong>
     {event.description &&<p className='event-project' style={{minHeight: '0px'}} dangerouslySetInnerHTML={{__html: event.description}}/>}
      
     {event.createdAt &&<p className='event-project' style={{minHeight: '0px', fontSize:'9px'}}><strong>Data de Postagem:</strong> {dateFullWithHour(event.createdAt)}</p>}
      
      </div>
      
      
      </a></div>)
     }
    )

    setSlides(l);
  },[filesList])



  const newSearch = () => {

    const getSearch = [...filesList];
    const newslides : Array<JSX.Element> = [];

    

    const newList = getSearch.map( event => {
      
      if( !search || event.title.toLowerCase().indexOf(search.toLowerCase()) >= 0 ) {
        newslides.push(     
          <div className='downloadItem' key={event._id} onClick={() => { downloadItem({ category : 'material-de-apoio', title: event.title,url : `${urlWebsite}/${event.url}`, user_name : user?.name || '', user_id : user?.id ||''})}} > <a href={`${urlWebsite}/${event.url}`}  target="_BLANK"  className='event-container'>
           {event.image ? <img src={`${urlWebsite}/${event.image}`} /> : <></>}
         
           <div className='eventTime'>
             <FaDownload color={"#333"} size={15}/>
           </div>
         
         <div className='event-content'>
          <strong className='event-title' style={{marginBottom: '10px'}}>{event.title}</strong>
         <p className='event-project' style={{minHeight: '0px'}} dangerouslySetInnerHTML={{__html: event.description}}/>
         </div>
         
         
         </a></div>);

        }

      
    });

    setSlides(newslides);

  }



    return     <>  { slides?.[0] &&  
    <Capa id="arquivos-apoio">


 { projectHash === 'simposio-dasa-tmo' ?<>
<h1 style={{color: 'rgb(0, 14, 64)', textAlign:'center', margin: '20px auto'}}>PODCASTS</h1>
<aside style={{width:'100%', maxWidth: '358px', margin: '15px auto'}}>
<iframe style={{borderRadius:'12px'}} src="https://open.spotify.com/embed/episode/3NwU0yMNqZZiBQ5wCI0PSO?utm_source=generator" width="100%" height="232" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
</aside>
</> : <></>}

{ projectHash === 'simposio-dasa-tmo' ?<>
<h1 style={{color: 'rgb(0, 14, 64)', textAlign:'center', margin: '20px auto'}}>PODCASTS</h1>
<aside style={{width:'100%', maxWidth: '358px', margin: '15px auto'}}>
<iframe style={{borderRadius:'12px'}} src="https://open.spotify.com/embed/episode/3NwU0yMNqZZiBQ5wCI0PSO?utm_source=generator" width="100%" height="232" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
</aside>
</> : <></>}
 
<aside style={{width: '100%', display: currentCategory === 'podcasts' ? 'flex' : 'none', alignItems:'center', justifyContent:'flex-start', flexDirection: 'column'}}>
   <h1 style={{color: 'rgb(0, 14, 64)', textAlign:'center', margin: '20px auto'}}>PODCASTS</h1>

 {podcastsList.length > 0 && podcastsList.map(pod => {

return <aside key={pod._id} style={{width:'100%', maxWidth: '358px', margin: '15px auto'}}>
<iframe style={{borderRadius:'12px'}} src={pod.url} width="100%" height="232" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
</aside>
 }) } 

<div className='event-list-container'>
{podcastsList.length < 1 && <h2>Nenhum arquivo disponível.</h2>}
    </div>

</aside>


<aside style={{width: '100%', display: currentCategory === 'files' ? 'flex' : 'none', alignItems:'center', justifyContent:'flex-start', flexDirection: 'column'}}>
   <h1 style={{color: 'rgb(0, 14, 64)', textAlign:'center', margin: '20px auto'}}>ARQUIVOS DE APOIO</h1>

  <aside style={{width: '100%', display:'flex', alignItems:'center', justifyContent:'center'}}> <Form
          style={{ display: 'flex', alignItems: 'stretch', background:'none', margin:'5px' }}
          onSubmit={newSearch}
        >
          
           
          <input style={{height: '43px', padding: '10px'}}
            name="pesquisa"
            type="text"
            placeholder={`${translate('Escreva para pesquisar')}...`}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

        <button style={{width: '100px',height: '43px', background:configuration?.theme_data?.primary_background? configuration?.theme_data?.primary_background : '#f16621', color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color :  '#fff', borderRadius:'10px', border: '0px',  margin: '0px 5px'}} type="submit">{translate('Pesquisar')}</button>
        </Form></aside>
    
    <div className='event-list-container'>
{slides.length > 0 ? slides : <h2>Nenhum arquivo disponível.</h2>}
    </div>
   </aside>
  </Capa>} </>
}

export default FileGallery;