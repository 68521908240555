import React, { useEffect, useState } from 'react';
import  { Page, PDFViewer, PDFDownloadLink, Text, View, Document, StyleSheet, Image, Font,  } from '@react-pdf/renderer';
 


import numero from 'numero-por-extenso';
import { dateLanguage, fullDate } from '../../../../utils/date';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';

  Font.register({
    family: 'Montserrat',
    fonts : [
      {src: '/assets/fonts/Montserrat-Regular.ttf'},
      {src: '/assets/fonts/Montserrat-Bold.ttf', fontStyle: 'bold'}
    ]
    
  });
   
  
interface IProps {
  hash ?: string;
}

interface IRecibo {
  name : string;
  document_number : string;
  sale_hash : string;
  total : number;
  payment_date : Date;
  projeto : string;

}

export const Recibo : React.FC<IProps> = () => {

  const [recibo,setRecibo] = useState<IRecibo>({} as IRecibo);
  const [ready,setReady] = useState(false);
  const [loading,setLoading] = useState(true);
  const [failed,setFailed] = useState(false);
  const [notPaid,setNotPaid] = useState(false);
  const {hash, projectHash} = useParams<Record<string,any>>();


  const load = async () => {

    const response = await api.get(`/recibo/${projectHash}/${hash}`);
  
    const {status } = response.data;

    if(status === 'failed'){
      setFailed(true);
      return;
    }
    if(status === 'notPaid'){
      setNotPaid(true);
      return;
    }
    if(status === 'ready'){
      setLoading(false);
      setRecibo(response?.data?.recibo || {});
      setReady(true);
    }
  
  }

  useEffect(() => {
    load();
  },[])

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "#333",
      padding: '20px'
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  if(notPaid) {
    return <div className='shadow' style={{background: '#fff', borderRadius: '15px', color: '#000',margin: '20px auto', width: '100%', maxWidth:'400px', padding: '30px', minHeight: '300px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
      <h2>O pagamento não foi identificado</h2><br/><br/>
      <p style={{textAlign: 'center'}}> <strong>Protocolo da compra:</strong><br/> {hash}</p>
    </div >
  }

  if(failed) {
    return <div className='shadow' style={{background: '#fff', borderRadius: '15px', color: '#000',margin: '20px auto', width: '100%', maxWidth:'400px', padding: '30px', minHeight: '300px', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
      <h2>Compra não identificada.</h2><br/><br/>
      <p style={{textAlign: 'center'}}><strong>Protocolo da compra:</strong> <br/>{hash}</p>
    </div >
  }

  

  if(loading) {
    return <div className='shadow' style={{background: '#fff', borderRadius: '15px', color: '#000',margin: '20px auto', width: '100%', maxWidth:'400px', padding: '30px', minHeight: '300px', display:'flex', alignItems:'center', justifyContent:'center'}}>
      <h2>Verificando...</h2>
    </div >
  }

if(ready){
return <>
<PDFDownloadLink fileName='recibo.pdf' document={
 <Document >
 {/*render a single page*/}
 <Page size="A4" style={styles.page}>
 
   <View>  <Image style={{width: '150px'}} src="/apoio/logo-fundmed.png"/> 
   </View>
    
   <View style={{borderTop: '1px solid #000', marginTop: '15px', paddingTop: '20px'}}>
     <Text style={{fontWeight: 'bold', fontSize:'24px', margin:'30px auto', textAlign:'center', width:'100%'}}>RECIBO</Text>
     <Text style={{fontWeight: 700, fontSize:'10px', textAlign:'center', marginBottom:'30px', width:'100%'}}>Chave de controle: {recibo.sale_hash}</Text>
   </View>


   <View style={styles.section}>
     <Text style={{fontSize:'14px'}}>{`A Fundação Médica do Rio Grande do Sul, CNPJ 94.391.901/0001-03, recebeu de ${recibo.name} inscrito no CPF nº ${recibo.document_number}, a quantia de R$ ${(recibo.total/100).toFixed(2).replace('.',',')} (${numero.porExtenso(recibo.total/100, numero.estilo.monetario)}) referente a inscrição realizada no projeto ${recibo.projeto}.`}</Text>
   </View>
   <View style={{width: '100%', textAlign:'right', fontSize:'14px', marginTop:'60px'}}>
     <Text>Porto Alegre, {fullDate(recibo.payment_date)}</Text>
   </View>
   

   <View fixed style={{width: '100%', position:'absolute', bottom:'20px', left: '20px', textAlign:'right'}}>

     
     <Text style={{fontSize:'12px', padding: '5px', textAlign:'center'}}> 51 3332.6840 | 51 3321.4041 – www.fundacaomedicars.org.br</Text>
     <Text style={{fontSize:'12px', borderTop:'1px solid #000', textAlign:'center',  padding: '5px'}}>  Rua Ramiro Barcelos 2350, Sala 177 | Bom Fim, Porto Alegre/RS – CEP 90035-003</Text>
   </View>
 </Page>
</Document>


}>
  <button className='defaultButton'> Realizar Download do recibo </button>
  </PDFDownloadLink>


<aside className='computerOnly' style={{width: '100%'}}>
<PDFViewer style={{
  width: window.innerWidth, //the pdf viewer will take up all of the width and height
  height: window.innerHeight,
}}>
       <Document >
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
        
          <View>  <Image style={{width: '150px'}} src="/apoio/logo-fundmed.png"/> 
          </View>
           
          <View style={{borderTop: '1px solid #000', marginTop: '15px', paddingTop: '20px'}}>
            <Text style={{fontWeight: 'bold', fontSize:'24px', margin:'30px auto', textAlign:'center', width:'100%'}}>RECIBO</Text>
            <Text style={{fontWeight: 700, fontSize:'10px', textAlign:'center', marginBottom:'30px', width:'100%'}}>Chave de controle: {recibo.sale_hash}</Text>
          </View>


          <View style={styles.section}>
            <Text style={{fontSize:'14px'}}>{`A Fundação Médica do Rio Grande do Sul, CNPJ 94.391.901/0001-03, recebeu de ${recibo.name} inscrito no CPF nº ${recibo.document_number}, a quantia de R$ ${(recibo.total/100).toFixed(2).replace('.',',')} (${numero.porExtenso(recibo.total/100, numero.estilo.monetario)}) referente a inscrição realizada no projeto ${recibo.projeto}.`}</Text>
          </View>
          <View style={{width: '100%', textAlign:'right', fontSize:'14px', marginTop:'60px'}}>
            <Text>Porto Alegre, {fullDate(recibo.payment_date)}</Text>
          </View>
          

          <View fixed style={{width: '100%', position:'absolute', bottom:'20px', left: '20px', textAlign:'right'}}>

            
            <Text style={{fontSize:'12px', padding: '5px', textAlign:'center'}}> 51 3332.6840 | 51 3321.4041 – www.fundacaomedicars.org.br</Text>
            <Text style={{fontSize:'12px', borderTop:'1px solid #000', textAlign:'center',  padding: '5px'}}>  Rua Ramiro Barcelos 2350, Sala 177 | Bom Fim, Porto Alegre/RS – CEP 90035-003</Text>
          </View>
        </Page>
      </Document>
   </PDFViewer>
   </aside>
   </>
}

return <></>
}