import React, {  useEffect, useState } from 'react';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';

import { useAuth } from '../../../../hooks/Auth';
import { useSocket } from '../../../../hooks/Socket';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';

import {StatusContainer,StatusItem} from './styles';

interface IProps {
  status : number;
  id: string;
  class_name ?: string;
  type ?: number;
  dificulty_id ?: number;
}

const StatusChanger: React.FC<IProps> = ({  status, id, type, class_name, dificulty_id }) => {
    const {user} = useAuth(); 
    const {addToast} = useToast();
    const {socket} = useSocket();

      const getStatusName = () => {
       if(status === 1){return 'Aguardando'}
       else if(status === 2) { return 'Ao vivo'}
       else if(status === 3){ return 'Finalizado'}
       else{ return 'Erro ao identificar status';}
      }


    const changeStatus = async (column, room_id, newStatus ) => {

      if(newStatus < 1 || newStatus > 3){ return addToast({title:'Status inválido', type: 'error'}); }
      if(newStatus === status ){ return addToast({title:'Este já é o status atual', type: 'info'}); }
      if(!room_id){
        return addToast({title:'ID da sala não identificado', type: 'error'});
      }

      const response = await api.put(`/lessons/${room_id}/${column}`,{[column]: newStatus});
     
      if(response){
      
        socket.emit('reloadRoom',{room : room_id});
       
      }
     


    } 

   return user && (user.profile === 1 ||  user.profile === 4) ? 
   <> <StatusContainer> 
      <p>{getStatusName()}</p>
      <div>
      <StatusItem title="Aguardando" active={status === 1} onClick={() => changeStatus('status',id,1)}><FaLock/></StatusItem> 
      <StatusItem title="Ao Vivo" active={status === 2} onClick={() => changeStatus('status',id,2)}><FaLockOpen/></StatusItem> 
      <StatusItem title="Finalizado" active={status === 3} onClick={() => changeStatus('status',id,3)}><FiLock/></StatusItem>
    
     
      </div>
     
    </StatusContainer>
     { type === 3 && user && (user.profile === 1 || user.profile === 4) && <button style={{margin: '10px auto'}} onClick={() => changeStatus('dificulty_id',id,dificulty_id === 2 ? 1 :2)} type="button" className="defaultButtonReverse">{dificulty_id !== 2 ? 'Bloquear sala' : 'Desbloquear sala'} </button>}</>
      : <></>
    
    
                



  
};

export default StatusChanger;
