import React, { useEffect, useState } from 'react';
import { Page, PDFViewer, PDFDownloadLink, Text, View, Document, StyleSheet, Image, Font, } from '@react-pdf/renderer';



import numero from 'numero-por-extenso';
import { dateLanguage, fullDate } from '../../../../utils/date';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: '/assets/fonts/Montserrat-Regular.ttf' },
    { src: '/assets/fonts/Montserrat-Bold.ttf', fontStyle: 'bold' }
  ]

});


interface IProps {
  hash?: string;
}

interface IRecibo {
  name: string;
  document_number: string;
  sale_hash: string;
  total: number;
  payment_date: Date;
  projeto: string;
  created_at: string;
  fields_text: string;

}

export const FormularioImpressao: React.FC<IProps> = () => {

  const [recibo, setRecibo] = useState<IRecibo>({} as IRecibo);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [notPaid, setNotPaid] = useState(false);
  const { hash, projectHash } = useParams<Record<string, any>>();

  const [profile, setProfile] = useState<Record<string, any>>({})


  const loadProfile = async () => {





    const response = await api.get(`/student-info`);

    if (response?.data) {
      setProfile(response.data);
    }


  }

  const load = async () => {

    const response = await api.get(`/recibo/${projectHash}/${hash}`);

    const { status } = response.data;

    if (status === 'failed') {
      setFailed(true);
      return;
    }
    if (status === 'notPaid') {
      setNotPaid(true);
      return;
    }
    if (status === 'ready') {
      loadProfile();
      setLoading(false);
      setRecibo(response?.data?.recibo || {});
      setReady(true);
    }

  }



  useEffect(() => {
    load();
  }, [])

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "#333",
      padding: '20px'
    },
    section: {
      margin: 0,
      padding: 0,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
    },
    text: {
      fontSize: '8px',
      minWidth: '40%',
      border: '1px solid #000',
      padding: '5px'


    },
    text2: {
      fontSize: '8px',
      minWidth: '40%',
      border: '1px solid #000',
      padding: '5px'
    },
    viewer: {

      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  if (notPaid) {
    return <div className='shadow' style={{ background: '#fff', borderRadius: '15px', color: '#000', margin: '20px auto', width: '100%', maxWidth: '400px', padding: '30px', minHeight: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <h2>O pagamento não foi identificado</h2><br /><br />
      <p style={{ textAlign: 'center' }}> <strong>Protocolo da compra:</strong><br /> {hash}</p>
    </div >
  }

  if (failed) {
    return <div className='shadow' style={{ background: '#fff', borderRadius: '15px', color: '#000', margin: '20px auto', width: '100%', maxWidth: '400px', padding: '30px', minHeight: '300px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <h2>Cadastro não identificado.</h2><br /><br />
      <p style={{ textAlign: 'center' }}><strong>Protocolo da compra:</strong> <br />{hash}</p>
    </div >
  }



  if (loading) {
    return <div className='shadow' style={{ background: '#fff', borderRadius: '15px', color: '#000', margin: '20px auto', width: '100%', maxWidth: '400px', padding: '30px', minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <h2>Verificando...</h2>
    </div >
  }

  if (ready) {
    return <>
      <PDFDownloadLink fileName='recibo.pdf' document={
        <Document >
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>

            <View>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Image style={{ width: '340px' }} src="/apoio/logo-fundmed-ufrgs-hcpa.png" />
              </div>
            </View>

            <View style={{ borderTop: '1px solid #000', marginTop: '15px', paddingTop: '20px' }}>
              <Text style={{ fontWeight: 'bold', fontSize: '24px', margin: '5px auto', textAlign: 'center', width: '100%' }}>Formulário</Text>
              <Text style={{ fontWeight: 700, fontSize: '10px', textAlign: 'center', marginBottom: '10px', width: '100%' }}>Chave de inscrição: {recibo.sale_hash}</Text>
            </View>


            <View style={styles.section}><Text style={styles.text}>{`Nome Completo: `} </Text> <Text style={styles.text2}> {`${profile.name}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Data de Nascimento: `} </Text> <Text style={styles.text2}> {`${profile.birth_date}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Carteira de Identidade (RG): `} </Text> <Text style={styles.text2}> {`${profile.rg}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Data Expedição do RG: `} </Text> <Text style={styles.text2}> {`${profile?.rg_date}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Orgão Expedidor do RG: `} </Text> <Text style={styles.text2}> {`${profile?.rg_emissor}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Estado Expedidor do RG: `} </Text> <Text style={styles.text2}> {`${profile?.rg_uf}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`CPF: `} </Text> <Text style={styles.text2}> {`${profile?.document_number}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Título de Eleitor: `} </Text> <Text style={styles.text2}> {`${profile?.voter_registration}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Data da Expedição: `} </Text> <Text style={styles.text2}> {`${profile?.voter_registration_date || ''}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Zona Eleitoral: `} </Text> <Text style={styles.text2}> {`${profile?.voter_registration_zone || ''}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Seção Eleitoral: `} </Text> <Text style={styles.text2}> {`${profile?.voter_registration_zone_section || ''}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Raça/Cor: `} </Text> <Text style={styles.text2}> {`${profile.etnia}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Sexo: `} </Text> <Text style={styles.text2}> {`${profile.gender_id}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Naturalidade: `} </Text> <Text style={styles.text2}> {`${profile.subscription.born_at}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Nacionalidade: `} </Text> <Text style={styles.text2}> {`${profile.nationality}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Nome Completo Mãe: `} </Text> <Text style={styles.text2}> {`${profile.mother_name}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Nome completo pai: `} </Text> <Text style={styles.text2}> {`${profile.father_name}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Telefone: `} </Text> <Text style={styles.text2}> {`${profile.phone}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Universidade: `} </Text> <Text style={styles.text2}> {`${profile.instituicao}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Semestre atual:`}</Text><Text style={styles.text2} /> </View>
            <View style={styles.section}><Text style={styles.text}>{`Data de Ingresso:`}</Text><Text style={styles.text2} /> </View>
            <View style={styles.section}><Text style={styles.text}>{`Data de Conclusão Graduação:`}</Text><Text style={styles.text2} /> </View>
            <View style={styles.section}><Text style={styles.text}>{`Email : `} </Text> <Text style={styles.text2}> {`${profile.email}`}</Text> </View>
            <View style={styles.section}><Text style={styles.text}>{`Área e Nome da Especialidade Médica – Residência`} </Text> <Text style={styles.text2}>{`${recibo.fields_text}`}</Text></View>
            <View style={{ width: '100%', textAlign: 'center', fontSize: '9px', margin: '10px 0px' }}>
              <Text>Afirmo que os dados acima estão corretos e que são verdadeiros: SIM</Text>
            </View>

            <View style={styles.section}><Text style={{ fontSize: '9px', width: '100%', textAlign: 'center', marginTop: '20px' }}>{`__________________________________________`}</Text></View>
            <View style={styles.section}><Text style={{ fontSize: '9px', width: '100%', textAlign: 'center' }}>{`Assinatura`}</Text></View>






            <View fixed style={{ width: '100%', position: 'absolute', bottom: '20px', left: '20px', textAlign: 'right' }}>


              <Text style={{ fontSize: '9px', padding: '5px', textAlign: 'center' }}> 51 3332.6840 | 51 3321.4041 – www.fundacaomedicars.org.br</Text>
              <Text style={{ fontSize: '9px', borderTop: '1px solid #000', textAlign: 'center', padding: '5px' }}>  Rua Ramiro Barcelos 2350, Sala 177 | Bom Fim, Porto Alegre/RS – CEP 90035-003</Text>
            </View>
          </Page>
        </Document>


      }>
        <button className='defaultButton'> Realizar Download do formulário </button>
      </PDFDownloadLink>


      <aside className='computerOnly' style={{ width: '100%' }}>
        <PDFViewer style={{
          width: window.innerWidth, //the pdf viewer will take up all of the width and height
          height: window.innerHeight,
        }}>
          <Document >
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>

              <View>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                  <Image style={{ width: '340px' }} src="/apoio/logo-fundmed-ufrgs-hcpa.png" />
                </div>
              </View>

              <View style={{ borderTop: '1px solid #000', marginTop: '15px', paddingTop: '20px' }}>
                <Text style={{ fontWeight: 'bold', fontSize: '24px', margin: '5px auto', textAlign: 'center', width: '100%' }}>Formulário</Text>
                <Text style={{ fontWeight: 700, fontSize: '10px', textAlign: 'center', marginBottom: '10px', width: '100%' }}>Chave de inscrição: {recibo.sale_hash}</Text>
              </View>


              <View style={styles.section}><Text style={styles.text}>{`Nome Completo: `} </Text> <Text style={styles.text}> {`${profile.name}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Data de Nascimento: `} </Text> <Text style={styles.text2}> {`${profile.birth_date}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Carteira de Identidade (RG): `} </Text> <Text style={styles.text2}> {`${profile.rg}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Data Expedição do RG: `} </Text> <Text style={styles.text2}> {`${profile?.rg_date}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Orgão Expedidor do RG: `} </Text> <Text style={styles.text2}> {`${profile?.rg_emissor}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Estado Expedidor do RG: `} </Text> <Text style={styles.text2}> {`${profile?.rg_uf}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`CPF: `} </Text> <Text style={styles.text2}> {`${profile?.document_number}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Título de Eleitor: `} </Text> <Text style={styles.text2}> {`${profile?.voter_registration}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Data da Expedição: `} </Text> <Text style={styles.text2}> {`${profile.voter_registration_date || ''}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Zona Eleitoral: `} </Text> <Text style={styles.text2}> {`${profile.voter_registration_zone}` || ''}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Seção Eleitoral: `} </Text> <Text style={styles.text2}> {`${profile.voter_registration_zone_section}` || ''}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Raça/Cor: `} </Text> <Text style={styles.text2}> {`${profile.etnia}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Sexo: `} </Text> <Text style={styles.text2}> {`${profile.gender_id}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Naturalidade: `} </Text> <Text style={styles.text2}> {`${profile.subscription.born_at}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Nacionalidade: `} </Text> <Text style={styles.text2}> {`${profile.nationality}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Nome Completo Mãe: `} </Text> <Text style={styles.text2}> {`${profile.mother_name}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Nome completo pai: `} </Text> <Text style={styles.text2}> {`${profile.father_name}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Telefone: `} </Text> <Text style={styles.text2}> {`${profile.phone}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Universidade: `} </Text> <Text style={styles.text2}> {`${profile.instituicao}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Semestre atual:`}</Text><Text style={styles.text2} /> </View>
              <View style={styles.section}><Text style={styles.text}>{`Data de Ingresso:`}</Text><Text style={styles.text2} /> </View>
              <View style={styles.section}><Text style={styles.text}>{`Data de Conclusão Graduação:`}</Text> <Text style={styles.text2} /></View>
              <View style={styles.section}><Text style={styles.text}>{`Email : `} </Text> <Text style={styles.text2}> {`${profile.email}`}</Text> </View>
              <View style={styles.section}><Text style={styles.text}>{`Área e Nome da Especialidade Médica – Residência`} </Text> <Text style={styles.text2}>{`${recibo.fields_text}`}</Text></View>


              <View style={{ width: '100%', textAlign: 'center', fontSize: '9px', margin: '10px 0px' }}>
                <Text style={{ fontWeight: 'bold' }}>Afirmo que os dados acima estão corretos e que são verdadeiros: SIM</Text>
              </View>

              <View style={styles.section}><Text style={{ fontSize: '9px', width: '100%', textAlign: 'center', marginTop: '20px' }}>{`__________________________________________`}</Text></View>
              <View style={styles.section}><Text style={{ fontSize: '9px', width: '100%', textAlign: 'center' }}>{`Assinatura`}</Text></View>






              <View fixed style={{ width: '100%', position: 'absolute', bottom: '20px', left: '20px', textAlign: 'right' }}>


                <Text style={{ fontSize: '9px', padding: '5px', textAlign: 'center' }}> 51 3332.6840 | 51 3321.4041 – www.fundacaomedicars.org.br</Text>
                <Text style={{ fontSize: '9px', borderTop: '1px solid #000', textAlign: 'center', padding: '5px' }}>  Rua Ramiro Barcelos 2350, Sala 177 | Bom Fim, Porto Alegre/RS – CEP 90035-003</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </aside>
    </>
  }

  return <></>
}