const template = {
    bodyBackgroundWithOpacity : 'rgba(0,0,0,0.3)',
    color: '#333',
    selectButton : {
        background : 'rgb(0,50,150)',
        borderColor:  'rgb(0,30,100)',
        color: '#fff',
    },
    input : {
        placeholder : '#333',
        background: '#fff',
        color: '#333',
        border: '1px solid #333',
        colorFilled : 'rgb(0,100,50)',
        colorFocused : 'rgb(0,150,50)',
        borderColorFocused: 'rgb(0,150,50)',
    },
    button : {
        background : 'rgb(100,100,100)',
        color: '#fff',
        backgroundHover: 'rgb(50,50,50)',
        colorHover: '#fff',
    }
}

export default template;