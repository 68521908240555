const medicalUniversities = [
    {value:'Centro de Ensino Superior de Valença (CESVA),',label:'Centro de Ensino Superior de Valença (CESVA),',text:'Centro de Ensino Superior de Valença (CESVA),'},
    {value:'Centro Educacional Anhanguera (ANHANGUERA),',label:'Centro Educacional Anhanguera (ANHANGUERA),',text:'Centro Educacional Anhanguera (ANHANGUERA),'},
    {value:'Centro Universitário Barão de Mauá (CBM),',label:'Centro Universitário Barão de Mauá (CBM),',text:'Centro Universitário Barão de Mauá (CBM),'},
    {value:'Centro Universitário Belo Horizonte (UniBH),',label:'Centro Universitário Belo Horizonte (UniBH),',text:'Centro Universitário Belo Horizonte (UniBH),'},
    {value:'Centro Universitário Cesmac (CESMAC),',label:'Centro Universitário Cesmac (CESMAC),',text:'Centro Universitário Cesmac (CESMAC),'},
    {value:'Centro Universitário Christus (UNICHRISTUS),',label:'Centro Universitário Christus (UNICHRISTUS),',text:'Centro Universitário Christus (UNICHRISTUS),'},
    {value:'Centro Universitário da Faculdade de Saúde, Ciências Humanas e Tecnológicas do Piauí (NOVAFAPI),',label:'Centro Universitário da Faculdade de Saúde, Ciências Humanas e Tecnológicas do Piauí (NOVAFAPI),',text:'Centro Universitário da Faculdade de Saúde, Ciências Humanas e Tecnológicas do Piauí (NOVAFAPI),'},
    {value:'Centro Universitário das Faculdades Associadas de Ensino (UNIFAE),',label:'Centro Universitário das Faculdades Associadas de Ensino (UNIFAE),',text:'Centro Universitário das Faculdades Associadas de Ensino (UNIFAE),'},
    {value:'Centro Universitário de Anápolis (UNIEVANGÉLICA),',label:'Centro Universitário de Anápolis (UNIEVANGÉLICA),',text:'Centro Universitário de Anápolis (UNIEVANGÉLICA),'},
    {value:'Centro Universitário de Araraquara (UNIARA),',label:'Centro Universitário de Araraquara (UNIARA),',text:'Centro Universitário de Araraquara (UNIARA),'},
    {value:'Centro Universitário de Brasília (UNICEUB),',label:'Centro Universitário de Brasília (UNICEUB),',text:'Centro Universitário de Brasília (UNICEUB),'},
    {value:'Centro Universitário de Caratinga (UNEC),',label:'Centro Universitário de Caratinga (UNEC),',text:'Centro Universitário de Caratinga (UNEC),'},
    {value:'Centro Universitário de Franca (UNI-FACEF),',label:'Centro Universitário de Franca (UNI-FACEF),',text:'Centro Universitário de Franca (UNI-FACEF),'},
    {value:'Centro Universitário de João Pessoa (UNIPÊ),',label:'Centro Universitário de João Pessoa (UNIPÊ),',text:'Centro Universitário de João Pessoa (UNIPÊ),'},
    {value:'Centro Universitário de Maringá UNICESUMAR (UNICESUMAR),',label:'Centro Universitário de Maringá UNICESUMAR (UNICESUMAR),',text:'Centro Universitário de Maringá UNICESUMAR (UNICESUMAR),'},
    {value:'Centro Universitário de Patos de Minas (UNIPAM),',label:'Centro Universitário de Patos de Minas (UNIPAM),',text:'Centro Universitário de Patos de Minas (UNIPAM),'},
    {value:'Centro Universitário de Várzea Grande (UNIVAG),',label:'Centro Universitário de Várzea Grande (UNIVAG),',text:'Centro Universitário de Várzea Grande (UNIVAG),'},
    {value:'Centro Universitário de Volta Redonda (UNIFOA),',label:'Centro Universitário de Volta Redonda (UNIFOA),',text:'Centro Universitário de Volta Redonda (UNIFOA),'},
    {value:'Centro Universitário do Espírito Santo (UNESC),',label:'Centro Universitário do Espírito Santo (UNESC),',text:'Centro Universitário do Espírito Santo (UNESC),'},
    {value:'Centro Universitário do Estado do Pará (CESUPA),',label:'Centro Universitário do Estado do Pará (CESUPA),',text:'Centro Universitário do Estado do Pará (CESUPA),'},
    {value:'Centro Universitário Franciscano (UNIFRA),',label:'Centro Universitário Franciscano (UNIFRA),',text:'Centro Universitário Franciscano (UNIFRA),'},
    {value:'Centro Universitário Lusíada (UNILUS),',label:'Centro Universitário Lusíada (UNILUS),',text:'Centro Universitário Lusíada (UNILUS),'},
    {value:'Centro Universitário Maurício de Nassau (UNINASSAU),',label:'Centro Universitário Maurício de Nassau (UNINASSAU),',text:'Centro Universitário Maurício de Nassau (UNINASSAU),'},
    {value:'Centro Universitário São Camilo (SAO CAMILO),',label:'Centro Universitário São Camilo (SAO CAMILO),',text:'Centro Universitário São Camilo (SAO CAMILO),'},
    {value:'Centro Universitário Serra dos Órgãos (UNIFESO),',label:'Centro Universitário Serra dos Órgãos (UNIFESO),',text:'Centro Universitário Serra dos Órgãos (UNIFESO),'},
    {value:'Centro Universitário Tiradentes (FITS),',label:'Centro Universitário Tiradentes (FITS),',text:'Centro Universitário Tiradentes (FITS),'},
    {value:'Centro Universitário UNIRG (UNIRG),',label:'Centro Universitário UNIRG (UNIRG),',text:'Centro Universitário UNIRG (UNIRG),'},
    {value:'Centro Universitário UNISEB (UNISEB),',label:'Centro Universitário UNISEB (UNISEB),',text:'Centro Universitário UNISEB (UNISEB),'},
    {value:'Centro Universitário UNIVATES (UNIVATES),',label:'Centro Universitário UNIVATES (UNIVATES),',text:'Centro Universitário UNIVATES (UNIVATES),'},
    {value:'Escola Bahiana de Medicina e Saúde Pública (EBMSP),',label:'Escola Bahiana de Medicina e Saúde Pública (EBMSP),',text:'Escola Bahiana de Medicina e Saúde Pública (EBMSP),'},
    {value:'Escola de Medicina Souza Marques da Fundação Técnico-Educacional Souza Marques (EMSM),',label:'Escola de Medicina Souza Marques da Fundação Técnico-Educacional Souza Marques (EMSM),',text:'Escola de Medicina Souza Marques da Fundação Técnico-Educacional Souza Marques (EMSM),'},
    {value:'Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória (EMESCAM),',label:'Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória (EMESCAM),',text:'Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória (EMESCAM),'},
    {value:'Escola Superior de Ciências da Saúde (ESCS),',label:'Escola Superior de Ciências da Saúde (ESCS),',text:'Escola Superior de Ciências da Saúde (ESCS),'},
    {value:'Faculdade Atenas,',label:'Faculdade Atenas,',text:'Faculdade Atenas,'},
    {value:'Faculdade Barão do Rio Branco (FAB),',label:'Faculdade Barão do Rio Branco (FAB),',text:'Faculdade Barão do Rio Branco (FAB),'},
    {value:'Faculdade Brasileira (MULTIVIX VITÓRIA),',label:'Faculdade Brasileira (MULTIVIX VITÓRIA),',text:'Faculdade Brasileira (MULTIVIX VITÓRIA),'},
    {value:'Faculdade Ceres (FACERES),',label:'Faculdade Ceres (FACERES),',text:'Faculdade Ceres (FACERES),'},
    {value:'Faculdade da Saúde e Ecologia Humana (FASEH),',label:'Faculdade da Saúde e Ecologia Humana (FASEH),',text:'Faculdade da Saúde e Ecologia Humana (FASEH),'},
    {value:'Faculdade das Américas (FAM),',label:'Faculdade das Américas (FAM),',text:'Faculdade das Américas (FAM),'},
    {value:'Faculdade de Ciências Agrárias e da Saúde (FAS),',label:'Faculdade de Ciências Agrárias e da Saúde (FAS),',text:'Faculdade de Ciências Agrárias e da Saúde (FAS),'},
    {value:'Faculdade de Ciências Biomédicas de Cacoal (FACIMED),',label:'Faculdade de Ciências Biomédicas de Cacoal (FACIMED),',text:'Faculdade de Ciências Biomédicas de Cacoal (FACIMED),'},
    {value:'Faculdade de Ciências da Saúde de Barretos Dr. Paulo Prata (FCSB),',label:'Faculdade de Ciências da Saúde de Barretos Dr. Paulo Prata (FCSB),',text:'Faculdade de Ciências da Saúde de Barretos Dr. Paulo Prata (FCSB),'},
    {value:'Faculdade de Ciências Gerenciais de Manhuaçu (FACIG),',label:'Faculdade de Ciências Gerenciais de Manhuaçu (FACIG),',text:'Faculdade de Ciências Gerenciais de Manhuaçu (FACIG),'},
    {value:'Faculdade de Ciências Humanas, Econômicas e da Saúde de Araguaína (FAHESA/ITPAC),',label:'Faculdade de Ciências Humanas, Econômicas e da Saúde de Araguaína (FAHESA/ITPAC),',text:'Faculdade de Ciências Humanas, Econômicas e da Saúde de Araguaína (FAHESA/ITPAC),'},
    {value:'Faculdade de Ciências Médicas da Paraíba (FCM-PB),',label:'Faculdade de Ciências Médicas da Paraíba (FCM-PB),',text:'Faculdade de Ciências Médicas da Paraíba (FCM-PB),'},
    {value:'Faculdade de Ciências Médicas da Santa Casa São Paulo (FCMSCSP),',label:'Faculdade de Ciências Médicas da Santa Casa São Paulo (FCMSCSP),',text:'Faculdade de Ciências Médicas da Santa Casa São Paulo (FCMSCSP),'},
    {value:'Faculdade de Ciências Médicas de Campina Grande (FCM),',label:'Faculdade de Ciências Médicas de Campina Grande (FCM),',text:'Faculdade de Ciências Médicas de Campina Grande (FCM),'},
    {value:'Faculdade de Ciências Médicas de Minas Gerais (FCMMG),',label:'Faculdade de Ciências Médicas de Minas Gerais (FCMMG),',text:'Faculdade de Ciências Médicas de Minas Gerais (FCMMG),'},
    {value:'Faculdade de Ciências Médicas e da Saúde de Juiz de Fora (FCMS/JF),',label:'Faculdade de Ciências Médicas e da Saúde de Juiz de Fora (FCMS/JF),',text:'Faculdade de Ciências Médicas e da Saúde de Juiz de Fora (FCMS/JF),'},
    {value:'Faculdade de Medicina de Barbacena (FAME),',label:'Faculdade de Medicina de Barbacena (FAME),',text:'Faculdade de Medicina de Barbacena (FAME),'},
    {value:'Faculdade de Medicina de Campos (FMC),',label:'Faculdade de Medicina de Campos (FMC),',text:'Faculdade de Medicina de Campos (FMC),'},
    {value:'Faculdade de Medicina de Itajubá (FMIT),',label:'Faculdade de Medicina de Itajubá (FMIT),',text:'Faculdade de Medicina de Itajubá (FMIT),'},
    {value:'Faculdade de Medicina de Jundiaí (FMJ),',label:'Faculdade de Medicina de Jundiaí (FMJ),',text:'Faculdade de Medicina de Jundiaí (FMJ),'},
    {value:'Faculdade de Medicina de Marília (FAMEMA),',label:'Faculdade de Medicina de Marília (FAMEMA),',text:'Faculdade de Medicina de Marília (FAMEMA),'},
    {value:'Faculdade de Medicina de Petrópolis (FMP),',label:'Faculdade de Medicina de Petrópolis (FMP),',text:'Faculdade de Medicina de Petrópolis (FMP),'},
    {value:'Faculdade de Medicina de São José do Rio Preto (FAMERP),',label:'Faculdade de Medicina de São José do Rio Preto (FAMERP),',text:'Faculdade de Medicina de São José do Rio Preto (FAMERP),'},
    {value:'Faculdade de Medicina do ABC (FMABC),',label:'Faculdade de Medicina do ABC (FMABC),',text:'Faculdade de Medicina do ABC (FMABC),'},
    {value:'Faculdade de Medicina Nova Esperança (FAMENE),',label:'Faculdade de Medicina Nova Esperança (FAMENE),',text:'Faculdade de Medicina Nova Esperança (FAMENE),'},
    {value:'Faculdade de Minas (FAMINAS),',label:'Faculdade de Minas (FAMINAS),',text:'Faculdade de Minas (FAMINAS),'},
    {value:'Faculdade de Minas BH (FAMINAS-BH),',label:'Faculdade de Minas BH (FAMINAS-BH),',text:'Faculdade de Minas BH (FAMINAS-BH),'},
    {value:'Faculdade de Odontologia São Leopoldo Mandic (SLMANDIC),',label:'Faculdade de Odontologia São Leopoldo Mandic (SLMANDIC),',text:'Faculdade de Odontologia São Leopoldo Mandic (SLMANDIC),'},
    {value:'Faculdade de Saúde Santo Agostinho de Vitória da Conquista (FASA),',label:'Faculdade de Saúde Santo Agostinho de Vitória da Conquista (FASA),',text:'Faculdade de Saúde Santo Agostinho de Vitória da Conquista (FASA),'},
    {value:'Faculdade de Tecnologia e Ciências (FTC SALVADOR),',label:'Faculdade de Tecnologia e Ciências (FTC SALVADOR),',text:'Faculdade de Tecnologia e Ciências (FTC SALVADOR),'},
    {value:'Faculdade Dinâmica do Vale do Piranga (FADIP),',label:'Faculdade Dinâmica do Vale do Piranga (FADIP),',text:'Faculdade Dinâmica do Vale do Piranga (FADIP),'},
    {value:'Faculdade Evangélica do Paraná (FEPAR),',label:'Faculdade Evangélica do Paraná (FEPAR),',text:'Faculdade Evangélica do Paraná (FEPAR),'},
    {value:'Faculdade Ingá,',label:'Faculdade Ingá,',text:'Faculdade Ingá,'},
    {value:'Faculdade Integrada de Patos (FIP),',label:'Faculdade Integrada de Patos (FIP),',text:'Faculdade Integrada de Patos (FIP),'},
    {value:'Faculdade Integral Diferencial (FACID),',label:'Faculdade Integral Diferencial (FACID),',text:'Faculdade Integral Diferencial (FACID),'},
    {value:'Faculdade Meridional (IMED),',label:'Faculdade Meridional (IMED),',text:'Faculdade Meridional (IMED),'},
    {value:'Faculdade Metropolitana da Amazônia (FAMAZ),',label:'Faculdade Metropolitana da Amazônia (FAMAZ),',text:'Faculdade Metropolitana da Amazônia (FAMAZ),'},
    {value:'Faculdade Pernambucana de Saúde (FPS),',label:'Faculdade Pernambucana de Saúde (FPS),',text:'Faculdade Pernambucana de Saúde (FPS),'},
    {value:'Faculdade Presidente Antônio Carlos (FAPAC),',label:'Faculdade Presidente Antônio Carlos (FAPAC),',text:'Faculdade Presidente Antônio Carlos (FAPAC),'},
    {value:'Faculdade Santa Marcelina (FASM),',label:'Faculdade Santa Marcelina (FASM),',text:'Faculdade Santa Marcelina (FASM),'},
    {value:'Faculdade Santa Maria (FSM),',label:'Faculdade Santa Maria (FSM),',text:'Faculdade Santa Maria (FSM),'},
    {value:'Faculdade São Lucas (FSL),',label:'Faculdade São Lucas (FSL),',text:'Faculdade São Lucas (FSL),'},
    {value:'Faculdade Ubaense Ozanam Coelho (FAGOC),',label:'Faculdade Ubaense Ozanam Coelho (FAGOC),',text:'Faculdade Ubaense Ozanam Coelho (FAGOC),'},
    {value:'Faculdades Assis Gurgacz (FAG),',label:'Faculdades Assis Gurgacz (FAG),',text:'Faculdades Assis Gurgacz (FAG),'},
    {value:'Faculdades Integradas Aparício Carvalho (FIMCA),',label:'Faculdades Integradas Aparício Carvalho (FIMCA),',text:'Faculdades Integradas Aparício Carvalho (FIMCA),'},
    {value:'Faculdades Integradas da União Educacional do Planalto Central FACIPLAC (FACIPLAC),',label:'Faculdades Integradas da União Educacional do Planalto Central FACIPLAC (FACIPLAC),',text:'Faculdades Integradas da União Educacional do Planalto Central FACIPLAC (FACIPLAC),'},
    {value:'Faculdades Integradas Padre Albino (FIPA),',label:'Faculdades Integradas Padre Albino (FIPA),',text:'Faculdades Integradas Padre Albino (FIPA),'},
    {value:'Faculdades Integradas Pitágoras (FIP-MOC),',label:'Faculdades Integradas Pitágoras (FIP-MOC),',text:'Faculdades Integradas Pitágoras (FIP-MOC),'},
    {value:'Faculdades Pequeno Príncipe (FPP),',label:'Faculdades Pequeno Príncipe (FPP),',text:'Faculdades Pequeno Príncipe (FPP),'},
    {value:'Fundação Educacional de Votuporanga (UNIFEV),',label:'Fundação Educacional de Votuporanga (UNIFEV),',text:'Fundação Educacional de Votuporanga (UNIFEV),'},
    {value:'Fundação Universidade Federal da Grande Dourados (UFGD),',label:'Fundação Universidade Federal da Grande Dourados (UFGD),',text:'Fundação Universidade Federal da Grande Dourados (UFGD),'},
    {value:'Fundação Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA),',label:'Fundação Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA),',text:'Fundação Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA),'},
    {value:'Fundação Universidade Federal de Rondônia (UNIR),',label:'Fundação Universidade Federal de Rondônia (UNIR),',text:'Fundação Universidade Federal de Rondônia (UNIR),'},
    {value:'Fundação Universidade Federal do Vale do São Francisco (UNIVASF),',label:'Fundação Universidade Federal do Vale do São Francisco (UNIVASF),',text:'Fundação Universidade Federal do Vale do São Francisco (UNIVASF),'},
    {value:'Fundação Universidade Federal do Vale do São Francisco (UNIVASF),',label:'Fundação Universidade Federal do Vale do São Francisco (UNIVASF),',text:'Fundação Universidade Federal do Vale do São Francisco (UNIVASF),'},
    {value:'Instituto de Ciências da Saúde (ICS),',label:'Instituto de Ciências da Saúde (ICS),',text:'Instituto de Ciências da Saúde (ICS),'},
    {value:'Instituto Máster de Ensino Presidente Antônio Carlos (IMEPAC),',label:'Instituto Máster de Ensino Presidente Antônio Carlos (IMEPAC),',text:'Instituto Máster de Ensino Presidente Antônio Carlos (IMEPAC),'},
    {value:'Instituto Metropolitano de Ensino Superior (IMES),',label:'Instituto Metropolitano de Ensino Superior (IMES),',text:'Instituto Metropolitano de Ensino Superior (IMES),'},
    {value:'Instituto Superior de Tecnologia Aplicada (INTA),',label:'Instituto Superior de Tecnologia Aplicada (INTA),',text:'Instituto Superior de Tecnologia Aplicada (INTA),'},
    {value:'Pontifícia Universidade Católica de Campinas (PUC-CAMPINAS),',label:'Pontifícia Universidade Católica de Campinas (PUC-CAMPINAS),',text:'Pontifícia Universidade Católica de Campinas (PUC-CAMPINAS),'},
    {value:'Pontifícia Universidade Católica de Goiás (PUC GOIÁS),',label:'Pontifícia Universidade Católica de Goiás (PUC GOIÁS),',text:'Pontifícia Universidade Católica de Goiás (PUC GOIÁS),'},
    {value:'Pontifícia Universidade Católica de Minas Gerais (PUC MINAS),',label:'Pontifícia Universidade Católica de Minas Gerais (PUC MINAS),',text:'Pontifícia Universidade Católica de Minas Gerais (PUC MINAS),'},
    {value:'Pontifícia Universidade Católica de São Paulo (PUCSP),',label:'Pontifícia Universidade Católica de São Paulo (PUCSP),',text:'Pontifícia Universidade Católica de São Paulo (PUCSP),'},
    {value:'Pontifícia Universidade Católica do Paraná (PUCPR),',label:'Pontifícia Universidade Católica do Paraná (PUCPR),',text:'Pontifícia Universidade Católica do Paraná (PUCPR),'},
    {value:'Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS),',label:'Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS),',text:'Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS),'},
    {value:'União das Faculdades dos Grandes Lagos (UNILAGO),',label:'União das Faculdades dos Grandes Lagos (UNILAGO),',text:'União das Faculdades dos Grandes Lagos (UNILAGO),'},
    {value:'Universidade Anhembi Morumbi (UAM),',label:'Universidade Anhembi Morumbi (UAM),',text:'Universidade Anhembi Morumbi (UAM),'},
    {value:'Universidade Camilo Castelo Branco (UNICASTELO),',label:'Universidade Camilo Castelo Branco (UNICASTELO),',text:'Universidade Camilo Castelo Branco (UNICASTELO),'},
    {value:'Universidade Católica de Brasília (UCB),',label:'Universidade Católica de Brasília (UCB),',text:'Universidade Católica de Brasília (UCB),'},
    {value:'Universidade Católica de Pelotas (UCPEL),',label:'Universidade Católica de Pelotas (UCPEL),',text:'Universidade Católica de Pelotas (UCPEL),'},
    {value:'Universidade Católica de Pernambuco (UNICAP),',label:'Universidade Católica de Pernambuco (UNICAP),',text:'Universidade Católica de Pernambuco (UNICAP),'},
    {value:'Universidade Cidade de São Paulo (UNICID),',label:'Universidade Cidade de São Paulo (UNICID),',text:'Universidade Cidade de São Paulo (UNICID),'},
    {value:'Universidade Comunitária da Região de Chapecó (UNOCHAPECÓ),',label:'Universidade Comunitária da Região de Chapecó (UNOCHAPECÓ),',text:'Universidade Comunitária da Região de Chapecó (UNOCHAPECÓ),'},
    {value:'Universidade da Região de Joinville (UNIVILLE),',label:'Universidade da Região de Joinville (UNIVILLE),',text:'Universidade da Região de Joinville (UNIVILLE),'},
    {value:'Universidade de Brasília (UNB),',label:'Universidade de Brasília (UNB),',text:'Universidade de Brasília (UNB),'},
    {value:'Universidade de Caxias do Sul (UCS),',label:'Universidade de Caxias do Sul (UCS),',text:'Universidade de Caxias do Sul (UCS),'},
    {value:'Universidade de Cuiabá (UNIC/PITÁGORAS),',label:'Universidade de Cuiabá (UNIC/PITÁGORAS),',text:'Universidade de Cuiabá (UNIC/PITÁGORAS),'},
    {value:'Universidade de Fortaleza (UNIFOR),',label:'Universidade de Fortaleza (UNIFOR),',text:'Universidade de Fortaleza (UNIFOR),'},
    {value:'Universidade de Franca (UNIFRAN),',label:'Universidade de Franca (UNIFRAN),',text:'Universidade de Franca (UNIFRAN),'},
    {value:'Universidade de Itaúna (UI),',label:'Universidade de Itaúna (UI),',text:'Universidade de Itaúna (UI),'},
    {value:'Universidade de Marília (UNIMAR),',label:'Universidade de Marília (UNIMAR),',text:'Universidade de Marília (UNIMAR),'},
    {value:'Universidade de Mogi das Cruzes (UMC),',label:'Universidade de Mogi das Cruzes (UMC),',text:'Universidade de Mogi das Cruzes (UMC),'},
    {value:'Universidade de Passo Fundo (UPF),',label:'Universidade de Passo Fundo (UPF),',text:'Universidade de Passo Fundo (UPF),'},
    {value:'Universidade de Pernambuco (UPE),',label:'Universidade de Pernambuco (UPE),',text:'Universidade de Pernambuco (UPE),'},
    {value:'Universidade de Ribeirão Preto (UNAERP),',label:'Universidade de Ribeirão Preto (UNAERP),',text:'Universidade de Ribeirão Preto (UNAERP),'},
    {value:'Universidade de Rio Verde (FESURV),',label:'Universidade de Rio Verde (FESURV),',text:'Universidade de Rio Verde (FESURV),'},
    {value:'Universidade de Santa Cruz do Sul (UNISC),',label:'Universidade de Santa Cruz do Sul (UNISC),',text:'Universidade de Santa Cruz do Sul (UNISC),'},
    {value:'Universidade de Santo Amaro (UNISA),',label:'Universidade de Santo Amaro (UNISA),',text:'Universidade de Santo Amaro (UNISA),'},
    {value:'Universidade de São Paulo (USP),',label:'Universidade de São Paulo (USP),',text:'Universidade de São Paulo (USP),'},
    {value:'Universidade de Taubaté (UNITAU),',label:'Universidade de Taubaté (UNITAU),',text:'Universidade de Taubaté (UNITAU),'},
    {value:'Universidade de Uberaba (UNIUBE),',label:'Universidade de Uberaba (UNIUBE),',text:'Universidade de Uberaba (UNIUBE),'},
    {value:'Universidade do CEUMA (UNICEUMA),',label:'Universidade do CEUMA (UNICEUMA),',text:'Universidade do CEUMA (UNICEUMA),'},
    {value:'Universidade do Estado da Bahia (UNEB),',label:'Universidade do Estado da Bahia (UNEB),',text:'Universidade do Estado da Bahia (UNEB),'},
    {value:'Universidade do Estado do Amazonas (UEA),',label:'Universidade do Estado do Amazonas (UEA),',text:'Universidade do Estado do Amazonas (UEA),'},
    {value:'Universidade do Estado do Mato Grosso (UNEMAT),',label:'Universidade do Estado do Mato Grosso (UNEMAT),',text:'Universidade do Estado do Mato Grosso (UNEMAT),'},
    {value:'Universidade do Estado do Pará (UEPA),',label:'Universidade do Estado do Pará (UEPA),',text:'Universidade do Estado do Pará (UEPA),'},
    {value:'Universidade do Estado do Rio de Janeiro (UERJ),',label:'Universidade do Estado do Rio de Janeiro (UERJ),',text:'Universidade do Estado do Rio de Janeiro (UERJ),'},
    {value:'Universidade do Estado do Rio Grande do Norte (UERN),',label:'Universidade do Estado do Rio Grande do Norte (UERN),',text:'Universidade do Estado do Rio Grande do Norte (UERN),'},
    {value:'Universidade do Extremo Sul Catarinense (UNESC),',label:'Universidade do Extremo Sul Catarinense (UNESC),',text:'Universidade do Extremo Sul Catarinense (UNESC),'},
    {value:'Universidade do Grande Rio Professor José de Souza Herdy (UNIGRANRIO),',label:'Universidade do Grande Rio Professor José de Souza Herdy (UNIGRANRIO),',text:'Universidade do Grande Rio Professor José de Souza Herdy (UNIGRANRIO),'},
    {value:'Universidade do Oeste de Santa Catarina (UNOESC),',label:'Universidade do Oeste de Santa Catarina (UNOESC),',text:'Universidade do Oeste de Santa Catarina (UNOESC),'},
    {value:'Universidade do Oeste Paulista (UNOESTE),',label:'Universidade do Oeste Paulista (UNOESTE),',text:'Universidade do Oeste Paulista (UNOESTE),'},
    {value:'Universidade do Planalto Catarinense (UNIPLAC),',label:'Universidade do Planalto Catarinense (UNIPLAC),',text:'Universidade do Planalto Catarinense (UNIPLAC),'},
    {value:'Universidade do Sul de Santa Catarina (UNISUL),',label:'Universidade do Sul de Santa Catarina (UNISUL),',text:'Universidade do Sul de Santa Catarina (UNISUL),'},
    {value:'Universidade do Vale do Itajaí (UNIVALI),',label:'Universidade do Vale do Itajaí (UNIVALI),',text:'Universidade do Vale do Itajaí (UNIVALI),'},
    {value:'Universidade do Vale do Sapucaí (UNIVÁS),',label:'Universidade do Vale do Sapucaí (UNIVÁS),',text:'Universidade do Vale do Sapucaí (UNIVÁS),'},
    {value:'Universidade Estácio de Sá (UNESA),',label:'Universidade Estácio de Sá (UNESA),',text:'Universidade Estácio de Sá (UNESA),'},
    {value:'Universidade Estácio de Sá (UNESA),',label:'Universidade Estácio de Sá (UNESA),',text:'Universidade Estácio de Sá (UNESA),'},
    {value:'Universidade Estadual de Campinas (UNICAMP),',label:'Universidade Estadual de Campinas (UNICAMP),',text:'Universidade Estadual de Campinas (UNICAMP),'},
    {value:'Universidade Estadual de Ciências da Saúde de Alagoas (UNICSAL),',label:'Universidade Estadual de Ciências da Saúde de Alagoas (UNICSAL),',text:'Universidade Estadual de Ciências da Saúde de Alagoas (UNICSAL),'},
    {value:'Universidade Estadual de Feira de Santana (UEFS),',label:'Universidade Estadual de Feira de Santana (UEFS),',text:'Universidade Estadual de Feira de Santana (UEFS),'},
    {value:'Universidade Estadual de Londrina (UEL),',label:'Universidade Estadual de Londrina (UEL),',text:'Universidade Estadual de Londrina (UEL),'},
    {value:'Universidade Estadual de Maringá (UEM),',label:'Universidade Estadual de Maringá (UEM),',text:'Universidade Estadual de Maringá (UEM),'},
    {value:'Universidade Estadual de Mato Grosso do Sul (UEMS),',label:'Universidade Estadual de Mato Grosso do Sul (UEMS),',text:'Universidade Estadual de Mato Grosso do Sul (UEMS),'},
    {value:'Universidade Estadual de Montes Claros (UNIMONTES),',label:'Universidade Estadual de Montes Claros (UNIMONTES),',text:'Universidade Estadual de Montes Claros (UNIMONTES),'},
    {value:'Universidade Estadual de Ponta Grossa (UEPG),',label:'Universidade Estadual de Ponta Grossa (UEPG),',text:'Universidade Estadual de Ponta Grossa (UEPG),'},
    {value:'Universidade Estadual de Santa Cruz (UESC),',label:'Universidade Estadual de Santa Cruz (UESC),',text:'Universidade Estadual de Santa Cruz (UESC),'},
    {value:'Universidade Estadual do Ceará (UECE),',label:'Universidade Estadual do Ceará (UECE),',text:'Universidade Estadual do Ceará (UECE),'},
    {value:'Universidade Estadual do Oeste do Paraná (UNOESTE),',label:'Universidade Estadual do Oeste do Paraná (UNOESTE),',text:'Universidade Estadual do Oeste do Paraná (UNOESTE),'},
    {value:'Universidade Estadual do Piauí (UESPI),',label:'Universidade Estadual do Piauí (UESPI),',text:'Universidade Estadual do Piauí (UESPI),'},
    {value:'Universidade Estadual Paulista Júlio de Mesquita Filho (UNESP),',label:'Universidade Estadual Paulista Júlio de Mesquita Filho (UNESP),',text:'Universidade Estadual Paulista Júlio de Mesquita Filho (UNESP),'},
    {value:'Universidade Federal da Bahia (UFBA),',label:'Universidade Federal da Bahia (UFBA),',text:'Universidade Federal da Bahia (UFBA),'},
    {value:'Universidade Federal da Fronteira Sul (UFFS),',label:'Universidade Federal da Fronteira Sul (UFFS),',text:'Universidade Federal da Fronteira Sul (UFFS),'},
    {value:'Universidade Federal da Fronteira Sul (UFFS),',label:'Universidade Federal da Fronteira Sul (UFFS),',text:'Universidade Federal da Fronteira Sul (UFFS),'},
    {value:'Universidade Federal da Integração Latino-Americana (UNILA),',label:'Universidade Federal da Integração Latino-Americana (UNILA),',text:'Universidade Federal da Integração Latino-Americana (UNILA),'},
    {value:'Universidade Federal da Paraíba (UFPB),',label:'Universidade Federal da Paraíba (UFPB),',text:'Universidade Federal da Paraíba (UFPB),'},
    {value:'Universidade Federal de Alagoas (UFAL),',label:'Universidade Federal de Alagoas (UFAL),',text:'Universidade Federal de Alagoas (UFAL),'},
    {value:'Universidade Federal de Alfenas (UNIFAL-MG),',label:'Universidade Federal de Alfenas (UNIFAL-MG),',text:'Universidade Federal de Alfenas (UNIFAL-MG),'},
    {value:'Universidade Federal de Campina Grande (UFCG),',label:'Universidade Federal de Campina Grande (UFCG),',text:'Universidade Federal de Campina Grande (UFCG),'},
    {value:'Universidade Federal de Goiás (UFG),',label:'Universidade Federal de Goiás (UFG),',text:'Universidade Federal de Goiás (UFG),'},
    {value:'Universidade Federal de Juiz de Fora (UFJF),',label:'Universidade Federal de Juiz de Fora (UFJF),',text:'Universidade Federal de Juiz de Fora (UFJF),'},
    {value:'Universidade Federal de Lavras (UFLA),',label:'Universidade Federal de Lavras (UFLA),',text:'Universidade Federal de Lavras (UFLA),'},
    {value:'Universidade Federal de Mato Grosso do Sul (UFMS),',label:'Universidade Federal de Mato Grosso do Sul (UFMS),',text:'Universidade Federal de Mato Grosso do Sul (UFMS),'},
    {value:'Universidade Federal de Minas Gerais (UFMG),',label:'Universidade Federal de Minas Gerais (UFMG),',text:'Universidade Federal de Minas Gerais (UFMG),'},
    {value:'Universidade Federal de Ouro Preto (UFOP),',label:'Universidade Federal de Ouro Preto (UFOP),',text:'Universidade Federal de Ouro Preto (UFOP),'},
    {value:'Universidade Federal de Pelotas (UFPEL),',label:'Universidade Federal de Pelotas (UFPEL),',text:'Universidade Federal de Pelotas (UFPEL),'},
    {value:'Universidade Federal de Pernambuco (UFPE),',label:'Universidade Federal de Pernambuco (UFPE),',text:'Universidade Federal de Pernambuco (UFPE),'},
    {value:'Universidade Federal de Roraima (UFRR),',label:'Universidade Federal de Roraima (UFRR),',text:'Universidade Federal de Roraima (UFRR),'},
    {value:'Universidade Federal de Santa Catarina (UFSC),',label:'Universidade Federal de Santa Catarina (UFSC),',text:'Universidade Federal de Santa Catarina (UFSC),'},
    {value:'Universidade Federal de Santa Maria (UFSM),',label:'Universidade Federal de Santa Maria (UFSM),',text:'Universidade Federal de Santa Maria (UFSM),'},
    {value:'Universidade Federal de São Carlos (UFSCAR),',label:'Universidade Federal de São Carlos (UFSCAR),',text:'Universidade Federal de São Carlos (UFSCAR),'},
    {value:'Universidade Federal de São João Del Rei (UFSJ),',label:'Universidade Federal de São João Del Rei (UFSJ),',text:'Universidade Federal de São João Del Rei (UFSJ),'},
    {value:'Universidade Federal de São Paulo (UNIFESP),',label:'Universidade Federal de São Paulo (UNIFESP),',text:'Universidade Federal de São Paulo (UNIFESP),'},
    {value:'Universidade Federal de Sergipe (UFS),',label:'Universidade Federal de Sergipe (UFS),',text:'Universidade Federal de Sergipe (UFS),'},
    {value:'Universidade Federal de Uberlândia (UFU),',label:'Universidade Federal de Uberlândia (UFU),',text:'Universidade Federal de Uberlândia (UFU),'},
    {value:'Universidade Federal de Viçosa (UFV),',label:'Universidade Federal de Viçosa (UFV),',text:'Universidade Federal de Viçosa (UFV),'},
    {value:'Universidade Federal do Acre (UFAC),',label:'Universidade Federal do Acre (UFAC),',text:'Universidade Federal do Acre (UFAC),'},
    {value:'Universidade Federal do Amapá (UNIFAP),',label:'Universidade Federal do Amapá (UNIFAP),',text:'Universidade Federal do Amapá (UNIFAP),'},
    {value:'Universidade Federal do Amazonas (UFAM),',label:'Universidade Federal do Amazonas (UFAM),',text:'Universidade Federal do Amazonas (UFAM),'},
    {value:'Universidade Federal do Cariri (UFCA),',label:'Universidade Federal do Cariri (UFCA),',text:'Universidade Federal do Cariri (UFCA),'},
    {value:'Universidade Federal do Ceará (UFC),',label:'Universidade Federal do Ceará (UFC),',text:'Universidade Federal do Ceará (UFC),'},
    {value:'Universidade Federal do Espírito Santo (UFES),',label:'Universidade Federal do Espírito Santo (UFES),',text:'Universidade Federal do Espírito Santo (UFES),'},
    {value:'Universidade Federal do Estado do Rio de Janeiro (UNIRIO),',label:'Universidade Federal do Estado do Rio de Janeiro (UNIRIO),',text:'Universidade Federal do Estado do Rio de Janeiro (UNIRIO),'},
    {value:'Universidade Federal do Maranhão (UFMA),',label:'Universidade Federal do Maranhão (UFMA),',text:'Universidade Federal do Maranhão (UFMA),'},
    {value:'Universidade Federal do Mato Grosso (UFMT),',label:'Universidade Federal do Mato Grosso (UFMT),',text:'Universidade Federal do Mato Grosso (UFMT),'},
    {value:'Universidade Federal do Oeste da Bahia (UFOB),',label:'Universidade Federal do Oeste da Bahia (UFOB),',text:'Universidade Federal do Oeste da Bahia (UFOB),'},
    {value:'Universidade Federal do Pará (UFPA),',label:'Universidade Federal do Pará (UFPA),',text:'Universidade Federal do Pará (UFPA),'},
    {value:'Universidade Federal do Paraná (UFPR),',label:'Universidade Federal do Paraná (UFPR),',text:'Universidade Federal do Paraná (UFPR),'},
    {value:'Universidade Federal do Piauí (UFPI),',label:'Universidade Federal do Piauí (UFPI),',text:'Universidade Federal do Piauí (UFPI),'},
    {value:'Universidade Federal do Recôncavo da Bahia (UFRB),',label:'Universidade Federal do Recôncavo da Bahia (UFRB),',text:'Universidade Federal do Recôncavo da Bahia (UFRB),'},
    {value:'Universidade Federal do Rio de Janeiro (UFRJ),',label:'Universidade Federal do Rio de Janeiro (UFRJ),',text:'Universidade Federal do Rio de Janeiro (UFRJ),'},
    {value:'Universidade Federal do Rio Grande (FURG),',label:'Universidade Federal do Rio Grande (FURG),',text:'Universidade Federal do Rio Grande (FURG),'},
    {value:'Universidade Federal do Rio Grande do Norte (UFRN),',label:'Universidade Federal do Rio Grande do Norte (UFRN),',text:'Universidade Federal do Rio Grande do Norte (UFRN),'},
    {value:'Universidade Federal do Rio Grande do Sul (UFRGS),',label:'Universidade Federal do Rio Grande do Sul (UFRGS),',text:'Universidade Federal do Rio Grande do Sul (UFRGS),'},
    {value:'Universidade Federal do Sul da Bahia (UFSBA),',label:'Universidade Federal do Sul da Bahia (UFSBA),',text:'Universidade Federal do Sul da Bahia (UFSBA),'},
    {value:'Universidade Federal do Tocantins (UFT),',label:'Universidade Federal do Tocantins (UFT),',text:'Universidade Federal do Tocantins (UFT),'},
    {value:'Universidade Federal do Triângulo Mineiro (UFTM),',label:'Universidade Federal do Triângulo Mineiro (UFTM),',text:'Universidade Federal do Triângulo Mineiro (UFTM),'},
    {value:'Universidade Federal dos Vales do Jequitinhonha e Mucuri (UFVJM),',label:'Universidade Federal dos Vales do Jequitinhonha e Mucuri (UFVJM),',text:'Universidade Federal dos Vales do Jequitinhonha e Mucuri (UFVJM),'},
    {value:'Universidade Federal Fluminense (UFF),',label:'Universidade Federal Fluminense (UFF),',text:'Universidade Federal Fluminense (UFF),'},
    {value:'Universidade Iguaçu (UNIG),',label:'Universidade Iguaçu (UNIG),',text:'Universidade Iguaçu (UNIG),'},
    {value:'Universidade José do Rosário Vellano (UNIFENAS),',label:'Universidade José do Rosário Vellano (UNIFENAS),',text:'Universidade José do Rosário Vellano (UNIFENAS),'},
    {value:'Universidade Luterana do Brasil (ULBRA),',label:'Universidade Luterana do Brasil (ULBRA),',text:'Universidade Luterana do Brasil (ULBRA),'},
    {value:'Universidade Metropolitana de Santos (UNIMES),',label:'Universidade Metropolitana de Santos (UNIMES),',text:'Universidade Metropolitana de Santos (UNIMES),'},
    {value:'Universidade Municipal de São Caetano do Sul (USCS),',label:'Universidade Municipal de São Caetano do Sul (USCS),',text:'Universidade Municipal de São Caetano do Sul (USCS),'},
    {value:'Universidade Nilton Lins (UNINILTONLINS),',label:'Universidade Nilton Lins (UNINILTONLINS),',text:'Universidade Nilton Lins (UNINILTONLINS),'},
    {value:'Universidade Nove de Julho (UNINOVE),',label:'Universidade Nove de Julho (UNINOVE),',text:'Universidade Nove de Julho (UNINOVE),'},
    {value:'Universidade Positivo (UP),',label:'Universidade Positivo (UP),',text:'Universidade Positivo (UP),'},
    {value:'Universidade Potiguar (UNP),',label:'Universidade Potiguar (UNP),',text:'Universidade Potiguar (UNP),'},
    {value:'Universidade Presidente Antônio Carlos (UNIPAC),',label:'Universidade Presidente Antônio Carlos (UNIPAC),',text:'Universidade Presidente Antônio Carlos (UNIPAC),'},
    {value:'Universidade Regional de Blumenau (FURB),',label:'Universidade Regional de Blumenau (FURB),',text:'Universidade Regional de Blumenau (FURB),'},
    {value:'Universidade Salvador (UNIFACS),',label:'Universidade Salvador (UNIFACS),',text:'Universidade Salvador (UNIFACS),'},
    {value:'Universidade São Francisco (USF),',label:'Universidade São Francisco (USF),',text:'Universidade São Francisco (USF),'},
    {value:'Universidade Severino Sombra (USS),',label:'Universidade Severino Sombra (USS),',text:'Universidade Severino Sombra (USS),'},
    {value:'Universidade Tiradentes (UNIT),',label:'Universidade Tiradentes (UNIT),',text:'Universidade Tiradentes (UNIT),'},
    {value:'Universidade Vila Velha (UVV),',label:'Universidade Vila Velha (UVV),',text:'Universidade Vila Velha (UVV),'},
    

];

export default medicalUniversities;