import { Form } from '@unform/web';
import Axios from 'axios';
import { addMonths, isBefore, parseISO, format as formatDate } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FaBarcode, FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';

import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLoading } from '../../../../hooks/LoadingHook';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import { dateFullWithHour, simpleDateBR, simpleDateUS } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';
import CreditCardExchange from './modules/CreditCardExchange';
import PaymentRegistration from './modules/PaymentRegistration';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;
  myMedicSubscription?: Record<string, any>;
}

const Pagamentos: React.FC<IProps> = ({ myMedicSubscription, changeStatus }) => {

  const { user } = useAuth();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])
  const [invoices, setInvoices] = useState<Array<Record<string, any>>>([])
  const [products, setProducts] = useState<Array<Record<string, any>>>([])
  const { configuration } = useConfiguration();
  const { projectHash } = useParams<Record<string, any>>();
  const { addLoading, removeLoading } = useLoading();
  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const getPaymentMethod = {

    boleto: "Boleto",
    boleto_recurring: "Boleto",
    credit_card: "Cartão de Crédito",
    credit_card_recurring: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }



  const fetchIgpmData = async ({ startDate, endDate }) => {
    try {

      const response = await Axios(
        `https://api.bcb.gov.br/dados/serie/bcdata.sgs.189/dados?formato=json&dataInicial=${startDate}`
      );


      const data = await response.data;
      return data;
    } catch (error) {
      return 0
    }
  };

  const loadInvoices = async () => {



    const response = await api.get(`/my-invoices/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      response?.data?.rows.sort((a, b) => a?.payment_date < b?.payment_date ? -1 : a?.payment_date > b?.payment_date ? 1 : 0);
      setInvoices(response.data.rows);
    }

  }
  const emitNewBoleto = async (item) => {
    addLoading({ title: 'Emitindo novo boleto', key: 'loading' })

    try {
      await api.post(`emit-new-invoice-boleto/${item?.invoice_hash}`)
      await loadInvoices();

      addToast({ title: 'Boleto emitido com sucesso', type: 'success' });
      removeModal('boleto')
      removeLoading('loading');

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao emitir boleto', type: 'error' });
      removeLoading('loading');
    }

  }

  const emitNewCreditCardPayment = async (item) => {
    addLoading({ title: 'Emitindo novo pagamento com cartão de crédito', key: 'loading' })

    try {
      await api.post(`emit-new-invoice-credit-card/${item?.invoice_hash}`)
      await loadInvoices();

      addToast({ title: 'Cobrança encaminhada com sucesso', type: 'success' });
      removeModal('boleto')
      removeLoading('loading');

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao emitir cobrança', type: 'error' });
      removeLoading('loading');
    }

  }

  const askNewBoleto = async (item) => {




    const currentValue = item?.installment_value;
    const multa = parseInt((currentValue * 0.02)?.toString(), 10);
    const juros = parseInt((currentValue * 0.01)?.toString(), 10);
    const total = currentValue + juros + multa;

    addModal({
      key: 'boleto',
      title: '', content: <>

        <h2 style={{ color: '#333' }}>Você deseja emitir um novo boleto?</h2>

        <table className='tableRef' style={{ maxWidth: '300px', margin: '10px auto' }}>
          <tbody>
            <tr><td style={{ maxWidth: '150px' }}>Valor original</td><td style={{ textAlign: 'right' }}>R$ {format(currentValue)}</td></tr>
            <tr><td style={{ maxWidth: '150px' }}>Multa</td><td style={{ textAlign: 'right' }}>R$ {format(multa)}</td></tr>
            <tr> <td style={{ maxWidth: '150px' }}>Juros de Mora</td><td style={{ textAlign: 'right' }}>R$ {format(juros)}</td></tr>
            <tr><td style={{ maxWidth: '150px', fontWeight: 'bold' }}>Total</td><td style={{ textAlign: 'right', fontWeight: 'bold' }}>R$ {format(total)}</td></tr>



          </tbody>
        </table>
        <button className='defaultButton' style={{ margin: '10px auto' }} onClick={() => emitNewBoleto(item)}>Emitir</button>
      </>, theme: 'whiteModalMini'
    })
  }

  const askNewCreditCardPayment = async (item) => {




    const currentValue = item?.installment_value;
    const multa = parseInt((currentValue * 0.02)?.toString(), 10);
    const juros = parseInt((currentValue * 0.01)?.toString(), 10);
    const total = currentValue + juros + multa;

    addModal({
      key: 'boleto',
      title: '', content: <>

        <h2 style={{ color: '#333' }}>Você deseja emitir um novo boleto?</h2>

        <table className='tableRef' style={{ maxWidth: '300px', margin: '10px auto' }}>
          <tbody>
            <tr><td style={{ maxWidth: '150px' }}>Valor original</td><td style={{ textAlign: 'right' }}>R$ {format(currentValue)}</td></tr>
            <tr><td style={{ maxWidth: '150px' }}>Multa</td><td style={{ textAlign: 'right' }}>R$ {format(multa)}</td></tr>
            <tr> <td style={{ maxWidth: '150px' }}>Juros de Mora</td><td style={{ textAlign: 'right' }}>R$ {format(juros)}</td></tr>
            <tr><td style={{ maxWidth: '150px', fontWeight: 'bold' }}>Total</td><td style={{ textAlign: 'right', fontWeight: 'bold' }}>R$ {format(total)}</td></tr>



          </tbody>
        </table>
        <button className='defaultButton' style={{ margin: '10px auto' }} onClick={() => emitNewCreditCardPayment(item)}>Emitir cobrança</button>
      </>, theme: 'whiteModalMini'
    })
  }

  const loadProducts = async () => {



    const response = await api.get(`/products-list/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setProducts(response.data.rows);
    }

  }

  const load = async () => {



    const response = await api.get(`/my-tickets/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }



  const printFields = (productList, item) => {

    const list: Array<JSX.Element> = [];
    const product = productList?.find(i => i?._id?.toString() === item?.product_id?.toString());



    if (item?.fields) {

      Object?.keys(item?.fields)?.map(key => {

        const fieldName = product?.fields?.find(p => p?.field === key);
        const content: JSX.Element[] = [];



        if (fieldName) {
          if (fieldName?.title === 'Requerer a pontuação adicional conforme item 3.2 do edital?') {
            if (!item?.fields?.[key]) {
              item.fields[key] = 'Não';
            }
          }

          if (item?.fields?.[key]) {

            if (typeof item?.fields?.[key] === 'object') {

              item?.fields?.[key]?.map(i => {
                console.log(i)
                if (i?.indexOf('event/') >= 0) {
                  content.push(<a href={`${urlWebsite}/${i}`} download><button style={{ margin: '5px 0px' }} className='defaultButtonMini'>Documento anexado</button></a>);
                }
                else {
                  content.push(<p style={{ color: '#333' }}>{i}</p>);

                }
              })

            }
            else {
              if (item?.fields?.[key]?.indexOf('event/') >= 0) {
                content.push(<a href={`${urlWebsite}/${item?.fields?.[key]}`} download><button className='defaultButtonMini' style={{ margin: '5px 0px' }}>Documento anexado</button></a>);
              }
              else {
                content?.push(<p style={{ color: '#333' }}>{item?.fields?.[key]}</p>)

              }

            }

          }

          if (content?.length > 0) {
            list.push(<p><strong style={{ color: '#333', marginBottom: '5px', display: 'block' }}>{fieldName?.title}:</strong>  {content}<br /></p>)
          }
        }



      })



    }

    return list;
  }

  const reloadAll = async () => {

    loadInvoices();
    load();
    loadProducts();
    removeModal('payment')


  }

  useEffect(() => {

    if (projectHash) {
      loadInvoices();
      load();
      loadProducts();
    }

  }, [projectHash])

  const openPayment = (project) => {

    addModal({ title: '', key: 'payment', theme: 'whiteModalMini', content: <PaymentRegistration reloadAll={reloadAll} project={{ url: projectHash }} /> })
  }

  const openCreditCard = (project) => {

    addModal({ title: '', key: 'payment', theme: 'whiteModalMini', content: <CreditCardExchange reloadAll={reloadAll} project={{ url: projectHash }} /> })
  }


  return (
    <>
      <Container >
        {myMedicSubscription?.status === 'aprovado' && configuration?.registration_product && invoices?.length < 1 ? <button className='defaultButton' style={{ alignSelf: 'flex-start' }} onClick={() => openPayment(configuration)}>Efetuar pagamento</button> : <></>}
        {configuration?.registration_product && invoices?.length > 0 ? <button className='defaultButton' style={{ alignSelf: 'flex-start' }} onClick={() => openCreditCard(configuration)}>Alterar Cartão de Crédito</button> : <></>}

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Meus pagamentos</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr><th>Data</th> <th>Pagamento</th></tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}><td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td> <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <strong>{sale?.product_id_response?.title}</strong><br />
                    <p style={{ margin: '5px 0px' }}>Protocolo de inscrição: {sale?.sale_hash}</p>
                    {sale?.category ? <p>Categoria : {sale?.category}</p> : <></>}
                    {sale?.area ? <p>Área : {sale?.area}</p> : <></>}
                    {sale?.subarea ? <p>Subárea : {sale?.subarea}</p> : <></>}

                    {printFields(products, sale)}
                    <br /> <br />Pagador: {sale?.client_id_response?.name}
                    <br /> Valor: {format(sale.total)}
                    <br />Forma de pagamento: {getPaymentMethod?.[sale?.payment_method_id] || 'Não identificado'}
                    <br />Status de pagamento: {getPaymentStatus?.[sale?.payment_status_id] || 'Erro ao identificar'}
                    {sale?.boleto_url ? <><br /><a href={sale?.boleto_url}><strong>Clique aqui para visualizar o boleto </strong></a></> : <></>}



                    {sale?.payment_status_id === 'paid' && sale?.total > 0 && <><br />
                      <a href={`/app/${projectHash}/recibo/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Visualizar Recibo </button></a></>}


                    {sale?.payment_status_id === 'paid' && projectHash === 'autoavaliacao-hcpa-2022' && <><br />
                      <a href={`/app/${projectHash}/formulario/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Visualizar formulário para impressão </button></a></>}


                  </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>Nenhum pagamento identificado</td></tr>}
            </tbody>
            </table>
          </div>

        </div>


        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Faturas</p>
            <table><tbody>
              {invoices?.length > 0 && <>    <tr> <th>Pagamento</th></tr>
                {invoices.map(sale => {

                  const isOutDated = sale?.payment_status_id === 'paid' ? false : sale?.payment_date_reference ? isBefore(new Date(sale?.payment_date_reference), new Date()) : sale?.payment_date ? isBefore(new Date(sale?.payment_date), new Date()) : true;

                  const currentValue = sale?.installment_value;
                  const multa = sale?.multa || 0;
                  const juros = sale?.juros || 0;
                  const correcao = sale?.correcao || 0;
                  const total = currentValue + multa + juros + correcao;



                  return <tr key={sale._id}>


                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>

                      <strong style={{ margin: '5px 0px', color: '#333' }}>Parcela:{` `}
                        {sale?.installment_number}
                      </strong>
                      <p style={{ margin: '5px 0px' }}>Data de Vencimento:{` `}
                        {dateFullWithHour(sale?.payment_date
                        )}</p>


                      <strong>{sale?.product_id_response?.title}</strong><br />
                      <p style={{ margin: '5px 0px' }}>Protocolo de inscrição: {sale?.sale_hash}</p>
                      {sale?.category ? <p>Categoria : {sale?.category}</p> : <></>}
                      {sale?.area ? <p>Área : {sale?.area}</p> : <></>}
                      {sale?.subarea ? <p>Subárea : {sale?.subarea}</p> : <></>}


                      <p style={{ margin: '5px 0px' }}>Pagador: {sale?.client_id_response?.name}</p>
                      <p style={{ margin: '5px 0px' }}> Valor da parcela: R$ {format(currentValue)}</p>
                      <p style={{ margin: '5px 0px' }}> Juros: R$ {format(juros)}</p>
                      <p style={{ margin: '5px 0px' }}> Multa: R$ {format(multa)}</p>
                      <p style={{ margin: '5px 0px' }}> Total: R$ {format(total)}</p>
                      <p style={{ margin: '5px 0px' }}>Forma de pagamento: {getPaymentMethod?.[sale?.payment_method_id] || 'Não identificado'}</p>
                      <p style={{ margin: '5px 0px' }}>Status de pagamento: {getPaymentStatus?.[sale?.payment_status_id] || 'Erro ao identificar'}</p>
                      <p style={{ margin: '5px 0px' }}>
                        {
                          !isOutDated && sale?.boleto_url ? <a href={sale?.boleto_url} target="_BLANK"> <button className='defaultButtonMini'>Visualizar boleto</button> </a> : <></>

                        }</p>

                      <p style={{ margin: '5px 0px' }}>
                        {
                          isOutDated && sale?.payment_method_id === 'boleto_recurring' ? <button className='defaultButtonMini' onClick={() => { askNewBoleto(sale) }}>Emitir novo boleto</button> : <></>

                        }
                        {
                          isOutDated && sale?.payment_method_id === 'credit_card_recurring' ? <button className='defaultButtonMini' onClick={() => { askNewCreditCardPayment(sale) }}>Efetuar pagamento com cartão de crédito</button> : <></>

                        }

                      </p>








                    </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>Nenhum pagamento identificado</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default Pagamentos;
